import { parseNumberLocal } from '../utils';

export type ActionType =
  | 'CLEAN_CHECKOUT_DATA'
  | 'ORDER_CREATE_DATA'
  | 'REQUEST_ORDER_CREATE_DATA'
  | 'RECEIVED_ORDER_CREATE_DATA'
  | 'ERROR_ORDER_CREATE_DATA'
  | 'PAYMENT_SUCCESS_DATA'
  | 'REQUEST_PAYMENT_SUCCESS_DATA'
  | 'RECEIVED_PAYMENT_SUCCESS_DATA'
  | 'ERROR_PAYMENT_SUCCESS_DATA'
  | 'RECOVER_PASSWORD_DATA'
  | 'STRIPE_ERROR_DATA'
  | 'REQUEST_STRIPE_ERROR_DATA'
  | 'RECEIVED_STRIPE_ERROR_DATA'
  | 'ERROR_STRIPE_ERROR_DATA'
  | 'GET_WALLET_CODES'
  | 'REQUEST_WALLET_CODES'
  | 'RECEIVED_WALLET_CODES'
  | 'ERROR_WALLET_CODES'
  | 'GET_EXTRA_NIGHT'
  | 'REQUEST_EXTRA_NIGHT'
  | 'RECEIVED_EXTRA_NIGHT'
  | 'ERROR_EXTRA_NIGHT'
  | 'GET_NOT_CONFIRMED_PAYMENT'
  | 'REQUEST_NOT_CONFIRMED_PAYMENT'
  | 'RECEIVED_NOT_CONFIRMED_PAYMENT'
  | 'ERROR_NOT_CONFIRMED_PAYMENT'
  | 'GET_CONFIRMED_PAYMENT'
  | 'REQUEST_CONFIRMED_PAYMENT'
  | 'RECEIVED_CONFIRMED_PAYMENT'
  | 'ERROR_CONFIRMED_PAYMENT';
interface TooltipObject {
  description: string;
  priceBeauty: string;
}

interface InsuranceObject {
  id: string;
  type: string;
  multiple?: boolean;
  description: string;
  selected: boolean;
  package: string;
  price: number;
  priceDetails: string;
  priceBeauty: string;
}

const emptyTooltip: TooltipObject = {
  description: '',
  priceBeauty: '',
};

const initialCheckoutState = {
  data: {},
  summary: {
    product: {
      title: '',
      name: '',
      departure_city: '',
      departure_date: '',
      arrival_date: '',
    },
    offer: {
      days: 0,
    },
    price: {
      flightPriceBeauty: '',
      flightPricePerPaxBeauty: '',
      totalBeauty: '',
      totalPerPaxBeauty: '',
    },
    includes: [],
    category: {
      type: '',
      name: '',
    },
    details: {
      passengersCount: 0,
    },
    pax: {
      passengers: 0,
      adults: 0,
      children: 0,
      babies: 0,
    },
    breakDown: {
      campaign: {
        description: '',
        priceBeauty: '',
        price: 0,
      },
      accommodation: {
        description: '',
        priceBeauty: '',
        price: 0,
      },
      insurances: [],
      options: [],
      donate: {},
      percentage: {
        currency: '',
        percentage: 0,
        price: 0,
      },
      fees: {
        description: '',
        priceBeauty: '',
        price: 0,
        priceDetails: '',
      },
      discount: {},
    },
    flight: {},
  },
  tooltip: emptyTooltip,
  breakdown: {
    campaign: {
      description: '',
      priceBeauty: '',
      price: 0,
    },
    extraNight: {
      nightsAmount: 0,
      priceBeauty: '',
      price: 0,
    },
    accommodation: {
      description: '',
      priceBeauty: '',
      price: 0,
    },
    insurances: [],
    options: [],
    donate: {
      description: '',
      priceBeauty: '',
      price: 0,
    },
    paymentFee: {
      percentage: 0,
      price: 0,
      description: '',
      priceBeauty: '',
      priceDetails: '',
    },
    fees: {
      description: '',
      priceBeauty: '',
      price: 0,
      priceDetails: '',
    },
    discount: {
      description: '',
      priceBeauty: '',
      price: 0,
    },
    wallet: [],
  },
  insurance: {},
  passengers: [],
  payment: [],
  paymentMethodId: 1,
  voucherCode: '',
  currency: {},
  setBudgetModal: { open: false, gtmEvent: '' },
  extraNight: {},
  isFetchingExtraNight: {},
  selectedExtraNightIndex: null,
  isFetching: false,
};

const checkout = (state = initialCheckoutState, action) => {
  const { data, type } = action;

  // Breakdown:
  // id (id of option),
  // type (accommodation, insurance, supplements),
  // multiple (if its a multiple option)
  // title
  // selected
  // price
  // priceDetails
  // priceBeauty

  switch (type) {
    case 'CLEAN_CHECKOUT_DATA':
      return { ...state, ...initialCheckoutState };
    case 'SET_CHECKOUT_SUMMARY_DATA':
      let updatedBreakdown: any = state.breakdown;
      updatedBreakdown.options = updatedBreakdown.options.map((option) => {
        const optionFromUpdatedSummary = data.supplements.supplements.filter(
          ({ id }) => id === option?.id,
        )[0];
        if (optionFromUpdatedSummary) {
          const passengersCount = data.pax?.adults + data.pax?.children;
          const priceTotal =
            passengersCount * parseFloat(optionFromUpdatedSummary?.price);

          const priceBeautyPerPerson = parseNumberLocal(
            parseFloat(optionFromUpdatedSummary?.price),
            2,
            true,
            data.currency,
          );

          const priceBeautyTotal = parseNumberLocal(
            priceTotal,
            2,
            true,
            data.currency,
          );
          const priceDetails = passengersCount + ' * ' + priceBeautyPerPerson;

          return {
            ...option,
            price: optionFromUpdatedSummary.price * passengersCount,
            priceDetails: priceDetails,
            priceBeauty: priceBeautyTotal,
          };
        } else {
          return option;
        }
      });

      const filterAccommodation = [
        ...data?.accommodations?.includes,
        ...data?.accommodations?.supplements,
      ].filter(
        (accommodation) =>
          accommodation.id === updatedBreakdown.accommodation.id,
      );

      const selectedAccommodationData =
        filterAccommodation.length > 0 ? filterAccommodation[0] : {};

      // Update the price of accommodation in the breakdown if it changed with the new summary data
      if (
        selectedAccommodationData?.price !==
        updatedBreakdown?.accommodation?.price
      ) {
        updatedBreakdown.accommodation.price = selectedAccommodationData.price;
        updatedBreakdown.accommodation.priceBeauty = parseNumberLocal(
          selectedAccommodationData.price,
          2,
          true,
          data.currency,
        );
      }

      return {
        ...state,
        summary: data,
        breakdown: updatedBreakdown,
      };
    case 'SET_CHECKOUT_SUMMARY_TOOLTIP':
      return { ...state, tooltip: data };
    case 'CLEAN_CHECKOUT_SUMMARY_TOOLTIP':
      return { ...state, tooltip: emptyTooltip };
    case 'SET_BUDGET_MODAL':
      return { ...state, setBudgetModal: data };
    case 'SET_CHECKOUT_BREAKDOWN_DATA':
      return {
        ...state,
        breakdown: { ...state.breakdown, ...data },
      };
    case 'ADD_CHECKOUT_BREAKDOWN_DATA':
      if (data.multiple) {
        return {
          ...state,
          custom: data.type === 'wallet' ? false : true,
          breakdown: {
            ...state.breakdown,
            [data.type]: data.singleInArray
              ? [data]
              : [...state.breakdown[data.type], data],
          },
        };
      }
      // single
      return {
        ...state,
        custom: data.type === 'wallet' ? false : true,
        breakdown: {
          ...state.breakdown,
          [data.type]: data,
        },
      };
    case 'REMOVE_CHECKOUT_BREAKDOWN_DATA':
      if (data.single) {
        return {
          ...state,
          breakdown: {
            ...state.breakdown,
            [data.type]: {},
          },
          voucherCode: '',
        };
      }
      // multiple
      return {
        ...state,
        breakdown: {
          ...state.breakdown,
          [data.type]: state.breakdown[data.type].filter(
            (el) => el.id !== data.id,
          ),
        },
        voucherCode: '',
      };
    case 'SET_CHECKOUT_PASSENGERS_DATA':
      return { ...state, passengers: data };
    case 'SET_CHECKOUT_INSURANCE_DATA':
      const newBreakdown: any = state.breakdown;
      newBreakdown.insurances.map(
        (insurance: InsuranceObject, index: number) => {
          if (data && data[insurance.package]) {
            const newPrice = parseFloat(data[insurance.package].TotalPremium);

            newBreakdown.insurances[index].price = newPrice;
            newBreakdown.insurances[index].priceBeauty = parseNumberLocal(
              newPrice,
            );
          }

          return null;
        },
      );
      return {
        ...state,
        breakdown: newBreakdown,
        insuranceData: data,
      };
    case 'SET_CHECKOUT_PAYMENT_DATA':
      // Preselect CARD (1) or STRIPE (6) if available
      const preselectedMethods =
        data.methods &&
        data.methods.length > 0 &&
        data.methods.filter((method) => method.id === 1 || method.id === 6);

      return {
        ...state,
        payment: data,
        paymentMethodId:
          preselectedMethods && preselectedMethods.length > 0
            ? preselectedMethods[0].id
            : 0,
      };
    case 'SET_CHECKOUT_PAYMENT_METHOD_DATA':
      return { ...state, paymentMethodId: data };

    case 'SET_CHECKOUT_VOUCHER_CODE_DATA':
      return { ...state, voucherCode: data };

    case 'ERROR_ORDER_CREATE_DATA':
    case 'ERROR_PAYMENT_SUCCESS_DATA':
    case 'ERROR_STRIPE_ERROR_DATA':
    case 'ERROR_EXTRA_NIGHT': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    case 'REQUEST_ORDER_CREATE_DATA':
    case 'REQUEST_PAYMENT_SUCCESS_DATA':
    case 'REQUEST_STRIPE_ERROR_DATA': {
      return {
        ...state,
        isFetching: true,
      };
    }

    case 'RECEIVED_ORDER_CREATE_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: { ...state.data, ...payload },
      };
    }

    case 'RECEIVED_PAYMENT_SUCCESS_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: { ...state.data, payments: payload },
      };
    }

    case 'RECEIVED_STRIPE_ERROR_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: { ...state.data, ...payload },
      };
    }

    case 'RECEIVED_WALLET_CODES': {
      const { payload = {} } = action;
      return {
        ...state,
        wallet: payload,
      };
    }

    case 'REQUEST_EXTRA_NIGHT': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetchingExtraNight: {
          ...state.isFetchingExtraNight,
          [payload.id]: true,
        },
      };
    }

    case 'RECEIVED_EXTRA_NIGHT': {
      const { payload = {} } = action;
      return {
        ...state,
        extraNight: {
          ...state.extraNight,
          [payload.id]: payload,
        },
        isFetchingExtraNight: {
          ...state.isFetchingExtraNight,
          [payload.id]: false,
        },
      };
    }

    case 'SET_EXTRA_NIGHT_SELECTED_INDEX': {
      const selectedExtraNight = state.extraNight[data];
      return {
        ...state,
        custom: true,
        breakdown: {
          ...state.breakdown,
          extraNight: selectedExtraNight
            ? {
                nightsAmount: selectedExtraNight.id,
                price: selectedExtraNight.incrementPrice,
                priceBeauty: selectedExtraNight.incrementPriceBeauty,
              }
            : {
                nightsAmount: 0,
                price: 0,
                priceBeauty: '',
              },
        },
        selectedExtraNightIndex: data,
      };
    }

    case 'SET_EXTRA_NIGHT_AS_NOT_AVAILABLE': {
      return {
        ...state,
        extraNight: {
          ...state.extraNight,
          [data]: {
            id: data,
            incrementPrice: null,
            incrementPriceBeauty: '',
            returnDate: null,
          },
        },
        isFetchingExtraNight: {
          ...state.isFetchingExtraNight,
          [data]: false,
        },
      };
    }

    case 'RECEIVED_NOT_CONFIRMED_PAYMENT': {
      const { payload = {} } = action;
      return {
        ...state,
        notConfirmedPaymentUrl: payload.url,
      };
    }

    case 'RECEIVED_CONFIRMED_PAYMENT': {
      const { payload = {} } = action;
      return {
        ...state,
        confirmedPaymentUrl: payload.url,
      };
    }

    default:
      return state;
  }
};

export default checkout;
