export type ActionType =
  | 'SET_PART_PAYMENT_DATA'
  | 'REQUEST_PART_PAYMENT_DATA'
  | 'RECEIVED_PART_PAYMENT_DATA'
  | 'ERROR_PART_PAYMENT_DATA'
  | 'SET_SECOND_PAYMENT_DATA'
  | 'REQUEST_SECOND_PAYMENT_DATA'
  | 'RECEIVED_SECOND_PAYMENT_DATA'
  | 'ERROR_SECOND_PAYMENT_DATA';

interface ActionProps {
  type: ActionType;
  isFetching?: boolean;
  data?: {} | any;
  error?: any;
  payload?: {} | any;
}

const initialPartPaymentState = {
  isFetching: true,
  data: {},
  error: {},
};

const PartPayment = (
  state = initialPartPaymentState,
  action: Partial<ActionProps>,
) => {
  switch (action.type) {
    case 'REQUEST_PART_PAYMENT_DATA':
    case 'REQUEST_SECOND_PAYMENT_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_PART_PAYMENT_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          ...payload,
        },
      };
    }

    case 'RECEIVED_SECOND_PAYMENT_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          ...payload,
        },
      };
    }

    case 'ERROR_PART_PAYMENT_DATA':
    case 'ERROR_SECOND_PAYMENT_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
};

export default PartPayment;
