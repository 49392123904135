import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Input from './Input';
import Icon from '../Icon';

const InputPasswordWrapper = styled.div`
  position: relative;
`;

const EyeButton = styled.div`
  position: absolute;
  right: 0px;
  width: 44px;
  height: 44px;
  top: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    top: 32px;
  }
`;

const InputPassword = (props: Input) => {
  const { type, ...newProps } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputPasswordWrapper>
      <Input type={showPassword ? 'text' : 'password'} {...newProps} />
      <EyeButton
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <Icon size={20} icon={showPassword ? 'close-eye' : 'open-eye'} />
      </EyeButton>
    </InputPasswordWrapper>
  );
};

InputPassword.displayName = 'InputPassword';

InputPassword.defaultProps = {
  type: 'text',
  error: false,
  success: false,
  width: '100%',
  readOnly: false,
};

export default InputPassword;
