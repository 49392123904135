import { ActionType } from '../reducers/auth';
import { api } from '../config';
import mockData from '../mocks/login.json';
import { setConfigData } from './config';

export function logUser(data, autoLogin) {
  return async (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockData;

    const parameters = Object.entries(data)
      .map(
        (e) =>
          encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1] as string),
      )
      .join('&');
    const randomNumber = Math.random() * 10000;

    const url = autoLogin
      ? `${api.login}/token?rand=${randomNumber}`
      : `${api.login}?rand=${randomNumber}`;

    const method = 'post';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {
      data: parameters,
    };

    const type: ActionType = 'SET_LOG_DATA';

    const response = await dispatch({
      type,
      fetch: {
        type: fetchType,
        actionTypes: {
          request: 'REQUEST_LOG_USER',
          success: 'RECEIVED_LOG_USER',
          fail: 'ERROR_LOG_USER',
        },
        mockResult,
        url,
        method,
        headers,
        options,
      },
    });

    await dispatch(setConfigData(true));
    localStorage.setItem('user_id', response.data.customerId);

    return response;
  };
}

export function loginFacebook(data) {
  return async (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockData;

    const enter_url = sessionStorage.getItem('enter_url');
    const referrer_url = sessionStorage.getItem('referrer_url');
    const utm_campaign = sessionStorage.getItem('utm_campaign');
    const utm_medium = sessionStorage.getItem('utm_medium');
    const utm_source = sessionStorage.getItem('utm_source');

    const parameters = Object.entries({
      ...data,
      enter_url: enter_url ? enter_url.replace(/&/g, '%26') : '',
      referrer_url: referrer_url ? referrer_url.replace(/&/g, '%26') : '',
      utm_campaign: utm_campaign ? utm_campaign : '',
      utm_medium: utm_medium ? utm_medium : '',
      utm_source: utm_source ? utm_source : '',
    })
      .map(
        (e) =>
          encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1] as string),
      )
      .join('&');
    const randomNumber = Math.random() * 10000;

    const url = `${api.loginFacebook}?rand=${randomNumber}`;
    const method = 'post';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {
      data: parameters,
    };

    const type: ActionType = 'SET_LOGIN_FACEBOOK_DATA';
    const response = await dispatch({
      type,
      fetch: {
        type: fetchType,
        actionTypes: {
          request: 'REQUEST_LOGIN_FACEBOOK_USER',
          success: 'RECEIVED_LOGIN_FACEBOOK_USER',
          fail: 'ERROR_LOGIN_FACEBOOK_USER',
        },
        mockResult,
        url,
        method,
        headers,
        options,
      },
    });

    await dispatch(setConfigData(true));

    return response;
  };
}

export function logout() {
  return async (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockData;

    const randomNumber = Math.random() * 10000;

    const url = `${api.logout}?rand=${randomNumber}`;
    const method = 'post';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {};

    const type: ActionType = 'SET_LOG_DATA';

    const response = await dispatch({
      type,
      fetch: {
        type: fetchType,
        actionTypes: {
          request: 'REQUEST_LOG_USER',
          success: 'RECEIVED_LOG_USER',
          fail: 'ERROR_LOG_USER',
        },
        mockResult,
        url,
        method,
        headers,
        options,
      },
    });

    await dispatch(setConfigData(true));

    return response;
  };
}

export const setUserAuthData = (data) => ({
  type: 'SET_USER_AUTH_DATA',
  payload: data,
});
