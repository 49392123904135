import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';

import Icon from '../../components/ui/Icon';

interface Params {
  lang: string;
}

const ErrorPage = styled.div<{ bgImage: string }>`
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.colors.mineShaftDarker};
  background-image: url(${(props) => props.bgImage});
  height: 100vh;
  background-size: cover;
  background-position: center;
  text-align: center;
`;

const CenteredElements = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled(CenteredElements)`
  margin: 26px 0 12px 0;
`;

const SomethingWentWrong = styled(CenteredElements)`
  font-size: 1.875rem;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1.625rem;
  }
  letter-spacing: 0.63px;
  font-weight: 600;
`;

const MeanWhile = styled(CenteredElements)`
  font-size: 1.25rem;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1rem;
  }
  letter-spacing: 0.42px;
`;

const TryList = styled(MeanWhile)`
  flex-direction: column;
  margin-top: 32px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-top: 20px;
  }
  div {
    ::before {
      content: '•';
      font-size: 1.563rem;
      padding: 7px;
    }
  }
`;

function Error500() {
  const { i18n, t } = useTranslation();
  const { market } = useParams();
  useEffect(() => {
    const finalLang =
      market === 'es'
        ? 'es-ES'
        : market === 'fr'
        ? 'fr-FR'
        : market === 'de'
        ? 'de-DE'
        : market === 'en'
        ? 'en-GB'
        : '';
    i18n.changeLanguage(finalLang);
    window.prerenderReady = true;
  }, [market, i18n]);

  const themeStyled = useContext(ThemeContext);

  const rand = Math.ceil(Math.random() * 3);
  const bgImage = require(`../../assets/images/errorPages/404-500-bg-${rand}.jpg`);

  return (
    <ErrorPage bgImage={bgImage}>
      <Container maxWidth={false}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid container item xs={12}>
            <IconWrapper>
              <Icon
                icon={'logo'}
                iconSet={'main'}
                color={themeStyled.colors.textFooterColor}
                customStyle={{
                  width: '179px',
                  height: '37px',
                }}
              />
            </IconWrapper>
            <SomethingWentWrong>
              {t('Uops, parece que algo ha ido mal.')}
            </SomethingWentWrong>
            <MeanWhile>
              {t('Mientras nosotros solucionamos el problema, tú puedes')}:
            </MeanWhile>
            <TryList>
              <div>{t('Refrescar la página (a veces funciona)')}</div>
              <div>{t('Llamarnos al 919 01 15 89')}</div>
              <div>
                {t(
                  'Enviarnos un email a viajar@exoticca.com (si todavía no eres cliente).',
                )}
              </div>
              <div>
                {t(
                  'Enviarnos un email a clientes@exoticca.com (si ya tienes una reserva).',
                )}
              </div>
            </TryList>
          </Grid>
        </Grid>
      </Container>
    </ErrorPage>
  );
}

export default Error500;
