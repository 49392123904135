import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';
import { LayoutContext } from '../../../context/LayoutContext';

interface CampaignInfoProps {
  country: string;
  length: number;
  name: string;
  colors?: string[];
  isBannerCheckoutTablet?: boolean;
}

interface FontProp {
  fontSize: string;
  color: string[];
}

interface LengthProp {
  fontSize: string;
  color: string;
  bold: boolean;
}

export const CampaignInfoDesktopWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.mineShaftDarker};
  width: 100%;
  min-height: 50px;
  border-radius: 5px 5px 0px 0px;
  padding: 8px 16px;
`;

export const CampaignInfoTabletWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  padding: 0px 24px;
  vertical-align: middle;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const CampaignInfoMobileWrapper = styled.div`
  display: inline-block;
  width: 100%;
  min-height: 50px;
  padding: 0px 15px;
`;

export const Country = styled.div<FontProp>`
  display: inline-block;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.fontSize};
  font-weight: 600;
  text-transform: uppercase;
  color: ${(props) =>
    props.theme.colors.mainNavIndicatorColor
      ? props.theme.colors.mainNavIndicatorColor
      : props.theme.colors[props.color]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    color: ${(props) =>
      props.theme.colors.mainNavIndicatorColor
        ? props.theme.colors.mainNavIndicatorColor
        : props.theme.colors[props.color]};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    color: ${(props) =>
      props.theme.colors.mainNavIndicatorColor
        ? props.theme.colors.mainNavIndicatorColor
        : props.theme.colors[props.color]};
  }
`;

export const Dot = styled.div`
  display: inline-block;
  background-color: ${(props) => props.theme.colors.white50};
  margin: 2px 10px;
  width: 7px;
  height: 7px;
  opacity: 0.5;
  border-radius: 7px;
`;

export const Line = styled.div`
  width: 16px;
  height: 1px;
  opacity: 0.5;
  border: solid 1px ${(props) => props.theme.colors.golden};
  margin: 8px 0px;
`;

export const Length = styled.div<LengthProp>`
  display: inline-block;
  color: ${(props) =>
    props.theme.colors.mainNavIndicatorColor
      ? props.theme.colors.mainNavIndicatorColor
      : props.theme.colors[props.color]};
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => (props.bold ? '600' : '500')};
`;

export const Name = styled.div<FontProp>`
  font-size: ${(props) => props.fontSize};
  font-weight: 400;
  color: ${(props) => props.theme.colors[props.color]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    color: ${(props) => props.theme.colors[props.color]};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    color: ${(props) => props.theme.colors[props.color]};
  }
`;

function CampaignInfo(props: CampaignInfoProps) {
  const { country, length, name, colors, isBannerCheckoutTablet } = props;

  const layout = useContext(LayoutContext);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  let Wrapper;
  let countryFontSize: string;
  let nameFontSize: string;
  let lengthColor: string;
  let countryColor: string;
  let nameColor: string;

  const LengthColor =
    colors === undefined ? ['whiteCreamy', 'golden', 'grandis'] : colors;
  const NameColors =
    colors === undefined
      ? ['whiteCreamy', 'whiteCreamy', 'whiteCreamy']
      : colors;
  const CountryColors = ['whiteCreamy', 'grandis', 'grandis'];

  const DesktopLength = LengthColor[2];
  const TabletLength = LengthColor[0];
  const MobileLength = LengthColor[1];

  const DesktopName = NameColors[2];
  const TabletName = NameColors[0];
  const MobileName = NameColors[1];

  const DesktopCountry = CountryColors[2];
  const TabletCountry = CountryColors[0];
  const MobileCountry = CountryColors[1];

  switch (layout) {
    case 'tablet':
      Wrapper = CampaignInfoTabletWrapper;
      countryFontSize = '16px';
      nameFontSize = '14px';
      lengthColor = TabletLength;
      countryColor = TabletCountry;
      nameColor = TabletName;
      break;
    case 'mobile':
      Wrapper = CampaignInfoMobileWrapper;
      countryFontSize = '16px';
      nameFontSize = '13px';
      lengthColor = MobileLength;
      countryColor = MobileCountry;
      nameColor = MobileName;
      break;
    case 'desktop':
    default:
      Wrapper = CampaignInfoDesktopWrapper;
      countryFontSize = '18px';
      nameFontSize = '17px';
      lengthColor = DesktopLength;
      countryColor = DesktopCountry;
      nameColor = DesktopName;
      break;
  }

  return (
    <Wrapper>
      <div>
        <Country
          fontSize={countryFontSize}
          color={countryColor as string & string[]}
        >
          {country}
        </Country>
        {!isBannerCheckoutTablet ? (
          layout === 'desktop' || layout === 'tablet' ? (
            <Dot />
          ) : (
            <br />
          )
        ) : (
          ''
        )}
        {!isBannerCheckoutTablet && (
          <Length
            fontSize={countryFontSize}
            color={lengthColor}
            bold={layout !== 'mobile'}
          >
            {length + ' ' + t('checkout@days')}
          </Length>
        )}
      </div>
      {layout === 'mobile' && <Line />}
      <div>
        {isBannerCheckoutTablet && (
          <>
            {' '}
            <Length
              fontSize={countryFontSize}
              color={lengthColor}
              bold={layout !== 'mobile'}
            >
              {length + ' ' + t('checkout@days')}
            </Length>
            <Dot />{' '}
          </>
        )}
        <Name fontSize={nameFontSize} color={nameColor as string & string[]}>
          {name}
        </Name>
      </div>
    </Wrapper>
  );
}

export default CampaignInfo;
