const loading = (state = {}, action) => {
  const { data, type } = action;

  switch (type) {
    case 'SET_LOADING_DATA':
      return { ...state, ...data };
    default:
      return state;
  }
};

export default loading;
