declare global {
  interface Window {
    fbAsyncInit: any;
    FB: {
      init(data: {
        appId: string;
        cookie: boolean;
        xfbml: boolean;
        version: string;
      }): void;
      Event: { subscribe: any; unsubscribe: any };
    };
    adiInit: string;
    adiRVO: boolean;
    adiFunc: any;
    rTapPostReplacement(): void;
  }
}

export const initFacebook = (appId: string, locale: string) => {
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: appId,
      cookie: true,
      xfbml: true,
      version: 'v7.0',
    });
  };
  ((d, s, id) => {
    const element: HTMLScriptElement = d.getElementsByTagName(
      s,
    )[0] as HTMLScriptElement;
    const fjs = element;
    let js = element;
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s) as HTMLScriptElement;
    js.id = id;
    js.async = true;
    js.defer = true;
    js.crossOrigin = 'anonymous';
    js.src = `https://connect.facebook.net/${locale}/sdk.js`;
    if (fjs && fjs.parentNode) {
      fjs.parentNode.insertBefore(js, fjs);
    }
  })(document, 'script', 'facebook-jssdk');
};

export const initTrustedShops = (trustedShopsId: string) => {
  (function () {
    const _tsid = trustedShopsId;
    //@ts-ignore
    window._tsConfig = {
      yOffset: '' /* offset from page bottom */,
      variant: 'custom_reviews' /* reviews, default, custom, custom_reviews */,
      responsive: {
        variant: 'custom_reviews',
        customElementId: 'TrustedShopBadge',
      },
      customElementId:
        'TrustedShopBadge' /* required for variants custom and custom_reviews */,
      trustcardDirection:
        '' /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */,
      customBadgeWidth: '' /* for custom variants: 40 - 90 (in pixels) */,
      customBadgeHeight: '60' /* for custom variants: 40 - 90 (in pixels) */,
      disableResponsive: 'false' /* deactivate responsive behaviour */,
      disableTrustbadge: 'false' /* deactivate trustbadge */,
    };
    let _ts = document.createElement('script');
    _ts.type = 'text/javascript';
    _ts.charset = 'utf-8';
    _ts.async = true;
    _ts.src = 'https://widgets.trustedshops.com/js/' + _tsid + '.js';
    let __ts = document.getElementsByTagName('script')[0];
    if (__ts && __ts.parentNode) {
      __ts.parentNode.insertBefore(_ts, __ts);
    }
  })();
};

export const initTrustPilot = () => {
  (function () {
    let _ts = document.createElement('script');
    _ts.type = 'text/javascript';
    _ts.charset = 'utf-8';
    _ts.async = true;
    _ts.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    let __ts = document.getElementsByTagName('script')[0];
    if (__ts && __ts.parentNode) {
      __ts.parentNode.insertBefore(_ts, __ts);
    }
  })();
};

export const initResponseTap = (adiInit: string) => {
  window.adiInit = adiInit;
  window.adiRVO = true;
  window.adiFunc = null;
  window.rTapPostReplacement = function () {
    var clientId = localStorage.getItem('client_id');
    var marketId = localStorage.getItem('market_id');
    window.adiFunc.pushVariable(0, clientId);
    window.adiFunc.pushVariable(1, marketId);
    window.adiFunc.flushVariables();
  };
  (function () {
    let adiSrc = document.createElement('script');
    adiSrc.type = 'text/javascript';
    adiSrc.async = true;
    adiSrc.src =
      ('https:' === document.location.protocol
        ? 'https://static-ssl'
        : 'http://static-cdn') +
      '.responsetap.com/static/scripts/rTapTrack.min.js';
    var s = document.getElementsByTagName('script')[0];
    if (s && s.parentNode) {
      s.parentNode.insertBefore(adiSrc, s);
    }
  })();
};

export const initAffirm = (affirm) => {
  const _affirm_config = affirm;
  (function (l, g, m, e, a: number | string, f, b: any) {
    var d,
      c = l[m] || {},
      h: any = document.createElement(f),
      n = document.getElementsByTagName(f)[0],
      k = function (a, b, c) {
        return function () {
          a[b]._.push([c, arguments]);
        };
      };
    c[e] = k(c, e, 'set');
    d = c[e];
    c[a] = {};
    c[a]._ = [];
    d._ = [];
    c[a][b] = k(c, a, b);
    a = 0;
    for (
      b = 'set add save post open empty reset on off trigger ready setProduct'.split(
        ' ',
      );
      a < b.length;
      a++
    )
      d[b[a]] = k(c, e, b[a]);
    a = 0;
    for (b = ['get', 'token', 'url', 'items']; a < b.length; a++)
      d[b[a]] = function () {};
    h.async = !0;
    h.src = g[f];
    if (n && n.parentNode) {
      n.parentNode.insertBefore(h, n);
    }
    delete g[f];
    d(g);
    l[m] = c;
  })(window, _affirm_config, 'affirm', 'checkout', 'ui', 'script', 'ready');
};
