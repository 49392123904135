import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import CategoryPassengers from './CategoryPassengers';
import FlightDetails from './FlightDetails';
import PriceIncludes from './PriceIncludes';
import TravelBreakdownDetails from './TravelBreakdownDetails';
import Accordian from '../../ui/Accordian';
import { LayoutContext } from '../../../context/LayoutContext';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';
import SplitPaymentTotalPrice from './SplitPaymentTotalPrice';
import DiscountTotalPrice from './DiscountTotalPrice';
import Grid from '@material-ui/core/Grid';
import { parseNumberLocal } from '../../../utils';
import { getTotalPriceFromState } from '../../../actions/checkout';

interface TravelDetailsProps {
  promotion: any;
  splitPaymentPercentage: number;
  includes: any;
  category: any;
  pax: any;
  flight: any;
  product: any;
  offer?: any;
  breakdown: any;
  tooltip: any;
  market: string;
  phone: string;
}

interface StyledProps {
  layout: string;
}

export const TravelDetailsWrapper = styled.div<StyledProps>`
  margin-bottom: ${(props) => (props.layout === 'desktop' ? '0px' : '86px')};
  width: 100%;

  ${(props) =>
    props.layout === 'desktop' &&
    `
    min-height: 240px;`}

  ${(props) =>
    props.layout === 'tablet' &&
    `
    min-height: 620px;`}
`;

const TotalPriceWrapper = styled.div`
  margin: 16px 0;
  padding-top: 16px;
  border-top: ${(props) => `3px solid ${props.theme.colors.grayDark}`};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin: 0 0 16px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-top: 8px;
    margin: 0 0 8px;
  }
`;

export const Total = styled.div`
  font-weight: 600;
  font-size: 1rem;
  padding: 0 16px;
`;

const TotalText = styled.div`
  display: inline-block;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;
`;

export const TotalPerPerson = styled.div`
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  margin-left: 10px;
`;

function TravelDetails(props: TravelDetailsProps) {
  const {
    promotion,
    splitPaymentPercentage,
    includes,
    category,
    pax,
    flight,
    product,
    offer,
    breakdown,
    tooltip,
    market,
    phone,
  } = props;

  const layout = useContext(LayoutContext);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const totalPrice = getTotalPriceFromState(breakdown);
  const passengersCount = pax.adults + pax.children + pax.babies;

  const totalBeauty = parseNumberLocal(totalPrice > 0 ? totalPrice : 0);

  const totalPriceWithoutDiscount = getTotalPriceFromState({
    ...breakdown,
    discount: {},
    wallet: [],
  });

  const totalPriceWithoutWallet = getTotalPriceFromState({
    ...breakdown,
    wallet: {},
  });

  const totalPriceWithoutDiscountBeauty = parseNumberLocal(
    totalPriceWithoutDiscount > 0 ? totalPriceWithoutDiscount : 0,
  );
  const totalPriceWithoutDiscountPerPax =
    totalPriceWithoutDiscount / passengersCount;
  const totalPriceWithoutDiscountPerPaxBeauty = parseNumberLocal(
    totalPriceWithoutDiscountPerPax > 0 ? totalPriceWithoutDiscountPerPax : 0,
  );

  const getMobileTabletTravelDetails = () => {
    return (
      <TravelDetailsWrapper layout={layout}>
        <CategoryPassengers
          category={category}
          pax={pax}
          flight={flight}
          market={market}
        />
        <FlightDetails
          flight={flight}
          market={market}
          departureCityIata={offer?.departureCity}
          phone={phone}
        />
        <TotalPriceWrapper>
          <Total>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={8}>
                <TotalText>{t('checkout@total')}</TotalText>
                <TotalPerPerson
                  dangerouslySetInnerHTML={{
                    __html:
                      totalPriceWithoutDiscountPerPaxBeauty +
                      ' x ' +
                      passengersCount,
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: totalPriceWithoutDiscountBeauty,
                  }}
                />
              </Grid>
            </Grid>
          </Total>
          {(breakdown?.discount?.priceBeauty ||
            breakdown?.wallet?.length > 0) && (
            <DiscountTotalPrice
              discount={breakdown.discount}
              wallet={breakdown.wallet}
              total={totalBeauty}
              totalWithoutWallet={totalPriceWithoutWallet}
              promotion={promotion}
            />
          )}
          {splitPaymentPercentage && totalPrice > 0 && (
            <SplitPaymentTotalPrice
              text={
                t('splitTotal') &&
                t('splitTotal').replace(
                  '#TOKEN#',
                  splitPaymentPercentage.toString(),
                )
              }
              total={parseNumberLocal(
                (totalPrice * splitPaymentPercentage) / 100,
              )}
            />
          )}
        </TotalPriceWrapper>
        <TravelBreakdownDetails
          breakdown={breakdown}
          product={product}
          offer={offer}
          includes={includes}
          promotionColor={promotion?.styling?.checkout?.color}
          tooltip={tooltip}
        />
        <PriceIncludes product={product} offer={offer} includes={includes} />
      </TravelDetailsWrapper>
    );
  };

  const getDesktopTravelDetails = () => {
    return (
      <Accordian isVisible={true} wrapped={false}>
        <TravelDetailsWrapper layout={layout}>
          <CategoryPassengers
            category={category}
            pax={pax}
            flight={flight}
            market={market}
          />
          <FlightDetails
            flight={flight}
            market={market}
            departureCityIata={offer?.departureCity}
            phone={phone}
          />
        </TravelDetailsWrapper>
      </Accordian>
    );
  };

  let content: object;
  switch (layout) {
    case 'desktop':
      content = getDesktopTravelDetails();
      break;
    case 'mobile':
    case 'tablet':
    default:
      content = getMobileTabletTravelDetails();
      break;
  }

  return <>{content}</>;
}

export default TravelDetails;
