import { ActionType } from '../reducers/register';
import { api } from '../config';

export function registerUser(data) {
  return async (dispatch) => {
    const parameters = Object.entries(data)
      .map(
        (e) =>
          encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1] as string),
      )
      .join('&');
    const randomNumber = Math.random() * 10000;

    const url = `${api.register}?rand=${randomNumber}`;
    const method = 'post';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {
      data: parameters,
    };

    const type: ActionType = 'SET_REGISTER_DATA';
    return dispatch({
      type,
      fetch: {
        type: 'FETCH',
        actionTypes: {
          request: 'REQUEST_REGISTER_USER',
          success: 'RECEIVED_REGISTER_USER',
          fail: 'ERROR_REGISTER_USER',
        },
        url,
        method,
        headers,
        options,
      },
    });
  };
}
