import React, { useEffect, useContext } from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { configActions, authActions } from '../actions';
import { AuthContext } from './AuthContext';
import { Helmet } from 'react-helmet';
import appConfig from '../config';
import { getCookie, setCookie, enableScroll } from '../utils';
import {
  initFacebook,
  initResponseTap,
  initTrustedShops,
} from '../utils/scripts';

interface ConfigProviderProps {
  config: any;
  setConfigData: any;
  children: any;
  loginUser(data: any, autoLogin?: boolean): any;
}

function ConfigProvider(props: ConfigProviderProps) {
  const { setConfigData, loginUser, config, children } = props;

  const { setAuthData } = useContext(AuthContext);

  // Enabling scroll on history back
  useEffect(() => {
    window.onpopstate = () => {
      enableScroll();
    };
  }, []);

  // Handle Parameters
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const auaParam = urlParams.get('aua');
    const utmMedium = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign');
    const utmSource = urlParams.get('utm_source');

    utmCampaign && sessionStorage.setItem('utm_campaign', utmCampaign);
    utmMedium && sessionStorage.setItem('utm_medium', utmMedium);
    utmSource && sessionStorage.setItem('utm_source', utmSource);

    if (
      window.location.origin.includes('sales') &&
      !getCookie('exoticca_sales_domain') &&
      auaParam === null
    ) {
      window.location.assign(
        `${window.location.origin.replace(/sales-|sales./, '')}${
          window.location.pathname
        }`,
      );
    } else if (auaParam) {
      const fetch = async () => {
        try {
          const response = await loginUser(
            {
              customer: auaParam,
            },
            true,
          );

          if (response.data.error) throw response;

          if (response.data?.hasOwnProperty('customerId')) {
            localStorage.setItem('user_id', response.data.customerId);
          }

          if (response.data?.hasOwnProperty('token')) {
            setAuthData({
              isLoggedIn: true,
              authToken: response.data.token,
            });

            setCookie('exoticca_sales_domain', '1');
            urlParams.delete('aua');
          }
        } catch (e) {
          console.log(e);
          setCookie('exoticca_sales_domain', '1');
        }
      };

      fetch();
    }
  }, [setAuthData, loginUser]);

  useEffect(() => {
    const fetch = async () => {
      const response = await setConfigData();
      const country = response.data.market;
      const locale =
        response.data.language.length > 0 && response.data.language[0].locale;

      let newObj: any = {};
      const filteredCountryConfig = Object.keys(appConfig)
        .filter((key) => country.includes(key))
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: appConfig[key],
          };
        }, newObj);

      if (Object.keys(filteredCountryConfig).length !== 0) {
        const obj =
          filteredCountryConfig[Object.keys(filteredCountryConfig)[0]];

        // Facebook
        if (obj.facebook.appId && locale) {
          initFacebook(obj.facebook.appId, locale);
        }

        //Trusted Shops
        if (obj.trustedShopsId) {
          initTrustedShops(obj.trustedShopsId);
        }

        // Response tap
        if (obj.responseTap) {
          initResponseTap(obj.responseTap.adiInit);
        }
      }
    };

    fetch();
  }, [setConfigData]);

  if (config.isFetching) {
    return <></>;
  }

  const favicon = config.data.favicon || 'exoticca.ico';

  return (
    <>
      <Helmet>
        <link rel="shortcut icon" href={`/img/${favicon}`} />
        <link rel="icon" type="image/png" href={`/img/${favicon}`} />
        <link
          type="image/x-icon"
          rel="Shortcut Icon"
          href={`/img/${favicon}`}
        />
        <link
          rel="apple-touch-icon"
          href={`${window.location.origin}/img/logos/thumbnail.jpg`}
        />
        <link
          rel="image_src"
          href={`${window.location.origin}/img/logos/thumbnail.jpg`}
        />
      </Helmet>
      {children}
    </>
  );
}

const mapStateToProps = ({ config }) => ({
  config,
});

const mapDispatchToProps = (dispatch) => ({
  setConfigData: () => dispatch(configActions.setConfigData()),
  loginUser: (data: any, autoLogin?: boolean) =>
    dispatch(authActions.logUser(data, autoLogin)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MappedStateToProps = ConnectedProps<typeof connector>;
export type MappedDispatchToProps = {};

export default compose<any>(connector)(ConfigProvider);
