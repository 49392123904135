import React from 'react';
import styled from 'styled-components/macro';

interface StyledInputCheckBoxProps {
  error?: boolean;
  color?: string;
  size?: number;
}

interface InputCheckBoxProps extends StyledInputCheckBoxProps {
  name: string;
  label?: string;
  children?: any;
  checked?: boolean;
  disabled?: boolean;
  styles?: object;
  onClick?(e: any): void;
  onChange?(e: any): void;
}

const InputCheckboxLabel = styled.label<StyledInputCheckBoxProps>`
  display: inline-block;
  position: relative;
  padding-left: 40px;
  font-size: 0.813rem;
  height: ${(props) => (props.size ? `${props.size}px` : '24px')};
  font-weight: 400;
  text-align: left;
  user-select: none;
  cursor: pointer;
  color: ${(props) =>
    props.error ? props.theme.colors.red : props.theme.colors.mineShaft};
  &:hover input ~ .checkmark {
    background-color: ${(props) => props.theme.colors.green};
  }

  a {
    text-decoration: underline !important;
  }
`;

const CheckMark = styled.span<StyledInputCheckBoxProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${(props) => (props.size ? `${props.size}px` : '24px')};
  width: ${(props) => (props.size ? `${props.size}px` : '24px')};

  border: solid 1px
    ${(props) =>
      props.error
        ? props.theme.colors.red
        : props.color
        ? props.color
        : props.theme.colors.silver};
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 30%;
    top: -6%;
    width: 40%;
    height: 100%;
    border: solid ${(props) => props.theme.colors.white};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const InputCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${CheckMark} {
    border-color: ${(props) =>
      props.color ? props.color : props.theme.colors.green};
    background-color: ${(props) =>
      props.color ? props.color : props.theme.colors.green};
  }

  &:checked ~ ${CheckMark}:after {
    display: block;
  }
`;

const InputCheckBox = (props: InputCheckBoxProps) => {
  const {
    label,
    name,
    checked,
    disabled,
    styles,
    onClick,
    error,
    onChange,
    children,
    color,
    size,
  } = props;

  return (
    <InputCheckboxLabel error={error} style={{ ...styles }}>
      <InputCheckbox
        onClick={onClick}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        name={name}
        color={color}
        size={size}
      />
      <CheckMark error={error} color={color} size={size} />
      {label && <span dangerouslySetInnerHTML={{ __html: label }} />}
      {children}
    </InputCheckboxLabel>
  );
};

InputCheckBox.displayName = 'InputCheckBox';

export default InputCheckBox;
