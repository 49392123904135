import React, { useContext, Fragment, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import FlightInfoDialogModal from './FlightInfoDialogModal';
import Icon from '../../ui/Icon';
import FlightsList from './FlightsList';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';
import { LayoutContext } from '../../../context/LayoutContext';

interface StyledProps {
  layout: string;
}

const ModalHeader = styled.div<StyledProps>`
  background-color: ${(props) => props.theme.colors.mineShaftDarker};
  width: 100%;
  min-height: 50px;
  padding: 15px;
  display: flex;
  span {
    color: ${(props) => props.theme.colors.whiteCreamy};
    font-size: 1.063rem;
    font-weight: 500;
    margin: auto;
  }
`;

const ModalContent = styled.div<StyledProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: ${(props) => (props.layout === 'mobile' ? '100%' : '66vh')};
  overflow-y: scroll;
  overflow-x: hidden;
`;

const MainFlight = styled.div<StyledProps>`
  background-color: ${(props) => props.theme.colors.ferra};
  height: 68px;
  width: 100%;
  padding: 22px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  ${(props) =>
    props.layout === 'mobile' &&
    `
    height: unset;
    min-height: 68px;
    flex-wrap: nowrap;
    padding: 5px 30px;
    margin-bottom: 0;`}
`;

const FlightCaptionDuration = styled.div<StyledProps>`
  color: ${(props) => props.theme.colors.mineShaft};
  padding: 0 20px;
  ${(props) =>
    props.layout !== 'mobile' &&
    `
      border-right: 2px dotted ${props.theme.colors.mineShaft};`}
  ${(props) =>
    props.layout === 'mobile' &&
    `
      width: 100%;`}
`;

const FlightCaptionWrapper = styled.div<StyledProps>`
  display: flex;
  align-items: center;
`;

const FlightCaptionAirportWrapper = styled(FlightCaptionWrapper)`
  ${(props) =>
    props.layout === 'mobile' &&
    `
      flex: 1 1 100%;`}
`;

const FlightCaption = styled.div`
  font-size: 1rem;
  font-weight: 500;
`;

const FlightCaptionAirport = styled.div<StyledProps>`
  color: ${(props) => props.theme.colors.mineShaft};
  padding: 0 20px;
  ${(props) =>
    props.layout === 'mobile' &&
    `
      padding: 5px 0;`}
  font-size: 1rem;
  max-width: 210px;
`;

const WhiteCircle = styled.div<StyledProps>`
  ${(props) =>
    props.layout !== 'mobile' &&
    `
    border-radius: 100%;
    background-color: ${props.theme.colors.whiteCreamy};`}
  width: 22px;
  height: 22px;
  padding: 0 5px;
  transform: rotate(180deg);
  div {
    vertical-align: unset;
  }
  ${(props) =>
    props.layout === 'mobile' &&
    `
      margin: 0 10px;`}
`;

const InternalFlight = styled(MainFlight)`
  background-color: rgba(0, 0, 0, 0.2);
  margin: 0 15px 22px;
  ${FlightCaptionDuration} {
    height: 44px;
    display: flex;
    align-items: center;
    border-color: ${(props) => props.theme.colors.golden};
  }
  ${(props) =>
    props.layout === 'mobile' &&
    `
    padding: 0;
    background-color: transparent;
    border-top: 2px solid ${props.theme.colors.mineShaft};
    border-bottom: 2px solid ${props.theme.colors.mineShaft};`}
`;

interface FlightInfoDialogProps {
  show: boolean;
  flight;
  toggle?: any;
}

function FlightInfoDialog(props: FlightInfoDialogProps) {
  const { show, flight } = props;

  const theme = useContext(ThemeContext);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);
  const layout = useContext(LayoutContext);

  const getFlightHeader = (
    iconSize: number,
    icon: string,
    dFlight: [any],
    outbound: boolean = false,
  ) => {
    const title = outbound ? t('checkout@outboundFlight') : t('inboundFlight');
    return (
      <MainFlight layout={layout}>
        <FlightCaptionWrapper layout={layout}>
          <Icon color={theme.colors.mineShaft} size={iconSize} icon={icon} />
        </FlightCaptionWrapper>
        <FlightCaptionAirportWrapper layout={layout}>
          <FlightCaptionDuration layout={layout}>
            <FlightCaption>{title}</FlightCaption>
          </FlightCaptionDuration>
          <FlightCaptionAirport layout={layout}>
            <strong>{dFlight[0].DairportCity}</strong> (
            {dFlight[0].DairportIATA})
          </FlightCaptionAirport>
          <WhiteCircle layout={layout}>
            <Icon color={theme.colors.mineShaft} size={11} icon="backArrow" />
          </WhiteCircle>
          <FlightCaptionAirport layout={layout}>
            <strong>{dFlight[dFlight.length - 1].AairportCity}</strong> (
            {dFlight[dFlight.length - 1].AairportIATA})
          </FlightCaptionAirport>
        </FlightCaptionAirportWrapper>
      </MainFlight>
    );
  };

  return (
    <FlightInfoDialogModal
      pose={show ? 'left' : 'right'}
      isVisible={show}
      layout={layout}
      onClose={props.toggle}
    >
      <ModalHeader layout={layout}>
        <span>{t('flightsDetails')}</span>
      </ModalHeader>
      <ModalContent layout={layout}>
        {getFlightHeader(29, 'plane', flight.departure.segments, true)}
        <FlightsList flightsList={flight.departure.segments} />

        {flight?.intermediate?.segments && (
          <Fragment>
            <InternalFlight layout={layout}>
              <FlightCaptionWrapper layout={layout}>
                <Icon
                  color={theme.colors.mineShaft}
                  size={49}
                  icon="internal"
                />
                <FlightCaptionDuration layout={layout}>
                  <FlightCaption>{t('checkout@internalFlight')}</FlightCaption>
                </FlightCaptionDuration>
              </FlightCaptionWrapper>
              <FlightCaptionAirport layout={layout}>
                <strong>
                  {flight.intermediate.segments[0][0].DairportCity}
                </strong>{' '}
                ({flight.intermediate.segments[0][0].DairportIATA})
              </FlightCaptionAirport>
              <WhiteCircle layout={layout}>
                <Icon
                  color={theme.colors.mineShaft}
                  size={11}
                  icon="backArrow"
                />
              </WhiteCircle>
              <FlightCaptionAirport layout={layout}>
                <strong>
                  {flight.intermediate.segments[0][0].AairportCity}
                </strong>{' '}
                ({flight.intermediate.segments[0][0].AairportIATA})
              </FlightCaptionAirport>
            </InternalFlight>
            <FlightsList flightsList={flight.intermediate.segments[0]} />
          </Fragment>
        )}

        {getFlightHeader(29, 'plane-back', flight.return.segments)}
        <FlightsList flightsList={flight.return.segments} />
      </ModalContent>
    </FlightInfoDialogModal>
  );
}

export default FlightInfoDialog;
