import { ActionType } from '../reducers/myaccount';
import { api } from '../config';
import mockWalletData from '../mocks/wallet.json';
import mockWalletMovementsData from '../mocks/walletMovements.json';
import mockWishListData from '../mocks/wishlist.json';

export const setMyOrdersData = (action, id) => {
  return (dispatch) => {
    const url = `${api.myaccount.myOrders}/${id}/${action}`;
    const method = 'get';

    const type: ActionType = 'SET_ORDER_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: 'FETCH',
          actionTypes: {
            request: 'REQUEST_ORDER_DATA',
            success: 'RECEIVED_ORDER_DATA',
            fail: 'ERROR_ORDER_DATA',
          },
          url,
          method,
        },
      }),
    );
  };
};

export const getMyProfileData = (id) => {
  return (dispatch) => {
    const url = `${api.myaccount.myProfile}/${id}/personal`;
    const method = 'get';

    const type: ActionType = 'GET_PROFILE_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: 'FETCH',
          actionTypes: {
            request: 'REQUEST_PROFILE_DATA',
            success: 'RECEIVED_PROFILE_DATA',
            fail: 'ERROR_PROFILE_DATA',
          },
          url,
          method,
        },
      }),
    );
  };
};

export const setMyProfileData = (data) => {
  return (dispatch) => {
    const url = `${api.myaccount.setProfile}`;
    const method = 'put';
    const options = {
      data: data,
    };

    const type: ActionType = 'SET_PROFILE_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: 'FETCH',
          actionTypes: {
            request: 'REQUEST_SET_PROFILE_DATA',
            success: 'RECEIVED_SET_PROFILE_DATA',
            fail: 'ERROR_SET_PROFILE_DATA',
          },
          url,
          method,
          options,
        },
      }),
    );
  };
};

export const setMyWishListData = (action, id) => {
  return (dispatch) => {
    const mockResult = mockWishListData;

    const url = `${api.myaccount.myWishList}`;
    const method = 'get';

    const type: ActionType = 'SET_WISHLIST_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: 'FETCH',
          actionTypes: {
            request: 'REQUEST_WISHLIST_DATA',
            success: 'RECEIVED_WISHLIST_DATA',
            fail: 'ERROR_WISHLIST_DATA',
          },
          url,
          mockResult,
          method,
        },
      }),
    );
  };
};

export const deleteMyWishListData = (id) => {
  return (dispatch) => {
    const mockResult = { id, data: { success: true } };

    const url = `${api.myaccount.myWishList}/${id}/delete`;
    const method = 'delete';

    const type: ActionType = 'DELETE_WISHLIST_ITEM';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: 'FETCH',
          actionTypes: {
            request: 'REQUEST_DELETE_WISHLIST_ITEM',
            success: 'RECEIVED_DELETE_WISHLIST_ITEM',
            fail: 'ERROR_DELETE_WISHLIST_ITEM',
          },
          url,
          mockResult,
          method,
          options: { id },
        },
      }),
    );
  };
};

export const getMyWalletData = (id) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockWalletData;

    const url = `${api.myaccount.myWallet}/${id}`;
    const method = 'get';

    const type: ActionType = 'GET_WALLET_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_WALLET_DATA',
            success: 'RECEIVED_WALLET_DATA',
            fail: 'ERROR_WALLET_DATA',
          },
          url,
          mockResult,
          method,
        },
      }),
    );
  };
};

export const getMyWalletBalanceData = (id) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockWalletData;

    const url = `${api.myaccount.myWalletBalance}/${id}`;
    const method = 'get';

    const type: ActionType = 'GET_WALLET_BALANCE_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_WALLET_BALANCE_DATA',
            success: 'RECEIVED_WALLET_BALANCE_DATA',
            fail: 'ERROR_WALLET_BALANCE_DATA',
          },
          url,
          mockResult,
          method,
        },
      }),
    );
  };
};

export const getMyWalletMovementsData = (id) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockWalletMovementsData;

    const url = `${api.myaccount.myWalletMovements}/${id}`;
    const method = 'get';

    const type: ActionType = 'GET_WALLET_MOVEMENTS_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_WALLET_MOVEMENTS_DATA',
            success: 'RECEIVED_WALLET_MOVEMENTS_DATA',
            fail: 'ERROR_WALLET_MOVEMENTS_DATA',
          },
          url,
          mockResult,
          method,
        },
      }),
    );
  };
};
