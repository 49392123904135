import React from 'react';
import Error500 from '../../containers/errorPages/Error500';

class ErrorBoundary extends React.Component<
  {},
  { error: any; errorInfo: React.ErrorInfo | null }
> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: React.ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    if (window.location.hash !== '#retry') {
      window.location.hash = '#retry';
      window.location.reload();
    }
  }

  render() {
    if (this.state.errorInfo) {
      return <Error500 />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
