import React, { useContext, Fragment, useEffect } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';
import { LayoutContext } from '../../../context/LayoutContext';

interface Category {
  name: string;
  type: string;
}

interface Pax {
  adults: number;
  children: number;
  babies: number;
}
interface CategoryPassengersProps {
  category: Category;
  pax: Pax;
  flight: any;
  market: string;
}

interface StyledProps {
  layout: string;
}

export const DescriptionLine = styled.div<StyledProps>`
  background-color: transparent;
  color: ${(props) => props.theme.colors.mineShaft};
  padding: 8px 8px 0;
  margin: 1px;
  min-height: 50px;
  font-size: 1.125rem;
  font-weight: 500;
  display: flex;
`;

const DescriptionLineDates = styled(DescriptionLine)`
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  ${(props) =>
    props.layout === 'tablet' &&
    `
    padding-left: 20px;`}
`;

export const LeftRightLine = styled.div<StyledProps>`
  width: 50%;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  ${(props) =>
    props.layout === 'tablet' &&
    `
    border-left: 1px solid ${props.theme.colors.ferra};
    padding-left: 10px;`}
`;

export const LeftText = styled.span`
  font-size: 0.938rem;
  line-height: 1.13;
  font-weight: 600;
  color: ${(props) => props.theme.colors.mineShaftDarker};
`;

export const LeftLine = styled(LeftRightLine)`
  margin-right: auto;
`;

export const RightLine = styled(LeftRightLine)`
  margin-left: auto;
`;

export const RightText = styled.span`
  font-size: 0.938rem;
  line-height: 1.13;
  font-weight: 600;
  color: ${(props) => props.theme.colors.mineShaftDarker};
`;

export const TextValue = styled.div`
  font-size: 0.938rem;
  line-height: 1.21;
  flex: 1 1 100%;
  font-weight: 400;
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
`;

const TextValueDates = styled(TextValue)`
  flex: 1;
  text-align: right;
  text-transform: unset;

  sup {
    font-weight: 600;
  }
`;

function CategoryPassengers(props: CategoryPassengersProps) {
  const { category, pax, flight, market } = props;

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);
  const layout = useContext(LayoutContext);
  const passengersAdults = `${pax.adults} ${
    pax.adults > 1 ? `${t('checkout@adults')}` : `${t('checkout@adult')}`
  }`;
  const passengersChildren = `${pax.children} ${
    pax.children > 1 ? `${t('checkout@children')}` : `${t('checkout@child')}`
  }`;
  const passengersBabies = `${pax.babies} ${
    pax.babies > 1 ? `${t('checkout@babies')}` : `${t('checkout@baby')}`
  }`;

  const dateFormat =
    market.toLocaleLowerCase() === 'us' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';

  const departureDate = moment(flight?.departure?.date).format(dateFormat);
  const returnDate = moment(flight?.return?.date).format(dateFormat);

  const changesDay =
    flight?.return?.changes_day > 0
      ? `+${flight.return.changes_day}`
      : undefined;

  return (
    <Fragment>
      <DescriptionLine layout={layout}>
        <LeftLine layout={layout}>
          <LeftText>{t('checkout@category')}</LeftText>
          <TextValue>{category.name}</TextValue>
        </LeftLine>
        <RightLine layout={layout}>
          <RightText>{t('checkout@passengers')}</RightText>
          <TextValue>
            {passengersAdults} &nbsp;&nbsp;{' '}
            {pax.children > 0 ? `${passengersChildren}` : ``} &nbsp;&nbsp;{' '}
            {pax.babies > 0 ? `${passengersBabies}` : ``}
          </TextValue>
        </RightLine>
      </DescriptionLine>
      <DescriptionLineDates layout={layout}>
        <RightText>{t('checkout@datesOfTravel')}</RightText>
        <TextValueDates>
          {departureDate} - {returnDate}{' '}
          {changesDay ? <sup>{changesDay}</sup> : ''}
        </TextValueDates>
        {changesDay && (
          <TextValueDates>
            {t(
              'checkout@datesOfTravel@differentReturnDay',
              'Arrive back in {{ arrivalAirport }} on a different day. ',
              {
                arrivalAirport: `${flight?.return?.aairportCityName} (${flight?.return?.aairportIATA})`,
              },
            )}
          </TextValueDates>
        )}
      </DescriptionLineDates>
    </Fragment>
  );
}

export default CategoryPassengers;
