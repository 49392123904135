export type ActionType =
  | 'SET_FOOTER_DATA'
  | 'REQUEST_FOOTER_DATA'
  | 'RECEIVED_FOOTER_DATA'
  | 'ERROR_FOOTER_DATA';

interface ActionProps {
  type: ActionType;
  isFetching?: boolean;
  data?: {} | any;
  error?: any;
  payload?: {} | any;
}

const initialFooterState = {
  isFetching: true,
  data: {},
  error: {},
};

const Footer = (state = initialFooterState, action: Partial<ActionProps>) => {
  switch (action.type) {
    case 'REQUEST_FOOTER_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_FOOTER_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: { ...payload },
      };
    }

    case 'ERROR_FOOTER_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
};

export default Footer;
