import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl, getCountryFromUrl } from '../../../utils';

import es from 'react-phone-input-2/lang/es.json';
import fr from 'react-phone-input-2/lang/fr.json';
import de from 'react-phone-input-2/lang/de.json';

type Props = {
  name: string;
  value: string;
  setFieldValue: any;
  customStyle?: any;
};

const PhoneInputWrapper = styled.div`
  .react-tel-input {
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 1rem;
    .form-control {
      height: 46px;
      width: 100%;
      &:focus {
        outline: none;
        box-shadow: unset;
        border-color: ${(props) => props.theme.colors.silver};
      }
    }
    .flag-dropdown {
      background-color: unset;
      border-right: 0;
    }
  }
`;

function InputPhone({ name, value, setFieldValue, customStyle }: Props) {
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const handleOnChange = (value, country) => {
    let dialcodeCount = country.dialCode.replace(/\s+/g, '').length;
    const newValue = `+${value.slice(0, dialcodeCount)}-${value.slice(
      dialcodeCount,
    )}`;
    setFieldValue(name, newValue);
  };

  let phoneInputLocale = {};
  switch (getLocaleFromUrl()) {
    case 'es-ES':
      phoneInputLocale = es;
      break;
    case 'fr-FR':
      phoneInputLocale = fr;
      break;
    case 'de-DE':
      phoneInputLocale = de;
      break;
  }

  const country = getCountryFromUrl().toLocaleLowerCase();

  return (
    <PhoneInputWrapper style={customStyle}>
      <PhoneInput
        inputProps={{
          name: name,
        }}
        value={value}
        placeholder={t('modalBudget@phonePlaceHolder', 'Your phone here')}
        country={country === 'uk' ? 'gb' : country}
        localization={phoneInputLocale}
        onChange={(value, country) => handleOnChange(value, country)}
        countryCodeEditable={false}
        masks={{
          fr: '. ... .... ..',
          ca: '(...) ...-.....',
          us: '(...) ...-.....',
          es: '... ... ....',
          de: '.... .........',
          gb: '.... .......',
        }}
      />
    </PhoneInputWrapper>
  );
}

export default InputPhone;
