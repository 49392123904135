import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';
import Icon from '../../ui/Icon';

interface Props {
  historyBack: string;
  onClick?(): void;
}

const StyledNavBack = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  color: ${(props) => props.theme.colors.mineShaft};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-right: 100px;
  }
`;

const NavBack = ({ historyBack, onClick }: Props) => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const sizeArrow = 17;
  const styledTheme = useContext(ThemeContext);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  return (
    <StyledNavBack
      onClick={() => {
        // TODO: add back when all project migrated
        // Meanwhile: No need to clean the state with an onClick() as we do a full reload of the website when going back
        // onClick && onClick();
        window.location.href = historyBack;
      }}
    >
      <Icon
        color={styledTheme.colors.mineShaft}
        size={sizeArrow}
        icon="backArrow"
        customStyle={{ marginRight: mdUp ? '10px' : '0px' }}
      />
      {mdUp && `${t('checkoutNavBack')}`}
    </StyledNavBack>
  );
};

export default NavBack;
