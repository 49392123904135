import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';

import { LayoutContext } from '../../../context/LayoutContext';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';

interface StyledProps {
  layout?: string;
  isPromotion?: boolean;
  promotionColor?: string;
  bold?: boolean;
}
interface TravelBreakdownDetailsProps {
  breakdown: any;
  includes: any;
  product: any;
  offer: any;
  promotionColor?: string;
  tooltip?: any;
}

const TravelBreakdownDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 4px;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 0.875rem;
  flex-direction: column;

  padding-top: 16px;
  margin-top: 16px;
  border-top: ${(props) => `1px dashed ${props.theme.colors.ferraGrayDark18}`};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 8px 0;
  }
`;

const TravelBreakdownDetailsInner = styled.div<StyledProps>`
  ${(props) =>
    props.layout === 'desktop' &&
    css`
      overflow-y: auto;
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    `}
`;

const TravelBreakDownLine = styled.div<StyledProps>`
  display: flex;
  justify-content: space-between;
  margin: 0 10px 8px 10px;
  align-items: center;
  color: ${(props) => (props.isPromotion ? props.promotionColor : 'inherit')};
  font-weight: ${(props) => (props.bold ? 600 : 400)};
`;

const LeftLine = styled.div`
  margin-right: auto;
`;

const DottedLine = styled.div`
  background-image: ${(props) =>
    `linear-gradient(to right, ${props.theme.colors.ferraGrayDark18} 33%, rgba(255,255,255,0) 0%)`};
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
  height: 10px;
  margin: 0 8px;
  display: inline-block;
  width: inherit;
  flex: 1;
`;

const PriceDetails = styled.div`
  display: inline-block;
  white-space: nowrap;
  text-align: center;
`;

const RightLine = styled.div`
  margin-left: auto;
  white-space: nowrap;
`;

function TravelBreakdownDetails(props: TravelBreakdownDetailsProps) {
  const { breakdown, tooltip } = props;

  const layout = useContext(LayoutContext);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  return (
    <TravelBreakdownDetailsWrapper>
      <TravelBreakdownDetailsInner layout={layout}>
        <TravelBreakDownLine>
          <LeftLine>{breakdown.campaign.description}</LeftLine>
          <DottedLine />
          <RightLine
            dangerouslySetInnerHTML={{ __html: breakdown.campaign.priceBeauty }}
          />
        </TravelBreakDownLine>
        {breakdown.extraNight?.nightsAmount > 0 && (
          <TravelBreakDownLine>
            <LeftLine>
              {`${t(
                'checkout@summary@breakdownExtraNightsSelected',
                '+ {{nightsAmount}} extra nights',
                { nightsAmount: breakdown.extraNight.nightsAmount },
              )}`}
            </LeftLine>
            <DottedLine />
            <RightLine
              dangerouslySetInnerHTML={{
                __html: breakdown.extraNight.priceBeauty,
              }}
            />
          </TravelBreakDownLine>
        )}
        <TravelBreakDownLine bold={breakdown.accommodation?.id === tooltip.id}>
          <LeftLine>{breakdown.accommodation.description}</LeftLine>
          <DottedLine />
          <RightLine
            dangerouslySetInnerHTML={{
              __html: breakdown.accommodation.priceBeauty,
            }}
          />
        </TravelBreakDownLine>
        {breakdown.insurances && Array.isArray(breakdown.insurances) ? (
          breakdown.insurances.map((insurance, index) => (
            <TravelBreakDownLine
              key={index}
              bold={insurance?.id === tooltip.id}
            >
              <LeftLine>{insurance.description}</LeftLine>
              <DottedLine />
              <RightLine
                dangerouslySetInnerHTML={{ __html: insurance.priceBeauty }}
              />
            </TravelBreakDownLine>
          ))
        ) : (
          <TravelBreakDownLine>
            <LeftLine>{breakdown.insurances.description}</LeftLine>
            <DottedLine />
            <RightLine
              dangerouslySetInnerHTML={{
                __html: breakdown.insurances.priceBeauty,
              }}
            />
          </TravelBreakDownLine>
        )}
        {breakdown.options &&
          breakdown.options.map((option, index) => (
            <TravelBreakDownLine key={index} bold={option?.id === tooltip.id}>
              <LeftLine>{option.description}</LeftLine>
              <DottedLine />
              <PriceDetails
                dangerouslySetInnerHTML={{
                  __html: option.priceDetails.replace('*', 'x'),
                }}
              />
              <DottedLine />
              <RightLine
                dangerouslySetInnerHTML={{ __html: option.priceBeauty }}
              />
            </TravelBreakDownLine>
          ))}
        {breakdown.donate.description && (
          <TravelBreakDownLine bold={breakdown.donate?.id === tooltip.id}>
            <LeftLine>{breakdown.donate.description}</LeftLine>
            <DottedLine />
            <RightLine
              dangerouslySetInnerHTML={{ __html: breakdown.donate.priceBeauty }}
            />
          </TravelBreakDownLine>
        )}
        {breakdown.fees && breakdown.fees.price > 0 && (
          <TravelBreakDownLine bold={breakdown.fees?.id === tooltip.id}>
            <LeftLine>{breakdown.fees.description}</LeftLine>
            <DottedLine />
            <PriceDetails
              dangerouslySetInnerHTML={{ __html: breakdown.fees.priceDetails }}
            />
            <DottedLine />
            <RightLine
              dangerouslySetInnerHTML={{ __html: breakdown.fees.priceBeauty }}
            />
          </TravelBreakDownLine>
        )}
        {breakdown.paymentFee.description && breakdown.paymentFee.price > 0 && (
          <TravelBreakDownLine bold={breakdown.paymentFee?.id === tooltip.id}>
            <LeftLine>{breakdown.paymentFee.description}</LeftLine>
            <DottedLine />
            <PriceDetails
              dangerouslySetInnerHTML={{
                __html: breakdown.paymentFee.priceDetails,
              }}
            />
            <DottedLine />
            <RightLine
              dangerouslySetInnerHTML={{
                __html: breakdown.paymentFee.priceBeauty,
              }}
            />
          </TravelBreakDownLine>
        )}
      </TravelBreakdownDetailsInner>
    </TravelBreakdownDetailsWrapper>
  );
}

export default TravelBreakdownDetails;
