import { ActionType } from '../reducers/budget';
import { api } from '../config';
import mockData from '../mocks/budgetPdf.json';

export const setBudgetData = (orderAttemptId) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockData;

    const rand = Math.random() * 10000000000000000;
    const url = `${api.budget.get}/${orderAttemptId}?rand=${rand}`;
    const method = 'get';

    const type: ActionType = 'SET_BUDGET_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_BUDGET_DATA',
            success: 'RECEIVED_BUDGET_DATA',
            fail: 'ERROR_BUDGET_DATA',
          },
          url,
          mockResult,
          method,
        },
      }),
    );
  };
};

export const budgetCreate = (data) => {
  return (dispatch) => {
    const parameters = Object.entries(data)
      .map(
        (e) =>
          encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1] as string),
      )
      .join('&');

    const url = `${api.budget.create}`;
    const method = 'post';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {
      data: parameters,
    };

    const type: ActionType = 'BUDGET_CREATE_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: 'FETCH',
          actionTypes: {
            request: 'REQUEST_BUDGET_CREATE_DATA',
            success: 'RECEIVED_BUDGET_CREATE_DATA',
            fail: 'ERROR_BUDGET_CREATE_DATA',
          },
          url,
          method,
          headers,
          options,
        },
      }),
    );
  };
};

export const updateBudgetData = (data: {}) => {
  return (dispatch) => {
    const url = `${api.budget.put}`;
    const method = 'put';
    const options = {
      data: data,
    };

    const type: ActionType = 'PUT_BUDGET_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: 'FETCH',
          actionTypes: {
            request: 'REQUEST_UPDATE_BUDGET_DATA',
            success: 'RECEIVED_UPDATE_BUDGET_DATA',
            fail: 'ERROR_UPDATE_BUDGET_DATA',
          },
          url,
          method,
          options,
        },
      }),
    );
  };
};
