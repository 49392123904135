import React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Route, Redirect, useRouteMatch } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';

type Props = {
  children: any;
  path: string;
  isGuest: boolean;
};

function PrivateRoute(props: Props) {
  const { children, isGuest, ...rest } = props;

  const isAuthenticated = useAuth();
  const langMatch = useRouteMatch('/(es|fr|de|uk|us|ca)?');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated.isLoggedIn || isGuest ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `${
                langMatch.params[0] ? `/${langMatch.params[0]}` : '/'
              }`,
              hash: 'login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.displayName = 'PrivateRoute';

const mapStateToProps = (state) => {
  return {
    isGuest: state.config.data.options.guestCheckout,
  };
};

const mapDispatchToProps = (dispatch) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MappedStateToProps = ConnectedProps<typeof connector>;
export type MappedDispatchToProps = {};

export default compose<any>(connector)(PrivateRoute);
