import React, { useState, useEffect, useContext } from 'react';
import { Link as RouteLink, useRouteMatch } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import Icon from '../components/ui/Icon';
import Hidden from '@material-ui/core/Hidden';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../utils';
import { cookiesActions } from '../actions';
import { connect } from 'react-redux';

const Cookie = styled.div<{ isPDP: boolean }>`
  position: sticky;
  bottom: -1px;
  z-index: 1000;
  background: ${(props) => props.theme.colors.black92};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  width: 100%;
  padding: 16px 0;
  > div {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    max-width: ${(props) => props.theme.breakpoints.lg};
    p {
      font-family: ${(props) => props.theme.fontFamily};
      font-size: 0.938rem;
      line-height: 1.47;
      color: ${(props) => props.theme.colors.white};
      margin: 0 24px;
      a {
        text-decoration: underline !important;
      }
    }
    button {
      font-size: 0.875rem;
      line-height: 1.14;
      font-weight: 700;
      letter-spacing: 1.87px;
      border-radius: 2px;
      margin-right: 24px;
      padding: 8px 32px;
      font-family: ${(props) => props.theme.fontFamily};
      font-weight: 500;
      background: ${(props) => props.theme.colors.grandis};
      border-color: ${(props) => props.theme.colors.grandis};
      color: ${(props) => props.theme.colors.mineShaftDarker};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      p {
        font-size: 0.875rem;
        line-height: 1.5;
        letter-spacing: 0.44px;
      }
    }

    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      p {
        line-height: 1.36;
      }
      button {
        padding: 8px 16px;
        font-size: 0.75rem;
        text-transform: uppercase;
        line-height: 1.33;
        letter-spacing: 1.6px;
      }
    }
  }
`;

interface CookieBannerProps {
  setCookieData: any;
}

const CookieBanner = (props: CookieBannerProps) => {
  const { setCookieData } = props;

  useEffect(() => {
    const cookiesStorage = localStorage.getItem('cookie');
    const isCookie = !!cookiesStorage;
    if (isCookie) {
      setIsCookieBannerVisible(false);
    } else {
      localStorage.setItem('cookie', 'true');
    }
  }, []);

  const theme = useTheme();
  const themeStyle = useContext(ThemeContext);
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const cookieLinkValue = t('cookieBanner@cookieLink', '/cookies-policy');

  const handleClick = () => {
    setIsCookieBannerVisible(false);
    localStorage.setItem('cookie', 'true');
    setCookieData({ isCookieVisible: false });
  };

  let pdpPage = useRouteMatch(
    '/:market(es|fr|de|uk|us|ca)?/([_a-zA-Z0-9-]+)/([_a-zA-Z0-9-]+)/:campaignId([0-9]+)-([_a-zA-Z0-9-]+)',
  );

  const [isCookieBannerVisible, setIsCookieBannerVisible] = useState(true);
  return isCookieBannerVisible ? (
    <Cookie isPDP={pdpPage}>
      <div>
        <Hidden xsDown>
          <Icon
            icon={'cookies'}
            size={35}
            color={themeStyle.colors.white}
            customStyle={{
              marginLeft: '24px',
            }}
          />
        </Hidden>
        <p>
          {t('bannerText')}
          <RouteLink to={cookieLinkValue}>{t('bannerLink')}</RouteLink>
        </p>
        <button onClick={handleClick}>
          {xsDown ? t('bannerButtonShort') : t('bannerButton')}
        </button>
      </div>
    </Cookie>
  ) : (
    <></>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCookieData: (data) => dispatch(cookiesActions.setCookieData(data)),
});

export default connect(null, mapDispatchToProps)(CookieBanner);
