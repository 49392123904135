import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouteLink, useHistory, useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import styled from 'styled-components/macro';
import { Formik } from 'formik';
import { string, object } from 'yup';
import TagManager from 'react-gtm-module';
import Typography from '../ui/v2/Typography';
import Icon from '../ui/Icon';
import AlertToast from '../ui/AlertToast';
import Input from '../ui/v2/Input';
import InputPassword from '../ui/v2/InputPassword';
import Button from '../ui/v2/Button';
import FacebookButton from './FacebookButton';
import { AuthContext } from '../../context/AuthContext';

import { getLocaleFromUrl } from '../../utils';

interface LoginFormProps {
  dialog?: boolean;
  loginUser(data: any): any;
  loginFacebook(data: any): any;
  setAuthView?(view: '#login' | '#register'): void;
  handleCloseDialog?(): void;
  setShowPromoModal?: any;
  showPromoModal?: boolean;
}

const Link = styled(RouteLink)`
  display: inline-block;
  text-decoration: underline !important;
  margin: 0 0 0 4px;
  cursor: pointer;
`;

const RecoverPasswordButton = styled(Link)`
  display: flex;
  margin: 16px 0;
`;

const LoginForm = ({
  dialog,
  loginUser,
  loginFacebook,
  setAuthView,
  handleCloseDialog,
  setShowPromoModal,
  showPromoModal,
}: LoginFormProps) => {
  const { i18n, t } = useTranslation();
  const { market } = useParams();
  let history = useHistory();

  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const [extraErrorMessage, setExtraErrorMessage] = useState('');

  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const { setAuthData } = useContext(AuthContext);

  return (
    <>
      <Container maxWidth={false}>
        <Grid
          container
          alignItems="center"
          justify="center"
          alignContent="center"
        >
          <Hidden xsDown={!dialog}>
            <Grid
              container
              alignItems="center"
              style={{ marginBottom: xsDown ? '0' : '12px' }}
            >
              <Grid item xs={12} sm={4}>
                <Typography variant={'h2'}>{t('Log in')}</Typography>
              </Grid>
              <Grid item sm={8}>
                <Typography>
                  {t("You don't have an account yet?")}
                  <Link
                    to={setAuthView ? '#' : t('/register')}
                    onClick={() => setAuthView && setAuthView('#register')}
                  >
                    {t('Create an account')}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={dialog ? 11 : 6} lg={dialog ? 11 : 4}>
            {extraErrorMessage && (
              <AlertToast
                type={'error'}
                showErrorToast={!!extraErrorMessage}
                hide={() => setExtraErrorMessage('')}
                message={extraErrorMessage}
              />
            )}
            <Formik
              initialValues={{
                email: '',
                pass: '',
              }}
              validationSchema={object().shape({
                email: string()
                  .email(t('Must be a valid email'))
                  .required(t('This field can not be empty')),
                pass: string().required(t('This field can not be empty')),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  setSubmitting(true);

                  const enter_url = sessionStorage.getItem('enter_url');
                  const referrer_url = sessionStorage.getItem('referrer_url');
                  const utm_campaign = sessionStorage.getItem('utm_campaign');
                  const utm_medium = sessionStorage.getItem('utm_medium');
                  const utm_source = sessionStorage.getItem('utm_source');
                  const response = await loginUser({
                    email: values.email,
                    pass: values.pass,
                    enter_url: enter_url ? enter_url.replace(/&/g, '%26') : '',
                    referrer_url: referrer_url
                      ? referrer_url.replace(/&/g, '%26')
                      : '',
                    utm_campaign: utm_campaign ? utm_campaign : '',
                    utm_medium: utm_medium ? utm_medium : '',
                    utm_source: utm_source ? utm_source : '',
                  });

                  if (response.data.error) throw response;

                  if (response.data?.hasOwnProperty('customerId')) {
                    localStorage.setItem('user_id', response.data.customerId);
                  }

                  if (response.data?.hasOwnProperty('token')) {
                    setAuthData({
                      isLoggedIn: true,
                      authToken: response.data.token,
                    });
                  }

                  TagManager.dataLayer({
                    dataLayer: { event: 'userLogin_ok' },
                  });

                  if (window.location.hash !== '#promo-login') {
                    if (handleCloseDialog) {
                      handleCloseDialog();
                    } else {
                      history.push(
                        document.referrer.includes('exoticca') &&
                          !document.referrer.includes(t('/login')) &&
                          !document.referrer.includes(t('/register')) &&
                          !document.referrer.includes(t('/retrieve-password'))
                          ? document.referrer
                          : market
                          ? `/${market}`
                          : '/',
                      );
                    }
                  }

                  if (showPromoModal) {
                    if (handleCloseDialog) {
                      handleCloseDialog();
                    }
                    setShowPromoModal(!showPromoModal);
                  }
                } catch (error) {
                  let message;
                  switch (error?.data?.extraInfo?.errorCode) {
                    case 'ERR_LOGIN_00':
                      message = t('Wrong email');
                      break;
                    case 'ERR_LOGIN_01':
                      message = t('Wrong password');
                      break;
                    default:
                      message = t('Error');
                  }
                  setExtraErrorMessage(message);
                  setSubmitting(false);
                }
              }}
              render={({
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                isSubmitting,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid item xs={12}>
                      <Input
                        labelText={t('Email')}
                        inputName={'email'}
                        type={'email'}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        width={'100%'}
                        placeHolderText={t('Email')}
                        error={touched.email && errors.email ? true : false}
                        errorText={errors.email ? errors.email : ''}
                        autoComplete="username"
                      />
                      <InputPassword
                        labelText={t('Password')}
                        inputName={'pass'}
                        type={'password'}
                        value={values.pass}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        width={'100%'}
                        placeHolderText={t('Password')}
                        error={touched.pass && errors.pass ? true : false}
                        errorText={errors.pass ? errors.pass : ''}
                        autoComplete="current-password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <RecoverPasswordButton
                          to={t('/retrieve-password')}
                          onClick={() => {
                            if (handleCloseDialog) {
                              handleCloseDialog();
                            }
                          }}
                        >
                          {t('Have you forgotten your password?')}
                        </RecoverPasswordButton>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button type="submit" loading={isSubmitting}>
                        <span>{t('Log in')}</span>
                        <Icon
                          icon={'iconFSArrow'}
                          size={17}
                          customStyle={{
                            marginLeft: '12px',
                            marginBottom: '1px',
                          }}
                        />
                      </Button>
                    </Grid>
                  </form>
                );
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={false} style={{ padding: 0 }}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: xsDown ? '16px 0' : '24px 0',
            margin: xsDown ? '16px 0 0' : '24px 0 0',
            backgroundColor: '#f2f2f2',
          }}
        >
          <Hidden xsDown>
            <Typography
              variant={'bodyText'}
              customStyle={{
                display: 'inline-block',
                margin: '8px 16px',
              }}
            >
              {t('Sign in with Facebook')}
            </Typography>
          </Hidden>
          <FacebookButton authCallback={loginFacebook} />
        </Grid>
      </Container>
    </>
  );
};

export default LoginForm;
