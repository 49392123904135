import { ActionType } from '../reducers/landing';
import { api } from '../config';

export const setLandingData = (action) => {
  return (dispatch) => {
    const url = `${api.landing}/${action}`;
    const method = 'get';

    const type: ActionType = 'SET_LANDING_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: 'FETCH',
          actionTypes: {
            request: 'REQUEST_LANDING_DATA',
            success: 'RECEIVED_LANDING_DATA',
            fail: 'ERROR_LANDING_DATA',
          },
          url,
          method,
        },
      }),
    );
  };
};
