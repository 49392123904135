import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Root';
import * as serviceWorker from './serviceWorker';
//import smoothScrollPolyfill from 'smoothscroll-polyfill';
import storage from 'local-storage-fallback';
import { hasLocalStorage, hasSessionStorage } from './utils';

if (!hasLocalStorage()) {
  Object.defineProperty(window, 'localStorage', { value: storage });
}

if (!hasSessionStorage()) {
  Object.defineProperty(window, 'sessionStorage', { value: storage });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
