import { combineReducers } from 'redux';

import auth from './auth';
import header from './header';
import footer from './footer';
import register from './register';
import loading from './loading';
import checkout from './checkout';
import market from './market';
import orderConfirm from './confirmation';
import campaign from './campaign';
import category from './category';
import home from './home';
import airports from './airports';
import flights from './flights';
import landing from './landing';
import destination from './destination';
import destinationInfo from './destinationInfo';
import destinationPoi from './destinationPoi';
import budget from './budget';
import ticket from './ticket';
import recoverPassword from './recoverPassword';
import cancellationVoucher from './cancellationVoucher';
import orderAttempt from './orderAttempt';
import partPayment from './partPayment';
import cookies from './cookies';
import genericPages from './genericPages';
import notifications from './notifications';
import promotion from './promotion';
import {
  myOrders,
  myWishList,
  myProfile,
  myWallet,
  myWalletBalance,
  myWalletMovements,
} from './myaccount';
import crossSelling from './crossSelling';
import config from './config';
import contact from './contact';

export default () =>
  combineReducers({
    config,
    auth,
    register,
    loading,
    header,
    footer,
    checkout,
    market,
    orderConfirm,
    campaign,
    category,
    home,
    airports,
    landing,
    destination,
    destinationInfo,
    destinationPoi,
    budget,
    ticket,
    recoverPassword,
    cancellationVoucher,
    orderAttempt,
    partPayment,
    cookies,
    genericPages,
    notifications,
    flights,
    promotion,
    myOrders,
    myWishList,
    myProfile,
    myWallet,
    myWalletBalance,
    myWalletMovements,
    crossSelling,
    contact,
  });
