export type ActionType =
  | 'SET_DESTINATION_INFO_DATA'
  | 'REQUEST_DESTINATION_INFO_DATA'
  | 'RECEIVED_DESTINATION_INFO_DATA'
  | 'ERROR_DESTINATION_INFO_DATA';

interface ActionProps {
  type: ActionType;
  isFetching?: boolean;
  data?: {} | any;
  error?: any;
  payload?: {} | any;
}

const initialDestinationInfoState = {
  isFetching: true,
  data: {},
  error: {},
};

const DestinationInfo = (
  state = initialDestinationInfoState,
  action: Partial<ActionProps>,
) => {
  switch (action.type) {
    case 'REQUEST_DESTINATION_INFO_DATA': {
      return {
        ...state,
        isFetching: true,
      };
    }

    case 'RECEIVED_DESTINATION_INFO_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: { ...payload },
      };
    }

    case 'ERROR_DESTINATION_INFO_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
};

export default DestinationInfo;
