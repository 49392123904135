import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { ModalProvider } from '../context/ModalContext';
import HeaderCheckout from '../containers/HeaderCheckout';
import Footer from '../containers/Footer';
import Notifications from '../containers/Notifications';
import Navigation from '../components/checkout/Navigation';
import Summary from '../components/checkout/Summary';
import ErrorModal from '../components/campaign/ErrorModal';
import BudgetModal from '../components/checkout/budget/BudgetModal';
import Loading from '../components/ui/Loading';
import SEOTags from '../components/ui/v2/SEOTags';

import PremiumCareBox from '../components/campaign/premiumCare/PremiumCareBox';
import PremiumCareMobileCheckout from '../components/campaign/premiumCare/PremiumCareMobileCheckout';
import LeadBannerCall from '../components/banners/LeadBannerCall';

import appConfig from '../config';
import { US_COUNTRY_CODE, getLocaleFromUrl, isLoggedIn } from '../utils';
import { initAffirm } from '../utils/scripts';
import { getDefaultTagManagerData } from '../utils/tagManager';
import { getTotalPriceFromState } from '../actions/checkout';

import {
  SummaryCall,
  PassengersCall,
  PaymentsCall,
  FlightCall,
} from '../api/checkout';

import {
  orderAttemptActions,
  partPaymentActions,
  checkoutActions,
  loadingActions,
  promotionActions,
  notificationsActions,
} from '../actions';

type Props = {
  url: string;
  citySummary: string;
  children: any;
  config: any;
  loading: LoadingProps;
  promotion: any;
  payments: any;
  details: {
    departureDate: string;
    returnDate: string;
    departureIATA: string;
    finalPrice: string;
  };
  selectedAccommodation: any;
  custom?: boolean;
  breakdown: any;
  wallet: any;
  categoryName: string;
  categoryId: string;
  setLoadingData: any;
  getMyWallet(): void;
  getOrderAttemptData(orderAttemptId: string): void;
  getPartPaymentData(methodPay: string, token: string): void;
  cleanCheckout(): void;
  setCheckoutSummary: any;
  setCheckoutBreakdown: any;
  setCheckoutInsurance: any;
  setCheckoutPassengers: any;
  setCheckoutPayment: any;
  orderAttempt: OrderAttemptProps;
  setFilledData: any;
  handleSetBreakdown(values: any): void;
  setNotificationData(data: any): void;
  partPayment: PartPaymentProps;
  partPaymentError: any;
  getPromotion(): void;
  setBudgetModal: any;
  selectedExtraNightIndex: number;
  hasFlight: boolean;
  myCurrentWalletBalance?: number;
};

function CheckoutLayout(props: Props) {
  const {
    children: childrenElements,
    url,
    citySummary,
    config: {
      options: { guestCheckout, budget },
      market,
      currency,
      affiliate,
    },
    payments,
    orderAttempt: {
      orderAttemptId,
      offerId,
      voyageOptionId,
      voyageId,
      adults,
      children,
      babies,
      departureDate,
      flightBookId,
      city,
      departureCityIata,
      filled,
    },
    selectedAccommodation,
    custom,
    breakdown,
    wallet,
    promotion,
    details,
    categoryName,
    categoryId,
    loading,
    getMyWallet,
    getOrderAttemptData,
    getPartPaymentData,
    cleanCheckout,
    setCheckoutSummary,
    setCheckoutBreakdown,
    setCheckoutPassengers,
    setCheckoutPayment,
    setLoadingData,
    setFilledData,
    handleSetBreakdown,
    setNotificationData,
    partPayment,
    partPaymentError,
    getPromotion,
    setBudgetModal,
    selectedExtraNightIndex,
    hasFlight,
    myCurrentWalletBalance,
  } = props;

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  let pathArray = useRouteMatch(
    '/(es|fr|de|uk|us|ca)?/checkout/([_a-zA-Z0-9-pL]*)/([a-zA-Z0-9]+)/([a-zA-Z0-9]+)/',
  );
  const idOrderAttempt =
    orderAttemptId || (pathArray && pathArray.params['3']) || '';

  let partPaymentPathArray = useRouteMatch(
    '/(es|fr|de|uk|us|ca)?/(pago-fraccionado|part-payment|paiment-echelonne|geteilte-zahlung)/([a-zA-Z0-9]+)/',
  );
  const partPaymentToken =
    (partPaymentPathArray && partPaymentPathArray.params['2']) || '';

  const isPaymentNotConfirmedPage = useRouteMatch(
    '/:lang(es|fr|de|uk|us|ca)?/(booking|reservation|reservierung|reserva)/:notConfirmedId/(not-confirmed|non-confirme|nicht-bestatigt|no-confirmada)',
  )?.params?.notConfirmedId;

  const isPaymentConfirmedPage = useRouteMatch(
    '/:market(es|fr|de|uk|us|ca)?/:paymentMethod(reserva-paypal|paypal-booking|reservation-paypal|paypal-reservierung)/:orderId/',
  )?.params?.orderId;

  const urlParams = new URLSearchParams(window.location.search);
  const campaignIdParams = urlParams.get('campaignId');
  const categoryIdParams = urlParams.get('categoryId');
  const departureCityParams = urlParams.get('departureCity');
  const departureCityIATAParams = urlParams.get('departureCityIATA');
  const departureDateParams = urlParams.get('departureDate');
  const adultsParams = urlParams.get('adults');
  const childrenParams = urlParams.get('children');
  const babiesParams = urlParams.get('babies');
  const flightIdParams = urlParams.get('flightId');
  const errorCode = urlParams.get('errorCode');

  const isAffirmEnabled =
    market && window.affirm?.ui ? market === US_COUNTRY_CODE : false;

  let history = useHistory();

  useEffect(() => {
    if (idOrderAttempt) {
      const fetch = async () => {
        try {
          await getOrderAttemptData(idOrderAttempt);
        } catch (error) {
          if (error.data?.extraInfo?.redirect) {
            history.push(error.data.extraInfo.redirect);
          }
        }
      };

      fetch();
    }
  }, [idOrderAttempt, getOrderAttemptData, history]);

  useEffect(() => {
    const fetch = async () => {
      try {
        await getPartPaymentData('', partPaymentToken);
      } catch (error) {
        if (error.data?.extraInfo?.redirect) {
          history.push(error.data.extraInfo.redirect);
        }
      }
    };
    if (partPaymentToken) {
      fetch();
    }
  }, [partPaymentToken, getPartPaymentData, history]);

  useEffect(() => {
    getPromotion();
  }, [getPromotion]);

  useEffect(() => {
    if (myCurrentWalletBalance) {
      getMyWallet();
    }
  }, [getMyWallet, myCurrentWalletBalance]);

  useEffect(() => {
    const fetchPassengersData = async () => {
      setLoadingData({ passengers: true });
      const paxData = await PassengersCall.getPassengers(idOrderAttempt);
      if (paxData.passengers) {
        setCheckoutPassengers(paxData.passengers);
        setLoadingData({ passengers: false });
      }
    };

    if (idOrderAttempt) {
      fetchPassengersData();
    }
  }, [setCheckoutPassengers, setLoadingData, idOrderAttempt]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData({ summary: true });
      const checkoutData = {
        offerId: offerId || categoryIdParams,
        categoryId: voyageOptionId || categoryIdParams,
        productId: voyageId || campaignIdParams,
        submitOrder: 1,
        passengers: children
          ? adults + children
          : adults ||
            (childrenParams
              ? parseInt(adultsParams || '') + parseInt(childrenParams)
              : adultsParams),
        adults: adults || adultsParams,
        children: children ? children : childrenParams ? childrenParams : 0,
        babies: babies ? babies : babiesParams ? babiesParams : 0,
        departure: departureDate || departureDateParams,
        city: city || departureCityParams,
        selectedFlightId: flightBookId || flightIdParams,
        departureCityIata: departureCityIata || departureCityIATAParams,
      };

      if (filled?.accomodation) {
        checkoutData['occupancyCode'] = filled.accomodation;
      }

      if (flightBookId || flightIdParams) {
        checkoutData['flightBookId'] = flightBookId || flightIdParams;
      }

      if (departureCityIata) {
        checkoutData['departureCityIata'] = departureCityIata;
      }

      const summaryData = await SummaryCall.getSummary(checkoutData);

      setCheckoutSummary({
        ...summaryData,
        offerId,
        voyageOptionId,
        flightBookId,
        currency,
      });

      if (summaryData?.breakDown) {
        setCheckoutBreakdown(summaryData.breakDown);
        setLoadingData({ summary: false });
      }

      if (
        typeof filled !== 'undefined' &&
        Object.keys(filled).length > 0 &&
        filled.constructor === Object
      ) {
        setFilledData(filled);
      }
    };

    if (
      (offerId && voyageOptionId && voyageId && adults && departureDate) ||
      (categoryIdParams &&
        campaignIdParams &&
        adultsParams &&
        departureDateParams)
    ) {
      fetchData();
    }
  }, [
    setCheckoutSummary,
    setCheckoutBreakdown,
    setLoadingData,
    offerId,
    voyageOptionId,
    voyageId,
    campaignIdParams,
    adults,
    children,
    babies,
    departureDate,
    flightBookId,
    city,
    departureCityIata,
    adultsParams,
    babiesParams,
    categoryIdParams,
    childrenParams,
    departureCityParams,
    departureCityIATAParams,
    departureDateParams,
    flightIdParams,
    filled,
    setFilledData,
    currency,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData({ prices: true });
      const checkoutData = {
        offerId: offerId || categoryIdParams,
        categoryId: voyageOptionId || categoryIdParams,
        productId: voyageId || campaignIdParams,
        submitOrder: 1,
        occupancyCode: selectedAccommodation.id,
        passengers: children
          ? adults + children
          : adults ||
            (childrenParams
              ? parseInt(adultsParams || '') + parseInt(childrenParams)
              : adultsParams),
        adults: adults || adultsParams,
        children: children ? children : childrenParams ? childrenParams : 0,
        babies: babies ? babies : babiesParams ? babiesParams : 0,
        departure: departureDate || departureDateParams,
        city: city || departureCityParams,
        selectedFlightId: flightBookId || flightIdParams,
        departureCityIata: departureCityIata || departureCityIATAParams,
        extraNight: selectedExtraNightIndex,
        bookingAttemptId: orderAttemptId,
        supplementIds: breakdown.options?.map((options) => options.id),
        insuranceIds: breakdown.insurances?.map((insurance) => insurance.id),
      };

      if (flightBookId || flightIdParams) {
        checkoutData['flightBookId'] = flightBookId || flightIdParams;
      }

      if (departureCityIata) {
        checkoutData['departureCityIata'] = departureCityIata;
      }

      const summaryData = await SummaryCall.getSummary(checkoutData);

      setCheckoutSummary({
        ...summaryData,
        offerId,
        voyageOptionId,
        flightBookId,
        currency,
      });

      setLoadingData({ prices: false });
    };

    if (selectedAccommodation?.id && custom && (offerId || categoryIdParams)) {
      fetchData();
    }

    if (
      selectedExtraNightIndex !== null &&
      custom &&
      (offerId || categoryIdParams)
    ) {
      fetchData();
    }
  }, [
    setCheckoutSummary,
    setCheckoutBreakdown,
    setLoadingData,
    setNotificationData,
    selectedAccommodation,
    offerId,
    voyageOptionId,
    voyageId,
    campaignIdParams,
    adults,
    children,
    babies,
    departureDate,
    flightBookId,
    city,
    departureCityIata,
    adultsParams,
    babiesParams,
    categoryIdParams,
    childrenParams,
    departureCityParams,
    departureCityIATAParams,
    departureDateParams,
    flightIdParams,
    custom,
    currency,
    selectedExtraNightIndex,
    orderAttemptId,
    breakdown,
    t,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData({ payments: true });
      const paymentsData = await PaymentsCall.getPayments(
        idOrderAttempt || partPayment.orderAttemptId,
        offerId || partPayment.offerId,
        voyageId || partPayment.productId,
        departureDate || partPayment.departureDate,
        partPaymentToken || '',
      );
      setCheckoutPayment(paymentsData);
      setLoadingData({ payments: false });
    };

    if (
      (idOrderAttempt && offerId && voyageId && departureDate) ||
      (partPayment.orderAttemptId &&
        partPayment.offerId &&
        partPayment.productId &&
        partPayment.departureDate)
    ) {
      fetchData();
    }
  }, [
    setCheckoutPayment,
    setLoadingData,
    idOrderAttempt,
    offerId,
    voyageId,
    departureDate,
    partPayment,
    partPaymentToken,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const flightData = {
        flightId: flightBookId,
        productId: voyageId,
      };
      FlightCall.verifyFlight(flightData);
    };

    if (voyageId && flightBookId) {
      fetchData();
    }
  }, [flightBookId, voyageId]);

  useEffect(() => {
    if (details?.finalPrice && setBudgetModal.gtmEvent && setBudgetModal.open) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'gaEvent',
          eventCategory: 'Budget',
          eventAction: setBudgetModal.gtmEvent,
          eventLabel: voyageId,
          eventValue: details?.finalPrice,
          ...getDefaultTagManagerData(),
        },
      });
    }
  }, [setBudgetModal.open, setBudgetModal.gtmEvent, voyageId, details]);

  const [showErrorModal, setShowErrorModal] = useState(() =>
    errorCode ? true : false,
  );

  useEffect(() => {
    return history.listen((location) => {
      const errorCode = new URLSearchParams(location.search).get('errorCode');
      if (errorCode) {
        setShowErrorModal(() => (errorCode ? true : false));
      }
    });
  }, [history]);

  const closeErrorModal = () => {
    setShowErrorModal(false);

    // clean url param
    window.history.replaceState({}, document.title, document.location.pathname);
  };

  const isPartPayment = !!partPaymentToken;

  let isLoading = isPartPayment
    ? !payments?.methods?.length && Object.keys(partPaymentError).length === 0
    : isPaymentNotConfirmedPage || isPaymentConfirmedPage
    ? false
    : !loading.hasOwnProperty('summary') || loading.summary;

  useEffect(() => {
    // Affirm
    if (appConfig[market]?.affirm) {
      initAffirm(appConfig[market].affirm);
    }
  }, [market]);

  const totalPriceWithoutDiscountAndWallet = getTotalPriceFromState({
    ...breakdown,
    discount: {},
    wallet: {},
  });

  useEffect(() => {
    if (totalPriceWithoutDiscountAndWallet) {
      wallet
        ?.filter((item) => item.walletType === 'promo_in_wallet')
        ?.map((item) => {
          const breakdownWalletItems = breakdown.wallet?.filter(
            (walletItem) => walletItem.id === item.code,
          );
          const breakdownWalletItem = breakdownWalletItems.length
            ? breakdownWalletItems[0]
            : {};

          if (
            item.minAmount
              ? item.maxAmount
                ? totalPriceWithoutDiscountAndWallet < item.minAmount ||
                  totalPriceWithoutDiscountAndWallet > item.maxAmount
                : totalPriceWithoutDiscountAndWallet < item.minAmount
              : item.maxAmount
              ? totalPriceWithoutDiscountAndWallet > item.maxAmount
              : false
          ) {
            if (breakdownWalletItem?.selected) {
              handleSetBreakdown({
                ...item,
                type: 'wallet',
                multiple: true,
                selected: false,
                price: -item.amount,
                id: item.code,
              });

              setNotificationData({
                isNotificationThrowed: true,
                notification: {
                  variant: 'promotion',
                  message:
                    item.maxAmount &&
                    totalPriceWithoutDiscountAndWallet > item.maxAmount
                      ? `${t(
                          'checkout@myWalletRemovedMaxAmount',
                          'Wallet removed due to maximum booking amount',
                        )}`
                      : `${t(
                          'checkout@myWalletRemovedMinAmount',
                          'Wallet removed due to minimum booking amount',
                        )}`,
                  icon: 'iconerrormessage',
                  iconSize: 32,
                },
              });
            }
          } else {
            if (!breakdownWalletItem.selected) {
              handleSetBreakdown({
                ...item,
                type: 'wallet',
                multiple: true,
                selected: true,
                price: -item.amount,
                id: item.code,
              });

              setNotificationData({
                isNotificationThrowed: true,
                notification: {
                  variant: 'promotion',
                  message: t(
                    'checkout@promotionAdded',
                    'Black Friday discount added to your trip',
                  ),
                  icon: 'iconerrormessage',
                  iconSize: 32,
                },
              });
            }
          }

          return null;
        });
    }
  }, [
    wallet,
    breakdown.wallet,
    totalPriceWithoutDiscountAndWallet,
    handleSetBreakdown,
    setNotificationData,
    t,
  ]);

  let cityUrlParam = '';
  if (hasFlight && (citySummary || departureCityParams)) {
    cityUrlParam = `&ciudad=${citySummary || departureCityParams}`;
  }

  return (
    <>
      <SEOTags
        title={t('meta@title@checkout')}
        description={t('meta@description@checkout')}
        keywords={t('meta@keywords@checkout')}
        canonical={window.location.pathname}
      />
      <HeaderCheckout />
      <div className="content-wrapper" id="checkout">
        <ModalProvider>
          <Container maxWidth={false}>
            {!isPartPayment && (
              <Navigation
                cleanCheckout={() => {
                  cleanCheckout();
                  setLoadingData({});
                }}
                isGuest={guestCheckout}
                voyageId={voyageId}
                orderAttemptId={idOrderAttempt}
                historyBack={`${url}?adultos=${adults || adultsParams}&ninos=${
                  children || childrenParams
                }&bebes=${
                  babies || babiesParams
                }${cityUrlParam}&category=${categoryId}#${categoryName}`}
                promotion={promotion}
              />
            )}
          </Container>
          <Container maxWidth="lg">
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item xs={12} lg={isPartPayment ? 12 : 8}>
                <Hidden lgUp>
                  <LeadBannerCall
                    contactPhone={affiliate.contactPhone}
                    fluidGrid
                  />
                  <PremiumCareMobileCheckout withClick />
                </Hidden>
                {isLoading ? (
                  <Loading isVisible height={'33vh'} />
                ) : (
                  childrenElements
                )}
              </Grid>
              {!isPartPayment && (
                <Grid item lg={4}>
                  {!isLoading && (
                    <Summary
                      promotion={promotion}
                      isAffirmEnabled={isAffirmEnabled}
                    />
                  )}
                  {isLoggedIn() && (
                    <Hidden mdDown>
                      <PremiumCareBox checkout />
                    </Hidden>
                  )}
                </Grid>
              )}
            </Grid>
          </Container>
          <BudgetModal
            isGuest={guestCheckout || budget}
            open={setBudgetModal.open}
            data={{
              productId: voyageId || campaignIdParams || '',
              budgetTotal: details?.finalPrice,
            }}
          />
          <ErrorModal
            errorCode={errorCode}
            isVisible={showErrorModal}
            onClose={closeErrorModal}
          />
        </ModalProvider>
      </div>
      <Notifications promotion={promotion} />
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    url: state.checkout.summary?.product?.url,
    citySummary: state.checkout.summary?.offer.departureCity,
    config: state.config.data,
    loading: state.loading,
    promotion: state.promotion.data,
    orderAttempt: state.orderAttempt.data,
    breakdown: state.checkout.breakdown,
    wallet: state.checkout.wallet,
    categoryName: state.checkout.summary?.category?.name,
    categoryId: state.checkout.summary?.category?.id,
    details: state.checkout.summary?.details,
    selectedAccommodation: state.checkout?.breakdown?.accommodation,
    custom: state.checkout?.custom,
    partPayment: state.partPayment.data,
    partPaymentError: state.partPayment.error,
    payments: state.checkout.payment,
    setBudgetModal: state.checkout.setBudgetModal,
    selectedExtraNightIndex: state.checkout.selectedExtraNightIndex,
    hasFlight: state.checkout.summary?.flight?.hasFlight,
    myCurrentWalletBalance: state.myWalletBalance?.data?.currentBalance,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMyWallet: () => dispatch(checkoutActions.getMyWallet()),
  getOrderAttemptData: (orderAttemptId: string) =>
    dispatch(orderAttemptActions.getOrderAttemptData(orderAttemptId)),
  getPartPaymentData: (methodPay: string, token: string) =>
    dispatch(partPaymentActions.getPartPaymentData(methodPay, token)),
  cleanCheckout: () => dispatch(checkoutActions.cleanCheckout()),
  setCheckoutSummary: (data) =>
    dispatch(checkoutActions.setCheckoutSummary(data)),
  setCheckoutBreakdown: (data) =>
    dispatch(checkoutActions.setCheckoutBreakdown(data)),
  setCheckoutInsurance: (data) =>
    dispatch(checkoutActions.setCheckoutInsurance(data)),
  setCheckoutPassengers: (data) =>
    dispatch(checkoutActions.setCheckoutPassengers(data)),
  setCheckoutPayment: (data) =>
    dispatch(checkoutActions.setCheckoutPayment(data)),
  setFilledData: (data) => dispatch(checkoutActions.setFilledData(data)),
  handleSetBreakdown: (data) => dispatch(checkoutActions.handleBreakdown(data)),
  setNotificationData: (data) =>
    dispatch(notificationsActions.setNotificationData(data)),
  setLoadingData: (data) => dispatch(loadingActions.setLoadingData(data)),
  getPromotion: () => dispatch(promotionActions.getPromotion()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MappedStateToProps = ConnectedProps<typeof connector>;
export type MappedDispatchToProps = {};

export default compose<any>(connector)(CheckoutLayout);
