import { matchPath } from 'react-router';
import { store } from '../Root';

export const getDefaultTagManagerData = () => {
  const state: any = store.getState();
  const affiliateData = state?.config?.data?.affiliate;
  const customerData = state?.config?.data?.customer;
  const hashEmail = customerData?.hashEmail;
  const affiliateId = affiliateData?.id;
  const affiliateName = affiliateData?.name;
  const leadId = localStorage.getItem('lead_id');
  const customerId = localStorage.getItem('user_id');
  const userConnection = localStorage.getItem('userConnection');
  const utmCampaign = sessionStorage.getItem('utm_campaign');
  const utmMedium = sessionStorage.getItem('utm_medium');
  const utmSource = sessionStorage.getItem('utm_source');

  return {
    user_id: customerId || 'not_set',
    lead_id: leadId,
    hashEmail: hashEmail || '',
    aff_id: affiliateId,
    aff_alias: affiliateName,
    user_connection: userConnection || 'not_set',
    internal_utm_campaign: utmCampaign || 'not_set',
    internal_utm_medium: utmMedium || 'not_set',
    internal_utm_source: utmSource || 'not_set',
  };
};

const productsArray = (products: any[], type: string, page: string) => {
  let counter = 0;
  const data = products.map((item) => {
    counter++;
    const pathArray = matchPath(item.url, {
      path:
        '/:lang(es|fr|de|uk|us|ca)?/:type([_a-zA-Z0-9-]+)/([_a-zA-Z0-9-]+)/:campaignId([0-9]+)-([_a-zA-Z0-9-]+)',
      exact: true,
      strict: false,
    });
    return {
      name: item.title,
      id: item.id,
      price: item.fromPrice.replace(/[,.]/g, ''),
      category: item.destination,
      variant: pathArray?.params['type'],
      dimension1: item.days,
      list: `${page}_${type}`.toLowerCase(),
      position: counter,
    };
  });

  return data;
};

export const getImpressionsTagManagerData = (
  products: any[],
  type: string,
  page: string,
) => {
  const state: any = store.getState();
  const prefixSign = state?.config?.data?.currency?.prefixSign;
  const sufixSign = state?.config?.data?.currency?.sufixSign;
  let currencySign = '';
  if (prefixSign) {
    currencySign = prefixSign;
  }
  if (sufixSign) {
    currencySign = sufixSign;
  }
  const currencyData =
    currencySign === '€' ? 'EUR' : currencySign === '$' ? 'USD' : currencySign;
  return {
    ecommerce: {
      currencyCode: currencyData,
      impressions: productsArray(products, type, page),
    },
  };
};
