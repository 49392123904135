import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import styled, { css } from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../utils';
import {
  isLocationCheckout,
  getResponseTapClassName,
  getDedicatedPhoneNumber,
  setExoticcaCountryCookie,
} from '../utils';
import { trustPilotConfig } from '../config';

import { footerActions } from '../actions';

import Icon from '../components/ui/Icon';

import american_society_icon_fallback from '../assets/images/logos/asta.png';
import tourvers_icon_fallback from '../assets/images/logos/tourVers.png';

declare global {
  interface Window {
    rTapNotifyDOMChange(body: HTMLElement): void;
  }
}

const paymentMethodsIcons = [
  'visa_icon',
  'paypal_icon',
  'affirm-white',
  'maestro_icon',
  'masterCard_icon',
  'stripe_icon',
  'bankTransfer_icon',
  'americanExpress_icon',
  'giropay_icon',
];

const getPaymentGuarantees = (guarantees) =>
  guarantees.filter((guarantee) =>
    paymentMethodsIcons.includes(guarantee.icon),
  );

const otherIcons = [
  'trust_icon',
  'acav_icon',
  'apst_icon',
  'data_protection_icon',
  'abta_icon',
  'atol_icon',
  'tourvers_icon',
  'drv_icon',
  'american_society_icon',
];

const getOtherGuarantees = (guarantees) =>
  guarantees.filter((guarantee) => otherIcons.includes(guarantee.icon));

const pngFallback = ['american_society_icon', 'tourvers_icon'];

const isPngFallback = (icon) => pngFallback.includes(icon);

const getPngFallback = (icon) => {
  switch (icon) {
    case 'american_society_icon':
      return american_society_icon_fallback;
    case 'tourvers_icon':
      return tourvers_icon_fallback;
    default:
      return '';
  }
};

const getIconSize = (icon: string) => {
  switch (icon) {
    case 'trust_icon':
      return 70;
    case 'atol_icon':
      return 46;
    case 'acav_icon':
    case 'apst_icon':
      return '';
    default:
      return 96;
  }
};

const getGuaranteeColor = (icon: string) => {
  switch (icon) {
    case 'drv_icon':
      return 'white';
    default:
      return '';
  }
};

const getSSLIcon = (language: string) => {
  switch (language?.substring(0, 2)) {
    case 'es':
      return 'footer-SSL-ES';
    case 'de':
      return 'footer-SSL-DE';
    case 'fr':
      return 'footer-SSL-FR';
    case 'en':
    default:
      return 'footer-SSL-EN';
  }
};

type StyledProps = {
  isAffiliateDomain?: boolean;
};

type FooterObject = {
  enterprise: { [key: string]: any };
  policies: any[];
  customerSupport: { [key: string]: any };
  alterDomains: any[];
  socialMedia: any[];
  guarantees: any[];
  contactPhone: string;
  email: string;
};

type Props = {
  isFetching: boolean;
  footer: FooterObject;
  language: string;
  getFooterData(): void;
  isAffiliateDomain: boolean;
  country: string;
};

const Heading = styled.div`
  width: 100%;
  height: 32px;
  font-size: 1.063rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.textFooterColor};
  border-bottom: 1px solid ${(props) => props.theme.colors.textFooterColorLight};
  text-transform: uppercase;
  margin-bottom: 26px;
  line-height: normal;
  padding-bottom: 10px;
`;

const List = styled.ul<StyledProps>`
  list-style-type: circle;
  padding-left: 20px;
  column-count: 2;
  column-gap: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    list-style: none;
    padding-left: 0;
  }

  ${(props) =>
    props.isAffiliateDomain &&
    css`
      column-count: 3;
      column-gap: 20px;

      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        column-count: 2;
      }
    `}
`;

const ListNoColumn = styled.ul`
  list-style-type: circle;
  padding-left: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    list-style: none;
    padding-left: 0;
  }
`;

const UnstyledList = styled.ul`
  padding-left: 0;
  list-style: none;
  column-count: 2;
  column-gap: 20px;
`;

const ItemList = styled.li<StyledProps>`
  color: ${(props) => props.theme.colors.white};
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 10px;
`;

const PhoneSupport = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  margin-bottom: 16px;
  color: ${(props) => props.theme.colors.white};
`;

const PhoneScheduler = styled.div`
  font-weight: 400;
  font-size: 0.75rem;

  strong {
    font-weight: 500;
    font-size: 1.063rem;
    display: block;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 15px;
  }
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-end;
`;

const SocialIcon = styled.div`
  margin-right: 26px;
`;

const IconWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    &::before,
    &::after {
      content: '';
      border-top: 1px solid
        ${(props) => props.theme.colors.textFooterColorLight};
      margin: 0 30px 0 0;
      flex: 1 0 10px;
    }

    &::after {
      margin: 0 0 0 30px;
    }
  }
`;

const Copyright = styled.div`
  margin-top: 22px;
  font-size: 0.688rem;
  color: ${(props) => props.theme.colors.white};
`;

const LegalUk = styled.p`
  margin-top: 22px;
  font-size: 0.813rem;
  color: ${(props) => props.theme.colors.white};
`;

const IconFallbackImage = styled.img`
  max-width: 168px;
  max-height: 40px;
`;

const GuaranteeText = styled.div`
  color: ${(props) => props.theme.colors.white};
  margin-top: 5px;
`;

const ItemCentered = styled.div`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    footerWrapper: (props) => ({
      backgroundColor: props.footerBgColor,
      paddingTop: '32px',
      paddingBottom: '32px',
    }),
    copyright: {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '40px',
      marginTop: '16px',
    },
  }),
);

function Footer({
  isFetching,
  footer: {
    enterprise,
    policies,
    alterDomains,
    socialMedia,
    customerSupport,
    guarantees,
    contactPhone,
    email,
  },
  language,
  isAffiliateDomain,
  country,
  getFooterData,
}: Props) {
  const isCheckout = isLocationCheckout(window.location.pathname);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);
  const themeStyled = useContext(ThemeContext);
  const classes = useStyles({
    footerBgColor: themeStyled.colors.footerBgColor,
  });

  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const isTrustPilot = trustPilotConfig[country]?.businessUnitId;

  useEffect(() => {
    getFooterData();

    // Load trustpilot widget
    // Disabled
    if (false && isTrustPilot) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src =
        '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
      script.async = true;

      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }, [getFooterData, isTrustPilot]);

  const dedicatedPhoneNumber = getDedicatedPhoneNumber();

  const responseTapClassName = dedicatedPhoneNumber
    ? ''
    : getResponseTapClassName();

  useEffect(() => {
    window.rTapNotifyDOMChange && window.rTapNotifyDOMChange(document.body);
  });

  return (
    <Container maxWidth={false} className={classes.footerWrapper}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid container item xs={12}>
          {!isAffiliateDomain && !isCheckout && (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              style={{
                paddingRight: !xsDown ? '24px' : '0',
                marginBottom: '43px',
              }}
            >
              <Heading>{t('company')}</Heading>
              <List>
                {enterprise &&
                  Object.keys(enterprise).map((key) => (
                    <ItemList key={`enterprise-${key}`}>
                      {enterprise[key].url.includes('http') ? (
                        <a
                          href={enterprise[key].url}
                          rel={'nofollow noopener noreferrer'}
                          target={'_blank'}
                          dangerouslySetInnerHTML={{
                            __html: enterprise[key].text,
                          }}
                        />
                      ) : (
                        <Link
                          to={enterprise[key].url}
                          dangerouslySetInnerHTML={{
                            __html: enterprise[key].text,
                          }}
                        />
                      )}
                    </ItemList>
                  ))}
              </List>
            </Grid>
          )}
          <Grid
            container
            item
            xs={12}
            sm={6}
            md={!isAffiliateDomain ? 4 : 6}
            style={{
              paddingRight: !smDown ? '24px' : '0',
              marginBottom: '43px',
              alignContent: isCheckout ? 'flex-start' : '',
              order: isCheckout ? 2 : 'unset',
            }}
          >
            <Heading>{t('customerSupport')}</Heading>
            {customerSupport && !isAffiliateDomain && (
              <>
                <Grid item xs={12} sm={customerSupport.insurance ? 6 : 8}>
                  <PhoneSupport>
                    <Icon
                      icon={'phone'}
                      size={!smDown ? 34 : 24}
                      customStyle={{
                        marginRight: '11px',
                      }}
                    />
                    <PhoneScheduler>
                      <strong
                        className={responseTapClassName}
                        dangerouslySetInnerHTML={{
                          __html: dedicatedPhoneNumber || customerSupport.phone,
                        }}
                      />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: customerSupport?.schedule?.replace(
                            /\n/g,
                            '<br />',
                          ),
                        }}
                      />
                    </PhoneScheduler>
                  </PhoneSupport>
                </Grid>
                <Grid item xs={12} sm={customerSupport.phone ? 4 : 4}>
                  <ListNoColumn>
                    {customerSupport.insurance && (
                      <ItemList
                        style={
                          xsDown
                            ? { display: 'inline-block', width: '49%' }
                            : {}
                        }
                      >
                        <a
                          href={customerSupport.insurance.url}
                          dangerouslySetInnerHTML={{
                            __html: customerSupport.insurance.text,
                          }}
                          target={isCheckout ? '_blank' : '_self'}
                        />
                      </ItemList>
                    )}
                    {customerSupport.contactUs && (
                      <ItemList
                        style={
                          xsDown
                            ? { display: 'inline-block', width: '49%' }
                            : {}
                        }
                      >
                        <Link
                          to={customerSupport.contactUs.url}
                          dangerouslySetInnerHTML={{
                            __html: customerSupport.contactUs.text,
                          }}
                          target={isCheckout ? '_blank' : '_self'}
                        />
                      </ItemList>
                    )}
                  </ListNoColumn>
                </Grid>
              </>
            )}
            {isAffiliateDomain && (
              <Grid item xs={12}>
                <List
                  style={
                    smDown
                      ? { padding: 0, columnCount: 'unset', columnGap: 'unset' }
                      : { padding: 0 }
                  }
                >
                  <ItemList style={{ listStyle: 'none' }}>
                    <ItemCentered>
                      <Icon
                        icon={'call-whitelabels'}
                        color={themeStyled.colors.white}
                        size={16}
                        customStyle={{
                          marginRight: '11px',
                        }}
                      />
                      <div>{contactPhone}</div>
                    </ItemCentered>
                  </ItemList>
                  {email && (
                    <ItemList style={{ listStyle: 'none' }}>
                      <ItemCentered>
                        <Icon
                          icon={'email-whitelabels'}
                          color={themeStyled.colors.white}
                          size={16}
                          customStyle={{
                            marginRight: '11px',
                          }}
                        />
                        <div>{email}</div>
                      </ItemCentered>
                    </ItemList>
                  )}
                </List>
              </Grid>
            )}
          </Grid>
          {!isCheckout && (
            <Grid
              item
              xs={12}
              sm={6}
              md={!isAffiliateDomain ? 4 : 6}
              style={{
                paddingRight: !xsDown ? '24px' : '0',
                marginBottom: '43px',
              }}
            >
              <Heading>{t('policies')}</Heading>
              <List isAffiliateDomain={isAffiliateDomain}>
                {policies &&
                  policies.map((item, index) => {
                    return (
                      <ItemList key={`policies-${index}`}>
                        <Link
                          to={item.url}
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        />
                      </ItemList>
                    );
                  })}
                {country === 'DE' && (
                  <ItemList key={`impressum`}>
                    <Link
                      to={'/de/impressum'}
                      dangerouslySetInnerHTML={{ __html: t('Impressum') }}
                    />
                  </ItemList>
                )}
              </List>
            </Grid>
          )}
          {!isAffiliateDomain && (
            <>
              <Grid
                container
                item
                xs={12}
                sm={6}
                md={isCheckout ? 8 : 4}
                style={{
                  paddingRight: !smDown ? '24px' : '0',
                  order: isCheckout ? 1 : 'unset',
                }}
              >
                <Heading>{t('paymentMethods')}</Heading>
                <Grid
                  container
                  alignItems="flex-start"
                  item
                  xs={12}
                  md={isCheckout ? 8 : 12}
                >
                  <Grid item xs={3} style={{ textAlign: 'center' }}>
                    <Icon
                      iconSet={'warranties'}
                      icon={getSSLIcon(language)}
                      customStyle={{
                        width: '69px',
                        height: '89px',
                      }}
                    />
                  </Grid>
                  <Grid container alignItems="flex-start" item xs={9}>
                    {guarantees &&
                      getPaymentGuarantees(guarantees).map((guarantee) => (
                        <Grid
                          item
                          xs={4}
                          key={guarantee.icon}
                          style={{ textAlign: 'center', margin: '0 0 15px' }}
                        >
                          <Icon
                            icon={guarantees && guarantee.icon}
                            iconSet={'payments'}
                            customStyle={{
                              width: '52px',
                              color: themeStyled.colors.white,
                            }}
                            onClick={
                              guarantee.url
                                ? () => {
                                    window.location.href = guarantee.url;
                                  }
                                : undefined
                            }
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
              {!isCheckout && (
                <Grid
                  container
                  item
                  xs={12}
                  md={8}
                  style={smDown ? { marginTop: '43px' } : {}}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ paddingRight: !xsDown ? '24px' : '0' }}
                  >
                    <Heading>{t('exoticcaDomains')}</Heading>
                    <UnstyledList>
                      {alterDomains &&
                        alterDomains.map((item, index) => {
                          return (
                            <ItemList key={`domains-${index}`}>
                              <a
                                href={item.url}
                                onClick={() =>
                                  setExoticcaCountryCookie(
                                    item.url.slice(-2).toLowerCase(),
                                  )
                                }
                              >
                                <Icon
                                  iconSet={'flags'}
                                  icon={item.icon}
                                  size={16}
                                  customStyle={{
                                    marginRight: '6px',
                                  }}
                                />
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item.text,
                                  }}
                                />
                              </a>
                            </ItemList>
                          );
                        })}
                    </UnstyledList>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={xsDown ? { marginTop: '33px' } : {}}
                  >
                    <Heading>{t('followUs')}</Heading>
                    <SocialMediaWrapper>
                      {socialMedia &&
                        socialMedia.map((item, index) => {
                          return (
                            <SocialIcon key={`social-${index}`}>
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Icon
                                  icon={item.icon}
                                  iconSet={'social_networks'}
                                  size={27}
                                  customStyle={{
                                    verticalAlign: 'text-bottom',
                                  }}
                                />
                                {item.text}
                              </a>
                            </SocialIcon>
                          );
                        })}
                    </SocialMediaWrapper>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          alignContent={'center'}
          justify={'center'}
          alignItems={'center'}
          spacing={2}
          style={{
            margin: xsDown ? '42px 0 16px' : '',
          }}
        >
          {guarantees &&
            getOtherGuarantees(guarantees).map((guarantee) => (
              <Grid
                item
                xs={'auto'}
                key={guarantee.icon}
                style={{ textAlign: 'center' }}
              >
                {isPngFallback(guarantee.icon) ? (
                  <a
                    href={guarantee.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={!guarantee.url ? { pointerEvents: 'none' } : {}}
                  >
                    <IconFallbackImage
                      src={getPngFallback(guarantee.icon)}
                      alt={guarantee.icon}
                    />
                    <GuaranteeText>{guarantee.text}</GuaranteeText>
                  </a>
                ) : (
                  <a
                    href={guarantee.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={!guarantee.url ? { pointerEvents: 'none' } : {}}
                  >
                    <Icon
                      icon={guarantees && guarantee.icon}
                      iconSet={'warranties'}
                      color={
                        themeStyled.colors[getGuaranteeColor(guarantee.icon)]
                      }
                      customStyle={{
                        color: themeStyled.colors.white,
                        maxWidth: '160px',
                      }}
                      size={getIconSize(guarantee.icon)}
                    />
                  </a>
                )}
              </Grid>
            ))}
          {false && isTrustPilot && (
            <Grid
              item
              xs={'auto'}
              style={{
                textAlign: 'center',
                maxHeight: '150px',
              }}
            >
              <div
                className="trustpilot-widget"
                data-locale={trustPilotConfig[country]?.language}
                data-template-id={trustPilotConfig[country]?.rating.id}
                data-businessunit-id={trustPilotConfig[country]?.businessUnitId}
                data-style-height={trustPilotConfig[country]?.rating.height}
                data-style-width="180px"
                data-theme="dark"
              >
                <a
                  href={trustPilotConfig[country]?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Trustpilot
                </a>
              </div>
            </Grid>
          )}
        </Grid>
        {country === 'DE' && (
          <Grid
            item
            xs={'auto'}
            style={{
              textAlign: 'center',
            }}
          >
            <div
              id="TrustedShopBadge"
              style={{
                height: '60px',
                margin: xsDown ? '32px auto' : '16px auto',
                textAlign: 'center',
              }}
            />
          </Grid>
        )}
        {country === 'GB' && (
          <Grid container item xs={12}>
            <LegalUk dangerouslySetInnerHTML={{ __html: t('legalUk') }} />
          </Grid>
        )}
        <Grid container item xs={12} className={classes.copyright}>
          <IconWrapper>
            <Icon
              icon={'logo'}
              iconSet={'main'}
              color={themeStyled.colors.textFooterColor}
              customStyle={{
                width: '184px',
                height: '38px',
              }}
            />
          </IconWrapper>
          <Copyright>
            {t('copyright') &&
              t('copyright').replace('#YEAR_TOKEN#', moment().format('YYYY'))}
          </Copyright>
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    isFetching: state.footer.isFetching,
    isFetchingHeader: state.header.isFetching,
    footer: state.footer.data,
    language:
      state.config?.data?.language?.length > 0 &&
      state.config?.data?.language[0].locale,
    country: state.config?.data?.market,
    isAffiliateDomain: state.header.data.isAffiliateDomain,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFooterData: () => dispatch(footerActions.getFooterData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
