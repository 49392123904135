import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import styled, { ThemeContext } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Icon from '../components/ui/Icon';
import { connect } from 'react-redux';

interface MySnackbarContentWrapper {
  className: string;
  message: string;
  onClose: void;
  variant: 'error' | 'success' | 'info' | 'warning' | 'promotion';
  icon: string;
  iconSize: number;
  iconCustomStyles?: any;
}

interface NotificationsProps {
  isNotificationThrowed?: boolean;
  notification?: any;
  notifications?: any;
  promotion?: any;
}

interface IconNotificationBoxProps {
  background: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props) => ({
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap' as 'nowrap',
      position: 'relative' as 'relative',
      overflow: 'hidden',
      minHeight: '83px',
      maxWidth: '490px',
      padding: '24px 54px 24px 24px',
      marginRight: '24px',
      [theme.breakpoints.down('sm')]: {
        bottom: '73px',
      },
      [theme.breakpoints.down('xs')]: {
        bottom: '90px',
      },
      '& .MuiSnackbarContent-message': {
        padding: 0,
      },
      '& p': {
        margin:
          props.variant === 'promotion' || props.variant === 'info'
            ? '0'
            : '0 0 0 67px',
        padding: '0 16px',
        fontSize: '1rem',
        letterSpacing: '0.4px',
        [theme.breakpoints.down('xs')]: {
          margin: 0,
        },
      },
    }),
    closeButton: {
      padding: '5px',
      fontSize: 0,
      position: 'absolute',
      top: '19px',
      right: '11px',
    },
    success: (props) => ({
      backgroundColor: props.success,
    }),
    error: (props) => ({
      backgroundColor: props.error,
      '& p': {
        color: props.white,
      },
    }),
    info: (props) => ({
      backgroundColor: props.info,
      '& p': {
        color: props.white,
      },
    }),
    warning: (props) => ({
      backgroundColor: props.warning,
      '& p': {
        color: props.black,
        margin: '0',
      },
    }),
    promotion: (props) => ({
      background: props.notificationBg,
      '& p': {
        color: props.white,
      },
    }),
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const IconNotificationBox = styled.div<IconNotificationBoxProps>`
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
  height: 100%;
  padding: 5px;
  position: absolute;
  left: 0;
  top: 0;
`;

function MySnackbarContentWrapper(props) {
  const {
    className,
    message,
    onClose,
    variant,
    icon,
    iconSize,
    iconCustomStyles,
    promotion,
    ...other
  } = props;

  const themeStyle = useContext(ThemeContext);

  const variantColor = {
    success: themeStyle.colors.white,
    warning: themeStyle.colors.black,
    error: themeStyle.colors.white,
    info: themeStyle.colors.white,
  };

  const variantBackground = {
    success: themeStyle.colors.jungleGreen,
    warning: themeStyle.colors.silver,
    error: themeStyle.colors.redBright,
    info: themeStyle.colors.lowGrey,
  };

  const classes = useStyles({
    success: themeStyle.colors.keppel,
    error: themeStyle.colors.red,
    warning: themeStyle.colors.flamePea,
    info: themeStyle.colors.biscay,
    white: themeStyle.colors.white,
    black: themeStyle.colors.black,
    variant: variant,
    notificationBg: promotion?.styling?.yoBar?.backgroundColor,
  });

  return (
    <SnackbarContent
      className={clsx(classes[variant], className + ' ' + classes.container)}
      aria-describedby="client-snackbar"
      message={
        <span className={classes.message}>
          {variant !== 'promotion' && icon && (
            <Hidden only="xs">
              <IconNotificationBox background={variantBackground[variant]}>
                <Icon
                  icon={icon}
                  size={iconSize}
                  color={variantColor[variant]}
                  customStyle={iconCustomStyles}
                />
                )
              </IconNotificationBox>
            </Hidden>
          )}
          <p dangerouslySetInnerHTML={{ __html: message }} />
        </span>
      }
      action={[
        <IconButton
          className={classes.closeButton}
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <Icon icon={'ico-close'} size={16} color={variantColor[variant]} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

function Notifications(props: NotificationsProps) {
  const { isNotificationThrowed, notification, promotion } = props;

  const { variant, message, icon, iconSize, iconCustomStyles } = notification;

  useEffect(() => {
    setOpen(true);
  }, [notification]);

  const [open, setOpen] = React.useState(true);
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return isNotificationThrowed ? (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={variant}
          message={message}
          icon={icon}
          iconSize={iconSize}
          iconCustomStyles={iconCustomStyles}
          promotion={promotion}
        />
      </Snackbar>
    </div>
  ) : (
    <></>
  );
}

const mapStateToProps = (state) => ({
  isNotificationThrowed: state.notifications.isNotificationThrowed,
  notification: state.notifications.notification,
  notifications: state.notifications,
});

export default connect(mapStateToProps, null)(Notifications);
