import { ActionType } from '../reducers/promotion';
import { api } from '../config';
import mockData from '../mocks/promotion.json';

import { isUrlCached, getUrlCache } from '../utils/cache';
import { disableScroll, enableScroll } from '../utils';

export const getPromotion = () => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockData;

    const url = `${api.promotion}`;
    const method = 'get';
    const headers = {};
    const options = {
      cache: true,
    };

    const cacheUrl = `${url}`;
    if (isUrlCached(cacheUrl)) {
      const urlCache = getUrlCache(cacheUrl);
      if (urlCache.data) {
        return dispatch({
          type: 'IS_CACHED_PROMOTION_DATA',
          payload: urlCache.data,
        });
      }
    }

    const type: ActionType = 'SET_PROMOTION_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_PROMOTION_DATA',
            success: 'RECEIVED_PROMOTION_DATA',
            fail: 'ERROR_PROMOTION_DATA',
          },
          url,
          // props only used when type = FETCH_MOCK:
          mockResult,
          method,
          headers,
          options,
        },
      }),
    );
  };
};

export const setShowPromoModal = (data) => {
  return (dispatch) => {
    if (data) {
      disableScroll();
    } else {
      enableScroll();
    }

    return dispatch({
      type: 'SET_SHOW_MODAL',
      data,
    });
  };
};
