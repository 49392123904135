import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Spanish from '@exoticcaengineering/translations/es-ES/translations.json';
import EnglishGB from '@exoticcaengineering/translations/en-GB/translations.json';
import EnglishUS from '@exoticcaengineering/translations/en-US/translations.json';
import EnglishCA from '@exoticcaengineering/translations/en-CA/translations.json';
import French from '@exoticcaengineering/translations/fr-FR/translations.json';
import German from '@exoticcaengineering/translations/de-DE/translations.json';

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    'en-GB': {
      translations: EnglishGB,
    },
    'en-US': {
      translations: EnglishUS,
    },
    'en-CA': {
      translations: EnglishCA,
    },
    'fr-FR': {
      translations: French,
    },
    'de-DE': {
      translations: German,
    },
    'es-ES': {
      translations: Spanish,
    },
  },
  fallbackLng: {
    en_US: ['en-GB'],
    en_CA: ['en-GB'],
    default: ['en-GB'],
  },
  lng: 'en-GB',
  returnEmptyString: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },
  nsSeparator: '|',
  react: {
    wait: false,
  },
});

export default i18n;
