import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';

import Typography from '../../ui/v2/Typography';
import PremiumCareLogoClaim from './PremiumCareLogoClaim';
import PremiumCareModal from './PremiumCareModal';
import { Hidden } from '@material-ui/core';

const PremiumCareBoxWrapper = styled.div<{ checkout: boolean }>`
  border-radius: 8px;
  padding: 14px;
  cursor: pointer;

  ${(props) =>
    props.checkout
      ? css`
          border-radius: 4px;
          box-shadow: 0 1px 10px 0 ${props.theme.colors.black12};
          background-color: ${props.theme.colors.white};
          border-bottom: solid 4px ${props.theme.colors.terracota};
          margin: 30px 0 0 !important;
        `
      : css`
          border: solid 1px ${props.theme.colors.terracota};
          margin: 16px 0;
        `}
  

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: ${(props) => (props.checkout ? '400px' : 'unset')};
  }
`;

const BenefitsList = styled.ul<{ checkout: boolean }>`
  padding: 0;
  margin-top: 16px;
  margin-bottom: 0;
  list-style-type: none;

  ${(props) =>
    !props.checkout
      ? css`
          column-count: 1;
          column-gap: 20px;
          column-fill: auto;

          @media (min-width: ${props.theme.breakpoints.sm}) {
            column-count: 2;
          }
        `
      : null}
`;

const BenefitElementList = styled.li`
  position: relative;
  break-inside: avoid-column;

  div {
    margin-left: 10px;
  }
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.terracota};
    position: absolute;
    top: 3px;
  }
`;

const BenefitElementListNoBullet = styled(BenefitElementList)`
  &:before {
    display: none;
  }
`;

const PremiumCareBoxHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PlusInfo = styled.div`
  text-decoration: underline;
  text-transform: uppercase;
  margin: 0;
  font-size: 0.625rem;
  line-height: 1.313rem;
`;

interface PremiumCareBoxProps {
  checkout?: boolean;
}

function PremiumCareBox(props: PremiumCareBoxProps) {
  const { checkout } = props;

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const [showPremiumCare, setShowPremiumCare] = useState(() => false);
  const togglePremiumCare = () => {
    setShowPremiumCare(!showPremiumCare);
  };

  return (
    <>
      <PremiumCareBoxWrapper
        checkout={checkout ? checkout : false}
        onClick={togglePremiumCare}
      >
        <PremiumCareBoxHead>
          <PremiumCareLogoClaim small checkout={checkout} />
          <Hidden xsDown>
            <PlusInfo>{t('+ info')}</PlusInfo>
          </Hidden>
        </PremiumCareBoxHead>
        <BenefitsList checkout={checkout ? checkout : false}>
          <BenefitElementList>
            <Typography
              variant={'smallText'}
              customStyle={{ margin: '0 0 0 10px' }}
            >
              {t('premiumCareTitle1')}
            </Typography>
          </BenefitElementList>
          <BenefitElementList>
            <Typography variant={'smallText'}>
              {t('premiumCareTitle2')}
            </Typography>
          </BenefitElementList>
          <BenefitElementList>
            <Typography variant={'smallText'}>
              {t('premiumCareTitle3')}
            </Typography>
          </BenefitElementList>
          {t('premiumCareTitle4') !== ' ' && (
            <BenefitElementList>
              <Typography variant={'smallText'}>
                {t('premiumCareTitle4')}
              </Typography>
            </BenefitElementList>
          )}
          <Hidden smUp>
            <BenefitElementListNoBullet>
              <PlusInfo>{t('+ info')}</PlusInfo>
            </BenefitElementListNoBullet>
          </Hidden>
        </BenefitsList>
      </PremiumCareBoxWrapper>
      <PremiumCareModal
        pose={showPremiumCare ? 'left' : 'right'}
        isVisible={showPremiumCare}
        onClose={togglePremiumCare}
      />
    </>
  );
}

export default React.memo(PremiumCareBox);
