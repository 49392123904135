import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl, parseNumberLocal } from '../../utils';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { ThemeContext } from 'styled-components';

import Icon from '../ui/Icon';

import { authActions } from '../../actions';

const MenuWrapper = styled.nav`
  background-color: ${(props) => props.theme.colors.concretDarker};
  max-width: 311px;
  height: 100%;
`;

const ListElement = styled.div`
  border-bottom: solid 1px ${(props) => props.theme.colors.golden24};
  padding: 14px;
  display: flex;
  flex-direction: column;
`;

const HiMessage = styled(ListElement)`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.67;
  letter-spacing: 0.38px;
  color: ${(props) => props.theme.colors.black};
`;

const MenuElement = styled(ListElement)<{ active?: boolean }>`
  font-size: 1rem;
  line-height: 1.78;
  letter-spacing: 0.28px;
  span {
    margin-left: 16px;
  }
  & > div {
    padding: 7px 16px;
    display: flex;
    align-items: center;
    ${(props) =>
      props.active &&
      `
      font-weight: 600;
    `}
  }
`;

interface MenuProps {
  activeSection: string;
  firstName: string;
  logout(): void;
  isFetchingWalletData: boolean;
  myWalletBalanceData: any;
}

function Menu(props: MenuProps) {
  const {
    activeSection,
    firstName,
    logout,
    isFetchingWalletData,
    myWalletBalanceData,
  } = props;
  const langMatch = useRouteMatch('/(es|fr|de|uk|us|ca)?');
  const themeStyled = useContext(ThemeContext);
  const { setAuthData } = useContext(AuthContext);

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const handleLogout = async () => {
    await logout();
    setAuthData({
      isLoggedIn: false,
      authToken: '',
    });
  };

  const getMenuElement = (to: string, icon: string, text: string) => (
    <Link
      to={
        to === 'logout'
          ? langMatch?.params[0]
            ? `/${langMatch.params[0]}`
            : '/'
          : to
      }
      onClick={() => {
        if (to === 'logout') {
          handleLogout();
        }
      }}
    >
      <MenuElement active={activeSection === to}>
        <div>
          <Icon
            icon={icon}
            size={23}
            color={
              activeSection === to ? themeStyled.colors.mineShaftDarker : ''
            }
            customStyle={{
              display: 'flex',
              alignItems: 'center',
            }}
          />
          <span>
            {text}
            {to === 'mywallet' &&
              myWalletBalanceData.currentBalance > 0 &&
              !isFetchingWalletData && (
                <small
                  style={{ display: 'block' }}
                  dangerouslySetInnerHTML={{
                    __html: `+ ${parseNumberLocal(
                      myWalletBalanceData.currentBalance,
                      0,
                      true,
                      null,
                      true,
                    )}
                  ${t('header@wallet@readyToUse', 'Ready to use')}`,
                  }}
                />
              )}
          </span>
          {activeSection === to && (
            <Icon
              icon={'iconsright-arrow'}
              size={12}
              color={themeStyled.colors.mineShaftDarker}
              customStyle={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
              }}
            />
          )}
        </div>
      </MenuElement>
    </Link>
  );

  return (
    <MenuWrapper>
      <HiMessage>
        {t('myaccount@hi')} {firstName},
      </HiMessage>
      {getMenuElement('myorders', 'MyTrips-MyAcc', t('myaccount@myTrips'))}
      {getMenuElement(
        'mywishlist',
        'MyWishlist-MyAcc',
        t('myaccount@myWishList', 'My Wishlist'),
      )}
      {getMenuElement(
        'mywallet',
        'myWallet',
        t('myaccount@myWallet', 'My Wallet'),
      )}
      {getMenuElement('myprofile', 'Profile--MyAcc', t('myaccount@myProfile'))}
      {getMenuElement(
        'mysubscriptions',
        'Suscriptions-MyAcc',
        t('myaccount@mySubscriptions'),
      )}
      {getMenuElement('faq', 'Faqs-MyAcc', t('myaccount@faq'))}
      {getMenuElement('logout', 'Logout-MyAcc', t('myaccount@logout'))}
    </MenuWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    firstName: state.config.data?.customer?.name,
    isFetchingWalletData: state.myWalletBalance.isFetching,
    myWalletBalanceData: state.myWalletBalance.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(authActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
