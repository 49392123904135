import { ActionType } from '../reducers/crossSelling';
import { api } from '../config';
import mockData from '../mocks/crossSelling.json';

export const setCrossSellingData = (productId) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockData;

    const url = `${api.crossSelling}/${productId}/related`;
    const method = 'get';

    const type: ActionType = 'SET_CROSS_SELLING_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_CROSS_SELLING_DATA',
            success: 'RECEIVED_CROSS_SELLING_DATA',
            fail: 'ERROR_CROSS_SELLING_DATA',
          },
          url,
          mockResult,
          method,
        },
      }),
    );
  };
};
