import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImage = ({
  src,
  placeholderSrc,
  width,
  height,
  alt,
  position,
  cover,
  placeholder,
  effect,
}: {
  src: string;
  placeholderSrc?: string;
  width?: string;
  height?: string;
  alt?: string;
  position?: string;
  cover?: boolean;
  placeholder?: any;
  effect?: string;
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleAfterLoad = () => {
    setImageLoaded(true);
  };
  return (
    <LazyLoadImage
      alt={alt || ''}
      height={height || '100%'}
      width={width || '100%'}
      src={src}
      placeholder={placeholder}
      placeholderSrc={placeholderSrc}
      afterLoad={handleAfterLoad}
      wrapperClassName={imageLoaded ? '' : 'lazyPlaceholder'}
      effect={effect}
      style={{
        position: position || 'absolute',
        objectFit: cover ? 'cover' : '',
      }}
    />
  );
};

LazyImage.displayName = 'LazyImage';

export default LazyImage;
