import React from 'react';
import styled, { keyframes, css } from 'styled-components/macro';
import Icon from '../Icon';

interface StyleButtonProps {
  size?: 'block' | 'large' | 'small';
  predefinedStyles?: 'default' | 'inverse';
  customStyle?: object;
  hover?: any;
  iconName?: string;
  iconColor?: string;
  iconSize?: number;
  iconStyle?: object;
  iconPosition?: 'right' | 'left';
}

interface ButtonProps extends StyleButtonProps {
  type?: string;
  children: any;
  loading?: boolean;
  disabled?: boolean;
  hover?: any;
  onClick?(e: any): any;
}

const StyledButton = styled.button<StyleButtonProps>`
  display: ${(props) => {
    if (props.predefinedStyles === 'default') return 'inline-block';
    return 'block';
  }};
  width: ${(props) => {
    if (props.predefinedStyles === 'default') return 'auto';
    return '100%';
  }};
  background-color: ${(props) => {
    if (
      props.predefinedStyles === 'default' ||
      props.predefinedStyles === 'inverse'
    )
      return 'transparent';
    return props.theme.colors.chathamsBlue;
  }};
  color: ${(props) => {
    if (props.predefinedStyles === 'default')
      return props.theme.colors.mineShaftDarker;
    return props.predefinedStyles === 'inverse'
      ? props.theme.colors.chathamsBlue
      : props.theme.colors.white;
  }};
  font-size: ${(props) => {
    if (props.size === 'small') return '0.75rem';
    return '1rem';
  }};
  font-weight: 500;
  padding: 7px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px
    ${(props) =>
      props.predefinedStyles === 'inverse'
        ? props.theme.colors.chathamsBlue
        : 'unset'};
  &:disabled {
    opacity: 0.5;
  }
  ${(props) => {
    if (props.hover) {
      return css`
        &:hover {
          ${props.hover};
        }
      `;
    }
  }};
`;

const Bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

const StyledDotsLoader = styled.div`
  display: inline-block;
`;

const Dot = styled.span`
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 100%;
  display: inline-block;
  animation: ${Bounce} 1s infinite ease-in-out both;
  &:first-child {
    animation-delay: -0.32s;
  }
  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`;

const ButtonLoader = () => (
  <StyledDotsLoader>
    <Dot />
    <Dot />
    <Dot />
  </StyledDotsLoader>
);

const Button = (props: ButtonProps) => {
  const {
    loading,
    disabled,
    children,
    onClick,
    type,
    predefinedStyles,
    size,
    customStyle,
    hover,
    iconName,
    iconColor,
    iconSize,
    iconStyle,
    iconPosition,
  } = props;

  return (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
      predefinedStyles={predefinedStyles}
      size={size}
      style={customStyle}
      hover={hover}
    >
      {iconName && iconPosition !== 'right' && (
        <Icon
          color={iconColor}
          size={iconSize}
          customStyle={iconStyle}
          icon={iconName ? iconName : ''}
        />
      )}
      {loading ? <ButtonLoader /> : <>{children}</>}
      {iconName && iconPosition === 'right' && (
        <Icon
          color={iconColor}
          size={iconSize}
          customStyle={iconStyle}
          icon={iconName ? iconName : ''}
        />
      )}
    </StyledButton>
  );
};

Button.displayName = 'Button';

Button.defaultProps = {
  type: 'button',
  loading: false,
  disabled: false,
};

export default Button;
