import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components/macro';
import { Formik } from 'formik';
import { string, object } from 'yup';
import {
  createStyles,
  Theme,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

import Icon from '../ui/Icon';
import InputPhone from '../ui/v2/InputPhone';
import { useTranslation } from 'react-i18next';
import {
  getLocaleFromUrl,
  getGoogleAnalyticsClientID,
  getResponseTapClassName,
  getDedicatedPhoneNumber,
} from '../../utils';
import { getApiRoot } from '../../config';

declare global {
  interface Window {
    rTapNotifyDOMChange(body: HTMLElement): void;
  }
}

type Props = {
  fromPortal: boolean;
  phone?: string;
  scheduleLong?: any;
  campaingDestination?: string;
  destinationName?: string;
  destinationType?: string;
  country: string;
  hideCallCenterSchedule?: boolean;
  customerLoggedEmail: string;
  promotionModeEnabled?: boolean;
};

const Title = styled.p<{ invertColor: boolean }>`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.mineShaftDarker};
  margin-bottom: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-top: 5px;
    color: ${(props) =>
      props.invertColor
        ? props.theme.colors.mineShaftDarker
        : props.theme.colors.white};
  }
`;

const Subtitle = styled.p<{ invertColor: boolean }>`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1rem;
  color: ${(props) => props.theme.colors.mineShaftDarker};
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    color: ${(props) =>
      props.invertColor
        ? props.theme.colors.mineShaftDarker
        : props.theme.colors.white};
  }
`;

const SuccessText = styled.p<{ invertColor: boolean }>`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: bold;
  color: ${(props) => props.theme.colors.mineShaftDarker};
  margin: 45px 0;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    color: ${(props) =>
      props.invertColor
        ? props.theme.colors.mineShaftDarker
        : props.theme.colors.white};
  }
`;

const PhoneButton = styled.a<{ color: string }>`
  font-family: ${(props) => props.theme.fontFamily};
  display: flex;
  justify-content: center;
  border: ${(props) => `1px solid ${props.color}`};
  color: ${(props) => props.color};
  padding: 12px 0;
  margin: 10px 0;
  font-size: 1rem;
  border-radius: 4px;
  text-align: center;
`;

const CallUsPhone = styled.p<{ invertColor?: boolean }>`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
  color: ${(props) => props.theme.colors.mineShaftDarker};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: ${(props) =>
      props.invertColor
        ? props.theme.colors.mineShaftDarker
        : props.theme.colors.white};
    text-align: center;

    &::before,
    &::after {
      content: '';
      border-top: ${(props) =>
        props.invertColor
          ? `1px solid ${props.theme.colors.mineShaftDarker}`
          : `1px solid ${props.theme.colors.white}`};
      margin: 0 10px 0 0;
      flex: 1 0 10px;
    }

    &::after {
      margin: 0 0 0 10px;
    }
  }
`;

const Hours = styled.div<{ invertColor: boolean }>`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 0.875rem;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.mineShaftDarker};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    color: ${(props) =>
      props.invertColor
        ? props.theme.colors.mineShaftDarker
        : props.theme.colors.white};
    text-align: center;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  margin-bottom: 60px;
  margin-top: 68px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    save: (props) => ({
      fontFamily: props.fontFamily,
      width: '100%',
      marginBottom: '20px',
      backgroundColor: props.colorBiscay,
      borderRadius: '4px',
      height: '48px',
      color: props.colorWhite,
      fontSize: '1rem',
      padding: '0',
      boxShadow: 'none',
      textAlign: 'center' as 'center',
      textTransform: 'capitalize' as 'capitalize',
      '&:hover, &:active, &:focus': {
        backgroundColor: props.colorBiscay,
        [theme.breakpoints.down('xs')]: {
          backgroundColor: props.invertColor
            ? props.colorBiscay
            : props.colorGolden,
        },
      },
      '&:disabled': {
        backgroundColor: props.colorBiscay,
        color: props.colorWhite,
        opacity: 0.5,
      },
      [theme.breakpoints.down('xs')]: {
        backgroundColor: props.invertColor
          ? props.colorBiscay
          : props.colorGolden,
      },
    }),
    rootInput: (props) => ({
      padding: '0 12px',
      height: '48px',
      borderRadius: '5px',
      border: `1px solid ${props.colorDustyGray}`,
      [theme.breakpoints.down('xs')]: {
        backgroundColor: props.colorWhite,
      },
    }),
    input: (props) => ({
      fontFamily: props.fontFamily,
      borderRadius: 5,
      fontSize: '1rem',
      width: '100%',
      padding: '0',
      color: props.colorMineShaftDarker,
      '&:focus': {
        borderColor: props.colorBiscay,
        color: props.colorMineShaftDarker,
      },
    }),
    formcontrol: {
      marginBottom: '8px',
    },
    buttonGroup: (props) => ({
      borderRadius: '2px',
      backgroundColor: props.colorConcretDarker,
      marginBottom: '10px',
      [theme.breakpoints.down('xs')]: {
        marginTop: '0',
        marginBottom: props.invertColor ? '16px' : '10px',
      },
    }),
    button: (props) => ({
      fontFamily: props.fontFamily,
      padding: '7px 25px',
      borderTop: '0',
      borderBottom: `4px transparent solid`,
      borderLeft: '0',
      borderRight: `1px ${props.colorDustyGray} solid`,
      '&:hover': {
        backgroundColor: 'transparent',
        borderBottom: `4px ${props.colorBiscay} solid`,
        [theme.breakpoints.down('xs')]: {
          backgroundColor: 'transparent',
          borderBottom: props.invertColor
            ? `4px ${props.colorBiscay} solid`
            : `4px ${props.colorGolden} solid`,
        },
      },
      '&:active, &.active': {
        borderBottom: `4px ${props.colorBiscay} solid`,
        [theme.breakpoints.down('xs')]: {
          borderBottom: props.invertColor
            ? `4px ${props.colorBiscay} solid`
            : `4px ${props.colorGolden} solid`,
        },
      },
    }),
    buttonLabel: {
      flexDirection: 'column',
      fontSize: '1rem',
      lineHeight: 1.2,
      fontWeight: 'normal',
    },
  }),
);

function HelpForm({
  fromPortal,
  phone,
  scheduleLong,
  destinationName,
  destinationType,
  country,
  hideCallCenterSchedule,
  customerLoggedEmail,
  promotionModeEnabled,
}: Props) {
  let { campaignId } = useParams();
  const invertColor = campaignId && fromPortal ? true : false;

  const homeMatch = useRouteMatch('/:lang(es|fr|de|uk|us|ca)?');
  const hideForm = homeMatch?.isExact || promotionModeEnabled;

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);
  const [showSuccessDiv, setShowSuccessDiv] = useState(false);
  const themeStyled = useContext(ThemeContext);
  const classes = useStyles({
    colorWhite: themeStyled.colors.white,
    colorBiscay: themeStyled.colors.biscay,
    colorDustyGray: themeStyled.colors.dustyGray,
    colorMineShaftDarker: themeStyled.colors.mineShaftDarker,
    colorConcretDarker: themeStyled.colors.concretDarker,
    colorGolden: themeStyled.colors.golden,
    invertColor: invertColor,
    fontFamily: themeStyled.fontFamily,
  });
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const dedicatedPhoneNumber = getDedicatedPhoneNumber();

  const responseTapClassName = dedicatedPhoneNumber
    ? ''
    : getResponseTapClassName();

  useEffect(() => {
    window.rTapNotifyDOMChange && window.rTapNotifyDOMChange(document.body);
  });

  return (
    <>
      {!showSuccessDiv ? (
        <>
          {!hideForm && (
            <div style={fromPortal && xsDown ? { order: 2 } : {}}>
              <Title invertColor={invertColor}>{t('contactExpert')}</Title>
              <Subtitle invertColor={invertColor}>{t('scheduleTime')}</Subtitle>
              <Formik
                initialValues={{ schedule: '09:00-12:00', phone: '' }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={object().shape({
                  schedule: string(),
                  phone: string()
                    .min(5, t('minCharacters'))
                    .required(t('fieldRequired')),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);

                  const gaClientId = getGoogleAnalyticsClientID();
                  let clientId;
                  if (gaClientId && typeof gaClientId != 'undefined') {
                    clientId = gaClientId;
                  }

                  let interestValue: string;
                  let linkIdValue: string;

                  if (campaignId) {
                    interestValue = 'product';
                    linkIdValue = campaignId || '';
                  } else if (
                    destinationType === 'continent' ||
                    destinationType === 'region' ||
                    destinationType === 'country'
                  ) {
                    if (destinationType === 'continent') {
                      interestValue = 'cont';
                    } else if (destinationType === 'region') {
                      interestValue = 'subcont';
                    } else {
                      interestValue = destinationType;
                    }
                    linkIdValue =
                      destinationName!
                        .toLowerCase()
                        .replace(/\s/g, '-')
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '') || '';
                  } else {
                    interestValue = 'generic';
                    linkIdValue = '';
                  }

                  const data = {
                    acquisition_source: 'callback',
                    schedule: values.schedule,
                    phone: encodeURIComponent(values.phone),
                    name: '',
                    market: country,
                    interest: interestValue,
                    linkId: linkIdValue,
                    email: customerLoggedEmail,
                    clientId: clientId,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    enter_url:
                      localStorage.getItem('enter_url') || window.location.href,
                    referrer_url: localStorage.getItem('referrer_url') || '',
                    utm_campaign: sessionStorage.getItem('utm_campaign') || '',
                    utm_medium: sessionStorage.getItem('utm_medium') || '',
                    utm_source: sessionStorage.getItem('utm_source') || '',
                    abTests: sessionStorage.getItem('abParams') || '',
                  };

                  try {
                    const url = `${getApiRoot()}/api/lead/callme`;
                    const urlParameters = Object.entries(data)
                      .map((e) => e.join('='))
                      .join('&');

                    const response = await axios.post(url, urlParameters, {
                      withCredentials: true,
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                      },
                    });

                    if (response.data) {
                      setShowSuccessDiv(true);
                    }
                  } catch (error) {
                    setSubmitting(false);
                  }
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <ButtonGroup
                      disableRipple={true}
                      fullWidth={true}
                      classes={{
                        root: classes.buttonGroup,
                      }}
                    >
                      <Button
                        value={'09:00-12:00'}
                        onClick={(e) => {
                          setFieldValue('schedule', e.currentTarget.value);
                        }}
                        className={
                          values.schedule === '09:00-12:00' ? 'active' : ''
                        }
                        classes={{
                          root: classes.button,
                          label: classes.buttonLabel,
                        }}
                      >
                        <span>09:00</span>
                        <span>12:00</span>
                      </Button>
                      <Button
                        value={'12:00-15:00'}
                        onClick={(e) => {
                          setFieldValue('schedule', e.currentTarget.value);
                        }}
                        className={
                          values.schedule === '12:00-15:00' ? 'active' : ''
                        }
                        classes={{
                          root: classes.button,
                          label: classes.buttonLabel,
                        }}
                      >
                        <span>12:00</span>
                        <span>15:00</span>
                      </Button>
                      <Button
                        value={'15:00-20:00'}
                        onClick={(e) => {
                          setFieldValue('schedule', e.currentTarget.value);
                        }}
                        className={
                          values.schedule === '15:00-20:00' ? 'active' : ''
                        }
                        classes={{
                          root: classes.button,
                          label: classes.buttonLabel,
                        }}
                        style={{ borderRight: '0' }}
                      >
                        <span>15:00</span>
                        <span>20:00</span>
                      </Button>
                    </ButtonGroup>
                    <FormControl
                      fullWidth={true}
                      className={classes.formcontrol}
                      error={errors.phone ? true : false}
                    >
                      <InputPhone
                        name={'phone'}
                        setFieldValue={setFieldValue}
                        value={values.phone}
                      />
                      {errors.phone && (
                        <FormHelperText>{errors.phone}</FormHelperText>
                      )}
                    </FormControl>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.save}
                      size="large"
                      disabled={isSubmitting}
                      type="submit"
                      disableRipple={true}
                    >
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          style={{
                            margin: '4px 40px',
                            color: themeStyled.colors.white,
                          }}
                        />
                      )}
                      {!isSubmitting && (
                        <>
                          {t('callMe')}
                          <Icon
                            icon="newarrowright"
                            size={16}
                            customStyle={{ marginLeft: '13px', height: '32px' }}
                            color={themeStyled.colors.white}
                          />
                        </>
                      )}
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          )}
          <div style={fromPortal && xsDown ? { order: 1 } : {}}>
            {!hideCallCenterSchedule && (
              <CallUsPhone invertColor={invertColor}>
                {t('youCanCallUs')}
              </CallUsPhone>
            )}
            {hideCallCenterSchedule ? (
              <></>
            ) : xsDown ? (
              <PhoneButton
                color={
                  invertColor
                    ? themeStyled.colors.biscay
                    : themeStyled.colors.golden
                }
                href={`tel:${dedicatedPhoneNumber || phone}`}
              >
                <Icon
                  icon={'header-call-desktop'}
                  color={
                    invertColor
                      ? themeStyled.colors.biscay
                      : themeStyled.colors.golden
                  }
                  size={18}
                  customStyle={{
                    marginRight: '8px',
                  }}
                />
                <div
                  className={responseTapClassName}
                  dangerouslySetInnerHTML={{
                    __html: dedicatedPhoneNumber || phone || '',
                  }}
                />
              </PhoneButton>
            ) : (
              <CallUsPhone
                className={responseTapClassName}
                dangerouslySetInnerHTML={{
                  __html: dedicatedPhoneNumber || phone || '',
                }}
              />
            )}
            {!hideCallCenterSchedule && scheduleLong && (
              <Hours
                invertColor={invertColor}
                dangerouslySetInnerHTML={{
                  __html: scheduleLong?.replace(/\n/g, '<br />'),
                }}
                style={
                  fromPortal && xsDown
                    ? {
                        paddingBottom: '10px',
                        borderBottom: `1px solid ${themeStyled.colors.mineShaftDarker}`,
                      }
                    : {}
                }
              />
            )}
          </div>
        </>
      ) : (
        <>
          <SuccessText invertColor={invertColor}>
            {t('successtitle')}
          </SuccessText>
          <SuccessText invertColor={invertColor}>
            {t('weContactYou')}
          </SuccessText>
          <IconWrapper>
            <Icon icon={'validation'} size={57} />
          </IconWrapper>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    phone: state.header.data.phone,
    scheduleLong: state.header.data?.scheduleLong,
    destinationName: state.destination.data.name,
    destinationType: state.destination.data.type,
    country: state.config?.data?.market,
    customerLoggedEmail: state.config?.data?.customer?.email,
  };
};

export default connect(mapStateToProps, {})(HelpForm);
