import { ActionType } from '../reducers/footer';
import { api } from '../config';
import mockData from '../mocks/footer.json';

export const getFooterData = () => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockData;

    const url = `${api.footer}`;
    const method = 'get';

    const type: ActionType = 'SET_FOOTER_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_FOOTER_DATA',
            success: 'RECEIVED_FOOTER_DATA',
            fail: 'ERROR_FOOTER_DATA',
          },
          url,
          // props only used when type = FETCH_MOCK:
          mockResult,
          method,
        },
      }),
    );
  };
};
