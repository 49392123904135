import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components/macro';
import { I18nextProvider } from 'react-i18next';

import ScrollToTop from './components/ui/v2/ScrollToTop';

import i18n from './i18n';
import configureStore from './store/configureStore';
import MainRoutes from './routes/MainRoutes';
import ConfigProvider from './context/ConfigProvider';
import { LayoutProvider } from './context/LayoutContext';
import { AuthContextProvider } from './context/AuthContext';

import { getThemedAffiliateId } from './utils';

import {
  theme as themeDefault,
  GlobalStyle as GlobalStyleDefault,
} from './themes/theme';
import {
  theme as themeSecretEscapes,
  GlobalStyle as GlobalStyleSecretEscapes,
} from './themes/themeSecretEscapes';
import {
  theme as themeJournaway,
  GlobalStyle as GlobalStyleJournaway,
} from './themes/themeJournaway';

export const store = configureStore();

function Root() {
  let theme;
  let GlobalStyle;
  const affiliateId = getThemedAffiliateId();

  switch (affiliateId) {
    case '2':
      theme = themeSecretEscapes;
      GlobalStyle = GlobalStyleSecretEscapes;
      break;
    case '40':
      theme = themeJournaway;
      GlobalStyle = GlobalStyleJournaway;
      break;
    default:
      theme = themeDefault;
      GlobalStyle = GlobalStyleDefault;
      break;
  }

  return (
    <Provider store={store}>
      <AuthContextProvider>
        <LayoutProvider>
          <ConfigProvider>
            {affiliateId === '40' && (
              <style>
                @import
                url('https://fonts.googleapis.com/css?family=Barlow+Condensed:400,600,600i&display=swap');
              </style>
            )}
            <ThemeProvider theme={theme}>
              <I18nextProvider i18n={i18n}>
                <Router>
                  <ScrollToTop />
                  <MainRoutes />
                </Router>
                <GlobalStyle />
              </I18nextProvider>
            </ThemeProvider>
          </ConfigProvider>
        </LayoutProvider>
      </AuthContextProvider>
    </Provider>
  );
}

export default Root;
