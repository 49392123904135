import React from 'react';
import styled from 'styled-components/macro';

type Props = {
  container: string;
};

const getPlaceholderByContainer = (page: string) => {
  switch (page) {
    case 'home': {
      return {
        lg: require('../../../assets/images/placeholders/homeSkeletonLg.svg'),
        sm: require('../../../assets/images/placeholders/homeSkeletonSm.svg'),
        xs: require('../../../assets/images/placeholders/homeSkeletonXs.svg'),
      };
    }
    case 'pdp': {
      return {
        lg: require('../../../assets/images/placeholders/campaignSkeletonLg.svg'),
        sm: require('../../../assets/images/placeholders/campaignSkeletonSm.svg'),
        xs: require('../../../assets/images/placeholders/campaignSkeletonXs.svg'),
      };
    }
    case 'destination': {
      return {
        lg: require('../../../assets/images/placeholders/destinationInfoSkeletonLg.svg'),
        sm: require('../../../assets/images/placeholders/destinationInfoSkeletonSm.svg'),
        xs: require('../../../assets/images/placeholders/destinationInfoSkeletonXs.svg'),
      };
    }
    case 'generic': {
      return {
        lg: require('../../../assets/images/placeholders/genericPagesSkeletonLg.png'),
        sm: require('../../../assets/images/placeholders/genericPagesSkeletonSm.png'),
        xs: require('../../../assets/images/placeholders/genericPagesSkeletonXs.png'),
      };
    }
  }
};

const ImgPlaceholder = styled.div<{ imagesSrc: any }>`
  display: flex;
  height: 100vh;
  background-image: url(${(props) => props.imagesSrc.lg});
  background-position: top center;
  background-size: cover;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    background-image: url(${(props) => props.imagesSrc.sm});
  }
  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
    background-image: url(${(props) => props.imagesSrc.xs});
  }
  &::after {
    animation: wave 1.6s linear 0.5s infinite;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgb(237, 237, 237, 0.7),
      rgba(255, 255, 255, 0)
    );
    background-color: transparent;
    content: '';
    position: absolute;
    transform: translateX(-100%);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
  }
  @keyframes wave {
    0% {
      transform: translateX(-100%);
    }
    60% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

function SkeletonPlaceholder({ container }: Props) {
  window.scroll(0, 0);

  return <ImgPlaceholder imagesSrc={getPlaceholderByContainer(container)} />;
}

export default SkeletonPlaceholder;
