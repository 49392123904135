import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';

import Typography from '../../ui/v2/Typography';
import Icon from '../../ui/Icon';

import PremiumCareModal from './PremiumCareModal';

interface PremiumCareMobileCheckout {
  withClick?: boolean;
  small?: boolean;
}

const PremiumCareLogoClaimWrapper = styled.div`
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border: solid 2px ${(props) => props.theme.colors.black12};
  background-color: ${(props) => props.theme.colors.white};
  padding: 10px 14px;
  margin: 20px 0;
`;

const PlusInfo = styled.div`
  text-decoration: underline;
`;

const PremiumCareHeading = styled.div`
  display: flex;
  justify-content: space-between;
`;

function PremiumCareLogoClaim(props: PremiumCareMobileCheckout) {
  const { withClick, small } = props;

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const [showPremiumCare, setShowPremiumCare] = useState(() => false);
  const togglePremiumCare = () => {
    setShowPremiumCare(!showPremiumCare);
  };

  return (
    <>
      <PremiumCareLogoClaimWrapper
        onClick={withClick ? togglePremiumCare : () => {}}
      >
        <PremiumCareHeading>
          <Typography variant={'smallText'} customStyle={{ margin: '6px 0' }}>
            {t('Included in all our trips')}
          </Typography>
          <PlusInfo>
            <Typography variant={'smallText'}>{t('+ info')}</Typography>
          </PlusInfo>
        </PremiumCareHeading>
        <Icon
          icon={'Premiumcare-primary'}
          customStyle={{
            width: small ? '140px' : '190px',
          }}
        />
      </PremiumCareLogoClaimWrapper>
      <PremiumCareModal
        pose={showPremiumCare ? 'left' : 'right'}
        isVisible={showPremiumCare}
        onClose={togglePremiumCare}
      />
    </>
  );
}

export default PremiumCareLogoClaim;
