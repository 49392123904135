export type ActionType =
  | 'SET_ORDER_DATA'
  | 'REQUEST_ORDER_DATA'
  | 'RECEIVED_ORDER_DATA'
  | 'ERROR_ORDER_DATA'
  | 'GET_PROFILE_DATA'
  | 'REQUEST_PROFILE_DATA'
  | 'RECEIVED_PROFILE_DATA'
  | 'ERROR_PROFILE_DATA'
  | 'SET_PROFILE_DATA'
  | 'REQUEST_SET_PROFILE_DATA'
  | 'RECEIVED_SET_PROFILE_DATA'
  | 'ERROR_SET_PROFILE_DATA'
  | 'GET_WALLET_DATA'
  | 'REQUEST_WALLET_DATA'
  | 'RECEIVED_WALLET_DATA'
  | 'ERROR_WALLET_DATA'
  | 'GET_WALLET_BALANCE_DATA'
  | 'REQUEST_WALLET_BALANCE_DATA'
  | 'RECEIVED_WALLET_BALANCE_DATA'
  | 'ERROR_WALLET_BALANCE_DATA'
  | 'GET_WALLET_MOVEMENTS_DATA'
  | 'REQUEST_WALLET_MOVEMENTS_DATA'
  | 'RECEIVED_WALLET_MOVEMENTS_DATA'
  | 'ERROR_WALLET_MOVEMENTS_DATA'
  | 'SET_WISHLIST_DATA'
  | 'REQUEST_WISHLIST_DATA'
  | 'RECEIVED_WISHLIST_DATA'
  | 'ERROR_WISHLIST_DATA'
  | 'DELETE_WISHLIST_ITEM'
  | 'REQUEST_DELETE_WISHLIST_ITEM'
  | 'RECEIVED_DELETE_WISHLIST_ITEM'
  | 'ERROR_DELETE_WISHLIST_ITEM';

interface ActionProps {
  type: ActionType;
  isFetching?: boolean;
  data?:
    | {
        email: string;
        psw: string;
        firstName: string;
        lastName: string;
        availableDocumentTypes: {};
        documentType: string;
        documentNumber: string;
        phone: string;
        nationality: string;
        address: string;
        postCode: string;
        city: string;
        cards: any[];
      }
    | any;
  error?: any;
  payload?: {} | any;
}

const initialState = {
  isFetching: true,
  data: {
    email: '',
    psw: '',
    firstName: '',
    lastName: '',
    availableDocumentTypes: {},
    documentType: '',
    documentNumber: '',
    phone: '',
    nationality: '',
    address: '',
    postCode: '',
    city: '',
    cards: [],
  },
  error: {},
};

export const myOrders = (
  state = initialState,
  action: Partial<ActionProps>,
) => {
  switch (action.type) {
    case 'REQUEST_ORDER_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_ORDER_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: payload,
      };
    }

    case 'ERROR_ORDER_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
};

export const myWishList = (
  state = initialState,
  action: Partial<ActionProps>,
) => {
  switch (action.type) {
    case 'REQUEST_WISHLIST_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_WISHLIST_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: payload,
      };
    }

    case 'ERROR_WISHLIST_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    case 'REQUEST_DELETE_WISHLIST_ITEM': {
      return {
        ...state,
        isFetching: true,
      };
    }

    case 'RECEIVED_DELETE_WISHLIST_ITEM': {
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          cards: [
            ...state.data.cards.filter(({ id }) => id !== action.payload.id),
          ],
        },
      };
    }

    default:
      return state;
  }
};

export const myProfile = (
  state = initialState,
  action: Partial<ActionProps>,
) => {
  switch (action.type) {
    case 'REQUEST_PROFILE_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_PROFILE_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: payload,
      };
    }

    case 'ERROR_PROFILE_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    case 'REQUEST_SET_PROFILE_DATA': {
      const { payload = {} } = action;
      const newState = { ...state };
      newState.data.email = payload.data.email;
      newState.data.psw = payload.data.psw;
      newState.data.firstName = payload.data.firstName;
      newState.data.lastName = payload.data.lastName;
      newState.data.documentType = payload.data.documentType;
      newState.data.documentNumber = payload.data.documentNumber;
      newState.data.phone = payload.data.phone;
      newState.data.nationality = payload.data.nationality;
      newState.data.address = payload.data.address;
      newState.data.postCode = payload.data.postCode;
      newState.data.city = payload.data.city;
      return { ...newState };
    }

    case 'RECEIVED_SET_PROFILE_DATA': {
      return {
        ...state,
        isFetching: false,
      };
    }

    case 'ERROR_SET_PROFILE_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
};

export const myWallet = (
  state = initialState,
  action: Partial<ActionProps>,
) => {
  switch (action.type) {
    case 'REQUEST_WALLET_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_WALLET_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: payload,
      };
    }

    case 'ERROR_WALLET_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
};

export const myWalletBalance = (
  state = initialState,
  action: Partial<ActionProps>,
) => {
  switch (action.type) {
    case 'REQUEST_WALLET_BALANCE_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_WALLET_BALANCE_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: payload,
      };
    }

    case 'ERROR_WALLET_BALANCE_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
};

export const myWalletMovements = (
  state = initialState,
  action: Partial<ActionProps>,
) => {
  switch (action.type) {
    case 'REQUEST_WALLET_MOVEMENTS_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_WALLET_MOVEMENTS_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: payload,
      };
    }

    case 'ERROR_WALLET_MOVEMENTS_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
};
