import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AuthContext } from '../../context/AuthContext';
import { Link as RouteLink, useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components/macro';
import Popper from '@material-ui/core/Popper';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../utils';
import { myAccountActions } from '../../actions';

import Icon from '../ui/Icon';

import { isLoggedIn, parseNumberLocal } from '../../utils';
import { NavItem, TextWrapper as TextWrapperContinent } from './MenuContinents';

declare global {
  interface Window {
    showAccess: any;
  }
}
window.showAccess = window.showAccess || {};

type MyAccountProps = {
  customerLoggedName: string;
  handlerAccount(state: boolean): void;
  open?: boolean;
  promotionModeEnabled?: boolean;
  yobarHeight?: number;
  lang?: string;
  isPrivate?: boolean;
  isCheckout?: boolean;
  mobile?: boolean;
  logout(): void;
  getMyWalletBalanceData: any;
  isFetchingWalletBalance: boolean;
  myWalletBalanceData: any;
  customerLoggedId: string;
};

const MyAccountWrapper = styled.div<{ open: boolean; isCheckout?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => !props.isCheckout && 'pointer'};
  flex-direction: column;
  margin: ${(props) => (props.isCheckout ? '0px 8px' : '0px 8px')};

  &:hover {
    background-color: ${(props) =>
      !props.isCheckout && props.theme.colors.mineShaft};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    flex-direction: unset;
    margin: ${(props) => (props.isCheckout ? '0px 8px' : 'unset')};
    min-height: 55px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    right: 0px;
  }
`;

const MyAccountText = styled.div<{ open?: boolean }>`
  display: block;
  font-size: 0.688rem;
  line-height: 0.813rem;
  letter-spacing: 0.61px;
  text-align: center;
  color: ${(props) =>
    props.open ? props.theme.colors.putty : props.theme.colors.white};
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    display: block;
    font-size: 0.75rem;
    line-height: 0.75rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1.36rem;
    letter-spacing: 1.07px;
    text-align: unset;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    text-transform: uppercase;
    font-size: 0.625rem;
    line-height: 0.625rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 11px;
  margin: 0;
`;

const Box = styled.div`
  background-color: ${(props) => props.theme.colors.mineShaft};
  color: ${(props) => props.theme.colors.white};
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.65);
  border-bottom: 0px;
  min-width: 232px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.mineShaft};
  }
`;

const Link = styled(RouteLink)`
  display: block;
  font-size: 1rem;
  letter-spacing: 0.89px;
  text-align: left;
  padding: 13px 16px;
  cursor: pointer;
  border-bottom: unset;
  text-decoration: none;
  color: ${(props) => props.theme.colors.white};

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    border-bottom: 1px solid ${(props) => props.theme.colors.quillGray};
    color: ${(props) => props.theme.colors.mineShaftDarker};
  }

  &:hover {
    text-decoration: underline !important;
  }

  &:active,
  &:visited,
  &:focus,
  &:hover {
    color: ${(props) => props.theme.colors.white};

    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
      color: ${(props) => props.theme.colors.mineShaftDarker};
    }
  }
`;

const Logout = styled(Link)`
  background-color: ${(props) => props.theme.colors.mercuryLight};
  font-weight: 500;
`;

const WalletBalanceMobile = styled.div`
  font-size: 0.875rem;
  line-height: 1.36;
`;

const MyAccountMobileWrapper = styled.div``;

function MyAccount({
  customerLoggedName,
  handlerAccount,
  open,
  promotionModeEnabled,
  yobarHeight,
  mobile,
  logout,
  lang,
  isPrivate,
  isCheckout,
  getMyWalletBalanceData,
  isFetchingWalletBalance,
  myWalletBalanceData,
  customerLoggedId,
}: MyAccountProps) {
  const themeStyle = useContext(ThemeContext);
  const { setAuthData } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const anchorFallback = document.getElementsByTagName(
    'header',
  )[0] as HTMLDivElement;

  const [openMyAccountLinks, setOpenMyAccountLinks] = useState(true);

  const { i18n, t } = useTranslation();
  let history = useHistory();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    handlerAccount && handlerAccount(!open);
  };

  const userLoggedIn = isLoggedIn();

  const loginUrl = '#login';
  const registerUrl = '#register';

  const handleLogout = async () => {
    await logout();
    setAuthData({
      isLoggedIn: false,
      authToken: '',
    });
  };

  useEffect(() => {
    if (userLoggedIn && customerLoggedId) {
      getMyWalletBalanceData(customerLoggedId);
    }
  }, [getMyWalletBalanceData, customerLoggedId, userLoggedIn]);

  if (mobile) {
    return (
      <MyAccountMobileWrapper>
        <NavItem
          onClick={
            !userLoggedIn
              ? () => {
                  handlerAccount(false);
                  history.push(loginUrl);
                }
              : () => setOpenMyAccountLinks(!openMyAccountLinks)
          }
          active={openMyAccountLinks}
        >
          <TextWrapperContinent>
            {userLoggedIn ? (
              <div>
                <div>
                  {t('hello')} {customerLoggedName}
                </div>
                {myWalletBalanceData.currentBalance > 0 &&
                !isFetchingWalletBalance ? (
                  <WalletBalanceMobile
                    dangerouslySetInnerHTML={{
                      __html: `${parseNumberLocal(
                        myWalletBalanceData.currentBalance,
                        0,
                        true,
                        null,
                        true,
                      )} ${t('header@wallet@readyToUse', 'Ready to use')}`,
                    }}
                  />
                ) : (
                  <div>{t('myAccount')}</div>
                )}
              </div>
            ) : (
              t('myAccount')
            )}
          </TextWrapperContinent>
          <Icon
            icon={openMyAccountLinks ? 'iconsdown' : 'iconsright-arrow'}
            size={17}
            color={themeStyle.colors.white}
          />
        </NavItem>
        {userLoggedIn ? (
          <Collapse in={openMyAccountLinks}>
            <Link
              onClick={() => handlerAccount(false)}
              to={`${lang ? `/${lang}` : ''}/my-account/myorders`}
            >
              <Icon
                icon={'MyTrips-MyAcc'}
                size={25}
                color={themeStyle.colors.white}
                customStyle={{ marginRight: '16px' }}
              />
              {t('myOrders')}
            </Link>

            <Link
              onClick={() => handlerAccount(false)}
              to={`${lang ? `/${lang}` : ''}/my-account/mywishlist`}
            >
              <Icon
                icon={'MyWishlist-MyAcc'}
                size={25}
                color={themeStyle.colors.white}
                customStyle={{ marginRight: '16px' }}
              />
              {t('myaccount@myWishList', 'My Wishlist')}
            </Link>

            <Link
              onClick={() => handlerAccount(false)}
              to={`${lang ? `/${lang}` : ''}/my-account/mywallet`}
            >
              <Icon
                icon={'myWallet'}
                size={25}
                color={themeStyle.colors.white}
                customStyle={{ marginRight: '16px' }}
              />
              {t('header@wallet@myWallet', 'My wallet')}
              {myWalletBalanceData.currentBalance > 0 &&
                !isFetchingWalletBalance && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `: ${parseNumberLocal(
                        myWalletBalanceData.currentBalance,
                        0,
                        true,
                        null,
                        true,
                      )}`,
                    }}
                  />
                )}
            </Link>

            <Link
              onClick={() => handlerAccount(false)}
              to={`${lang ? `/${lang}` : ''}/my-account/myprofile`}
            >
              <Icon
                icon={'Profile--MyAcc'}
                size={25}
                color={themeStyle.colors.white}
                customStyle={{ marginRight: '16px' }}
              />
              {t('myPersonalData')}
            </Link>

            <Link
              onClick={() => handlerAccount(false)}
              to={`${lang ? `/${lang}` : ''}/my-account/mysubscriptions`}
            >
              <Icon
                icon={'Suscriptions-MyAcc'}
                size={25}
                color={themeStyle.colors.white}
                customStyle={{ marginRight: '16px' }}
              />
              {t('mySubscription')}
            </Link>
            <Link
              onClick={() => handlerAccount(false)}
              to={`${lang ? `/${lang}` : ''}/my-account/faq`}
            >
              <Icon
                icon={'Faqs-MyAcc'}
                size={25}
                color={themeStyle.colors.white}
                customStyle={{ marginRight: '16px' }}
              />
              {t('myFAQ')}
            </Link>
            <Link
              to={isPrivate ? `${lang ? `/${lang}` : ''}/` : '#'}
              onClick={() => {
                handlerAccount(false);
                handleLogout();
              }}
            >
              <Icon
                icon={'Logout-MyAcc'}
                size={25}
                color={themeStyle.colors.white}
                customStyle={{ marginRight: '16px' }}
              />
              {t('logout')}
            </Link>
          </Collapse>
        ) : (
          <Link onClick={() => handlerAccount(false)} to={registerUrl}>
            {t('register')}
          </Link>
        )}
      </MyAccountMobileWrapper>
    );
  }

  return (
    <>
      {open ? (
        <div
          style={{
            position: 'fixed',
            zIndex: 0,
            left: 0,
            right: 0,
            top: promotionModeEnabled ? yobarHeight : '53px',
            bottom: 0,
          }}
          onClick={() => handlerAccount(false)}
        />
      ) : null}
      <MyAccountWrapper
        onClick={handleClick}
        open={open || false}
        isCheckout={isCheckout}
      >
        <TextWrapper>
          <MyAccountText open={open}>
            {userLoggedIn ? (
              <div>
                <div>
                  <Icon
                    icon={'header-myaccount'}
                    size={16}
                    color={
                      open ? themeStyle.colors.putty : themeStyle.colors.white
                    }
                    customStyle={{ marginRight: '6px' }}
                  />
                  {t('hello')} {customerLoggedName}
                </div>
                {myWalletBalanceData.currentBalance > 0 &&
                !isFetchingWalletBalance ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${parseNumberLocal(
                        myWalletBalanceData.currentBalance,
                        0,
                        true,
                        null,
                        true,
                      )} ${t('header@wallet@readyToUse', 'Ready to use')}`,
                    }}
                  />
                ) : myWalletBalanceData.promoBalance > 0 &&
                  !isFetchingWalletBalance ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${t(
                        'myaccount@myWalletBalancePromo',
                        'up to',
                      ).toLowerCase()} ${parseNumberLocal(
                        myWalletBalanceData.promoBalance,
                        0,
                        true,
                        null,
                        true,
                      )}`,
                    }}
                  />
                ) : (
                  <div>{t('myAccount')}</div>
                )}
              </div>
            ) : (
              t('myAccount')
            )}
          </MyAccountText>
        </TextWrapper>
        {!isCheckout && (
          <Icon
            icon={open ? 'iconsup-arrow1' : 'iconsdown'}
            size={13}
            color={themeStyle.colors.white}
            customStyle={{
              margin: '6px 16px 4px 8px',
            }}
          />
        )}
      </MyAccountWrapper>
      {anchorFallback && !isCheckout && (
        <Popper
          open={true}
          anchorEl={anchorEl || anchorFallback}
          placement={'bottom-end'}
          disablePortal={true}
          transition={false}
          modifiers={{
            preventOverflow: {
              enabled: false,
            },
            hide: {
              enabled: false,
            },
          }}
        >
          <Collapse in={open}>
            <Box>
              {userLoggedIn ? (
                <>
                  <Link
                    to={`${lang ? `/${lang}` : ''}/my-account/myorders`}
                    onClick={() => {
                      handlerAccount(false);
                    }}
                  >
                    {t('myOrders')}
                  </Link>
                  <Link
                    to={`${lang ? `/${lang}` : ''}/my-account/mywishlist`}
                    onClick={() => {
                      handlerAccount(false);
                    }}
                  >
                    {t('myaccount@myWishList', 'My Wishlist')}
                  </Link>
                  <Link
                    to={`${lang ? `/${lang}` : ''}/my-account/mywallet`}
                    onClick={() => {
                      handlerAccount(false);
                    }}
                  >
                    {t('header@wallet@myWallet', 'My wallet')}
                    {myWalletBalanceData.currentBalance > 0 &&
                      !isFetchingWalletBalance && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `: ${parseNumberLocal(
                              myWalletBalanceData.currentBalance,
                              0,
                              true,
                              null,
                              true,
                            )}`,
                          }}
                        />
                      )}
                  </Link>
                  <Link
                    to={`${lang ? `/${lang}` : ''}/my-account/myprofile`}
                    onClick={() => {
                      handlerAccount(false);
                    }}
                  >
                    {t('myPersonalData')}
                  </Link>
                  <Link
                    to={`${lang ? `/${lang}` : ''}/my-account/mysubscriptions`}
                    onClick={() => {
                      handlerAccount(false);
                    }}
                  >
                    {t('mySubscription')}
                  </Link>
                  <Link
                    to={`${lang ? `/${lang}` : ''}/my-account/faq`}
                    onClick={() => {
                      handlerAccount(false);
                    }}
                  >
                    {t('myFAQ')}
                  </Link>
                  <Logout
                    to={isPrivate ? `${lang ? `/${lang}` : ''}/` : '#'}
                    onClick={() => {
                      handlerAccount(false);
                      handleLogout();
                    }}
                  >
                    {t('logout')}
                  </Logout>
                </>
              ) : (
                <>
                  <Link onClick={() => handlerAccount(false)} to={'#login'}>
                    {t('login')}
                  </Link>
                  <Link onClick={() => handlerAccount(false)} to={'#register'}>
                    {t('register')}
                  </Link>
                </>
              )}
            </Box>
          </Collapse>
        </Popper>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isFetchingWalletBalance: state.myWalletBalance.isFetching,
    myWalletBalanceData: state.myWalletBalance.data,
    customerLoggedId: state.config.data?.customer?.token,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMyWalletBalanceData: (id) =>
    dispatch(myAccountActions.getMyWalletBalanceData(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
