import { ActionType } from '../reducers/config';
import appConfig, { api } from '../config';

import { isUrlCached, getUrlCache } from '../utils/cache';
import { isLoggedIn } from '../utils';

const setStaticConfigData = (market) => {
  return (dispatch) => {
    let newObj: any = {};
    const filteredCountryConfig = Object.keys(appConfig)
      .filter((key) => market.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: appConfig[key],
        };
      }, newObj);

    if (Object.keys(filteredCountryConfig).length !== 0) {
      const obj = filteredCountryConfig[Object.keys(filteredCountryConfig)[0]];
      return dispatch({
        type: 'SET_STATIC_CONFIG_DATA',
        payload: obj,
      });
    }
  };
};

export const setConfigData = (force?: boolean) => {
  return async (dispatch) => {
    const url = `${force || isLoggedIn() ? api.config : api.configGuest}`;
    const method = 'get';
    const headers = {};
    const options = {
      cache: true,
    };

    const cacheUrl = `${url}`;
    if (!force && isUrlCached(cacheUrl)) {
      const urlCache = getUrlCache(cacheUrl);
      if (urlCache.data) {
        dispatch({
          type: 'IS_CACHED_CONFIG_DATA',
          payload: urlCache.data,
        });

        await dispatch(setStaticConfigData(urlCache.data.market));

        if (urlCache.data?.customer?.leadId) {
          localStorage.setItem('lead_id', urlCache.data.customer.leadId);
        }

        return urlCache;
      }
    }

    const type: ActionType = 'SET_CONFIG_DATA';
    const response = await dispatch({
      type,
      fetch: {
        type: 'FETCH',
        actionTypes: {
          request: 'REQUEST_CONFIG_DATA',
          success: 'RECEIVED_CONFIG_DATA',
          fail: 'ERROR_CONFIG_DATA',
        },
        url,
        method,
        headers,
        options,
      },
    });

    await dispatch(setStaticConfigData(response.data.market));
    localStorage.setItem(
      'userConnection',
      response.data?.customer?.userConnection,
    );

    if (response.data?.customer?.leadId) {
      localStorage.setItem('lead_id', response.data.customer.leadId);
    }

    return response;
  };
};
