import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Icon from '../../ui/Icon';

interface SplitPaymentPriceProps {
  text: string;
  total: string;
}

const SplitPaymentWrapper = styled.div`
  margin: 4px 14px;
  color: ${(props) => props.theme.colors.golden};
  font-weight: 600;
  font-size: 1rem;
`;

function SplitPaymentTotalPrice(props: SplitPaymentPriceProps) {
  const { text, total } = props;

  const styledTheme = useContext(ThemeContext);

  return (
    <SplitPaymentWrapper>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={8}>
          <Icon
            icon={'triangle-checkout'}
            size={8}
            color={styledTheme.colors.golden}
            customStyle={{
              marginRight: '4px',
              position: 'relative',
              bottom: '2px',
            }}
          />
          {text}
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right' }}>
          <div dangerouslySetInnerHTML={{ __html: total }} />
        </Grid>
      </Grid>
    </SplitPaymentWrapper>
  );
}

export default SplitPaymentTotalPrice;
