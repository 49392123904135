import React, { useState, useEffect, useContext, useCallback } from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import axios from 'axios';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components/macro';
import { string, object } from 'yup';
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import { Formik } from 'formik';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TagManager from 'react-gtm-module';
import Hidden from '@material-ui/core/Hidden';

import useGoogleOptimize from '../../hooks/useGoogleOptimize';
import { useTranslation } from 'react-i18next';
import {
  getLocaleFromUrl,
  getGoogleAnalyticsClientID,
  setCookie,
  getCookie,
  disableScroll,
  enableScroll,
} from '../../utils';
import { getApiRoot } from '../../config';

import Icon from '../ui/Icon';
import leadBackground from '../../assets/images/generic_lead.jpg';
import peakDecoratorImg from '../../assets/images/peak-decorator.png';

import { promotionActions } from '../../actions';

const COOKIE_LEAD_SHOWED = 'exoticca_showed_lead';
const COOKIE_PROMOTION_LEAD_SHOWED = 'exoticca_showed_promotion_lead';

type Props = {
  promotion: Promotion;
  isLoadingPromotion: boolean;
  setShowPromoModal: any;
};

const Transbox = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    background-color: ${(props) => props.theme.colors.white};
    opacity: 0.6;
    height: 100%;
    display: flex;
  }
`;

const PeakDecorator = styled.div`
  height: 26px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: absolute;
  bottom: 0;

  span {
    width: 100%;
    height: inherit;
    margin-left: -2px;
    background-image: url(${peakDecoratorImg});
    background-position: center;
    background-repeat: repeat;
  }

  &:before,
  &:after {
    content: '';
    background-color: ${(props) => props.theme.colors.merino};
    height: 26px;
    margin-right: -2px;
  }

  &:after {
    margin-left: -2px;
  }
`;

const Claim = styled.div`
  font-size: 1.25rem;
  line-height: 1.25;
  text-align: center;
  color: ${(props) => props.theme.colors.mineShaftDarker};
  margin: 14px 20px 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 52px 20px 0;
  }
`;

const GoodieText = styled.div`
  font-size: 1.063rem;
  line-height: 1.2;
  color: ${(props) => props.theme.colors.mineShaftDarker};
  padding-right: 15px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-right: 0;
    text-align: center;
  }
`;

const SubClaim = styled.div`
  font-size: 1.063rem;
  line-height: 1.47;
  text-align: center;
  color: ${(props) => props.theme.colors.mineShaftDarker};
  margin: 21px auto 0;
`;

const Privacy = styled.div`
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  text-align: center;
  color: ${(props) => props.theme.colors.grey};
  display: block;
  margin: 40px auto 25px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 20px auto;
  }
`;

const SuccessMessage = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
  color: ${(props) => props.theme.colors.mineShaftDarker};
  display: block;
  margin: 31px auto 0;
  padding: 0 38px;
`;

const SuccessSubtitleMessage = styled.div`
  font-size: 1rem;
  font-weight: 400;
  margin-top: 25px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: (props) => ({
      fontFamily: props.fontFamily,
      backgroundColor: props.colorMerino,
      height: '100%',
      padding: '0',
      '&:first-child': {
        paddingTop: '0',
      },
    }),
    topImage: (props) => ({
      position: 'relative' as 'relative',
      height: '219px',
      backgroundImage: `url(${props.topImage})`,
      [theme.breakpoints.down('xs')]: {
        height: '165px',
      },
    }),
    save: (props) => ({
      width: '100%',
      marginTop: '21px',
      backgroundColor: props.colorBiscay,
      borderRadius: '4px',
      color: props.colorWhite,
      fontSize: '1.063rem',
      padding: '10px 38px',
      boxShadow: 'none',
      '&:hover, &:active, &:focus': {
        backgroundColor: props.colorBiscay,
      },
    }),
    goodieBox: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        margin: '15px 0',
        flexDirection: 'column',
      },
    },
    formcontrol: { width: '100%' },
    label: (props) => ({
      width: '100%',
      fontFamily: props.fontFamily,
      fontSize: '1.25rem',
      transform: 'none',
      fontWeight: 500,
      color: props.colorMineShaftDarker,
      textAlign: 'center' as 'center',
      '&:focus': {
        color: props.colorMineShaftDarker,
      },
    }),
    rootInput: {
      'label + &': {
        marginTop: theme.spacing(5),
      },
    },
    input: (props) => ({
      backgroundColor: props.colorWhite,
      height: '33px',
      padding: '10px',
      fontSize: '0.938rem',
      borderRadius: 3,
      border: `1px solid ${props.colorGolden}`,
      lineHeight: '48px',
      color: props.colorMineShaftDarker,
      '&:focus': {
        borderColor: props.colorGolden,
        color: props.colorMineShaftDarker,
      },
    }),
  }),
);

function LeadDialog({
  promotion,
  setShowPromoModal,
  isLoadingPromotion,
}: Props) {
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);
  const themeStyled = useContext(ThemeContext);
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({
    colorBiscay: themeStyled.colors.biscay,
    colorWhite: themeStyled.colors.white,
    colorMerino: themeStyled.colors.merino,
    colorMineShaftDarker: themeStyled.colors.mineShaftDarker,
    colorGolden: themeStyled.colors.golden,
    topImage: leadBackground,
    fontFamily: themeStyled.fontFamily,
  });
  const [open, setOpen] = useState(false);
  const [showSuccessDiv, setShowSuccessDiv] = useState(false);
  let leadABTestVariant = useGoogleOptimize(t('leadABTestCode')) || '0';

  let openingTime;
  switch (leadABTestVariant) {
    case '0':
      openingTime = 10000;
      break;
    case '1':
      openingTime = 30000;
      break;
    case '2':
      openingTime = 0; // third test: open at start. Requested by carlos molinet
      break;
    case '3':
      openingTime = null;
      break;
    case '4':
      openingTime = null;
      break;
    default:
      openingTime = 10000;
      break;
  }

  const handleOpeningTime = useCallback((openingTime: any) => {
    setTimeout(function () {
      if (!document.body.classList.contains('noMoreLeads')) {
        setOpen(true);
        setCookie(COOKIE_LEAD_SHOWED, 'yes', 72);
      }
    }, openingTime);
  }, []);

  const onBlur = useCallback(() => {
    handleOpeningTime(0);
  }, [handleOpeningTime]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const utmMedium = sessionStorage.getItem('utm_medium');
    const utmSource = sessionStorage.getItem('utm_source');
    const isTravelZooEmailCampaign =
      utmMedium === 'email' && utmSource === 'travelzoo';

    const isExoticcaNewsletter =
      utmMedium === 'email' && utmSource?.toLowerCase() === 'exoticca';

    if (
      !getCookie('exoticca_usr') &&
      !getCookie(COOKIE_LEAD_SHOWED) &&
      !isTravelZooEmailCampaign &&
      !isExoticcaNewsletter &&
      !promotion.enabled &&
      !isLoadingPromotion
    ) {
      if (
        leadABTestVariant === '0' ||
        leadABTestVariant === '1' ||
        leadABTestVariant === '2'
      ) {
        handleOpeningTime(openingTime);
      } else if (leadABTestVariant === '3') {
        window.addEventListener('blur', onBlur);
      } else if (leadABTestVariant === '4') {
        setOpen(false);
      } else {
        handleOpeningTime(openingTime);
      }
    }
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, [
    onBlur,
    leadABTestVariant,
    openingTime,
    promotion,
    isLoadingPromotion,
    handleOpeningTime,
  ]);

  useEffect(() => {
    if (promotion.enabled && !getCookie(COOKIE_PROMOTION_LEAD_SHOWED)) {
      setTimeout(() => {
        setShowPromoModal(true);
        setCookie(COOKIE_PROMOTION_LEAD_SHOWED, 'yes', 72);
      }, 10000);
    }
  }, [setShowPromoModal, promotion.enabled]);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash === 'subscribe') {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    if (open) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [open]);

  const hash = window.location.hash.substring(1);
  const isAuthDialogOpen = hash === 'login' || hash === 'register';

  return (
    <Dialog
      open={!isAuthDialogOpen && open}
      onClose={handleClose}
      aria-labelledby="lead-dialog"
      scroll={'body'}
      maxWidth={'sm'}
      fullScreen={xsDown ? true : false}
      style={{ zIndex: 1800 }}
    >
      <DialogContent
        classes={{
          root: classes.content,
        }}
      >
        <Grid container>
          <Grid item xs={12} className={classes.topImage}>
            <Transbox>
              <Icon
                icon={'ico-close'}
                size={14}
                onClick={handleClose}
                customStyle={{
                  position: 'absolute',
                  width: '40px',
                  height: '40px',
                  padding: '8px',
                  top: 10,
                  right: 10,
                  zIndex: 100,
                  borderRadius: '100%',
                  textAlign: 'center' as 'center',
                  backgroundColor: themeStyled.colors.white50,
                }}
              />
              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <Claim
                  dangerouslySetInnerHTML={{ __html: t('leadModalClaim') }}
                />
              </Hidden>
              <Hidden only="xs">
                <PeakDecorator>
                  <span />
                </PeakDecorator>
              </Hidden>
            </Transbox>
          </Grid>
          {!showSuccessDiv && (
            <>
              <Hidden only="xs">
                <Grid item xs={12}>
                  <Claim
                    dangerouslySetInnerHTML={{ __html: t('leadModalClaim') }}
                  />
                </Grid>
              </Hidden>
              <Grid
                container
                item
                xs={12}
                style={{ marginBottom: '25px', padding: '0 20px' }}
              >
                <Grid item xs={4} className={classes.goodieBox}>
                  <Icon
                    icon={'ico-discount'}
                    size={56}
                    customStyle={{ marginRight: '5px' }}
                  />
                  <GoodieText
                    dangerouslySetInnerHTML={{ __html: t('goodPrices') }}
                  />
                </Grid>
                <Grid item xs={4} className={classes.goodieBox}>
                  <Icon
                    icon={'ico-promotions'}
                    size={56}
                    customStyle={{ marginRight: '5px' }}
                  />
                  <GoodieText
                    dangerouslySetInnerHTML={{ __html: t('goodPromos') }}
                  />
                </Grid>
                <Grid item xs={4} className={classes.goodieBox}>
                  <Icon
                    icon={'ico-experts'}
                    size={56}
                    customStyle={{ marginRight: '5px' }}
                  />
                  <GoodieText
                    dangerouslySetInnerHTML={{ __html: t('goodTips') }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        {!showSuccessDiv && (
          <>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item xs={11} sm={8}>
                <Formik
                  initialValues={{ email: '' }}
                  validationSchema={object().shape({
                    email: string()
                      .email(t('invalidMail'))
                      .required(t('fieldRequired')),
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    const gaClientId = getGoogleAnalyticsClientID();
                    let clientId;
                    if (gaClientId && typeof gaClientId != 'undefined') {
                      clientId = gaClientId;
                    }
                    const data = {
                      acquisition_source: 'generic',
                      email: values.email,
                      interest: 'generic_lead',
                      clientId: clientId,
                      enter_url:
                        localStorage.getItem('enter_url') ||
                        window.location.href,
                      referrer_url: localStorage.getItem('referrer_url') || '',
                      utm_campaign:
                        sessionStorage.getItem('utm_campaign') || '',
                      utm_medium: sessionStorage.getItem('utm_medium') || '',
                      utm_source: sessionStorage.getItem('utm_source') || '',
                    };
                    try {
                      const url = `${getApiRoot()}/api/lead?rand=${new Date().getTime()}`;
                      const urlParameters = Object.entries(data)
                        .map((e) => e.join('='))
                        .join('&');
                      const response = await axios.post(url, urlParameters, {
                        withCredentials: true,
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/x-www-form-urlencoded',
                        },
                      });

                      if (response.data.hasOwnProperty('leadId')) {
                        localStorage.setItem('lead_id', response.data.leadId);

                        setShowSuccessDiv(true);
                        const tagManagerArgs = {
                          dataLayer: {
                            lead_type: 'generic',
                            event: 'lead_generated_ok',
                            lead_id: response.data.lead_id,
                            user_id: localStorage.getItem('user_id') || '',
                          },
                        };
                        TagManager.dataLayer(tagManagerArgs);
                      }
                    } catch (error) {
                      setSubmitting(false);
                    }
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <FormControl
                        fullWidth={true}
                        className={classes.formcontrol}
                        error={errors.email && touched.email ? true : false}
                      >
                        <InputLabel
                          shrink
                          htmlFor="lead-input"
                          classes={{ root: classes.label }}
                        >
                          {t('suscribeClaim')}
                        </InputLabel>
                        <InputBase
                          id="lead-input"
                          aria-describedby="lead-email-input"
                          value={values.email}
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t('placeholderInput')}
                          classes={{
                            root: classes.rootInput,
                            input: classes.input,
                          }}
                        />
                        {errors.email && touched.email ? (
                          <FormHelperText>{errors.email}</FormHelperText>
                        ) : null}
                      </FormControl>
                      <SubClaim>{t('keepYouUpdated')}</SubClaim>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.save}
                        size="large"
                        disabled={isSubmitting}
                        type="submit"
                        disableRipple={true}
                      >
                        {isSubmitting && <CircularProgress size={14} />}
                        {!isSubmitting && t('saveButton')}
                      </Button>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item xs={8}>
                <Privacy
                  dangerouslySetInnerHTML={{ __html: t('termsAndCondition') }}
                />
              </Grid>
            </Grid>
          </>
        )}
        {showSuccessDiv && (
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                padding: '61px 40px 88px',
              }}
            >
              <Icon icon={'ico-confirm-subscription'} size={70} />
              <SuccessMessage
                dangerouslySetInnerHTML={{ __html: t('successMessage') }}
              />
              <SuccessSubtitleMessage
                dangerouslySetInnerHTML={{
                  __html: t('successSubtitleMessage'),
                }}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.save}
                size="large"
                onClick={handleClose}
                disableRipple={true}
              >
                {t('closeButton')}
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    promotion: state.promotion.data,
    isLoadingPromotion: state.promotion.isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setShowPromoModal: (status) =>
    dispatch(promotionActions.setShowPromoModal(status)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MappedStateToProps = ConnectedProps<typeof connector>;
export type MappedDispatchToProps = {};

export default compose<any>(connector)(LeadDialog);
