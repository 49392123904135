import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../utils';
import NavBack from './navigation/NavBack';
import NavLink from './navigation/NavLink';

type Props = {
  isGuest?: boolean;
  historyBack: string;
  voyageId: string;
  orderAttemptId?: string;
  cleanCheckout(): void;
  promotion: any;
};

export const Wrapper = styled.nav<{ promotionEnable: boolean }>`
  position: fixed;
  right: 0;
  left: 0;
  top: 56px;
  width: 100%;
  z-index: 14;

  ${(props) =>
    props.promotionEnable &&
    css`
      top: 106px;
    `}
`;

export const Nav = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  background-color: ${(props) => props.theme.colors.whiteCreamy};
  box-shadow: 0 -1px 14px 0 rgba(0, 0, 0, 0.66);

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 -8px 12px -9px rgba(0, 0, 0, 0.66);
    padding: 0 5px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    justify-content: flex-start;
  }
`;

const Navigation = ({
  isGuest,
  voyageId,
  orderAttemptId,
  historyBack,
  cleanCheckout,
  promotion,
}: Props) => {
  const { market } = useParams();
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const isLogin = window.location.pathname.includes('login');
  const isRegister = window.location.pathname.includes('register');
  const isPersonalization = window.location.pathname.includes(
    'personalization',
  );
  const isPassengers = window.location.pathname.includes('passengers');
  const isPayment = window.location.pathname.includes('payment');
  const isBudget = window.location.pathname.includes('budget');

  const ACTIONS = [
    {
      id: 'signup',
      text: isGuest ? t('yourTrip') : t('signUp'),
      selected: isLogin || isRegister,
      checked: isPersonalization || isPassengers || isPayment,
      disabled: true,
      url: `${
        market ? `/${market}` : ''
      }/checkout/login/${voyageId}/${orderAttemptId}`,
    },
    {
      id: 'personalization',
      text: t('checkout@personalization'),
      selected: isPersonalization,
      checked: isPassengers || isPayment,
      disabled: isLogin || isRegister || isBudget,
      url: `${
        market ? `/${market}` : ''
      }/checkout/personalization/${voyageId}/${orderAttemptId}`,
    },
    {
      id: 'passengers',
      text: t('checkout@passengers'),
      selected: isPassengers,
      checked: isPayment,
      disabled: isLogin || isRegister || isPersonalization || isBudget,
      url: `${
        market ? `/${market}` : ''
      }/checkout/passengers/${voyageId}/${orderAttemptId}`,
    },
    {
      id: 'payment',
      text: t('payment'),
      selected: isPayment,
      checked: false,
      disabled:
        isLogin || isRegister || isPersonalization || isPassengers || isBudget,
      url: `${
        market ? `/${market}` : ''
      }/checkout/payment/${voyageId}/${orderAttemptId}`,
    },
  ];

  return ReactDOM.createPortal(
    <>
      <Wrapper promotionEnable={promotion.enabled}>
        <Nav>
          <NavBack historyBack={historyBack} onClick={cleanCheckout} />
          {ACTIONS.map((item, index) => (
            <NavLink key={index} item={item} num={index} />
          ))}
        </Nav>
      </Wrapper>
    </>,
    document.body,
  );
};

export default Navigation;
