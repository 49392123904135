import React, { useState, useEffect, useContext } from 'react';
import TagManager from 'react-gtm-module';
import { AuthContext } from '../../context/AuthContext';
import { withRouter, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { createStyles, withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent, {
  DialogContentProps,
} from '@material-ui/core/DialogContent';

import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import Icon from '../ui/Icon';
import PromoBanner from '../promotion/PromoBanner';

import {
  disableScroll,
  enableScroll,
  isLoggedIn,
  hotjarTrigger,
} from '../../utils';
import { getDefaultTagManagerData } from '../../utils/tagManager';
import {
  authActions,
  registerActions,
  marketActions,
  promotionActions,
} from '../../actions';

interface AuthDialogProps {
  country: string;
  location: any;
  config: any;
  loginUser(data: any, autoLogin?: boolean): any;
  loginFacebook(data: any): any;
  registerUser(data: any): any;
  setMarket(): void;
  promotion: Promotion;
  setShowPromoModal: any;
  showPromoModal: boolean;
}

interface CustomDialogContentProps extends DialogContentProps {
  withPromoBanner: boolean;
  fontFamily: string;
}

const StyledDialogContent = withStyles(() =>
  createStyles({
    root: (props) => ({
      fontFamily: props.fontFamily,
      padding: props.withPromoBanner ? '0!important' : '16px 0 0',
    }),
  }),
)(({ withPromoBanner, ...props }: CustomDialogContentProps) => (
  <DialogContent {...props} />
));

const StyledDialog = withStyles((theme) =>
  createStyles({
    paper: {
      margin: '8px',
    },
    paperScrollBody: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%!important',
      },
    },
  }),
)((props: DialogProps) => <Dialog {...props} />);

function AuthDialog({
  country,
  loginUser,
  loginFacebook,
  registerUser,
  setMarket,
  config: { affiliate, customer },
  promotion,
  setShowPromoModal,
  showPromoModal,
}: AuthDialogProps) {
  const themeStyled = useContext(ThemeContext);
  const { setAuthData } = useContext(AuthContext);

  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const [open, setOpen] = useState(false);
  const [authView, setAuthView] = useState<
    '#login' | '#register' | '#promo-login' | '#promo-register'
  >('#login');

  const [hash, setHash] = useState(window.location.hash);

  const handleClose = () => {
    setOpen(false);
    setHash('');
    window.history.replaceState(null, '', ' ');
  };

  let history = useHistory();
  useEffect(() => {
    return history.listen((location) => {
      if (location.hash) {
        setHash(location.hash);
      }
    });
  }, [history]);

  useEffect(() => {
    if (hash && window.dataLayer) {
      TagManager.dataLayer({
        dataLayer: {
          page_type:
            authView === '#login' || authView === '#promo-login'
              ? 'login'
              : 'register',
          ...getDefaultTagManagerData(),
        },
      });
    }
  }, [hash, authView, affiliate, customer]);

  useEffect(() => {
    setMarket();
  }, [setMarket]);

  useEffect(() => {
    if (
      !isLoggedIn() &&
      (hash === '#login' ||
        hash === '#register' ||
        hash === '#promo-login' ||
        hash === '#promo-register')
    ) {
      setOpen(true);
      setAuthView(hash);

      hotjarTrigger('auth_dialog');
    }
  }, [hash]);

  useEffect(() => {
    if (open) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [open]);

  const handleLoginFacebook = async (
    data: {
      accessToken: string;
    },
    newsletter?: boolean,
  ) => {
    const response = await loginFacebook({
      access_token: data.accessToken,
      newsletter: newsletter,
    });

    if (response?.data?.result === 'ok') {
      setAuthData({
        isLoggedIn: true,
        authToken: response?.data?.token,
      });
      handleClose();
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="lead-dialog"
      scroll={'body'}
      maxWidth={'sm'}
      style={{ zIndex: 1800 }}
    >
      <StyledDialogContent
        fontFamily={themeStyled.fontFamily}
        withPromoBanner={promotion.enabled}
      >
        {promotion.enabled && (
          <PromoBanner styling={promotion.styling.banner} />
        )}
        <Icon
          icon={'ico-close'}
          size={xsDown ? 16 : 14}
          onClick={handleClose}
          color={
            promotion.enabled
              ? themeStyled.colors.white
              : themeStyled.colors.black
          }
          customStyle={{
            position: 'absolute',
            width: '28px',
            height: '28px',
            padding: '8px',
            top: 0,
            right: 10,
            zIndex: 100,
            borderRadius: '100%',
            textAlign: 'center' as 'center',
          }}
        />
        {(authView === '#login' || authView === '#promo-login') && (
          <LoginForm
            loginUser={loginUser}
            loginFacebook={handleLoginFacebook}
            setAuthView={setAuthView}
            handleCloseDialog={handleClose}
            dialog
            setShowPromoModal={setShowPromoModal}
            showPromoModal={showPromoModal}
          />
        )}
        {(authView === '#register' || authView === '#promo-register') && (
          <RegisterForm
            registerUser={registerUser}
            loginUser={loginUser}
            loginFacebook={handleLoginFacebook}
            setAuthView={setAuthView}
            handleCloseDialog={handleClose}
            dialog
            setShowPromoModal={setShowPromoModal}
            showPromoModal={showPromoModal}
          />
        )}
      </StyledDialogContent>
    </StyledDialog>
  );
}

const mapStateToProps = ({ market, config, promotion }) => ({
  country: market?.country,
  config: config.data,
  promotion: promotion.data,
  showPromoModal: promotion.showModal,
});

const mapDispatchToProps = (dispatch) => ({
  registerUser: (data) => dispatch(registerActions.registerUser(data)),
  loginUser: (data: any, autoLogin?: boolean) =>
    dispatch(authActions.logUser(data, autoLogin)),
  loginFacebook: (data) => dispatch(authActions.loginFacebook(data)),
  setMarket: () => dispatch(marketActions.setMarket()),
  setShowPromoModal: (status) =>
    dispatch(promotionActions.setShowPromoModal(status)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default compose<any>(connector, withRouter)(AuthDialog);
