import React, { useState, useEffect } from 'react';

interface LayoutProviderProps {
  children: object;
}

const getLayout = (width: number) => {
  let layout: string;
  if (width > 1199) {
    layout = 'desktop';
  } else if (width > 479) {
    layout = 'tablet';
  } else {
    layout = 'mobile';
  }
  return layout;
};

const LayoutContext = React.createContext(getLayout(window.innerWidth));

const LayoutProvider = (props: LayoutProviderProps) => {
  const [layout, setLayout] = useState(getLayout(window.innerWidth));

  useEffect(() => {
    window.addEventListener('resize', () => {
      setLayout(getLayout(window.innerWidth));
    });
  }, []);

  return (
    <LayoutContext.Provider value={layout}>
      {props.children}
    </LayoutContext.Provider>
  );
};
export { LayoutContext, LayoutProvider };
