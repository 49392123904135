import React, { useContext, Fragment, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import posed from 'react-pose';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';
import { LayoutContext } from '../../../context/LayoutContext';

import CampaignInfo from './CampaignInfo';
import TravelDetails from './TravelDetails';
import Icon from '../../ui/Icon';

interface FullScreenDialogProps {
  pose: string;
  country: string;
  length: number;
  name: string;
  closeDialog(): void;
  includes: object[];
  category: object;
  product: object;
  splitPaymentPercentage: number;
  pax: object;
  flight: object;
  breakdown: object;
  isAccordianOpen?: boolean;
  handleAccordianOpen?: any;
  promotion?: any;
  isAffirmEnabled: boolean;
  tooltip: any;
  market: string;
  offer: any;
  phone: string;
}

interface CloseProps {
  color: string;
}

const SlideLeft = posed.div({
  closed: { left: '100vw' },
  open: { left: '0px' },
});

export const Dialog = styled(SlideLeft)`
  position: fixed;
  top: ${(props) => (props.layout === 'tablet' ? '54px' : '0px')};
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.whiteCreamy};
  font-family: ${(props) => props.theme.fontFamily};
  z-index: 1100;
  overflow: scroll;
`;

export const Top = styled.div`
  background-color: ${(props) => props.theme.colors.mineShaftDarker};
  width: 100%;
  min-height: 50px;
  padding: 15px 0px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const Close = styled.div<CloseProps>`
  fill: ${(props) => props.theme.colors[props.color]};
  float: right;
  line-height: 1.563rem;
  position: absolute;
  height: 25px;
  top: 10px;
  right: 15px;
`;

export const HideDetails = styled.div`
  display: inline-block;
  color: ${(props) => props.theme.colors.gray};
  font-size: 0.938rem;
  font-weight: 500;
  vertical-align: middle;
  margin-left: 10px;
`;

function FullScreenDialog(props: FullScreenDialogProps) {
  const {
    pose,
    country,
    length,
    name,
    closeDialog,
    includes,
    category,
    product,
    splitPaymentPercentage,
    pax,
    flight,
    breakdown,
    promotion,
    tooltip,
    market,
    offer,
    phone,
  } = props;

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);
  const layout = useContext(LayoutContext);

  const getTabletContent = () => {
    return (
      <Fragment>
        <Close color="gray" onClick={closeDialog}>
          <Icon color={'inherit'} size={11} icon={'ico-close'} />
          <HideDetails>{t('hideDetails')}</HideDetails>
        </Close>
        <TravelDetails
          includes={includes}
          category={category}
          pax={pax}
          flight={flight}
          breakdown={breakdown}
          promotion={promotion}
          splitPaymentPercentage={splitPaymentPercentage}
          product={product}
          offer={offer}
          tooltip={tooltip}
          market={market}
          phone={phone}
        />
      </Fragment>
    );
  };

  const getMobileContent = () => {
    return (
      <Fragment>
        <Top>
          <Close color="whiteCreamy" onClick={closeDialog}>
            <Icon color={'inherit'} size={11} icon={'ico-close'} />
          </Close>
          <CampaignInfo
            country={country}
            length={length}
            name={name}
            colors={['white', 'white', 'white']}
          />
        </Top>
        <TravelDetails
          includes={includes}
          category={category}
          pax={pax}
          flight={flight}
          breakdown={breakdown}
          promotion={promotion}
          splitPaymentPercentage={splitPaymentPercentage}
          product={product}
          offer={offer}
          tooltip={tooltip}
          market={market}
          phone={phone}
        />
      </Fragment>
    );
  };

  let content: object;
  switch (layout) {
    case 'tablet':
      content = getTabletContent();
      break;
    case 'mobile':
    default:
      content = getMobileContent();
      break;
  }

  return ReactDOM.createPortal(
    <Dialog pose={pose} layout={layout}>
      {content}
    </Dialog>,
    document.body,
  );
}

export default FullScreenDialog;
