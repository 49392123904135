import { ActionType } from '../reducers/airports';
import { api } from '../config';
import mockData from '../mocks/flightsAirports.json';

export const getAirportsData = (categoryId) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockData;

    const url = `${api.category}/${categoryId}/airports/1`;
    const method = 'get';

    const type: ActionType = 'SET_AIRPORTS';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_AIRPORTS',
            success: 'RECEIVED_AIRPORTS',
            fail: 'ERROR_AIRPORTS',
          },
          url,
          // props only used when type = FETCH_MOCK:
          mockResult,
          method,
        },
      }),
    );
  };
};

export const setAirportsData = (data) => ({
  type: 'SET_AIRPORTS_DATA',
  payload: data,
});

export const getCountryAirportsData = () => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockData;

    const url = api.departures;
    const method = 'get';

    const type: ActionType = 'SET_COUNTRY_AIRPORTS';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_COUNTRY_AIRPORTS',
            success: 'RECEIVED_COUNTRY_AIRPORTS',
            fail: 'ERROR_COUNTRY_AIRPORTS',
          },
          url,
          // props only used when type = FETCH_MOCK:
          mockResult,
          method,
        },
      }),
    );
  };
};
