import { getCountryFromUrl, getLocaleFromUrl } from '../utils';

export const setMarket = () => {
  return (dispatch) => {
    dispatch(
      setMarketData({
        country: getCountryFromUrl(),
        language: getLocaleFromUrl(),
      }),
    );
  };
};

export const setMarketData = (data) => ({
  type: 'SET_MARKET_DATA',
  data,
});
