import React, { createContext, useContext, useState } from 'react';
import { isLoggedIn, getAuthToken } from '../utils';

interface ContextObject {
  isLoggedIn: boolean;
  authToken: string;
  setAuthData: any;
}

const initialContext: ContextObject = {
  isLoggedIn: false,
  authToken: '',
  setAuthData: () => {},
};

export const AuthContext = createContext(initialContext);

export const AuthContextProvider = (props) => {
  const [authObject, setAuth] = useState({
    isLoggedIn: isLoggedIn(),
    authToken: getAuthToken(),
  });

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: authObject.isLoggedIn,
        authToken: authObject.authToken,
        setAuthData: setAuth,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
