import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import posed from 'react-pose';
import { ThemeContext } from 'styled-components';
import Modal, { ModalProps } from '../../ui/Modal';
import Icon from '../../ui/Icon';

// Thats so bad ... there is some issue with pose and sharing pose states through the app. Temp workaround.
const RightLeft = posed.div({
  right: { left: '100vw' },
  left: { left: '0px' },
});

export const Dialog = styled(RightLeft)`
  position: fixed;
  top: ${(props) => (props.layout === 'tablet' ? '54px' : '0px')};
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.whiteCreamy};
  z-index: 99;
  overflow: scroll;
`;

export const LeftIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 15px;
  left: 20px;
  z-index: 1;
`;

const MobileDialog = styled(Dialog)`
  z-index: 101;
`;

const FlightInfoDialogModal = (props: ModalProps) => {
  const { pose, onClose, children, layout } = props;

  const theme = useContext(ThemeContext);

  if (layout === 'mobile') {
    return ReactDOM.createPortal(
      <MobileDialog pose={pose} layout={layout}>
        <LeftIcon>
          <Icon
            color={theme.colors.whiteCreamy}
            onClick={onClose}
            size={18}
            icon="backArrow"
          />
        </LeftIcon>
        {children}
      </MobileDialog>,
      document.body,
    );
  }

  return <Modal {...props} />;
};

export default FlightInfoDialogModal;
