import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components/macro';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Icon from '../ui/Icon';
import PromotedCard from './PromotedCard';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../utils';

interface MenucountriesProps {
  continentIndex: number;
  menu: any[];
  closeMenu(): void;
}

const CountryElement = styled.div`
  font-size: 1rem;

  width: 100%;
  color: ${(props) => props.theme.colors.white};
  font-weight: 300;
  padding: 12px 0 12px 10px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    color: unset;
    font-weight: unset;
    padding: 9px 0;
  }

  a {
    &:hover {
      text-decoration: underline !important;
    }
  }
`;

const CountryElementNotAvailable = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
`;

const TripsTo = styled.div`
  font-size: 1rem;
  margin: 15px 0;
  color: ${(props) => props.theme.colors.white};

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    margin: 0 0 15px 0;
    color: unset;
  }

  a {
    font-size: 1.125rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.golden};
    text-decoration: underline !important;
  }
`;

const NotifyMe = styled.div`
  font-size: 1rem;
  padding: 9px 0;
  color: ${(props) => props.theme.colors.white};
  margin-left: 10px;
  margin-top: 10px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    color: unset;
    margin-left: unset;
    margin-top: unset;
  }
`;

const Link = styled.a``;

function Menucountries({
  continentIndex,
  menu,
  closeMenu,
}: MenucountriesProps) {
  const themeStyled = useContext(ThemeContext);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  if (continentIndex === -1) {
    return <></>;
  }

  const subDestinationsWithCampaigns = menu[
    continentIndex
  ]?.subDestinations.filter(
    (subDestination) => subDestination.totalProducts > 0,
  );
  const columnsWithCampaigns = _.chunk(
    subDestinationsWithCampaigns,
    Math.round(subDestinationsWithCampaigns.length / 4),
  );
  const subDestinationsWithNoCampaigns = menu[
    continentIndex
  ]?.subDestinations.filter(
    (subDestination) => subDestination.totalProducts === 0,
  );
  const columnsWithNoCampaigns = _.chunk(
    subDestinationsWithNoCampaigns,
    Math.ceil(subDestinationsWithNoCampaigns.length / 6),
  );

  return (
    <div
      id={`continent-${continentIndex}`}
      style={!mdDown ? { display: 'none' } : {}}
      className={'continents'}
    >
      <Container
        maxWidth={'lg'}
        style={{
          paddingTop: mdDown ? '0' : '32px',
          paddingBottom: mdDown ? '10px' : '17px',
        }}
      >
        <Grid container justify="center">
          <Grid item xs={12} lg={8}>
            <Hidden lgUp>
              <Grid item xs={12} lg={4}>
                {menu[continentIndex]?.highlighted && (
                  <PromotedCard
                    campaign={menu[continentIndex]?.highlighted[0]}
                    closeMenu={closeMenu}
                  />
                )}
              </Grid>
            </Hidden>
            <TripsTo>
              <Link
                href={menu[continentIndex]?.url}
                title={`${t('tripsTo')} ${menu[continentIndex]?.name}`}
                onClick={closeMenu}
              >
                {t('tripsTo')} {menu[continentIndex]?.name}
                &nbsp;
                <Icon
                  icon={'ico-arrow-right'}
                  color={themeStyled.colors.golden}
                  size={10}
                />
              </Link>
              &nbsp;({menu[continentIndex]?.totalProducts} {t('tripsAvailable')}
              )
            </TripsTo>
            <Grid container>
              {columnsWithCampaigns.map((column, n) => (
                <Grid item xs={12} lg={3} key={`column-${n}`}>
                  {column.map((country) => (
                    <CountryElement key={country.id}>
                      <Link
                        href={country.url}
                        title={`${t('tripsTo')} ${country.name}`}
                        onClick={closeMenu}
                      >
                        {country.name} ({country.totalProducts})
                      </Link>
                    </CountryElement>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} lg={4}>
              {menu[continentIndex]?.highlighted && (
                <>
                  {menu[continentIndex]?.highlighted[0] && (
                    <PromotedCard
                      campaign={menu[continentIndex]?.highlighted[0]}
                      closeMenu={closeMenu}
                    />
                  )}
                  {menu[continentIndex]?.highlighted[1] && (
                    <>
                      <hr />
                      <PromotedCard
                        campaign={menu[continentIndex]?.highlighted[1]}
                        closeMenu={closeMenu}
                      />
                    </>
                  )}
                </>
              )}
            </Grid>
          </Hidden>
        </Grid>
      </Container>
      {columnsWithNoCampaigns.length > 0 && (
        <Container
          maxWidth={'xl'}
          style={{
            backgroundColor: mdDown ? 'unset' : themeStyled.colors.gallery,
            paddingBottom: mdDown ? '0' : '17px',
            padding: mdDown ? '0' : 'unset',
          }}
        >
          <Container maxWidth={'lg'}>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{
                borderTop: mdDown
                  ? `1px solid ${themeStyled.colors.doveGray}`
                  : 'unset',
                padding: '10px 0',
              }}
            >
              <Grid item xs={12} lg={12}>
                <NotifyMe>{t('notifyMe')}</NotifyMe>
                <Grid container>
                  {columnsWithNoCampaigns.map((column, n) => (
                    <Grid item xs={12} lg={2} key={`column-${n}`}>
                      {column.map((country) => (
                        <CountryElement key={country.id}>
                          <Link
                            href={country.url}
                            title={`${t('tripsTo')} ${country.name}`}
                            onClick={closeMenu}
                          >
                            <CountryElementNotAvailable>
                              <Icon
                                icon={'NewHeaderBell2'}
                                size={16}
                                color={
                                  mdDown
                                    ? themeStyled.colors.white
                                    : themeStyled.colors.mineShaftDarker
                                }
                                customStyle={{ margin: '0 5px 3px 0' }}
                              />
                              <span>{country.name}</span>
                            </CountryElementNotAvailable>
                          </Link>
                        </CountryElement>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Container>
      )}
    </div>
  );
}

export default Menucountries;
