import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

interface StyledProps {
  type: 'error' | 'warning' | 'success';
}

interface AlertToastProps extends StyledProps {
  message: string;
  showErrorToast: boolean;
  hide(e: any): void;
  customStyles?: {};
}

const alertToastSlideUpIn = keyframes`
  0% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
   }
  100% {
    transform: scaleY(1);
      transform-origin: 0% 0%;
      opacity: 1;
   }
`;

const AlertToastWrapper = styled.div<StyledProps>`
  position: sticky;
  width: 100%;
  margin-bottom: 20px;
  padding: 0.75rem 1.25rem;
  padding-right: 4rem;
  background-color: ${(props) => {
    if (props.type === 'success') return props.theme.colors.green;
    if (props.type === 'warning') return props.theme.colors.golden;
    if (props.type === 'error') return props.theme.colors.red;
    return props.theme.colors.white;
  }};
  text-align: left;
  z-index: 100;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  animation: ${alertToastSlideUpIn} 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: both;
  max-height: 220px;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
`;

const AlertToastMessage = styled.p`
  color: ${(props) => props.theme.colors.white};
  margin: 0;

  a {
    text-decoration: underline;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 1.875rem;
  font-weight: 700;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  color: ${(props) => props.theme.colors.white};
`;

const AlertToast = (props: AlertToastProps) => {
  const { message, type, showErrorToast, hide, customStyles } = props;

  if (!showErrorToast) return null;

  return (
    <AlertToastWrapper
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      type={type}
      style={customStyles}
    >
      <CloseButton type={'button'} role="button" onClick={hide}>
        <span
          aria-hidden="true"
          dangerouslySetInnerHTML={{ __html: '&times;' }}
        />
      </CloseButton>
      <AlertToastMessage dangerouslySetInnerHTML={{ __html: message }} />
    </AlertToastWrapper>
  );
};

AlertToast.displayName = 'AlertToast';

export default AlertToast;
