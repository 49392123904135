import React, { Suspense, useEffect } from 'react';
import SkeletonPlaceholder from '../components/ui/v2/SkeletonPlaceholder';
type Props = {
  container: string;
  children: any;
};

function SuspenseFallback({ container }) {
  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
    }, 1);
  }, []);

  return <SkeletonPlaceholder container={container} />;
}
function WithSuspense({ container, children }: Props) {
  return (
    <Suspense fallback={<SuspenseFallback container={container} />}>
      {children}
    </Suspense>
  );
}

WithSuspense.displayName = 'WithSuspense';

export default WithSuspense;
