import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory as createHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import fetchMiddleware from '../middleware/fetchMiddleware';

// FORCING PROD
const isProd = process.env.NODE_ENV === 'production';
export const history = createHistory();

const loggerMiddleware = createLogger({
  level: 'info',
  collapsed: true,
});

const enhancer = !isProd
  ? composeWithDevTools(
      applyMiddleware(
        thunkMiddleware,
        fetchMiddleware,
        loggerMiddleware, // logger at the end
      ),
    )
  : composeWithDevTools(applyMiddleware(thunkMiddleware, fetchMiddleware));
export default function configureStore(initialState = {}) {
  return createStore(rootReducer(), initialState, enhancer);
}
