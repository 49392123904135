import { ActionType } from '../reducers/contact';
import { api } from '../config';
import mockData from '../mocks/contact.json';

export const setContactData = (data) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockData;

    const url = `${api.contact}`;
    const method = 'post';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {
      data: Object.entries(data)
        .map(
          (e) =>
            encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1] as string),
        )
        .join('&'),
    };

    const type: ActionType = 'SET_CONTACT_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_CONTACT_DATA',
            success: 'RECEIVED_CONTACT_DATA',
            fail: 'ERROR_CONTACT_DATA',
          },
          url,
          mockResult,
          method,
          headers,
          options,
        },
      }),
    );
  };
};
