import { api } from '../config';

export const setCategoryData = (data) => ({
  type: 'SET_CATEGORY_DATA',
  data,
});

export const getConditionsBlock = (blockName: string) => {
  return (dispatch) => {
    const url = `${api.contentBlock}/${blockName}`;
    const method = 'get';

    const type = 'GET_OFFER_CONDITIONS_BLOCK';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: 'FETCH',
          actionTypes: {
            request: 'REQUEST_OFFER_CONDITIONS_BLOCK',
            success: 'RECEIVED_OFFER_CONDITIONS_BLOCK',
            fail: 'ERROR_OFFER_CONDITIONS_BLOCK',
          },
          url,
          method,
        },
      }),
    );
  };
};
