import _ from 'lodash';
import { api } from '../config';
import { UK_COUNTRY_CODE, METHOD_AFFIRM_ID } from '../utils';
import mockDataPostOrder from '../mocks/postOrder.json';
import mockDataStripe from '../mocks/payments/stripe.json';
import mockDataGiroPay from '../mocks/payments/giropay.json';
import mockDataPayPal from '../mocks/payments/paypal.json';
import mockDataBankTransfer from '../mocks/payments/banktransfer.json';
import mockDataRedsys from '../mocks/payments/redsys.json';
import mockDataAffirm from '../mocks/payments/affirm.json';
import mockDataPhone from '../mocks/payments/phone.json';
import mockWalletData from '../mocks/walletCheckout.json';
import mockNotConfirmedPaymentUrl from '../mocks/notConfirmedPaymentUrl.json';
import mockConfirmedPaymentUrl from '../mocks/confirmedPaymentUrl.json';
import { ActionType } from '../reducers/checkout';
import { parseNumberLocal } from '../utils';
import i18n from '../i18n';

export const getTotalPriceFromState = (state) => {
  const prices = _.flatMap(state, (obj) => {
    if (Array.isArray(obj)) {
      return _.map(obj, (arr) => {
        return arr['price'];
      });
    }
    return obj['price'];
  });
  let totalPrice = 0;
  prices.map((price) => {
    totalPrice += price ? price : 0;
    return null;
  });

  return totalPrice;
};

const setBreakdownDataById = (
  state: any,
  prop: string,
  id: any,
  type: string = '',
) => {
  return (dispatch, getState) => {
    const {
      market: { country },
      checkout: {
        summary: { pax },
        insuranceData,
        breakdown,
      },
    } = getState();

    if (state) {
      const data =
        prop !== 'donate' ? findDataById(state, id, type) : { price: id };

      let passengersCount = 0;
      if (pax.adults) passengersCount += pax.adults;
      if (pax.children) passengersCount += pax.children;

      const object = createObjectFromParam(
        breakdown,
        prop,
        data,
        passengersCount,
        country,
        insuranceData,
      );
      return dispatch(setCheckoutBreakdown(object));
    }
  };
};

const findDataById = (state: any, id: any, type: string) => {
  let obj;
  if (type !== '') {
    obj = _.find(state, { id: id, type: type });
  } else {
    obj = _.find(state, ['id', id]);
  }
  return obj;
};

const createObjectFromParam = (
  object,
  prop,
  val,
  passengersCount,
  country,
  insuranceData,
) => {
  let last = object;
  if (val === undefined || val === null) return last;

  if (last.hasOwnProperty(prop) && Array.isArray(last[prop])) {
    if (prop === 'options') {
      const priceTotal = passengersCount * parseFloat(val.price);
      const priceBeautyPerPerson = parseNumberLocal(parseFloat(val.price));
      const priceBeautyTotal = parseNumberLocal(priceTotal);
      const priceDetails = passengersCount + ' * ' + priceBeautyPerPerson;

      val = {
        id: val.id,
        type: 'options',
        multiple: true,
        description: val.supplierCategory,
        selected: true,
        price: priceTotal,
        priceDetails: priceDetails,
        priceBeauty: priceBeautyTotal,
      };
    } else if (prop === 'insurances') {
      const title =
        val.type === 'ampliado'
          ? i18n.t('insuranceAmpliadoTitle')
          : i18n.t('insuranceCancelTitle');

      const insuranceRecalculatedData =
        insuranceData &&
        insuranceData[val.type === 'ampliado' ? 'package' : 'cancel']
          ? insuranceData[val.type === 'ampliado' ? 'package' : 'cancel']
          : undefined;

      const pricePerPerson = insuranceRecalculatedData
        ? parseFloat(insuranceRecalculatedData.TotalPremium) / passengersCount
        : val.pricePerAssured;
      const priceTotal = insuranceRecalculatedData
        ? parseFloat(insuranceRecalculatedData.TotalPremium)
        : val.priceTotal;

      const priceBeautyPerPerson = parseNumberLocal(parseFloat(pricePerPerson));
      const priceBeautyTotal = parseNumberLocal(parseFloat(priceTotal));
      const priceDetails = passengersCount + ' * ' + priceBeautyPerPerson;

      val = {
        id: val.id,
        type: 'insurances',
        multiple: true,
        singleInArray: country === UK_COUNTRY_CODE,
        description: title,
        selected: true,
        package: val.type,
        price: parseFloat(priceTotal),
        priceDetails: priceDetails,
        priceBeauty: priceBeautyTotal,
      };
    }

    last[prop] = last[prop] || [];
    last[prop].push(val);
  } else {
    last[prop] = val;
  }
  return last;
};

export const setFilledData = (data) => {
  return (dispatch, getState): Promise<any> => {
    const {
      checkout: {
        summary: {
          accommodations: { supplements: accomodationsSupplements },
          supplements: { supplements: supplementsSupplements },
          insurance: { supplements: insuranceSupplements },
        },
      },
    } = getState();

    if (data.accomodation !== '') {
      dispatch(
        setBreakdownDataById(
          accomodationsSupplements,
          'accommodation',
          data.accomodation,
        ),
      );
    }

    if (data.insurances.length > 0) {
      data.insurances.forEach((el) =>
        dispatch(
          setBreakdownDataById(
            insuranceSupplements,
            'insurances',
            el.id,
            el.type,
          ),
        ),
      );
    }

    if (data.supplements.length > 0) {
      data.supplements.forEach((el) =>
        dispatch(setBreakdownDataById(supplementsSupplements, 'options', el)),
      );
    }

    if (data.donations !== '') {
      dispatch(setBreakdownDataById({}, 'donate', data.donations));
    }

    if (data.extraNights && data.extraNights.length > 0) {
      data.extraNights.forEach((el) => dispatch(addExtraNight(el)));
      dispatch(setExtraNightSelectedIndex(data.selectedExtraNightIndex));
    }

    dispatch(calculatePaymentFee());

    return Promise.resolve();
  };
};

export const getPromotionData = (total: number, discounts: any[]) =>
  discounts.filter((discount) => total >= discount.minimum).pop();

export const isSplitPaymentEnabled = (paymentMethodId) => {
  switch (paymentMethodId) {
    case METHOD_AFFIRM_ID:
      return false;
  }

  return true;
};

// Clean checkout data
export const cleanCheckout = () => {
  return {
    type: 'CLEAN_CHECKOUT_DATA',
  };
};

// Summary
export const setCheckoutSummary = (data) => {
  return {
    type: 'SET_CHECKOUT_SUMMARY_DATA',
    data,
  };
};

// Tooltip
export const setCheckoutSummaryTooltip = (data) => ({
  type: 'SET_CHECKOUT_SUMMARY_TOOLTIP',
  data,
});

export const cleanCheckoutSummaryTooltip = () => ({
  type: 'CLEAN_CHECKOUT_SUMMARY_TOOLTIP',
});

// Budget Modal
export const setBudgetModal = (data) => ({
  type: 'SET_BUDGET_MODAL',
  data,
});

// Breakdown
export const setCheckoutBreakdown = (data) => {
  return {
    type: 'SET_CHECKOUT_BREAKDOWN_DATA',
    data,
  };
};

export const addCheckoutBreakdown = (data) => ({
  type: 'ADD_CHECKOUT_BREAKDOWN_DATA',
  data,
});

export const handleBreakdown = (data) => {
  return (dispatch) => {
    if (data.selected) {
      dispatch(addCheckoutBreakdown(data));

      const { id, description, price, priceBeauty } = data;
      if (price > 0) {
        dispatch(setCheckoutSummaryTooltip({ id, description, priceBeauty }));
        setTimeout(() => dispatch(cleanCheckoutSummaryTooltip()), 2000);
      }
    } else {
      dispatch(removeCheckoutBreakdown(data));
    }

    dispatch(calculatePaymentFee());
  };
};

export const removeCheckoutBreakdown = (data) => ({
  type: 'REMOVE_CHECKOUT_BREAKDOWN_DATA',
  data,
});

// Passengers
export const setCheckoutPassengers = (data) => ({
  type: 'SET_CHECKOUT_PASSENGERS_DATA',
  data,
});

// Insurance
export const setCheckoutInsurance = (data) => ({
  type: 'SET_CHECKOUT_INSURANCE_DATA',
  data,
});

// Payment
export const setCheckoutPayment = (data) => ({
  type: 'SET_CHECKOUT_PAYMENT_DATA',
  data,
});

export const setCheckoutVoucherCode = (data) => ({
  type: 'SET_CHECKOUT_VOUCHER_CODE_DATA',
  data,
});

export const setCheckoutPaymentMethod = (data) => ({
  type: 'SET_CHECKOUT_PAYMENT_METHOD_DATA',
  data,
});

export const calculatePaymentFee = () => {
  return async (dispatch, getState) => {
    const {
      checkout: { breakdown },
    } = getState();

    if (breakdown.paymentFee && breakdown.paymentFee.fee > 0) {
      const totalPrice =
        getTotalPriceFromState(breakdown) - breakdown.paymentFee.price;
      const percentagePrice = (totalPrice / 100) * breakdown.paymentFee.fee;
      const priceBeauty = parseNumberLocal(percentagePrice);

      dispatch(
        addCheckoutBreakdown({
          ...breakdown.paymentFee,
          price: percentagePrice,
          priceBeauty: priceBeauty,
        }),
      );
    }
  };
};

export const postOrder = (data, paymentMethodId) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    let mockResult: any = mockDataPostOrder;
    const url = `${api.checkout.order}`;
    const method = 'post';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {
      data: JSON.stringify(data),
    };

    switch (paymentMethodId) {
      case 6:
        mockResult = mockDataStripe;
        break;
      case 2:
        mockResult = mockDataPayPal;
        break;
      case 7:
        mockResult = mockDataGiroPay;
        break;
      case 3:
        mockResult = mockDataBankTransfer;
        break;
      case 1:
        mockResult = mockDataRedsys;
        break;
      case 11:
        mockResult = mockDataAffirm;
        break;
      case 12:
        mockResult = mockDataPhone;
        break;
      case 13:
        mockResult = mockDataBankTransfer;
        break;
    }

    const type: ActionType = 'ORDER_CREATE_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_ORDER_CREATE_DATA',
            success: 'RECEIVED_ORDER_CREATE_DATA',
            fail: 'ERROR_ORDER_CREATE_DATA',
          },
          url,
          mockResult,
          method,
          headers,
          options,
        },
      }),
    );
  };
};

export const onPaymentSuccess = (
  data,
  paymentMethodId,
  status: 'create' | 'confirm',
) => {
  return (dispatch) => {
    const parameters = Object.entries(data)
      .map(
        (e) =>
          encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1] as string),
      )
      .join('&');
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    let mockResult: any = mockDataPostOrder;

    let url;
    switch (paymentMethodId) {
      case 6:
        url = api.payment.stripe[status];
        break;
      case 11:
        url = api.payment.affirm[status];
        break;
    }

    const method = 'post';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {
      data: parameters,
    };

    const type: ActionType = 'PAYMENT_SUCCESS_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_PAYMENT_SUCCESS_DATA',
            success: 'RECEIVED_PAYMENT_SUCCESS_DATA',
            fail: 'ERROR_PAYMENT_SUCCESS_DATA',
          },
          url,
          mockResult,
          method,
          headers,
          options,
        },
      }),
    );
  };
};

export const recoverPasswordByEmail = (data) => {
  return (dispatch) => {
    const parameters = Object.entries(data)
      .map(
        (e) =>
          encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1] as string),
      )
      .join('&');

    const url = `${api.passwordEmail}`;
    const method = 'post';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {
      data: parameters,
    };

    const type: ActionType = 'RECOVER_PASSWORD_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: 'FETCH',
          actionTypes: {
            request: 'REQUEST_RECOVER_PASSWORD_DATA',
            success: 'RECEIVED_RECOVER_PASSWORD_DATA',
            fail: 'ERROR_RECOVER_PASSWORD_DATA',
          },
          url,
          method,
          headers,
          options,
        },
      }),
    );
  };
};

export const stripeErrorToAdiona = (data) => {
  return (dispatch) => {
    const url = `${api.payment.stripe.error}`;
    const method = 'post';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {
      data: Object.entries(data)
        .map(
          (e) =>
            encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1] as string),
        )
        .join('&'),
    };
    const type: ActionType = 'STRIPE_ERROR_DATA';

    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: 'FETCH',
          actionTypes: {
            request: 'REQUEST_STRIPE_ERROR_DATA',
            success: 'RECEIVED_STRIPE_ERROR_DATA',
            fail: 'ERROR_STRIPE_ERROR_DATA',
          },
          url,
          method,
          headers,
          options,
        },
      }),
    );
  };
};

export const getMyWallet = () => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockWalletData;

    const url = `${api.checkout.wallet}`;
    const method = 'get';

    const type: ActionType = 'GET_WALLET_CODES';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_WALLET_CODES',
            success: 'RECEIVED_WALLET_CODES',
            fail: 'ERROR_WALLET_CODES',
          },
          url,
          mockResult,
          method,
        },
      }),
    );
  };
};

// extra nights
export const getExtraNight = (orderAttemptId: string, nightNumber: string) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    let mockResult = {
      id:
        Math.floor(Math.random() * (Math.floor(20) - Math.ceil(1) + 1)) +
        Math.ceil(1),
      incrementPrice:
        Math.floor(Math.random() * (Math.floor(1000) - Math.ceil(100) + 1)) +
        Math.ceil(1),
      incrementPriceBeauty: '232€',
      returnDate: '26/10/2020',
    };

    const url = api.checkout.extranight(orderAttemptId, nightNumber);
    const method = 'get';
    const options = {
      id: nightNumber,
    };

    const type: ActionType = 'GET_EXTRA_NIGHT';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_EXTRA_NIGHT',
            success: 'RECEIVED_EXTRA_NIGHT',
            fail: 'ERROR_EXTRA_NIGHT',
          },
          url,
          mockResult,
          method,
          options,
        },
      }),
    );
  };
};

export const setExtraNightSelectedIndex = (data) => ({
  type: 'SET_EXTRA_NIGHT_SELECTED_INDEX',
  data,
});

export const addExtraNight = (data) => ({
  type: 'RECEIVED_EXTRA_NIGHT',
  payload: data,
});

export const setExtraNightNotAvailable = (data) => ({
  type: 'SET_EXTRA_NIGHT_AS_NOT_AVAILABLE',
  data,
});

//confirm
export const getNotConfirmedPaymentUrl = (
  orderId: string,
  isFractional: boolean,
) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockNotConfirmedPaymentUrl;

    const url =
      `${api.checkout.notConfirmed}/${orderId}` +
      (isFractional ? '?fractional' : '');
    const method = 'get';

    const type: ActionType = 'GET_NOT_CONFIRMED_PAYMENT';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_NOT_CONFIRMED_PAYMENT',
            success: 'RECEIVED_NOT_CONFIRMED_PAYMENT',
            fail: 'ERROR_NOT_CONFIRMED_PAYMENT',
          },
          url,
          mockResult,
          method,
        },
      }),
    );
  };
};

export const getConfirmedPaymentUrl = (
  bookingId: string,
  paymentMethod: string,
  token: string,
  payerId: string,
) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockConfirmedPaymentUrl;

    const parameters = Object.entries({ token, payerId })
      .map(
        (e) =>
          encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1] as string),
      )
      .join('&');

    const url = `${api.checkout.confirmed(bookingId, paymentMethod)}`;
    const method = 'post';

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {
      data: parameters,
    };

    const type: ActionType = 'GET_CONFIRMED_PAYMENT';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_CONFIRMED_PAYMENT',
            success: 'RECEIVED_CONFIRMED_PAYMENT',
            fail: 'ERROR_CONFIRMED_PAYMENT',
          },
          url,
          mockResult,
          method,
          headers,
          options,
        },
      }),
    );
  };
};
