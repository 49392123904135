import { ActionType } from '../reducers/destinationPoi';
import { api } from '../config';
import mockData from '../mocks/destinationPoi.json';

export const setDestinationPoiData = (countryId) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockData;

    const url = `${api.landing}/poi/${countryId}`;
    const method = 'get';

    const type: ActionType = 'SET_DESTINATION_POI_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_DESTINATION__POI_DATA',
            success: 'RECEIVED_DESTINATION_POI_DATA',
            fail: 'ERROR_DESTINATION_POI_DATA',
          },
          url,
          // props only used when type = FETCH_MOCK:
          mockResult,
          method,
        },
      }),
    );
  };
};
