import React, { useContext, useState, Fragment } from 'react';
import { ThemeContext } from 'styled-components';
import styled, { css } from 'styled-components/macro';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import posed from 'react-pose';

import MenuCountries from './MenuCountries';

import Icon from '../ui/Icon';

const ANIMATION_DELAY = 255;

type Props = {
  menu: any[];
  handlerDrawer?(index: number): void;
  closeMenu(): void;
  continentIndex: number;
  setContinent?: any;
};

type StyledProps = {
  active?: boolean;
};

const CountriesToggle = posed.div({
  countriesClosed: { height: 0 },
  countriesOpen: { height: 'auto' },
});

const NavbarNav = styled.div`
  display: flex;
  flex-direction: column;
  list-style: none;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    flex-direction: row;
  }
`;

export const NavItem = styled.div<StyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white};
  margin-right: 0;
  padding: 19px 19px 19px 0;
  cursor: pointer;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    &:hover {
      background-color: ${(props) => props.theme.colors.mineShaft};
    }
  }

  ${(props) =>
    !props.active &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.doveGray};
    `}

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    justify-content: center;
    border-bottom: unset;
    padding-bottom: 13px;
    padding: 16px 15px 13px 15px;

    &:after {
      position: absolute;
      background: 'transparent';
      display: block;
      height: 4px;
      bottom: 0;
      content: '';
    }

    &:hover {
      &:after {
        width: 100%;
        left: 0;
      }
    }
  }

  ${(props) =>
    props.active &&
    css`
      @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
        color: ${(props) => props.theme.colors.golden};
        &:after {
          width: 100%;
          left: 0;
        }
      }
    `}
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

function MenuContinents({
  menu,
  handlerDrawer,
  continentIndex,
  setContinent,
  closeMenu,
}: Props) {
  const themeStyled = useContext(ThemeContext);

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(-1);

  return (
    <NavbarNav>
      {menu.map((item, index) => {
        let active = continentIndex === index;

        return (
          <Fragment key={item.id}>
            <NavItem
              onClick={() =>
                handlerDrawer && !mdDown
                  ? handlerDrawer(index)
                  : setTimeout(() => {
                      setOpen(open === index ? -1 : index);
                      setContinent(open === index ? -1 : index);
                    }, ANIMATION_DELAY)
              }
              active={active}
            >
              <TextWrapper>
                <span>{item.name}</span>
              </TextWrapper>
              {mdDown ? (
                <Icon
                  icon={active ? 'iconsdown' : 'iconsright-arrow'}
                  size={17}
                  color={themeStyled.colors.white}
                />
              ) : (
                <Icon
                  icon={active ? 'iconsup-arrow1' : 'iconsdown'}
                  iconSet={active ? 'common' : 'main'}
                  size={14}
                  color={
                    active
                      ? themeStyled.colors.golden
                      : themeStyled.colors.white
                  }
                  customStyle={{
                    marginLeft: '11px',
                    marginBottom: '3px',
                  }}
                />
              )}
            </NavItem>
            {mdDown && (
              <CountriesToggle
                pose={open === index ? 'countriesOpen' : 'countriesClosed'}
                style={{
                  overflow: open === index ? '' : 'hidden',
                  borderBottom:
                    open === index
                      ? `1px solid ${themeStyled.colors.doveGray}`
                      : '',
                }}
              >
                <MenuCountries
                  menu={menu}
                  continentIndex={index}
                  closeMenu={() => {
                    closeMenu();
                    setContinent(-1);
                  }}
                />
              </CountriesToggle>
            )}
          </Fragment>
        );
      })}
    </NavbarNav>
  );
}

export default MenuContinents;
