import React, {
  useEffect,
  useContext,
  useState,
  useLayoutEffect,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import { ThemeContext } from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { enableScroll, disableScroll } from '../utils';
import { Link, useRouteMatch } from 'react-router-dom';

import { headerActions } from '../actions';

import Logo from '../components/header/Logo';
import Help from '../components/help/Help';
import MyAccount from '../components/header/MyAccount';
import YoBar from '../components/promotion/YoBar';
import PromotionModal from '../components/promotion/PromotionModal';

import { promotionActions } from '../actions';

type HeaderObject = {
  phone: string;
  scheduleShort: string;
  isCustomerServiceAvailable: boolean;
  menu: any[];
  promotionModeEnabled: boolean;
  isAffiliateDomain: boolean;
  dealOfTheWeek?: string;
};

type Props = {
  header: HeaderObject;
  country: string;
  language: string;
  config: any;
  getHeaderData(): void;
  promotion: Promotion;
  setShowPromoModal: any;
  showPromoModal: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    appBar: (props) => ({
      backgroundColor: props.headerBackground,
      boxShadow: 'none',
    }),
    MuiDrawer: (props) => ({
      backgroundColor: props.headerBackground,
      position: 'relative' as 'relative',
    }),
    toolbar: {
      minHeight: 56,
      maxHeight: 56,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

const handleDisableBodyScroll = (disable) => {
  if (disable) {
    disableScroll();
  } else {
    enableScroll();
  }
};

function HeaderCheckout({
  header: {
    isCustomerServiceAvailable,
    phone,
    menu,
    dealOfTheWeek,
    isAffiliateDomain,
  },
  language,
  config,
  getHeaderData,
  promotion,
  setShowPromoModal,
  showPromoModal,
}: Props) {
  const langMatch = useRouteMatch('/(es|fr|de|uk|us|ca)?');
  const themeStyled = useContext(ThemeContext);
  const classes = useStyles({
    headerBackground: themeStyled.colors.headerBackground,
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const [openHelp, setOpenHelp] = useState(false);

  const modalToggleButton = document.getElementById('modalToggleButton');

  const anchorRef = useRef(null);

  const handlerHelp = (state: boolean) => {
    setOpenHelp(state);
    handleDisableBodyScroll(state);
  };

  const togglePromotionModal = () => {
    setShowPromoModal(!showPromoModal);
    handleDisableBodyScroll(!showPromoModal);
  };

  const [showCoronaModal, setShowCoronaModal] = useState(() => false);
  const toggleCoronaModal = () => {
    setShowCoronaModal(!showCoronaModal);
    handleDisableBodyScroll(!showCoronaModal);
  };

  useEffect(() => {
    getHeaderData();
  }, [getHeaderData]);

  const headerHeight = document.getElementsByTagName('header')[0]?.clientHeight;
  useEffect(() => {
    const contentWrapper = document.getElementsByClassName(
      'content-wrapper',
    ) as HTMLCollectionOf<HTMLDivElement>;

    if (contentWrapper && contentWrapper.length > 0) {
      contentWrapper[0].style['margin-top'] = `${headerHeight}px`;
    }
  }, [menu, headerHeight]);

  useLayoutEffect(() => {
    modalToggleButton &&
      modalToggleButton.addEventListener('click', () => {
        setShowPromoModal(!showPromoModal);
      });

    return () => {
      modalToggleButton &&
        modalToggleButton.removeEventListener('click', () => {
          setShowPromoModal(!showPromoModal);
        });
    };
  });

  const [yobarHeight, setYobarHeight] = useState(0);
  const promotionEnabled = promotion.enabled;

  return (
    <>
      <AppBar ref={anchorRef} className={classes.appBar}>
        <Toolbar
          className={classes.toolbar}
          disableGutters={mdUp ? true : false}
          id={'innerHeader'}
        >
          <Link to={langMatch?.params[0] ? `/${langMatch.params[0]}` : '/'}>
            <Logo
              language={language}
              affiliateId={config && config.affiliate.id.toString()}
            />
          </Link>
          <Help
            isCustomerServiceAvailable={isCustomerServiceAvailable}
            phone={phone}
            handlerHelp={handlerHelp}
            open={openHelp}
            yobarHeight={yobarHeight}
            promotionModeEnabled={false}
            isCheckout={true}
            alignRight={!dealOfTheWeek}
            toggleCoronaModal={toggleCoronaModal}
          />
          {smUp && !isAffiliateDomain && (
            <MyAccount
              customerLoggedName={config.customer && config.customer.name}
              lang={langMatch?.params[0]}
              handlerAccount={() => {}}
              logout={() => {}}
              isCheckout
            />
          )}
        </Toolbar>
        {promotionEnabled && (
          <>
            <YoBar
              isFlash={promotion.flash}
              isDiscount={promotion.discount}
              toggleModal={togglePromotionModal}
              styling={promotion.styling.yoBar}
              setYobarHeight={setYobarHeight}
              isAffiliateDomain={isAffiliateDomain}
            />
            <PromotionModal
              styling={promotion.styling.modal}
              isFlash={promotion.flash}
              isDiscount={promotion.discount}
              pose={showPromoModal ? 'left' : 'right'}
              isVisible={showPromoModal}
              onClose={togglePromotionModal}
            />
          </>
        )}
      </AppBar>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    language:
      state.config?.data?.language?.length > 0 &&
      state.config?.data?.language[0].locale,
    country: state.config?.data?.market,
    header: state.header.data,
    config: state.config.data,
    promotion: state.promotion.data,
    showPromoModal: state.promotion.showModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getHeaderData: () => dispatch(headerActions.getHeaderData()),
  setShowPromoModal: (status) =>
    dispatch(promotionActions.setShowPromoModal(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCheckout);
