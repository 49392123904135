import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import { useTheme, createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl, isLoggedIn } from '../../utils';

import Icon from '../ui/Icon';
import Button from '../ui/Button';

type StyledProps = {
  mustShow?: boolean;
};
type Props = {
  isFlash: boolean;
  isDiscount: boolean;
  promotionEnds?: string;
  toggleModal: () => void;
  forwardedRef?: any;
  mustShow?: boolean;
  styling: PromotionStylingYoBar;
  setYobarHeight?: any;
  isAffiliateDomain?: boolean;
};

const PromotionIntegratedButton = styled.div`
  font-size: 0.938rem;
  color: ${(props) => props.theme.colors.white};
  font-weight: normal;
  border: none;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    text-transform: lowercase;
    line-height: 0.938rem;
  }
`;

const SubTitle = styled.h3`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1rem;
  margin: 0;
  color: ${(props) => props.theme.colors.white};

  span {
    font-weight: 600;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 0.875rem;
    padding-left: 0px;
    span {
      display: block;
    }
  }
`;

const PromoTitle = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fontFamilyTertiaryMedium};
  font-size: 1.25rem;
  color: ${(props) => props.theme.colors.white};
  width: 30%;
  line-height: 0.9;

  span {
    display: block;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: auto;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    width: 100%;
    font-size: 1.875rem;
    margin-top: 10px;
    margin-left: 30px;

    span {
      display: inline-block;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: auto;
    font-size: 1.625rem;
    margin: 10px 0 10px 0;

    span {
      display: inline-block;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1.25rem;
  }

  ${(props) =>
    props.mustShow &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        margin: 0px;
        font-size: 0.938rem;
      }
    `}
`;

const Title = styled.h2<{ color?: string }>`
  font-family: ${(props) => props.theme.fontFamilyTertiaryMedium}!important;
  font-weight: 900;
  margin: 0;
  color: ${(props) => props.color || props.theme.colors.white};

  small {
    color: ${(props) => props.theme.colors.white};
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 1rem;

    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
      margin-left: 8px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      display: block;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 0.813rem;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 1.25rem;
    display: inline;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 1.125rem;
    padding: 8px 10px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1rem;
    width: calc(80% - 40px);
    margin: 0;
    display: block;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const TitleIconWrapper = styled.div<{ margin: string }>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin};
`;

const Heading = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  font-size: 1.75rem;
  color: ${(props) => props.color};
  font-weight: 400;
  margin-left: 20px;

  span {
    font-weight: 700;
    margin-left: 5px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mdLegacy}) {
    font-size: 0.9375rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1.125rem;
    margin-left: 8px;
  }
`;

const SubtitleHeading = styled.div<{ color: string }>`
  font-size: 0.813rem;
  color: ${(props) => props.color};
  font-weight: 500;

  span {
    font-weight: 700;
  }
`;

const IconWrapper = styled.div`
  width: auto;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 146px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 142px;
  }
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: (props) => ({
      cursor: 'pointer',
      background:
        props.backgroundImage !== undefined
          ? `${props.backgroundColor} url('${props.backgroundImage}') 0 0 repeat`
          : props.backgroundColor,
      padding: '4px 12px ',
      boxShadow: `0 4px 13px 0 ${props.shadowColor}`,
      [theme.breakpoints.down('md')]: {
        padding: '4px 16px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '4px',
      },
    }),
    textBox: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '4px',
      },
    },
  }),
);

function YoBar({
  isFlash,
  isDiscount,
  promotionEnds,
  toggleModal,
  mustShow,
  styling,
  setYobarHeight,
  isAffiliateDomain,
}: Props) {
  const { campaignId } = useParams();

  const isCampaign = campaignId && !mustShow ? true : false;
  const themeStyled = useContext(ThemeContext);

  let backgroundImage;
  if (styling.background !== undefined) {
    try {
      backgroundImage = require(`../../assets/images/${styling.background}`);
    } catch {
      // console.log('yobar background image not found');
    }
  }

  const classes = useStyles({
    white: themeStyled.colors.white,
    backgroundColor: styling.backgroundColor,
    backgroundImage: backgroundImage,
    shadowColor: themeStyled.colors.mineShaftDarker50,
  });

  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const smOnly = useMediaQuery(theme.breakpoints.only('sm'));

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const yoBarTitle = smUp ? t('yoBarTitle') : t('yoBarTitleMobile');
  const yoBarTitleSecondary = smUp
    ? t('yoBarTitleSecondary')
    : t('yoBarTitleSecondaryMobile');

  const isDouble = isDiscount && isFlash;
  const userLoggedIn = isLoggedIn();

  useEffect(() => {
    if (setYobarHeight) {
      setYobarHeight(document.getElementById('yobar')?.clientHeight);
    }
  }, [setYobarHeight]);

  const lang = getLocaleFromUrl()?.substring(0, 2).toUpperCase();

  const regularPromotionLayout = () => {
    return (
      <Container
        maxWidth={false}
        className={classes.container}
        id={'yobar'}
        onClick={toggleModal}
      >
        <Grid container>
          <Grid
            item
            xs={10}
            sm={9}
            md={8}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {styling.icon && (
              <IconWrapper>
                <Icon
                  icon={`${styling.icon}${lang}`}
                  color={styling.iconColor ? styling.iconColor : ''}
                  iconSet={'promos'}
                  customStyle={{
                    maxWidth: '180px',
                  }}
                />
              </IconWrapper>
            )}

            <Heading
              color={
                styling.titleColor
                  ? styling.titleColor
                  : themeStyled.colors.white
              }
              dangerouslySetInnerHTML={{
                __html: xsDown
                  ? t('promotionCampaignBannerTitle')
                  : t('promotionTitle'),
              }}
            />
          </Grid>
          <Grid container item xs={2} sm={3} md={4} alignItems={'center'}>
            <Hidden only="xs">
              <Grid
                item
                sm={7}
                md={!userLoggedIn ? 12 : 7}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {!userLoggedIn && mdUp && (
                  <Icon
                    icon={'newarrowright'}
                    size={20}
                    customStyle={{ marginRight: '10px' }}
                    color={
                      styling.subtitleColor
                        ? styling.subtitleColor
                        : themeStyled.colors.white
                    }
                  />
                )}
                {mdUp && (
                  <SubtitleHeading
                    color={
                      styling.subtitleColor
                        ? styling.subtitleColor
                        : themeStyled.colors.white
                    }
                    onClick={() => !userLoggedIn && toggleModal()}
                    dangerouslySetInnerHTML={{
                      __html:
                        !userLoggedIn && !isAffiliateDomain && mdUp
                          ? t('yoBarSubTitleLoginRegister')
                          : t('yoBarSubTitleSecondary'),
                    }}
                    style={!userLoggedIn ? { cursor: 'pointer' } : {}}
                  />
                )}
                {userLoggedIn && (
                  <Icon
                    icon={'newarrowright'}
                    size={20}
                    customStyle={{ marginLeft: '10px' }}
                    color={
                      styling.subtitleColor
                        ? styling.subtitleColor
                        : themeStyled.colors.white
                    }
                  />
                )}
              </Grid>
            </Hidden>
            {(smDown || userLoggedIn) && (
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                style={
                  mdUp
                    ? { paddingRight: '100px' }
                    : smOnly
                    ? { paddingRight: '25px' }
                    : {}
                }
              >
                <Button
                  text={mdUp ? t('yoBarButton') : t('yoBarButtonLittle')}
                  customStyle={{
                    borderRadius: '2px',
                    fontSize: '0.75rem',
                    padding: '8px',
                    backgroundColor: styling.buttonBackgroundColor,
                    color: styling.buttonTextColor,
                    marginLeft: smUp ? '20px' : '0',
                    border: 'none',
                  }}
                  onClick={toggleModal}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  };

  const oldPromotionLayout = () => {
    return (
      <Container maxWidth={false} className={classes.container} id={'yobar'}>
        <Grid
          container
          alignItems="center"
          style={{
            justifyContent: smUp ? 'center' : 'unset',
            paddingLeft: '0',
          }}
        >
          {!mustShow && (
            <>
              {isDouble && (
                <Grid
                  item
                  xs={10}
                  sm={12}
                  md={2}
                  xl={3}
                  style={{
                    display: 'flex',
                    position: 'relative' as 'relative',
                  }}
                >
                  {styling.icon && (
                    <Icon
                      icon={styling.icon}
                      size={56}
                      color={styling.iconColor ? styling.iconColor : ''}
                      customStyle={{
                        marginLeft: lgUp ? '30px' : '0',
                        marginRight: '10px',
                        display: smDown ? 'none' : 'block',
                      }}
                    />
                  )}
                  <PromoTitle
                    dangerouslySetInnerHTML={{ __html: t('promotionTitle') }}
                  />
                  <Hidden mdDown>
                    {styling.arrowUp && (
                      <Icon
                        icon={styling.arrowUp}
                        size={40}
                        customStyle={
                          mdUp
                            ? {
                                position: 'absolute' as 'absolute',
                                top: '-7px',
                                right: '10px',
                              }
                            : {
                                position: 'absolute' as 'absolute',
                                top: '3px',
                                left: '10px',
                              }
                        }
                      />
                    )}
                    {styling.arrowDown && (
                      <Icon
                        icon={styling.arrowDown}
                        size={40}
                        customStyle={
                          mdUp
                            ? {
                                position: 'absolute' as 'absolute',
                                bottom: '-7px',
                                right: '10px',
                              }
                            : {
                                position: 'absolute' as 'absolute',
                                top: '3px',
                                left: '278px',
                              }
                        }
                      />
                    )}
                  </Hidden>
                </Grid>
              )}
              <Grid container item xs={12} sm={10} md={7} xl={6}>
                <Grid
                  item
                  xs={isDouble ? 6 : 12}
                  sm={isDouble ? 6 : 12}
                  md={12}
                  className={classes.textBox}
                >
                  <TitleIconWrapper margin={isDiscount ? '' : 'auto'}>
                    {(styling.iconFlash || styling.icon) && (
                      <Hidden smDown={isDouble}>
                        <Icon
                          icon={styling.iconFlash || styling.icon}
                          color={styling.iconColor ? styling.iconColor : ''}
                          size={
                            mdUp && !isDouble
                              ? styling.iconSize
                              : styling.iconSize * 0.66
                          }
                          customStyle={{
                            marginRight: mdDown ? '4px' : '0',
                          }}
                        />
                      </Hidden>
                    )}
                    <Title
                      color={styling.titleColor}
                      dangerouslySetInnerHTML={{ __html: yoBarTitle }}
                    />
                    {!isDiscount && lgUp && styling.iconFlash && (
                      <Icon
                        icon={styling.iconFlash}
                        size={
                          !isDouble ? styling.iconSize : styling.iconSize * 0.66
                        }
                      />
                    )}
                  </TitleIconWrapper>
                  {isDiscount && (
                    <SubTitle
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'yoBarSubTitle',
                          'OUR <span>BEST OFFER EVER</span>',
                        ),
                      }}
                    />
                  )}
                </Grid>
                {isDouble && (
                  <Grid
                    item
                    xs={6}
                    md={12}
                    className={classes.textBox}
                    style={{
                      borderTop: mdUp
                        ? `1px solid ${styling.lineColor}`
                        : 'none',
                      marginRight: lgUp ? '15px' : '0',
                    }}
                  >
                    {styling.iconDiscount && (
                      <Hidden smDown>
                        <Icon
                          icon={styling.iconDiscount}
                          color={styling.iconColor ? styling.iconColor : ''}
                          size={
                            mdUp && !isDouble
                              ? styling.iconSize
                              : styling.iconSize * 0.66
                          }
                          customStyle={{
                            marginRight: mdDown ? '4px' : '0',
                          }}
                        />
                      </Hidden>
                    )}
                    <Title
                      dangerouslySetInnerHTML={{ __html: yoBarTitleSecondary }}
                    />
                    {isDiscount && (
                      <SubTitle>
                        <span>{t('yoBarSubTitleSpanSecondary') + ' '}</span>
                        {t('yoBarSubTitleSecondary')}
                      </SubTitle>
                    )}
                  </Grid>
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={2} md={2}>
            {mustShow && xsDown ? (
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '8px',
                }}
              >
                <PromoTitle
                  mustShow={mustShow}
                  dangerouslySetInnerHTML={{ __html: t('promotionTitle') }}
                />
                {isDiscount && !promotionEnds && (
                  <SubTitle
                    style={{
                      marginBottom: 0,
                      paddingLeft: 0,
                      lineHeight: 0.9,
                    }}
                  >
                    {t('yoBarSubTitleSpanSecondary')}
                  </SubTitle>
                )}
                <PromotionIntegratedButton onClick={toggleModal}>
                  {t('yoBarButtonLittle')}
                </PromotionIntegratedButton>
              </Grid>
            ) : (
              <ButtonContainer>
                {mdUp && styling.arrowRight && (
                  <Icon
                    icon={styling.arrowRight}
                    color={themeStyled.colors.white}
                    size={51}
                    customStyle={{
                      maxHeight: '13px',
                      alignItems: 'center',
                      display: 'flex',
                      margin: 'auto',
                      paddingRight: '15px',
                    }}
                  />
                )}
                <Button
                  text={mdUp ? t('yoBarButton') : t('yoBarButtonLittle')}
                  customStyle={{
                    borderRadius: '2px',
                    fontSize: mdUp ? '0.75rem' : '0.813rem',
                    padding: mdUp ? '8px' : '2px 0',
                    backgroundColor: themeStyled.colors.white,
                    color: styling.buttonTextColor,
                    borderColor: themeStyled.colors.white,
                    textTransform: 'uppercase',
                    letterSpacing: '1px',
                    width: mdUp ? '100%' : mdDown ? '20%' : '55%',
                    minWidth: mdUp ? '206px' : 'unset',
                    position: mdDown ? 'absolute' : 'relative',
                    right: mdDown ? '4px' : 'unset',
                    top: mdDown ? '4px' : 'unset',
                  }}
                  onClick={toggleModal}
                />
                {mdUp && styling.arrowLeft && (
                  <Icon
                    icon={styling.arrowLeft}
                    color={themeStyled.colors.white}
                    size={51}
                    customStyle={{
                      maxHeight: '13px',
                      alignItems: 'center',
                      display: 'flex',
                      margin: 'auto',
                      paddingLeft: '15px',
                    }}
                  />
                )}
              </ButtonContainer>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  };

  return xsDown && isCampaign ? (
    <></>
  ) : isFlash || isDiscount ? (
    oldPromotionLayout()
  ) : (
    regularPromotionLayout()
  );
}

export default YoBar;
