import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Home as AsyncHome,
  PDP as AsyncPDP,
  Destination as AsyncDestination,
  DestinationInfo as AsyncDestinationInfo,
  CheckoutRegisterLoginStep as AsyncCheckoutRegisterLoginStep,
  CheckoutPersonalizationStep as AsyncCheckoutPersonalizationStep,
  CheckoutPassengerStep as AsyncCheckoutPassengerStep,
  CheckoutPaymentsStep as AsyncCheckoutPaymentsStep,
  CheckoutPaymentsNotConfirmedStep as AsyncCheckoutPaymentsNotConfirmedStep,
  CheckoutPaymentsConfirmedStep as AsyncCheckoutPaymentsConfirmedStep,
  CheckoutBudgetStep as AsyncCheckoutBudgetStep,
  Confirmation as AsyncConfirmation,
  Login as AsyncLogin,
  Register as AsyncRegister,
  RecoverPassword as AsyncRecoverPassword,
  Landing as AsyncLanding,
  BudgetPdf as AsyncBudgetPdf,
  CancellationVoucherPdf as AsyncCancellationVoucherPdf,
  TicketPdf as AsyncTicketPdf,
  AboutUs as AsyncAboutUs,
  GenericPages as AsyncGenericPages,
  Contact as AsyncContact,
  MyOrders as AsyncMyOrders,
  MyWishList as AsyncMyWishList,
  MyProfile as AsyncMyProfile,
  MySubscriptions as AsyncMySubscriptions,
  MyWallet as AsyncMyWallet,
  Faq as AsyncFaq,
  Error404 as AsyncError404,
  Error500 as AsyncError500,
  Maintenance as AsyncMaintenance,
  PreferenceCenter as AsyncPreferenceCenter,
} from './routes';

import PrivateRoute from '../components/privateRoute/PrivateRoute';

import MainLayout from '../layout/MainLayout';
import MyAccountLayout from '../layout/MyAccountLayout';
import CheckoutLayout from '../layout/CheckoutLayout';
import WithSuspense from '../layout/WithSuspense';

import { getLocaleFromUrl } from '../utils';
import { useTranslation } from 'react-i18next';
import SEOTags from '../components/ui/v2/SEOTags';
import PageView from '../components/tracking/PageView';

import ErrorBoundary from '../components/error/ErrorBoundary';

const MainRoutes = ({ country }) => {
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  return (
    <>
      <ErrorBoundary>
        <SEOTags
          charSet={'UTF-8'}
          description={t('meta@description')}
          keywords={t('meta@keywords@PageController')}
          canonical={window.location.pathname}
          language={t('header@language')}
          robots={t('header@robots')}
          revisitAfter={t('header@revisitAfter')}
          copyright={t('header@copyright')}
          audience={t('header@audience')}
          contentLanguage={t('header@contentLanguage')}
          viewport={'width=device-width, initial-scale=1, maximum-scale=1'}
        />
        <Switch>
          {/* Checkout views: CheckoutLayout */}
          <Route path="/:market(es|fr|de|uk|us|ca)?/(checkout|pago-fraccionado|part-payment|paiment-echelonne|geteilte-zahlung)/:path?">
            <CheckoutLayout>
              <Switch>
                <Route path="/:market(es|fr|de|uk|us|ca)?/checkout/login/:id">
                  <PageView type={'checkout login'} country={country} />
                  <WithSuspense container="pdp">
                    <AsyncCheckoutRegisterLoginStep />
                  </WithSuspense>
                </Route>

                <Route path="/:market(es|fr|de|uk|us|ca)?/checkout/budget/:productId/:orderAttemptId">
                  <PageView type={'checkout budget'} country={country} />
                  <WithSuspense container="pdp">
                    <AsyncCheckoutBudgetStep />
                  </WithSuspense>
                </Route>

                <PrivateRoute path="/:market(es|fr|de|uk|us|ca)?/checkout/personalization/:productId/:orderAttemptId">
                  <PageView
                    type={'checkout personalization'}
                    country={country}
                  />
                  <WithSuspense container="pdp">
                    <AsyncCheckoutPersonalizationStep />
                  </WithSuspense>
                </PrivateRoute>

                <PrivateRoute path="/:market(es|fr|de|uk|us|ca)?/checkout/passengers/:productId/:orderAttemptId">
                  <PageView type={'checkout passengers'} country={country} />
                  <WithSuspense container="pdp">
                    <AsyncCheckoutPassengerStep />
                  </WithSuspense>
                </PrivateRoute>

                <PrivateRoute path="/:market(es|fr|de|uk|us|ca)?/checkout/payment/:productId/:orderAttemptId">
                  <PageView type={'checkout payment'} country={country} />
                  <WithSuspense container="pdp">
                    <AsyncCheckoutPaymentsStep />
                  </WithSuspense>
                </PrivateRoute>

                <Route path="/:market(es|fr|de|uk|us|ca)?/(checkout)?/(pago-fraccionado|part-payment|paiment-echelonne|geteilte-zahlung)/:token">
                  <PageView type={'part payment'} country={country} />
                  <WithSuspense container="pdp">
                    <AsyncCheckoutPaymentsStep />
                  </WithSuspense>
                </Route>
              </Switch>
            </CheckoutLayout>
          </Route>
          <Route path="/:market(es|fr|de|uk|us|ca)?/(booking|reservation|reservierung|reserva)/:orderId/(not-confirmed|non-confirme|nicht-bestatigt|no-confirmada)">
            <CheckoutLayout>
              <PageView type={'not confirmed'} country={country} />
              <WithSuspense container="pdp">
                <AsyncCheckoutPaymentsNotConfirmedStep />
              </WithSuspense>
            </CheckoutLayout>
          </Route>
          <Route path="/:market(es|fr|de|uk|us|ca)?/:paymentMethodRoute(reserva-paypal|paypal-booking|reservation-paypal|paypal-reservierung)/:orderId/">
            <CheckoutLayout>
              <PageView type={'confirmed'} country={country} />
              <WithSuspense container="pdp">
                <AsyncCheckoutPaymentsConfirmedStep />
              </WithSuspense>
            </CheckoutLayout>
          </Route>
          {/* Private Area views: MyAccountLayout */}
          <Route path="/:market(es|fr|de|uk|us|ca)?/(mi-cuenta|my-account|mon-compte|mein-konto)/:path?">
            <MyAccountLayout>
              <Switch>
                <Route
                  path="/:market(es|fr|de|uk|us|ca)?/(mi-cuenta|my-account|mon-compte|mein-konto)"
                  exact
                  render={({ match }) => (
                    <Redirect
                      to={`${
                        match.params.market ? `/${match.params.market}` : ''
                      }/my-account/myorders`}
                    />
                  )}
                ></Route>
                <PrivateRoute path="/:market(es|fr|de|uk|us|ca)?/(mi-cuenta|my-account|mon-compte|mein-konto)/myorders">
                  <PageView type={'my account'} country={country} />
                  <WithSuspense container="pdp">
                    <AsyncMyOrders />
                  </WithSuspense>
                </PrivateRoute>
                <PrivateRoute path="/:market(es|fr|de|uk|us|ca)?/(mi-cuenta|my-account|mon-compte|mein-konto)/mywishlist">
                  <PageView type={'my account'} country={country} />
                  <WithSuspense container="pdp">
                    <AsyncMyWishList />
                  </WithSuspense>
                </PrivateRoute>
                <PrivateRoute path="/:market(es|fr|de|uk|us|ca)?/(mi-cuenta|my-account|mon-compte|mein-konto)/myprofile">
                  <PageView type={'my account'} country={country} />
                  <WithSuspense container="pdp">
                    <AsyncMyProfile />
                  </WithSuspense>
                </PrivateRoute>

                <PrivateRoute path="/:market(es|fr|de|uk|us|ca)?/(mi-cuenta|my-account|mon-compte|mein-konto)/mysubscriptions">
                  <PageView type={'my account'} country={country} />
                  <WithSuspense container="pdp">
                    <AsyncMySubscriptions />
                  </WithSuspense>
                </PrivateRoute>

                <PrivateRoute path="/:market(es|fr|de|uk|us|ca)?/(mi-cuenta|my-account|mon-compte|mein-konto)/faq">
                  <PageView type={'my account'} country={country} />
                  <WithSuspense container="pdp">
                    <AsyncFaq />
                  </WithSuspense>
                </PrivateRoute>

                <PrivateRoute path="/:lang(es|fr|de|uk|us|ca)?/(mi-cuenta|my-account|mon-compte|mein-konto)/mywallet">
                  <PageView type={'my account'} country={country} />
                  <WithSuspense container="pdp">
                    <AsyncMyWallet />
                  </WithSuspense>
                </PrivateRoute>
              </Switch>
            </MyAccountLayout>
          </Route>

          {/* PDF views: */}
          <Route path="/:market(es|fr|de|uk|us|ca)?/api/:path?">
            <Route path="/:market(es|fr|de|uk|us|ca)?/api/budget/pdf/:orderAtttemptId">
              <WithSuspense container="pdp">
                <AsyncBudgetPdf />
              </WithSuspense>
            </Route>

            <Route path="/:market(es|fr|de|uk|us|ca)?/api/voucher/cancellation/:voucherId">
              <WithSuspense container="pdp">
                <AsyncCancellationVoucherPdf />
              </WithSuspense>
            </Route>

            <Route path="/:market(es|fr|de|uk|us|ca)?/api/ticket/pdf/:bookingToken">
              <WithSuspense container="pdp">
                <AsyncTicketPdf />
              </WithSuspense>
            </Route>
          </Route>

          {/* App views: 500, Maintenance, PreferenceCenter */}
          <Route path={`/frontend/build/:market(en|es|de|fr)/error500`}>
            <PageView type={'error 500'} country={country} />
            <WithSuspense container="pdp">
              <AsyncError500 />
            </WithSuspense>
          </Route>
          <Route path={`/frontend/build/:market(en|es|de|fr)/maintenance`}>
            <PageView type={'maintenance'} country={country} />
            <WithSuspense container="pdp">
              <AsyncMaintenance />
            </WithSuspense>
          </Route>
          <Route
            path={`/frontend/build/:market(en|es|de|fr)/preference-center`}
          >
            <PageView type={'preference center'} country={country} />
            <WithSuspense container="pdp">
              <AsyncPreferenceCenter />
            </WithSuspense>
          </Route>

          {/* public views: MainLayout */}
          <MainLayout>
            <Switch>
              <Route exact path="/:market(es|fr|de|uk|us|ca)?">
                <PageView type={'homepage'} country={country} />
                <WithSuspense container="home">
                  <AsyncHome />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(preview|offline)/:campaignId([0-9]+)-([_a-zA-Z0-9-]+)">
                <PageView type={'preview offline'} country={country} />
                <WithSuspense container="pdp">
                  <AsyncPDP />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/([_a-zA-Z0-9-]+)/([_a-zA-Z0-9-]+)/:campaignId([0-9]+)-([_a-zA-Z0-9-]+)">
                <PageView type={'product'} country={country} />
                <WithSuspense container="pdp">
                  <AsyncPDP />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(america|asia|africa|europa|oceania|europe|australasia-and-pacific|amerique|afrique|asie|oceanie|afrika|amerika|asien|ozeanien)/([_a-zA-Z0-9-\pL]*)/:destinationInfoId([_a-zA-Z0-9-\pL]*)/:destinationInfoType(eventos|gastronomia|compras|events|gastronomy|shopping|evenements|gastronomie|ereignisse|einkaufe)">
                <PageView type={'country content'} country={country} />
                <WithSuspense container="destination">
                  <AsyncDestinationInfo />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(america|asia|africa|europa|oceania|europe|australasia-and-pacific|amerique|afrique|asie|oceanie|afrika|amerika|asien|ozeanien)/([_a-zA-Z0-9-\pL]*)/:destinationInfoId([_a-zA-Z0-9-\pL]*)/:idPoi([_a-zA-Z0-9-]+)">
                <PageView type={'poi'} country={country} />
                <WithSuspense container="destination">
                  <AsyncDestinationInfo isPoiPage />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/:continent(america|asia|africa|europa|oceania|europe|australasia-and-pacific|amerique|afrique|asie|oceanie|afrika|amerika|asien|ozeanien)/:region([_a-zA-Z0-9-\pL]*)?/:country([_a-zA-Z0-9-\pL]*)?">
                <PageView type={'destination'} country={country} />
                <WithSuspense container="destination">
                  <AsyncDestination />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(recuperar-password|retrieve-password|recuperer-mot-de-passe|passwort-zurucksetzen)">
                <PageView type={'recover password'} country={country} />
                <WithSuspense container="home">
                  <AsyncRecoverPassword />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(top-ventas|top-sales|top-ventes|am-meisten-verkauft)">
                <PageView type={'top sales'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'top-ventas'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(mas-vendidos|top-selling|meilleures-ventes|bestseller)">
                <PageView type={'top sales'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'top-sales'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(beach-escapes)">
                <PageView type={'beach escapes'} country={country} />
                <WithSuspense container="pdp">
                  <AsyncLanding type={'beach-escapes'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(short-trips)">
                <PageView type={'short trips'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'short-trips'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(luna-de-miel|honeymoon|lune-de-miel|flitterwochen)">
                <PageView type={'luna de miel'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'luna-de-miel'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(viajes-extralargos|voyages-extralongs|extra-long-trips|besonders-lange-reisen)">
                <PageView type={'viajes extralargos'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'viajes-extralargos'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(classics|klassiker|clasicos|classiques)">
                <PageView type={'full price'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'full-price'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(meilleuresoffres/amerique-du-sud|mejoresofertas/america-del-sur)">
                <PageView type={'voyages amerique du sud'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'voyages-amerique-du-sud'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/meilleuresoffres/europe">
                <PageView type={'voyages europe'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'voyages-europe'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(exoticca-asiatic-selection|mejoresofertas/sudeste-asiatico)">
                <PageView
                  type={'exoticca asiatic selection'}
                  country={country}
                />
                <WithSuspense container="home">
                  <AsyncLanding type={'exoticca-asiatic-selection'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/vente-privee">
                <PageView type={'vente privee'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'vente-privee'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/safaris">
                <PageView type={'safaris'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'safaris'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(magicos-paises-nordicos|magical-nordic-countries|pays-nordiques)">
                <PageView type={'magicos paises nordicos'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'magicos-paises-nordicos'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(semana-santa|over-easter|ostern)">
                <PageView type={'semana santa'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'semana-santa'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(black-friday|cyber-week)">
                <PageView type={'black friday'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'black-friday'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(bestoffers/peru|meilleuresoffres/perou|mejoresofertas/peru)">
                <PageView type={'viajes peru'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'viajes-peru'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(bestoffers/india|meilleuresoffres/inde|mejoresofertas/india)">
                <PageView type={'india best offers'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'india-best-offers'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(bestoffers/vietnam|meilleuresoffres/vietnam|mejoresofertas/vietnam)">
                <PageView type={'viajes vietnam'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'viajes-vietnam'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(puente-diciembre)">
                <PageView type={'puente diciembre'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'puente-diciembre'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(viajas-solo|travel-alone|voyages-seul|sie-reisen-alleine)">
                <PageView type={'viajas solo'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'viajas-solo'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(circuitos-en-privado|private-tours|circuits-privatifs)">
                <PageView type={'private tours'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'private-tours'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(navidades-y-fin-de-año|christmas-and-new-year|noel-et-nouvel-an|weihnachten-und-jahresende)">
                <PageView type={'navidades y fin de año'} country={country} />
                <WithSuspense container="home">
                  <AsyncLanding type={'navidades-y-fin-de-ano'} />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(acerca-de|about-us|a-propos-de|bezuglich)">
                <PageView type={'exoticca info'} country={country} />
                <WithSuspense container="generic">
                  <AsyncAboutUs />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/:slug(terminos-y-condiciones|terms-and-conditions|termes-et-conditions|geschaftsbedingungen|ayuda|help|aide|hilfe|politica-de-cookies|cookies-policy|politique-des-cookies|cookies-politique|cookie-richtlinien|cookies-richtlinie|politica-de-privacidad|privacy-policy|politique-de-confidentialite|datenschutzrichtlinie|privacy-richtlinie)">
                <PageView type={'policies'} country={country} />
                <WithSuspense container="generic">
                  <AsyncGenericPages />
                </WithSuspense>
              </Route>
              <Route path="/:market(de)?/:slug(impressum)">
                <PageView type={'impressum'} country={country} />
                <WithSuspense container="generic">
                  <AsyncGenericPages />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(contacto|contact|kontakt)">
                <PageView type={'exoticca info'} country={country} />
                <WithSuspense container="generic">
                  <AsyncContact />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/login">
                <PageView type={'login'} country={country} />
                <WithSuspense container="home">
                  <AsyncLogin />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(register|inscription)">
                <PageView type={'register'} country={country} />
                <WithSuspense container="home">
                  <AsyncRegister />
                </WithSuspense>
              </Route>
              <Route path="/:market(es|fr|de|uk|us|ca)?/(reserva|booking|reservation|reservierung)/:orderId([0-9]+)/(confirmada|confirmed|confirme|bestatigt)">
                <PageView type={'confirmation'} country={country} />
                <WithSuspense container="pdp">
                  <AsyncConfirmation />
                </WithSuspense>
              </Route>
              {/* Error views: MainLayout */}
              <Route path="/:market(es|fr|de|uk|us|ca)?*">
                <PageView type={'error 400'} country={country} />
                <WithSuspense container="home">
                  <AsyncError404 />
                </WithSuspense>
              </Route>
            </Switch>
          </MainLayout>
        </Switch>
      </ErrorBoundary>
    </>
  );
};

const mapStateToProps = ({ config }) => ({
  country: config.data.market,
});

const mapDispatchToProps = (dispatch) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose<any>(connector)(MainRoutes);
