import React, { useContext, Fragment, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import moment from 'moment';
import Icon from '../../ui/Icon';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';
import { LayoutContext } from '../../../context/LayoutContext';

interface StyledProps {
  layout: string;
}

const FlightsLine = styled.div<StyledProps>`
  background-color: transparent;
  color: ${(props) => props.theme.colors.mineShaft};
  padding: 10px 22px;
  ${(props) =>
    props.layout === 'mobile' &&
    `
    padding: 10px;`}
  flex: 1 1 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const LeftFlight = styled.div<StyledProps>`
  width: 50%;
  padding-left: 10px;
  div {
    margin-bottom: 2px;
  }
  ${(props) =>
    props.layout !== 'mobile' &&
    `
    border-right: 1px solid ${props.theme.secondaryColors.darkGrey};`}
  ${(props) =>
    props.layout === 'mobile' &&
    `
    border-bottom: 1px dotted ${props.theme.colors.golden};
    padding-left: 10px;
    flex: 1 1 100%;`}
`;

const RightFlight = styled.div<StyledProps>`
  ${(props) =>
    props.layout !== 'mobile' &&
    `
    width: 50%;
    padding-left: 22px;`}
  ${(props) =>
    props.layout === 'mobile' &&
    `
    padding: 10px 10px 0;
    width: 100%;`}
`;

const CaptionDate = styled.div`
  color: ${(props) => props.theme.colors.cafeRoyale};
  font-size: 0.813rem;
  font-weight: 500;
  span {
    margin-left: 10px;
  }
`;

const AirportCaption = styled.div`
  font-size: 0.813rem;
`;

const FlightTime = styled.div`
  font-size: 1rem;
  font-weight: 500;
`;

const ConnectionHalf = styled.div<StyledProps>`
  display: inline-block;
  width: 50%;
  ${(props) =>
    props.layout === 'mobile' &&
    `
    padding: 5px 20px 5px;
    width: 50%;
    `}
`;

const FlightConnection = styled.div<StyledProps>`
  background-color: transparent;
  color: ${(props) => props.theme.colors.purple};
  padding: 0 32px;
  flex: 1 1 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  div {
    padding-right: 5px;
  }
  ${(props) =>
    props.layout === 'mobile' &&
    `
    padding: 0;`}
`;

const DashedSeparation = styled.div<StyledProps>`
  border-top: 1px dashed ${(props) => props.theme.colors.purple};
  flex: 1 1 100%;
  width: 100%;
  height: 1px;
  margin: 15px 0;
  ${(props) =>
    props.layout === 'mobile' &&
    `
    margin: 0;`}
`;

const AirlineDuration = styled.div`
  flex: 1 1 100%;
  padding: 0 10px 10px;
  display: flex;
  align-items: center;
  font-size: 0.813rem;
  color: ${(props) => props.theme.colors.cafeRoyale};
`;

interface FlightInfoDialogProps {
  flightsList: any;
}

function FlightsList(props: FlightInfoDialogProps) {
  const { flightsList } = props;

  const theme = useContext(ThemeContext);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);
  const layout = useContext(LayoutContext);

  return flightsList.map((dFlight, index) => {
    const stopDurationHours = moment
      .utc(moment.duration(dFlight.timeAtAirport, 'minutes').asMilliseconds())
      .format('HH');
    const stopDurationMins = moment
      .utc(moment.duration(dFlight.timeAtAirport, 'minutes').asMilliseconds())
      .format('mm');

    return (
      <Fragment key={index}>
        {dFlight.timeAtAirport > 0 && (
          <Fragment>
            <FlightConnection layout={layout}>
              <DashedSeparation layout={layout} />
              <ConnectionHalf layout={layout}>
                {layout !== 'mobile' && (
                  <Icon
                    color={theme.colors.purple}
                    size={17}
                    icon="connection"
                  />
                )}
                <span>
                  {t('checkout@connectionIn')}{' '}
                  <strong>
                    {layout === 'mobile' && <br />}
                    {dFlight.DairportName}
                  </strong>
                </span>
              </ConnectionHalf>
              <ConnectionHalf layout={layout}>
                {layout !== 'mobile' && (
                  <Icon color={theme.colors.purple} size={17} icon="duration" />
                )}
                <span>
                  {t('checkout@duration')}:{' '}
                  <strong>
                    {layout === 'mobile' && <br />}
                    {stopDurationHours}
                    {t('checkout@hoursShort')} {stopDurationMins}
                    {t('checkout@minutesShort')}
                  </strong>
                </span>
              </ConnectionHalf>
              <DashedSeparation layout={layout} />
            </FlightConnection>
          </Fragment>
        )}
        <FlightsLine layout={layout}>
          <AirlineDuration>
            {t('checkout@airline')}: &nbsp;<strong>{dFlight.airline}</strong>
          </AirlineDuration>
          <LeftFlight layout={layout}>
            <CaptionDate>
              <Icon
                color={theme.colors.cafeRoyale}
                size={20}
                icon="departure"
              />
              <span>
                {t('departureShort')} {dFlight.day}
              </span>
            </CaptionDate>
            <FlightTime>
              <strong>{dFlight.Ddate}</strong>
            </FlightTime>
            <AirportCaption>
              {dFlight.DairportCity} ({dFlight.DairportIATA})
            </AirportCaption>
          </LeftFlight>
          <RightFlight layout={layout}>
            <CaptionDate>
              <Icon color={theme.colors.cafeRoyale} size={20} icon="llegada" />
              <span>{t('checkout@arrival')}</span>
            </CaptionDate>
            <FlightTime>
              <strong>{dFlight.Adate}</strong>
            </FlightTime>
            <AirportCaption>
              {dFlight.AairportCity} ({dFlight.AairportIATA})
            </AirportCaption>
          </RightFlight>
        </FlightsLine>
      </Fragment>
    );
  });
}

export default FlightsList;
