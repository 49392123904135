import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import appConfig from '../../config';

import { getDefaultTagManagerData } from '../../utils/tagManager';

interface PageViewProps {
  type: string;
  country: string;
}

const PageView = ({ type, country }: PageViewProps) => {
  const params = useParams();

  useEffect(() => {
    let pageType = '';
    switch (type) {
      case 'destination':
        if (params.country) {
          pageType = 'country';
        } else if (params.region) {
          pageType = 'region';
        } else {
          pageType = 'destination';
        }
        break;
      default:
        pageType = type;
    }

    let data: any = {
      page_url: window.location.href,
      page_type: pageType,
      ...getDefaultTagManagerData(),
    };

    if (params.campaignId || params.productId) {
      data = {
        ...data,
        travel_id: params.campaignId || params.productId,
      };
    }

    if (country) {
      // Google Tag Manager
      // Hackerman: workaround for GTM init
      //@ts-ignore
      if (typeof dataLayer === 'undefined') {
        const tagManagerArgs = {
          gtmId: appConfig[country]?.tracking.tag_manager_id,
          auth: appConfig[country]?.tracking.tag_manager_auth,
          preview: appConfig[country]?.tracking.tag_manager_preview,
          dataLayer: data,
        };

        TagManager.initialize(tagManagerArgs);
      } else {
        TagManager.dataLayer({
          dataLayer: { event: 'react_page_view', ...data },
        });
      }
    }
  }, [
    type,
    params.continent,
    params.country,
    params.region,
    params.campaignId,
    params.productId,
    country,
  ]);

  return <></>;
};

export default PageView;
