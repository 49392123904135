import { useEffect, useState } from 'react';

declare global {
  interface Window {
    dataLayer: any;
    google_optimize: any;
  }
}

export default function useGoogleOptimize(experimentID: string) {
  const isDevelop = process.env.NODE_ENV === 'development';
  const [variant, setVariant] = useState(null);
  const updateVariant = (value) => {
    setVariant(value === undefined || value === null ? '0' : value);
  };

  const saveABTestParamsOnSessionStore = (value: string) => {
    const sessionItem = sessionStorage.getItem('abParams');
    const arr = (sessionItem && JSON.parse(sessionItem)) || [];
    const existId = arr.some((el) => el.id === experimentID);
    if (!existId) {
      arr.push({ id: experimentID, variant: value });
      sessionStorage.setItem('abParams', JSON.stringify(arr));
    }
  };

  useEffect(() => {
    const delayedInitialization = () => {
      const value =
        window.google_optimize && window.google_optimize.get(experimentID);
      setVariant(value);
      saveABTestParamsOnSessionStore(value);
    };

    if (!isDevelop && window.dataLayer) {
      const hideEnd =
        window.dataLayer && window.dataLayer.hide && window.dataLayer.hide.end;

      if (hideEnd) {
        window.dataLayer.hide.end = () => {
          delayedInitialization();
          hideEnd();
        };
      } else {
        delayedInitialization();
      }

      window.dataLayer?.push('event', 'optimize.callback', {
        name: experimentID,
        callback: updateVariant,
      });
    }

    return () => {
      if (!isDevelop) {
        window.dataLayer?.push('event', 'optimize.callback', {
          name: experimentID,
          callback: updateVariant,
          remove: true,
        });
      }
    };
  });

  return variant;
}
