export type ActionType =
  | 'SET_LOG_DATA'
  | 'REQUEST_LOG_USER'
  | 'RECEIVED_LOG_USER'
  | 'ERROR_LOG_USER'
  | 'SET_LOGIN_FACEBOOK_DATA'
  | 'REQUEST_LOGIN_FACEBOOK_USER'
  | 'RECEIVED_LOGIN_FACEBOOK_USER'
  | 'ERROR_LOGIN_FACEBOOK_USER'
  | 'SET_USER_AUTH_DATA'
  | 'DISCONNECT_USER';

interface ActionProps {
  type: ActionType;
  isFetching?: boolean;
  data?: {} | any;
  error?: {} | any;
  payload?: {} | any;
}

const initialAuthState = {
  isFetching: false,
  isLogging: false,
  data: {
    email: '',
    pass: '',
    nombre: '',
    terms: false,
    newsletter: true,
  },
  isAuthenticated: false,
  error: {},
};

const auth = (state = initialAuthState, action: ActionProps) => {
  switch (action.type) {
    case 'SET_USER_AUTH_DATA': {
      return {
        ...state,
        data: action.payload,
      };
    }

    case 'DISCONNECT_USER': {
      return {
        ...state,
        isAuthenticated: false,
        data: initialAuthState.data,
      };
    }

    case 'REQUEST_LOG_USER':
    case 'REQUEST_LOGIN_FACEBOOK_USER': {
      return {
        ...state,
        isLogging: true,
      };
    }

    case 'RECEIVED_LOG_USER':
    case 'RECEIVED_LOGIN_FACEBOOK_USER': {
      const customerId = action.payload ? action.payload?.customerId : '';

      return {
        ...state,
        isAuthenticated: true,
        customerId: customerId,
        isLogging: false,
      };
    }

    case 'ERROR_LOG_USER':
    case 'ERROR_LOGIN_FACEBOOK_USER': {
      return {
        ...state,
        isAuthenticated: false,
        isFetching: false,
      };
    }

    default:
      return state;
  }
};

export default auth;
