export type ActionType =
  | 'SET_HOME_DATA'
  | 'REQUEST_HOME_DATA'
  | 'RECEIVED_HOME_DATA'
  | 'IS_CACHED_HOME_DATA'
  | 'ERROR_HOME_DATA';

interface ActionProps {
  type: ActionType;
  isFetching?: boolean;
  data?: {} | any;
  error?: any;
  payload?: {} | any;
}

const initialHomeState = {
  isFetching: true,
  data: {
    slides: [],
    hero: {},
    topSales: {},
    dealsOfTheWeek: [],
  },
  error: {},
};

const Home = (state = initialHomeState, action: Partial<ActionProps>) => {
  switch (action.type) {
    case 'REQUEST_HOME_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_HOME_DATA':
    case 'IS_CACHED_HOME_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: { ...payload },
      };
    }

    case 'ERROR_HOME_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
};

export default Home;
