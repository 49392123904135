import { MiddlewareAPI, Dispatch, Middleware, AnyAction } from 'redux';
import axios from 'axios';

import { setUrlCached } from '../utils/cache';

export const FETCH_MOCK = 'FETCH_MOCK';
export const FETCH = 'FETCH';

export type FetchMiddleWareAction = {
  fetch: {
    type: string;
    actionTypes?: {
      request: string;
      success: string;
      fail: string;
    };
    url: string;
    method: 'get' | 'put' | 'post' | 'delete';
    headers?: any; // OPTIONAL CONTENT like: data: { someprop: 'value ...}
    options?: any; // OPTIONAL CONTENT like: Authorization: 'Bearer _A_TOKEN_'
  };
  mockResult?: any;
};

const fetchMiddleware: Middleware<Dispatch> = (store: MiddlewareAPI) => (
  next: Function,
) => async (action: AnyAction | FetchMiddleWareAction) => {
  if (!action.fetch) {
    return next(action);
  }

  if (!action.fetch.type) {
    return next(action);
  }

  if (!action.fetch.actionTypes) {
    return next(action);
  }

  if (action.fetch.type === 'FETCH_MOCK') {
    if (!action.fetch.mockResult) {
      throw new Error(
        'Fetch middleware require a mockResult payload when type is "FETCH_MOCK"',
      );
    }

    const {
      actionTypes: { request, success },
      mockResult,
    } = action.fetch;

    // request
    store.dispatch({ type: request });

    // received successful for mock
    return Promise.resolve(
      store.dispatch({
        type: success,
        payload: mockResult,
      }),
    );
  }

  if (action.fetch.type === 'FETCH') {
    const {
      actionTypes: { request, success, fail },
      url,
      method,
      headers,
      options,
    } = action.fetch;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // request
    store.dispatch({
      type: request,
      payload: { ...options, cancelToken: source },
    });

    // fetch server (success or fail)
    try {
      const data = await axios.request({
        method,
        url,
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...headers,
        },
        ...options,
        cancelToken: source.token,
      });

      if (options?.cache && typeof data?.data === 'object') {
        const cacheUrl = options.data ? `${url}?${options.data}` : url;
        const cacheData = {
          data: data?.data,
          lastUpdate: new Date(),
        };
        setUrlCached(cacheUrl, cacheData);
      }

      store.dispatch({ type: success, payload: data?.data });
      return data;
    } catch (error) {
      if (error?.response?.status === 503) {
        try {
          const data = await axios.request({
            method,
            url,
            withCredentials: true,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              ...headers,
            },
            ...options,
          });

          store.dispatch({ type: success, payload: data?.data });
          return data;
        } catch (retryError) {
          return Promise.reject(retryError?.response);
        }
      } else {
        if (axios.isCancel(error)) {
          console.log(error?.message);
        } else {
          store.dispatch({ type: fail, error: error?.response?.data?.error });
        }
        return Promise.reject(error?.response);
      }
    }
  }

  return next(action);
};

export default fetchMiddleware;
