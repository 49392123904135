import React, { useState, useContext, Fragment, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';

import { LayoutContext } from '../../context/LayoutContext';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../utils';
import CampaignInfo from './summary/CampaignInfo';
import TravelDetails from './summary/TravelDetails';
import TotalPrice from './summary/TotalPrice';
import SplitPaymentTotalPrice from './summary/SplitPaymentTotalPrice';
import DiscountTotalPrice from './summary/DiscountTotalPrice';
import FullScreenDialog from './summary/FullScreenDialog';
import TravelIncluded from './summary/TravelIncluded';
import TravelBreakdownDetails from './summary/TravelBreakdownDetails';
import OpenDialog from './buttons/OpenDialog';

import {
  getTotalPriceFromState,
  getPromotionData,
  isSplitPaymentEnabled,
  setBudgetModal,
} from '../../actions/checkout';

import { promotionActions } from '../../actions';

import { notificationsActions, orderAttemptActions } from '../../actions';
import Icon from '../ui/Icon';

import { parseNumberLocal, isLoggedIn } from '../../utils';

export interface SummaryProps {
  config: any;
  orderAttempt: { orderAttemptId: string };
  checkout: any;
  promotion: any;
  setNotificationData;
  paymentMethodId;
  voucherCode;
  updateOrderAttemptData;
  showBudget: boolean;
  isAffirmEnabled: boolean;
  setBudgetModal(data): void;
  setShowPromoModal: any;
}

export const DesktopWrapper = styled.div`
  width: 400px;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  font-family: ${(props) => props.theme.fontFamily};
  background-color: ${(props) => props.theme.colors.whiteCreamy};
`;

export const TabletWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0px;
  left: 0px;
  width: 100%;
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.5);
  background-color: ${(props) => props.theme.colors.mineShaftDarker};
  font-family: ${(props) => props.theme.fontFamily};
  z-index: 1100;
  height: 72px;
`;

export const MobileWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0px;
  left: 0px;
  width: 100%;
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.5);
  background-color: ${(props) => props.theme.colors.mineShaftDarker};
  font-family: ${(props) => props.theme.fontFamily};
  z-index: 1100;
  height: 89px;
`;

const BudgetBox = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 22%;
  background: ${(props) => props.theme.colors.grandis};
  & :after  {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    top: 40%;
    right: -1px;
    border-width: 7px;
    border-right-color: ${(props) => props.theme.colors.mineShaftDarker};
  }
  span  {
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.3px;
    text-align: center;
    margin: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: 15%;
    background: ${(props) => props.theme.colors.grandis};
    & :after  {
      border-color: transparent;
    }

    > div {
      & :after,
      :before  {
        content: ' ';
        position: absolute;
        width: 10px;
        height: 1px;
        top: 38%;
        background-color: ${(props) => props.theme.colors.grayDark};
      }
      & :after {
        left: 20px;
      }
      & :before {
        right: 20px;
      }
    }

    span {
      margin: 0 5px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    color: ${(props) => props.theme.colors.black};
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 22%;
    background: ${(props) => props.theme.colors.grandis};
    & :after  {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      top: 40%;
      right: -1px;
      border-width: 7px;
      border-right-color: ${(props) => props.theme.colors.mineShaftDarker};
    }
    > div {
      & :after,
      :before  {
        display: none;
      }
    }
    span  {
      font-family: ${(props) => props.theme.fontFamily};
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.3px;
      text-align: center;
      margin: 0;
    }
  }
`;

const GTM_EVENT_ACTION = 'open modal';

const Summary = (props: SummaryProps) => {
  const {
    config: {
      options: { leadModal },
      market,
      affiliate: { contactPhone },
    },
    orderAttempt: { orderAttemptId },
    checkout: { summary, breakdown, tooltip, payment },
    promotion,
    setNotificationData,
    paymentMethodId,
    voucherCode,
    updateOrderAttemptData,
    showBudget,
    isAffirmEnabled,
    setBudgetModal,
    setShowPromoModal,
  } = props;

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenFullScreenDialog(!openFullScreenDialog);
  };

  const { product, offer, includes, category, pax, flight } = summary;
  const splitPaymentPercentage =
    isSplitPaymentEnabled(paymentMethodId) && payment?.splitPayment?.percentage;

  const totalPrice = getTotalPriceFromState(breakdown);
  const passengersCount = pax.adults + pax.children + pax.babies;
  const totalPricePerPax = totalPrice / passengersCount;

  const totalBeauty = parseNumberLocal(totalPrice > 0 ? totalPrice : 0);
  const totalPerPaxBeauty = parseNumberLocal(
    totalPricePerPax > 0 ? totalPricePerPax : 0,
  );

  const totalPriceWithoutDiscount = getTotalPriceFromState({
    ...breakdown,
    discount: {},
    wallet: [],
  });

  const totalPriceWithoutWallet = getTotalPriceFromState({
    ...breakdown,
    wallet: [],
  });

  const totalPriceWithoutDiscountBeauty = parseNumberLocal(
    totalPriceWithoutDiscount > 0 ? totalPriceWithoutDiscount : 0,
  );
  const totalPriceWithoutDiscountPerPax =
    totalPriceWithoutDiscount / passengersCount;
  const totalPriceWithoutDiscountPerPaxBeauty = parseNumberLocal(
    totalPriceWithoutDiscountPerPax > 0 ? totalPriceWithoutDiscountPerPax : 0,
  );
  const { value: promotionValue = 0 } = promotion.discount
    ? getPromotionData(totalPriceWithoutDiscount, promotion.discounts)
    : {};

  const totalWithPromotionBeauty =
    promotionValue &&
    parseNumberLocal(
      totalPrice > 0 ? totalPriceWithoutDiscount - promotionValue : 0,
    );
  const promotionValueBeauty =
    promotionValue && parseNumberLocal(promotionValue, 0);

  const showLead: boolean = leadModal && showBudget;

  const handleNotification = async () => {
    const supplementsId = breakdown.options.map((option) => option.id);
    const totalPrice = getTotalPriceFromState(breakdown);
    const insurances = breakdown.insurances.map((insurance) => {
      const newInsuranceObject = {};
      const type = insurance.package ? insurance.package : 'basico';
      newInsuranceObject[type] = insurance.id;
      return newInsuranceObject;
    });

    const orderAttemptData = {
      orderAttemptId: orderAttemptId,
      productId: summary.product.id,
      categoryId: summary.category.id,
      totalAmount: totalPrice,
      accommodationSupplements: breakdown.accommodation.id,
      supplementsId,
      insurances,
      donations: breakdown.donate.price,
      paymentMethod: paymentMethodId,
      discountCode: voucherCode,
      abTests: sessionStorage.getItem('abParams') || '',
    };

    let notificationData;
    try {
      const newOrderAttempt = await updateOrderAttemptData(orderAttemptData);
      notificationData = {
        isNotificationThrowed: true,
        notification: {
          variant: 'success',
          message: `${t('budgetSentTo')} ${newOrderAttempt.data.email}`,
          icon: 'envelope_bn',
          iconSize: 32,
        },
      };
    } catch (e) {
      notificationData = {
        isNotificationThrowed: true,
        notification: {
          variant: 'error',
          message: e.data.error.message
            ? e.data.error.message
            : `${t('error')}`,
          icon: 'iconerrormessage',
          iconSize: 32,
        },
      };
    }
    setNotificationData(notificationData);

    // Clean notification after 6 seconds
    setTimeout(() => {
      setNotificationData({ isNotificationThrowed: false });
    }, 6000);
  };

  const getDesktopContent = (isPromoted) => {
    return (
      <Fragment>
        <CampaignInfo
          country={product.title}
          length={offer.days}
          name={product.name}
        />
        <TravelIncluded
          breakdown={breakdown}
          showLead={showLead}
          handleNotification={
            isLoggedIn()
              ? handleNotification
              : () => setBudgetModal({ open: true, gtmEvent: GTM_EVENT_ACTION })
          }
          includes={includes}
          product={product}
          offer={offer}
          promotionColor={promotion.styling?.checkout?.color}
        />
        <TravelDetails
          breakdown={breakdown}
          promotion={promotion}
          splitPaymentPercentage={splitPaymentPercentage}
          includes={includes}
          category={category}
          pax={pax}
          flight={flight}
          product={product}
          offer={offer}
          tooltip={tooltip}
          market={market}
          phone={contactPhone}
        />
        <TotalPrice
          total={totalPriceWithoutDiscountBeauty}
          totalPerPerson={totalPriceWithoutDiscountPerPaxBeauty}
          passengersCount={passengersCount}
          promotion={promotion}
          totalWithPromotion={totalWithPromotionBeauty}
          promotionValue={promotionValueBeauty}
          isPromoted={isPromoted}
          setShowPromoModal={setShowPromoModal}
        />
        {(breakdown?.discount?.priceBeauty ||
          breakdown?.wallet?.length > 0) && (
          <DiscountTotalPrice
            discount={breakdown.discount}
            wallet={breakdown.wallet}
            total={totalBeauty}
            totalWithoutWallet={totalPriceWithoutWallet}
            promotion={promotion}
          />
        )}
        {splitPaymentPercentage && totalPrice > 0 && (
          <SplitPaymentTotalPrice
            text={
              t('splitTotal') &&
              t('splitTotal').replace('#TOKEN#', splitPaymentPercentage)
            }
            total={parseNumberLocal(
              (totalPrice * splitPaymentPercentage) / 100,
            )}
          />
        )}
        <TravelBreakdownDetails
          breakdown={breakdown}
          includes={includes}
          product={product}
          offer={offer}
          promotionColor={promotion.styling?.checkout?.color}
          tooltip={tooltip}
        />
      </Fragment>
    );
  };

  const getTabletContent = (isPromoted) => {
    return (
      <Fragment>
        {showLead && (
          <BudgetBox
            onClick={
              isLoggedIn()
                ? handleNotification
                : () =>
                    setBudgetModal({ open: true, gtmEvent: GTM_EVENT_ACTION })
            }
          >
            <Icon
              icon={'budget-mini'}
              size={20}
              customStyle={{ marginBottom: '8px' }}
            />
            <span>{t('sendBudget')}</span>
          </BudgetBox>
        )}
        <CampaignInfo
          country={product.title}
          length={offer.days}
          name={product.name}
          isBannerCheckoutTablet={true}
          colors={['white', 'white', 'white']}
        />
        <TotalPrice
          total={totalBeauty}
          totalPerPerson={totalPerPaxBeauty}
          passengersCount={passengersCount}
          promotion={promotion}
          totalWithPromotion={totalWithPromotionBeauty}
          promotionValue={promotionValueBeauty}
          isPromoted={isPromoted}
          setShowPromoModal={setShowPromoModal}
        />
        <OpenDialog
          pose={openFullScreenDialog ? 'open' : 'closed'}
          onClick={handleOpenDialog}
        />
      </Fragment>
    );
  };

  const getMobileContent = (isPromoted) => {
    return (
      <Fragment>
        {showLead && (
          <BudgetBox
            onClick={
              isLoggedIn()
                ? handleNotification
                : () =>
                    setBudgetModal({ open: true, gtmEvent: GTM_EVENT_ACTION })
            }
          >
            <Icon
              icon={'budget-mini'}
              size={20}
              customStyle={{ marginBottom: '8px' }}
            />
            <span>{t('sendBudget')}</span>
          </BudgetBox>
        )}
        <TotalPrice
          total={totalBeauty}
          totalPerPerson={totalPerPaxBeauty}
          passengersCount={passengersCount}
          promotion={promotion}
          totalWithPromotion={totalWithPromotionBeauty}
          promotionValue={promotionValueBeauty}
          isPromoted={isPromoted}
          setShowPromoModal={setShowPromoModal}
        />
        <OpenDialog
          pose={openFullScreenDialog ? 'open' : 'closed'}
          onClick={handleOpenDialog}
        />
      </Fragment>
    );
  };

  const getFullScreenDialog = () => {
    return (
      <div>
        <FullScreenDialog
          pose={openFullScreenDialog ? 'open' : 'closed'}
          country={product.title}
          length={offer.days}
          name={product.name}
          closeDialog={handleOpenDialog}
          includes={includes}
          category={category}
          product={product}
          splitPaymentPercentage={splitPaymentPercentage}
          pax={pax}
          flight={flight}
          breakdown={breakdown}
          isAccordianOpen={true}
          promotion={promotion}
          isAffirmEnabled={isAffirmEnabled}
          tooltip={tooltip}
          market={market}
          offer={summary.offer}
          phone={contactPhone}
        />
      </div>
    );
  };

  const layout = useContext(LayoutContext);

  let Wrapper;
  let content: object;
  switch (layout) {
    case 'tablet':
      Wrapper = TabletWrapper;
      content = getTabletContent(product.discountsEnabled);
      break;
    case 'mobile':
      Wrapper = MobileWrapper;
      content = getMobileContent(product.discountsEnabled);
      break;
    case 'desktop':
    default:
      Wrapper = DesktopWrapper;
      content = getDesktopContent(product.discountsEnabled);
      break;
  }

  if (!product.id) {
    return <Fragment />;
  }

  if (layout === 'desktop') {
    return <Wrapper>{content}</Wrapper>;
  }

  return createPortal(
    <Fragment>
      {getFullScreenDialog()}
      <Wrapper>{content}</Wrapper>
    </Fragment>,
    document.body,
  );
};

Summary.defaultProps = {
  showBudget: true,
};

const mapDispatchToProps = (dispatch) => ({
  setBudgetModal: (state) => dispatch(setBudgetModal(state)),
  setNotificationData: (data) =>
    dispatch(notificationsActions.setNotificationData(data)),
  updateOrderAttemptData: (data) =>
    dispatch(orderAttemptActions.updateOrderAttemptData(data)),
  setShowPromoModal: (status) =>
    dispatch(promotionActions.setShowPromoModal(status)),
});

const mapStateToProps = (state) => {
  return {
    config: state.config.data,
    orderAttempt: state.orderAttempt.data,
    checkout: state.checkout,
    summary: state.checkout.summary,
    paymentMethodId: state.checkout.paymentMethodId,
    voucherCode: state.checkout.voucherCode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
