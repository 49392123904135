import { ActionType } from '../reducers/destination';
import { api } from '../config';

export const setDestinationData = (group, id) => {
  return (dispatch) => {
    let shouldFetchMock: boolean;
    let mockResult: {};

    switch (group) {
      default:
        shouldFetchMock = false;
        mockResult = {};
        break;
    }

    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';

    const url = `${api.landing}/${group}/${id}`;
    const method = 'get';

    const type: ActionType = 'SET_DESTINATION_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_DESTINATION_DATA',
            success: 'RECEIVED_DESTINATION_DATA',
            fail: 'ERROR_DESTINATION_DATA',
          },
          url,
          // props only used when type = FETCH_MOCK:
          mockResult,
          method,
        },
      }),
    );
  };
};
