import React, { useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../utils';

type Props = {
  styling: PromotionStylingBanner;
};

const Wrapper = styled.div<{
  backgroundcolor: string;
  bordercolor: string;
  color: string;
}>`
  background-color: ${(props) => props.backgroundcolor};
  padding: 15px 29px 10px;
  border: 4px solid ${(props) => props.bordercolor};
  border-bottom: none;
  margin-bottom: 20px;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: ${(props) => props.color};
  font-weight: 400;
  margin-right: 8px;
  span {
    font-weight: 700;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1.25rem;
  }
`;

const Description = styled.div<{ color: string }>`
  font-weight: 400;
  font-size: 1rem;
  color: ${(props) => props.color};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    line-height: 1rem;
  }
`;

function PromoBanner({ styling }: Props) {
  const themeStyled = useContext(ThemeContext);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  return (
    <Wrapper
      backgroundcolor={styling.bannerBackgroundColor}
      bordercolor={styling.bannerBorderColor}
      color={styling.spanColor ? styling.spanColor : themeStyled.colors.white}
    >
      <Inner>
        <Title
          color={styling.color ? styling.color : themeStyled.colors.white}
          dangerouslySetInnerHTML={{ __html: t('promoBannerHeaderTitle') }}
        />
        <Title
          color={
            styling.spanColor ? styling.spanColor : themeStyled.colors.white
          }
          dangerouslySetInnerHTML={{
            __html: t('promotionCampaignBannerTitle'),
          }}
        />
      </Inner>
      <Description
        color={styling.color ? styling.color : themeStyled.colors.white}
        dangerouslySetInnerHTML={{ __html: t('promoBannerDescription') }}
      />
    </Wrapper>
  );
}

export default PromoBanner;
