export type ActionType =
  | 'RECOVER_PASSWORD_DATA'
  | 'REQUEST_RECOVER_PASSWORD_DATA'
  | 'RECEIVED_RECOVER_PASSWORD_DATA'
  | 'ERROR_RECOVER_PASSWORD_DATA';

interface ActionProps {
  type: ActionType;
  isFetching?: boolean;
  data?: {} | any;
  error?: any;
  payload?: {} | any;
}

const initialRecoverPasswordState = {
  isFetching: true,
  data: {},
  error: {},
};

const RecoverPassword = (
  state = initialRecoverPasswordState,
  action: Partial<ActionProps>,
) => {
  switch (action.type) {
    case 'REQUEST_RECOVER_PASSWORD_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_RECOVER_PASSWORD_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: { ...payload },
      };
    }

    case 'ERROR_RECOVER_PASSWORD_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
};

export default RecoverPassword;
