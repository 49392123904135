import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';

import PromotionSummary from './PromotionSummary';

interface TotalPriceProps {
  total: string;
  totalPerPerson: string;
  passengersCount: number;
  promotion: any;
  totalWithPromotion?: string;
  promotionValue?: string;
  isPromoted?: boolean;
  setShowPromoModal: any;
}

interface StyledProps {
  isPromotion?: boolean;
}

export const TotalPriceDesktopWrapper = styled.div`
  color: ${(props) => props.theme.colors.mineShaft};
  width: 100%;
  padding: 12px 16px 0;
  padding-top: 16px;
  border-top: ${(props) => `3px solid ${props.theme.colors.grayDark}`};
`;

export const TotalPriceTabletWrapper = styled.div<StyledProps>`
  color: ${(props) => props.theme.colors.black};
  display: inline-block;
  padding: 0px 0px 0px 25px;
  margin-top: ${(props) => (props.isPromotion ? '-10px' : '0px')};
  margin-left: 3px;
  vertical-align: middle;
  text-align: left;
  line-height: 1.25rem;
  position: relative;
  &:before {
    content: ' ';
    position: absolute;
    width: 1px;
    height: 90%;
    top: 5%;
    left: 0;
    background: ${(props) => props.theme.colors.white};
  }
`;

export const TotalPriceMobileWrapper = styled.div<StyledProps>`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.mineShaftDarker};
  display: inline-block;
  min-height: 50px;
  padding: 0 12px 0 0;
  vertical-align: middle;
  margin-top: ${(props) => (props.isPromotion ? '-10px' : '0px')};
`;

export const Total = styled.div`
  font-weight: 600;
  font-size: 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mdLegacy}) {
    text-align: left;
    margin-left: 10px;
  }
`;

const TotalText = styled.div`
  display: inline-block;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;

  @media (max-width: ${(props) => props.theme.breakpoints.mdLegacy}) {
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: ${(props) => props.theme.colors.white};
  }
`;

const TotalPriceText = styled.div`
  font-size: 1rem;
  line-height: 1.125rem;
  color: ${(props) => props.theme.colors.grandis};
  span {
    margin-right: 4px;
  }
`;

export const TotalPerPerson = styled.div`
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  margin-left: 10px;
  @media (max-width: ${(props) => props.theme.breakpoints.mdLegacy}) {
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-left: 0px;
    color: ${(props) => props.theme.colors.white};
  }
`;

export const Right = styled.div`
  float: right;
  font-size: 1rem;

  span {
    margin-right: 4px;
  }
`;

export const Left = styled.div`
  font-size: 1.25rem;
`;

function TotalPrice(props: TotalPriceProps) {
  const {
    total,
    totalPerPerson,
    passengersCount,
    promotion,
    totalWithPromotion,
    promotionValue,
    isPromoted,
    setShowPromoModal,
  } = props;

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const mdDown = useMediaQuery('@media (max-width:1199px)'); // Hack: we have to do this until we mix usage of layout and theme breakpoints
  const mdUp = useMediaQuery('@media (min-width:1199px)'); // Hack: we have to do this until we mix usage of layout and theme breakpoints

  let Wrapper;

  if (xsDown) {
    Wrapper = TotalPriceMobileWrapper;
  } else if (mdDown) {
    Wrapper = TotalPriceTabletWrapper;
  } else {
    Wrapper = TotalPriceDesktopWrapper;
  }

  return (
    <Wrapper isPromotion={promotionValue}>
      {mdUp && (
        <Total>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={8}>
              <TotalText>{t('checkout@total')}</TotalText>
              <TotalPerPerson
                dangerouslySetInnerHTML={{
                  __html: totalPerPerson + ' x ' + passengersCount,
                }}
              />
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <div dangerouslySetInnerHTML={{ __html: total }} />
            </Grid>
          </Grid>
        </Total>
      )}
      {mdDown && (
        <Total>
          <TotalText>{t('checkout@total')}</TotalText>
          <TotalPriceText dangerouslySetInnerHTML={{ __html: total }} />
          <TotalPerPerson
            dangerouslySetInnerHTML={{
              __html: totalPerPerson + ' x ' + passengersCount,
            }}
          />
        </Total>
      )}
      {promotionValue && totalWithPromotion && isPromoted ? (
        <PromotionSummary
          promotion={promotion}
          value={promotionValue}
          total={totalWithPromotion}
          setShowPromoModal={setShowPromoModal}
        />
      ) : (
        <></>
      )}
    </Wrapper>
  );
}

export default TotalPrice;
