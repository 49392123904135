import { ActionType } from '../reducers/recoverPassword';
import { api } from '../config';
import { setConfigData } from './config';

export const recoverPasswordByEmail = (data) => {
  return (dispatch) => {
    const parameters = Object.entries(data)
      .map(
        (e) =>
          encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1] as string),
      )
      .join('&');

    const url = `${api.passwordEmail}`;
    const method = 'post';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {
      data: parameters,
    };

    const type: ActionType = 'RECOVER_PASSWORD_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: 'FETCH',
          actionTypes: {
            request: 'REQUEST_RECOVER_PASSWORD_DATA',
            success: 'RECEIVED_RECOVER_PASSWORD_DATA',
            fail: 'ERROR_RECOVER_PASSWORD_DATA',
          },
          url,
          method,
          headers,
          options,
        },
      }),
    );
  };
};

export const changePasswordByPasskey = (data, passkey) => {
  return async (dispatch) => {
    const url = `${api.changePasswordByPasskey}/${passkey}`;
    const method = 'put';
    const options = {
      data: data,
    };
    const type: ActionType = 'RECOVER_PASSWORD_DATA';

    const response = await dispatch({
      type,
      fetch: {
        type: 'FETCH',
        actionTypes: {
          request: 'REQUEST_RECOVER_PASSWORD_DATA',
          success: 'RECEIVED_RECOVER_PASSWORD_DATA',
          fail: 'ERROR_RECOVER_PASSWORD_DATA',
        },
        url,
        method,
        options,
      },
    });

    await dispatch(setConfigData(true));

    return response;
  };
};
