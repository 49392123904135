import React, { useEffect } from 'react';
import { connect } from 'react-redux';

declare global {
  interface Window {
    FB: {
      init(data: {
        appId: string;
        cookie: boolean;
        xfbml: boolean;
        version: string;
      }): void;
      Event: {
        subscribe: any;
        unsubscribe: any;
      };
    };
  }
}

interface FacebookButtonProps {
  authCallback(data: any): any;
  appId: string;
}

const FacebookButton = ({ authCallback, appId }: FacebookButtonProps) => {
  const handleResponse = (response) => {
    if (response.authResponse) {
      authCallback(response.authResponse);
    } else {
      // Do nothing when user cancel
      console.log('User cancelled login or did not fully authorize.');
    }
  };

  useEffect(() => {
    window.FB?.Event?.subscribe('auth.statusChange', handleResponse);

    return () => {
      window.FB?.Event?.unsubscribe('auth.statusChange', handleResponse);
    };
  });

  useEffect(() => {
    if (window.FB?.init) {
      window.FB?.init({
        appId: `'${appId}'`,
        cookie: true,
        xfbml: true,
        version: 'v7.0',
      });
    }
  }, [appId]);

  return (
    <div
      className="fb-login-button"
      data-size="large"
      data-button-type="continue_with"
      data-layout="default"
      data-auto-logout-link="false"
      data-use-continue-as="true"
      data-scope="email"
      data-width=""
    />
  );
};

const mapStateToProps = (state) => ({
  appId: state.config.data?.facebook?.appId,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookButton);
