export type ActionType =
  | 'SET_PROMOTION_DATA'
  | 'REQUEST_PROMOTION_DATA'
  | 'RECEIVED_PROMOTION_DATA'
  | 'IS_CACHED_PROMOTION_DATA'
  | 'ERROR_PROMOTION_DATA'
  | 'SET_SHOW_MODAL';

interface ActionProps {
  type: ActionType;
  isFetching?: boolean;
  data?: {} | any;
  error?: any;
  payload?: {} | any;
  showModal: boolean;
}

interface PromotionStateObject {
  isFetching: boolean;
  data: Promotion;
  error: any;
  showModal: boolean;
}

const initialPromotionState: PromotionStateObject = {
  isFetching: true,
  data: {
    enabled: false,
    flash: false,
    discount: false,
    product: false,
    landing: false,
    discounts: [],
    styling: {
      banner: {
        bannerBackgroundColor: '',
        bannerBorderColor: '',
        bannerColor: '',
        spanColor: '',
      },
      yoBar: {
        icon: '',
        iconSize: 0,
        background: '',
        backgroundColor: '',
        buttonTextColor: '',
        buttonBackgroundColor: '',
        titleColor: '',
        subtitleColor: '',
      },
      modal: {
        icon: '',
        background: '',
        backgroundColor: '',
      },
      product: {
        icon: '',
        color: '',
        linkColor: '',
      },
      landing: {
        icon: '',
      },
    },
  },
  showModal: false,
  error: {},
};

const Promotion = (
  state = initialPromotionState,
  action: Partial<ActionProps>,
) => {
  switch (action.type) {
    case 'REQUEST_PROMOTION_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_PROMOTION_DATA':
    case 'IS_CACHED_PROMOTION_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: { ...payload },
      };
    }

    case 'ERROR_PROMOTION_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    case 'SET_SHOW_MODAL':
      const { data = {} } = action;
      return {
        ...state,
        showModal: data,
      };

    default:
      return state;
  }
};

export default Promotion;
