let appConfig: any;
export const getApiRoot = () => {
  if (
    process.env.NODE_ENV === 'development' &&
    window.location.hostname === '192.168.33.1'
  ) {
    return window.location.pathname.substring(1, 3);
  }
  let environment = '';
  let protocol = '';
  let affiliate = '';
  let topLevelDomain = 'com';

  switch (process.env.REACT_APP_ENV) {
    case 'production': {
      protocol = 'https';
      const regexAffiliate = window.location.hostname.match(
        /(.*).exoticca\.com/,
      );

      if (
        regexAffiliate &&
        regexAffiliate.length &&
        regexAffiliate[1] !== 'www' &&
        !!regexAffiliate[1].indexOf('api')
      ) {
        affiliate = `${regexAffiliate[1]}-`;
      }
      break;
    }
    case 'preproduction': {
      protocol = 'https';
      environment = 'prepro-';
      const regexHost = window.location.hostname.match(/.*([0-9])\.exoticca\./);
      const preFromCookieMatch = document.cookie.match(
        '(^|;) ?preBeEnv=([^;]*)(;|$)',
      );

      const preFromCookie = preFromCookieMatch ? preFromCookieMatch[2] : false;

      if (preFromCookie) {
        environment = `prepro${preFromCookie}-`;
      } else if (regexHost) {
        environment = `prepro0${regexHost[1]}-`;
      } else if (document.location.hostname.includes('agency')) {
        environment = `prepro-`;
      }

      if (document.location.hostname.includes('agency')) {
        topLevelDomain = 'agency';
      }

      const regexAffiliate = new RegExp(
        `(.*)-${environment.slice(0, -1)}.exoticca.`,
      ).exec(window.location.hostname);

      if (regexAffiliate) {
        affiliate = `${regexAffiliate[1]}-`;
      }
      break;
    }
    default: {
      environment = 'develop-';
      protocol = 'http';

      const regexAffiliate = window.location.hostname.match(
        /(.*)-develop\.exoticca\.com/,
      );

      if (regexAffiliate) {
        affiliate = `${regexAffiliate[1]}-`;
      }
      break;
    }
  }

  const regexApi = window.location.hostname.match(/(.*)api-(.*).exoticca\./);

  const market = regexApi
    ? regexApi[2]
    : window.location.pathname.substring(1, 3);

  switch (market) {
    case 'es':
      return `${protocol}://${environment}api-${affiliate}es.exoticca.${topLevelDomain}`;
    case 'fr':
      return `${protocol}://${environment}api-${affiliate}fr.exoticca.${topLevelDomain}`;
    case 'de':
      return `${protocol}://${environment}api-${affiliate}de.exoticca.${topLevelDomain}`;
    case 'uk':
      return `${protocol}://${environment}api-${affiliate}uk.exoticca.${topLevelDomain}`;
    case 'ca':
      return `${protocol}://${environment}api-${affiliate}ca.exoticca.${topLevelDomain}`;
    case 'us':
      return `${protocol}://${environment}api-${affiliate}us.exoticca.${topLevelDomain}`;
    default:
      return `${protocol}://${environment}api-${affiliate}es.exoticca.${topLevelDomain}`;
  }
};

const apiRoot = getApiRoot();

export const api = {
  header: `${apiRoot}/api/header`,
  footer: `${apiRoot}/api/footer`,
  home: `${apiRoot}/api/home`,
  landing: `${apiRoot}/api/landing`,
  orderConfirm: `${apiRoot}/api/order/confirm`,
  login: `${apiRoot}/api/customer/login`,
  loginFacebook: `${apiRoot}/a/customer/facebook`,
  logout: `${apiRoot}/api/logout`,
  passwordEmail: `${apiRoot}/api/customer/password/email`,
  changePasswordByPasskey: `${apiRoot}/api/customer/password`,
  register: `${apiRoot}/api/customer/create`,
  category: `${apiRoot}/api/category`,
  voyages: `${apiRoot}/api/product`,
  budget: {
    get: `${apiRoot}/api/budget`,
    create: `${apiRoot}/api/budget/create`,
    put: `${apiRoot}/api/lead/phone`,
  },
  checkout: {
    order: `${apiRoot}/api/checkout/order`,
    wallet: `${apiRoot}/api/checkout/wallet`,
    extranight: (orderAttemptId: string, nightNumber: string) =>
      `${apiRoot}/api/checkout/${orderAttemptId}/extranight/${nightNumber}`,
    notConfirmed: `${apiRoot}/api/checkout/notConfirmed`,
    confirmed: (bookingId, method) =>
      `${apiRoot}/api/booking/${bookingId}/confirm/${method}`,
  },
  payment: {
    affirm: {
      create: `${apiRoot}/api/affirm/charge/create`,
    },
    stripe: {
      create: `${apiRoot}/api/stripe/intent/create`,
      confirm: `${apiRoot}/api/stripe/intent/confirm`,
      error: `${apiRoot}/api/stripe/error`,
    },
  },
  ticket: {
    get: `${apiRoot}/api/ticket`,
  },
  cancellationVoucher: {
    get: `${apiRoot}/api/voucher/cancellation/pdf`,
  },
  contentBlock: `${apiRoot}/api/block`,
  genericPages: `${apiRoot}/api/generic`,
  orderAttempt: {
    get: `${apiRoot}/api/checkout/orderAttempt`,
    put: `${apiRoot}/api/budget/update`,
    create: (productId) => `${apiRoot}/api/checkout/${productId}/attempt`,
  },
  partPayment: {
    get: `${apiRoot}/api/fractional/payment`,
  },
  promotion: `${apiRoot}/api/promotion`,
  myaccount: {
    myOrders: `${apiRoot}/api/myaccount`,
    myProfile: `${apiRoot}/api/customer`,
    setProfile: `${apiRoot}/api/customer/personal`,
    myWallet: `${apiRoot}/api/wallet`,
    myWalletBalance: `${apiRoot}/api/wallet/balance`,
    myWalletMovements: `${apiRoot}/api/wallet/movements`,
    myWishList: `${apiRoot}/api/wishlist`,
  },
  crossSelling: `${apiRoot}/api/product`,
  config: `${apiRoot}/api/site/context`,
  configGuest: `${apiRoot}/api/site/context/guest`,
  contact: `${apiRoot}/api/customer/contact`,
  leadForm: `${apiRoot}/api/lead/form`,
  departures: `${apiRoot}/api/site/departures`,
};

export const typeformUrl = Object.freeze({
  ES: 'https://exoticca.typeform.com/to/qpezPD',
  GB: 'https://exoticca.typeform.com/to/gUoURQ',
  US: 'https://exoticca.typeform.com/to/aNePpU',
  CA: 'https://exoticca.typeform.com/to/PDQRrl',
  FR: 'https://exoticca.typeform.com/to/FJCdPs',
  DE: 'https://exoticca.typeform.com/to/ingys1',
});

export const cloudinary = Object.freeze({
  cloud_name: 'exoticca',
  api_key: '553415253551775',
});

const production = Object.freeze({
  ES: {
    facebook: {
      appId: '255316184593007',
    },
    responseTap: {
      adiInit: '70671',
      class: 'rTapNumber436460',
    },
    tracking: {
      tag_manager_id: 'GTM-TTKQV6',
      tag_manager_preview: 'env-7',
      tag_manager_auth: 'khW3ICbLzeL2fSwigCqqCw',
    },
  },
  AU: {
    facebook: {
      appId: '290149081413292',
    },
    responseTap: {
      adiInit: '70671',
      class: 'rTapNumber409076',
    },
    tracking: {
      tag_manager_id: 'GTM-NBJR35P',
      tag_manager_preview: 'env-2',
      tag_manager_auth: 'Woh71bSJQul_yoKVpZJ_GQ',
    },
  },
  UK: {
    facebook: {
      appId: '1325079820844581',
    },
    responseTap: {
      adiInit: '70671',
      class: 'rTapNumber436458',
    },
    tracking: {
      tag_manager_id: 'GTM-T3WR6H',
      tag_manager_preview: 'env-2',
      tag_manager_auth: 'EQJ70Onc0O5Bg8MUuKR86A',
    },
  },
  US: {
    facebook: {
      appId: '255316184593007',
    },
    responseTap: {
      adiInit: '70671',
      class: 'rTapNumber407804',
    },
    tracking: {
      tag_manager_id: 'GTM-WD98T59',
      tag_manager_preview: 'env-2',
      tag_manager_auth: '2c5CrAo7WhJQVO0QLaSJ-w',
    },
    affirm: {
      public_api_key: 'KEHVTAXI6J9PJE6N',
      script: 'https://cdn1.affirm.com/js/v2/affirm.js',
    },
  },
  FR: {
    facebook: {
      appId: '290149081413292',
    },
    responseTap: {
      adiInit: '70671',
      class: 'rTapNumber436462',
    },
    tracking: {
      tag_manager_id: 'GTM-KBC3HJF',
      tag_manager_preview: 'env-2',
      tag_manager_auth: 'nO5Dr0DGdMDkNLDzXAn7aw',
    },
  },
  DE: {
    facebook: {
      appId: '255316184593007',
    },
    responseTap: {
      adiInit: '70671',
      class: 'rTapNumber436463',
    },
    trustedShopsId: 'X8BD1B977840A133CCAE9FFDF138CF077',
    tracking: {
      tag_manager_id: 'GTM-K46D2QK',
      tag_manager_preview: 'env-2',
      tag_manager_auth: 'howRqkFVv2NYabqM0qH4cA',
    },
  },
  CA: {
    facebook: {
      appId: '255316184593007',
    },
    responseTap: {
      adiInit: '70671',
      class: 'rTapNumber436459',
    },
    tracking: {
      tag_manager_id: 'GTM-WX9S92P',
      tag_manager_preview: 'env-2',
      tag_manager_auth: 'jixv-gNErOWMHre0yzkAWQ',
    },
  },
});

const preproduction = Object.freeze({
  ES: {
    facebook: {
      appId: '255316184593007',
    },
    responseTap: {
      adiInit: '70671',
      class: 'rTapNumber436460',
    },
    tracking: {
      tag_manager_id: 'GTM-TTKQV6',
      tag_manager_preview: 'env-71',
      tag_manager_auth: '364802YxPEkoJ2ZVaF6nIA',
    },
  },
  AU: {
    facebook: {
      appId: '255316184593007',
    },
    tracking: {
      tag_manager_id: 'GTM-NBJR35P',
      tag_manager_preview: 'env-42',
      tag_manager_auth: 'H-sUkCYZnyfCMsSZAPLHWw',
    },
  },
  UK: {
    facebook: {
      appId: '1325079820844581',
    },
    tracking: {
      tag_manager_id: 'GTM-T3WR6H',
      tag_manager_preview: 'env-38',
      tag_manager_auth: 'y9dYC105byaT6a0fLW60JQ',
    },
  },
  US: {
    facebook: {
      appId: '255316184593007',
    },
    tracking: {
      tag_manager_id: 'GTM-WD98T59',
      tag_manager_preview: 'env-32',
      tag_manager_auth: 'U1AaWYnwBh9RfdYy1cYlwA',
    },
    affirm: {
      public_api_key: 'PG6CNF51OAEZT602',
      script: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
    },
  },
  FR: {
    facebook: {
      appId: '290149081413292',
    },
    tracking: {
      tag_manager_id: 'GTM-KBC3HJF',
      tag_manager_preview: 'env-48',
      tag_manager_auth: '99dSy2aCiH5e7ZSgj4rb1g',
    },
  },
  DE: {
    facebook: {
      appId: '255316184593007',
    },
    trustedShopsId: 'X8BD1B977840A133CCAE9FFDF138CF077',
    tracking: {
      tag_manager_id: 'GTM-K46D2QK',
      tag_manager_preview: 'env-37',
      tag_manager_auth: 'bwYUIwcTbX2VQ1BPrTBrOg',
    },
  },
  CA: {
    facebook: {
      appId: '255316184593007',
    },
    tracking: {
      tag_manager_id: 'GTM-WX9S92P',
      tag_manager_preview: 'env-5',
      tag_manager_auth: 'z21xAhoGG7dZwc4o9wHnSQ',
    },
  },
});

const develop = Object.freeze({
  ES: {
    facebook: {
      appId: '255316184593007',
    },
    tracking: {
      tag_manager_id: 'GTM-000000',
      tag_manager_preview: 'env-71',
      tag_manager_auth: '364802YxPEkoJ2ZVaF6nIA',
    },
  },
  AU: {
    facebook: {
      appId: '255316184593007',
    },
    tracking: {
      tag_manager_id: 'GTM-000000',
      tag_manager_preview: 'env-42',
      tag_manager_auth: 'H-sUkCYZnyfCMsSZAPLHWw',
    },
  },
  UK: {
    facebook: {
      appId: '1325079820844581',
    },
    tracking: {
      tag_manager_id: 'GTM-000000',
      tag_manager_preview: 'env-38',
      tag_manager_auth: 'y9dYC105byaT6a0fLW60JQ',
    },
  },
  US: {
    facebook: {
      appId: '255316184593007',
    },
    tracking: {
      tag_manager_id: 'GTM-000000',
      tag_manager_preview: 'env-32',
      tag_manager_auth: 'U1AaWYnwBh9RfdYy1cYlwA',
    },
    affirm: {
      public_api_key: 'PG6CNF51OAEZT602',
      script: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
    },
  },
  FR: {
    facebook: {
      appId: '290149081413292',
    },
    tracking: {
      tag_manager_id: 'GTM-000000',
      tag_manager_preview: 'env-48',
      tag_manager_auth: '99dSy2aCiH5e7ZSgj4rb1g',
    },
  },
  DE: {
    facebook: {
      appId: '255316184593007',
    },
    trustedShopsId: 'X8BD1B977840A133CCAE9FFDF138CF077',
    tracking: {
      tag_manager_id: 'GTM-000000',
      tag_manager_preview: 'env-37',
      tag_manager_auth: 'bwYUIwcTbX2VQ1BPrTBrOg',
    },
  },
  CA: {
    facebook: {
      appId: '255316184593007',
    },
    tracking: {
      tag_manager_id: 'GTM-000000',
      tag_manager_preview: 'env-5',
      tag_manager_auth: 'z21xAhoGG7dZwc4o9wHnSQ',
    },
  },
});

export const trustPilotConfig = Object.freeze({
  es: {
    businessUnitId: '5c78d049c09c790001605f1a',
    url: 'https://es.trustpilot.com/review/exoticca.com',
    language: 'es-ES',
    rating: {
      id: '5613c9cde69ddc09340c6beb',
      height: '150px',
    },
    review: {
      id: '530d0eaf748a510e2093cf9b',
      height: '900px',
    },
  },
  uk: {
    businessUnitId: '5c78d049c09c790001605f1a',
    url: 'https://uk.trustpilot.com/review/exoticca.com',
    language: 'en-GB',
    height: '30px',
    rating: {
      id: '5613c9cde69ddc09340c6beb',
      height: '30px',
    },
    review: {
      id: '530d0eaf748a510e2093cf9b',
      height: '900px',
    },
  },
  us: {
    businessUnitId: '5c78d049c09c790001605f1a',
    url: 'https://www.trustpilot.com/review/exoticca.com',
    language: 'en-US',
    height: '30px',
    rating: {
      id: '5613c9cde69ddc09340c6beb',
      height: '30px',
    },
    review: {
      id: '530d0eaf748a510e2093cf9b',
      height: '900px',
    },
  },
  ca: {
    businessUnitId: '5c78d049c09c790001605f1a',
    url: 'https://ca.trustpilot.com/review/exoticca.com',
    language: 'en-CA',
    rating: {
      id: '5613c9cde69ddc09340c6beb',
      height: '30px',
    },
    review: {
      id: '530d0eaf748a510e2093cf9b',
      height: '900px',
    },
  },
  fr: {
    businessUnitId: '5c78d049c09c790001605f1a',
    url: 'https://fr.trustpilot.com/review/exoticca.com',
    language: 'fr-FR',
    rating: {
      id: '5613c9cde69ddc09340c6beb',
      height: '30px',
    },
    review: {
      id: '530d0eaf748a510e2093cf9b',
      height: '900px',
    },
  },
  de: {},
});

export const themedAffiliates = Object.freeze({
  'secretescapes-develop.exoticca.com': '2',
  'secretescapes-v2-develop.exoticca.com': '2',
  'secretescapes-prepro.exoticca.com': '2',
  'secretescapes-prepro01.exoticca.com': '2',
  'secretescapes-prepro02.exoticca.com': '2',
  'secretescapes-v2-prepro.exoticca.com': '2',
  'secretescapes-v2-prepro01.exoticca.com': '2',
  'secretescapes-v2-prepro02.exoticca.com': '2',
  'secretescapes.exoticca.com': '2',
  'secretescapes-v2.exoticca.com': '2',

  'journaway-develop.exoticca.de': '40',
  'journaway-prepro.exoticca.de': '40',
  'journaway-prepro01.exoticca.de': '40',
  'journaway-prepro02.exoticca.de': '40',
  'journaway.exoticca.de': '40',
});

switch (process.env.REACT_APP_ENV) {
  case 'production': {
    appConfig = production;
    break;
  }
  case 'preproduction': {
    appConfig = preproduction;
    break;
  }
  default: {
    appConfig = develop;
    break;
  }
}

export default appConfig;
