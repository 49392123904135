import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

export interface LoadingObject {
  campaign?: boolean;
  summary?: boolean;
  payments?: boolean;
  passengers?: boolean;
  category?: boolean;
}

interface LoadingProps {
  isVisible: boolean;
  children?: any;
  height?: string;
  color?: string;
}

interface StyledProps {
  isVisible?: boolean;
  height?: string;
}

const LoadingWrapper = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${(props) => (props.height ? props.height : 'inherit')};
`;

const LoadingContent = styled.div<StyledProps>`
  opacity: 0.86;
  display: none;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.isVisible &&
    `
    display: flex;`}
`;

const LoadingKeyframes = keyframes`
  0% {
    background: #000000;
    transform: scale(0.1);
  }
  50% {
    background: rgba(0, 0, 0, 0.25);
    transform: scale(0.5);
  }
  100% {
    background: #000000;
    transform: scale(1);
  }
`;

const LoadingDots = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;

  span {
    position: absolute;
    width: 10px;
    height: 10px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    animation: ${LoadingKeyframes} 1.5s infinite ease-out;
  }

  span:nth-child(1) {
    left: 0px;
    animation-delay: 0.2s;
  }

  span:nth-child(2) {
    left: 15px;
    animation-delay: 0.4s;
  }

  span:nth-child(3) {
    left: 30px;
    animation-delay: 0.6s;
  }

  span:nth-child(4) {
    left: 45px;
    animation-delay: 0.8s;
  }
`;

const Loading = (props: LoadingProps) => {
  const { isVisible, children, height } = props;

  return (
    <LoadingWrapper height={height}>
      <LoadingContent isVisible={isVisible}>
        <LoadingDots>
          <span />
          <span />
          <span />
          <span />
        </LoadingDots>
      </LoadingContent>
      {children}
    </LoadingWrapper>
  );
};

export default Loading;
