import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components/macro';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import Icon from '../Icon';

interface SuccessErrorProps {
  error: boolean;
  success: boolean;
  warning?: boolean;
  eye?: boolean;
}

interface InputElementProps extends SuccessErrorProps {
  tooltipText?: string;
  width: string;
}

interface StyledTooltipProps {
  colors: {
    white: string;
    fireBush: string;
    mineShaftDarker: string;
  };
}

const InputWrapper = styled.div`
  padding: 10px 0;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    padding: 2px 0;
  }
`;

const InputElementWrapper = styled.div<SuccessErrorProps>`
  position: relative;

  &:before{
    position: absolute;
    right: 13px;
    top: 11px;
    font-family: 'icomoon';
    ${(props) =>
      props.error &&
      `
      color: ${props.theme.colors.red};
      content: "";`}
    ${(props) =>
      props.success &&
      `
      color: ${props.theme.colors.keppel};
      content: "";`}
   ${(props) =>
     props.warning &&
     `
      color: ${props.theme.colors.fireBush};
      content: "";
      top: 7px;
      font-size: 1.2rem;`}
  }
`;

const InputElement = styled.input<InputElementProps>`
  width: ${(props) => props.width};
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    min-width: ${(props) => props.width};
  }
  border: solid 1px ${(props) => props.theme.colors.silver};
  border-radius: 5px;
  ${(props) =>
    props.error &&
    `
    border-color: ${props.theme.colors.red};`}

  ${(props) =>
    props.warning &&
    `
    border-color: ${props.theme.colors.fireBush};`}
  padding: 10px 15px;
  &::-webkit-input-placeholder {
    font-style: italic;
  }
  &::-moz-placeholder {
    font-style: italic;
  }
  &:focus {
    border: solid 1px
      ${(props) =>
        props.error
          ? props.theme.colors.red
          : props.warning
          ? props.theme.colors.fireBush
          : props.theme.colors.ferra};
    &::-webkit-input-placeholder {
      opacity: 0;
      font-style: italic;
    }
    &::-moz-placeholder {
      opacity: 0;
      font-style: italic;
    }
  }
`;

const LabelElement = styled.label<SuccessErrorProps>`
  width: 100%;
  font-weight: 500;
  text-align: start;
  color: ${(props) => props.theme.colors.mineShaftDarker};
  ${(props) =>
    props.error &&
    `
    color: ${props.theme.colors.red};`}
`;

const StyledHelpText = styled.small`
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
  color: ${(props) => props.theme.colors.doveGray};
  text-align: left;
`;

const StyledErrorText = styled.small`
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
  color: ${(props) => props.theme.colors.red};
  text-align: left;
`;

const StyledWarningText = styled.small`
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 5px;
  text-align: left;

  > span {
    color: ${(props) => props.theme.colors.fireBush};
  }
`;

const InputTooltipText = styled.div`
  display: inline-block;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.43;
  margin-right: 4px;
  color: ${(props) => props.theme.colors.mineShaftDarker};

  span {
    font-weight: 500;
    color: ${(props) => props.theme.colors.fireBush};
  }
`;

const InputTooltip = withStyles(() => ({
  tooltip: (props: StyledTooltipProps) => ({
    backgroundColor: props.colors.white,
    border: 'solid 1px ' + props.colors.fireBush,
    borderRadius: '5px',
    padding: '12px 16px',
    marginBottom: '9px',
  }),
  popper: {
    zIndex: 13,
  },
}))(Tooltip);

const Input = (props: Input) => {
  const {
    id,
    inputName,
    value,
    type,
    labelText,
    error,
    success,
    warning,
    errorText,
    width,
    placeHolderText,
    readOnly,
    wrapperStyle,
    customStyle,
    onChange,
    onBlur,
    onKeyDown,
    helpText,
    warningText,
    tooltipText,
    autoComplete,
  } = props;

  const themeStyled = useContext(ThemeContext);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  const handleTooltipOpen = () => {
    setShowTooltip(true);
  };

  const handleOnBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
    handleTooltipClose();
  };

  return (
    <InputWrapper style={wrapperStyle}>
      {labelText && (
        <LabelElement error={error} success={success} htmlFor={inputName}>
          {tooltipText ? (
            <InputTooltip
              onClose={handleTooltipClose}
              onOpen={handleTooltipOpen}
              open={showTooltip}
              placement={'top-start'}
              title={
                <InputTooltipText
                  dangerouslySetInnerHTML={{ __html: tooltipText }}
                />
              }
              colors={{
                white: themeStyled.colors.white,
                fireBush: themeStyled.colors.fireBush,
                mineShaftDarker: themeStyled.colors.mineShaftDarker,
              }}
            >
              <div>
                {labelText}
                <Icon
                  customStyle={{ marginLeft: '4px' }}
                  color={themeStyled.colors.fireBush}
                  icon={'more-info'}
                  size={16}
                />
              </div>
            </InputTooltip>
          ) : (
            labelText
          )}
        </LabelElement>
      )}
      <InputElementWrapper error={error} success={success} warning={warning}>
        <InputElement
          id={id ? id : inputName}
          name={inputName}
          value={value}
          type={type}
          error={error}
          success={success}
          warning={warning}
          style={customStyle}
          width={width}
          placeholder={placeHolderText}
          readOnly={readOnly}
          onChange={onChange}
          onBlur={handleOnBlur}
          onFocus={handleTooltipOpen}
          onKeyDown={onKeyDown}
          autoComplete={autoComplete}
        />
      </InputElementWrapper>
      {warningText && (
        <StyledWarningText dangerouslySetInnerHTML={{ __html: warningText }} />
      )}
      {error && <StyledErrorText>{errorText}</StyledErrorText>}
      {helpText && (
        <StyledHelpText dangerouslySetInnerHTML={{ __html: helpText }} />
      )}
    </InputWrapper>
  );
};

Input.displayName = 'Input';

Input.defaultProps = {
  type: 'text',
  error: false,
  success: false,
  width: '100%',
  readOnly: false,
};

export default Input;
