import { ActionType } from '../reducers/orderAttempt';
import { api } from '../config';
import putOrderAttemptMockData from '../mocks/putOrderAttempt.json';
import getOrderAttemptMockData from '../mocks/getOrderAttempt.json';
import postOrderAttemptMockData from '../mocks/postOrderAttempt.json';

export const getOrderAttemptData = (orderAttemptId: string) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = getOrderAttemptMockData;

    const randomNumber = Math.random() * 10000000000000000;
    const url = `${api.orderAttempt.get}/${orderAttemptId}?rand=${randomNumber}`;
    const method = 'get';

    const type: ActionType = 'SET_ORDER_ATTEMPT_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_ORDER_ATTEMPT_DATA',
            success: 'RECEIVED_ORDER_ATTEMPT_DATA',
            fail: 'ERROR_ORDER_ATTEMPT_DATA',
          },
          url,
          mockResult,
          method,
        },
      }),
    );
  };
};

export const updateOrderAttemptData = (data: {}) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = putOrderAttemptMockData;

    const url = `${api.orderAttempt.put}`;
    const method = 'put';
    const options = {
      data: data,
      headers: { 'Content-Type': 'application/json' },
    };

    const type: ActionType = 'PUT_ORDER_ATTEMPT_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_UPDATE_ORDER_ATTEMPT_DATA',
            success: 'RECEIVED_UPDATE_ORDER_ATTEMPT_DATA',
            fail: 'ERROR_UPDATE_ORDER_ATTEMPT_DATA',
          },
          url,
          mockResult,
          method,
          options,
        },
      }),
    );
  };
};

export const orderAttemptCreate = (data) => {
  return (dispatch) => {
    const parameters = Object.entries(data)
      .map(
        (e) =>
          encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1] as string),
      )
      .join('&');

    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = postOrderAttemptMockData;
    const url = api.orderAttempt.create(data.productId);
    const method = 'post';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {
      data: parameters,
    };

    const type: ActionType = 'ORDER_ATTEMPT_CREATE_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_ORDER_ATTEMPT_CREATE_DATA',
            success: 'RECEIVED_ORDER_ATTEMPT_CREATE_DATA',
            fail: 'ERROR_ORDER_ATTEMPT_CREATE_DATA',
          },
          url,
          mockResult,
          method,
          headers,
          options,
        },
      }),
    );
  };
};
