const CACHE_TIME_15_MINUTES = 900000;
const CACHE_TIME_5_MINUTES = 300000;

export const getUrlCache = (url) => {
  let sessionStorage;

  try {
    sessionStorage = window.sessionStorage;
    // Get the url cache from the localstorage
    return sessionStorage.getItem(url)
      ? JSON.parse(sessionStorage.getItem(url))
      : {};
  } catch (e) {
    console.log('sessionStorage does not exist');
  }
};

export const isUrlCached = (url: string) => {
  const urlCache = getUrlCache(url);
  const lastUpdate = new Date(urlCache.lastUpdate);
  const now = new Date();

  const cacheTime = url.includes('home')
    ? CACHE_TIME_5_MINUTES
    : CACHE_TIME_15_MINUTES;

  const validCache =
    urlCache &&
    lastUpdate.getTime() &&
    now.getTime() - lastUpdate.getTime() < cacheTime;

  if (!validCache) {
    removeUrlCached(url);
    return false;
  }

  setCurrentUrlCached(url);

  return true;
};

export const setUrlCached = (url, data) => {
  sessionStorage.setItem(url, JSON.stringify(data));
  setCurrentUrlCached(url);

  return true;
};

export const removeUrlCached = (url) => {
  const myCache = getUrlCache(url);

  if (myCache) {
    sessionStorage.removeItem(url);
    return true;
  }

  return false;
};

export const setCurrentUrlCached = (url) => {
  if (url.includes('getFlights')) {
    sessionStorage.setItem('currentUrlCached', url);
  }

  return true;
};

export const removeCurrentUrlCached = () => {
  const currentUrlCached = sessionStorage.getItem('currentUrlCached');

  if (currentUrlCached) {
    removeUrlCached(currentUrlCached);
    sessionStorage.removeItem('currentUrlCached');
    return true;
  }

  return false;
};
