import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import posed, { PoseGroup } from 'react-pose';
import Icon from './Icon';

export interface ModalProps {
  isVisible: boolean;
  onClose(): void;
  children: any;
  layout?: any;
  pose?: string;
  width?: string;
  isPromotion?: boolean;
  isDoublePromotion?: boolean;
  color?: string;
  customStyle?: any;
}

interface WidthProp {
  width?: string;
  isPromotion?: boolean;
  isDoublePromotion?: boolean;
}

const PopUp = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 150,
    transition: {
      y: { type: 'spring', stiffness: 100, damping: 15 },
      default: { duration: 200 },
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 150 },
  },
});

const ShadeUp = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
});

export const Shade = styled(ShadeUp)`
  position: fixed !important; /* Must have !important because POSE adds absolute */
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`;

export const ModalWrapper = styled(PopUp)<WidthProp>`
  position: fixed;
  top: ${(props) => (props.isPromotion ? '0' : '14vh')};
  left: calc((100% - ${(props) => (props.width ? props.width : '740px')}) / 2);
  width: ${(props) => (props.width ? props.width : '740px')};
  background: white;
  max-width: ${(props) => (props.isPromotion ? '100%' : '740px')};
  z-index: 9999999;
  border-radius: 4px;
  overflow-y: auto;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    top: ${(props) =>
      props.isDoublePromotion ? '0vh' : props.isPromotion ? '0vh' : '56px'};
    left: calc((100% - 740px) / 2);
    z-index: 9999999;
    width: 740px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    max-height: ${(props) =>
      props.isPromotion ? '100vh' : 'calc(100vh - 72px)'};
    z-index: 9999999;
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 1;
`;

const Modal = (props: ModalProps) => {
  const {
    isVisible,
    onClose,
    children,
    width,
    isPromotion,
    isDoublePromotion,
    color,
    customStyle,
  } = props;

  const theme = useContext(ThemeContext);

  // avoids body from scrolling
  if (isVisible) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }

  return ReactDOM.createPortal(
    <PoseGroup>
      {isVisible && [
        <ModalWrapper
          style={customStyle}
          key="modal"
          width={width}
          isDoublePromotion={isDoublePromotion}
          isPromotion={isPromotion}
        >
          <CloseIcon>
            <Icon
              color={theme.colors[color || 'whiteCreamy']}
              onClick={onClose}
              size={14}
              icon="ico-close"
            />
          </CloseIcon>
          {children}
        </ModalWrapper>,
        <Shade key="shade" onClick={onClose} />,
      ]}
    </PoseGroup>,
    document.body,
  );
};

interface HeaderProps {
  backgroundUrl?: string;
}

Modal.Header = styled.div<HeaderProps>`
  position: relative;
  background-color: ${(props) => props.theme.colors.mineShaftDarker};
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;

  ${(props) =>
    props.backgroundUrl &&
    css`
      background-image: url(${props.backgroundUrl});
      background-position: center;
    `}

  span {
    color: ${(props) => props.theme.colors.whiteCreamy};
    font-size: 1.063rem;
    font-weight: 500;
    padding: 15px 0 0 15px;
  }
`;

Modal.Content = styled.div`
  display: flex;
  max-height: 50vh;
  overflow-y: scroll;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
`;

export default Modal;
