import { store } from './Root';
import { themedAffiliates, getApiRoot } from './config';
/*import { matchPath } from 'react-router';*/

declare global {
  interface Window {
    ga: any;
    hj: any;
    localStorage: any;
    prerenderReady: boolean;
  }
}
window.hj =
  window.hj ||
  function () {
    (window.hj.q = window.hj.q || []).push(arguments);
  };

// Constants
const ICON_HEART = 'charm';
const ICON_STARS_4 = 'stars4';
const ICON_STARS_5 = 'stars1';
const ICON_STARS_5GL = 'stars-GL';
const ICON_STARS_3GL = 'stars3-SP';
const ICON_STARS_4SP = 'stars4-SP';

const AMERICA = 'america-stamp';
const ASIA = 'asia-stamp';
const AFRICA = 'africa-stamp';
const EUROPA = 'europa-stamp';
const OCEANIA = 'oceania-stamp';

const EXOTICCA_LOGO_DESKTOP = 'Logo-dorado';
const EXOTICCA_LOGO_TABLET = 'Logo-dorado';
const EXOTICCA_LOGO_MOBILE = 'Logo-dorado';
const EXOTICCA_LOGO_PDP = 'logo-mobile';
const VEEPEE_LOGO_DESKTOP = 'veepee-desktop-logo';
const VEEPEE_LOGO_TABLET = 'veepee-tablet-logo';
const VEEPEE_LOGO_MOBILE = 'veepee-mobile-logo';
const VEEPEE_LOGO_PDP = 'logo-veepee-pdp';
const PRIVALIA_LOGO_DESKTOP = 'privalia-veepee-desktop-logo';
const PRIVALIA_LOGO_TABLET = 'privalia-veepee-tablet-logo';
const PRIVALIA_LOGO_MOBILE = 'privalia-veepee-mobile-logo';
const PRIVALIA_LOGO_PDP = 'privalia-icon';
const SECRET_ESCAPES_ES_LOGO_DESKTOP = 'secret-escapes-desktop-logo-NEW';
const SECRET_ESCAPES_ES_LOGO_TABLET = 'secret-escapes-tablet-logo-NEW';
const SECRET_ESCAPES_ES_LOGO_MOBILE = 'secret-escapes-mobile-logo-NEW';
const SECRET_ESCAPES_ES_LOGO_PDP = 'SE-logo-barra';
const SECRET_ESCAPES_FR_LOGO_DESKTOP = 'evasions-secretes-desktop-logo-NEW';
const SECRET_ESCAPES_FR_LOGO_TABLET = 'evasions-secretes-tablet-logo-NEW';
const SECRET_ESCAPES_FR_LOGO_MOBILE = 'evasions-secretes-mobile-logo-NEW';
const SECRET_ESCAPES_FR_LOGO_PDP = 'SE-logo-barra';
const AVASA_LOGO_DESKTOP = 'avasa-Desktop--tablets';
const AVASA_LOGO_TABLET = 'avasa-Desktop--tablets';
const AVASA_LOGO_MOBILE = 'avasa-mobile';
const AVASA_LOGO_PDP = 'logo-mobile';
const JOURNAWAY_LOGO_DESKTOP = 'Journaway---desktop-logo';
const JOURNAWAY_LOGO_TABLET = 'Journaway---mobile-logo';
const JOURNAWAY_LOGO_MOBILE = 'Journaway---tablet-logo';
const JOURNAWAY_LOGO_PDP = 'logo-mobile';
const WEEKENDESK_LOGO_DESKTOP = 'weekendesk-desktop';
const WEEKENDESK_LOGO_TABLET = 'weekendesk-tablet';
const WEEKENDESK_LOGO_MOBILE = 'weekendesk-mobile';
const WEEKENDESK_LOGO_PDP = 'logo-mobile';

const METHOD_CARD = 'TARJETA';
const METHOD_PAYPAL = 'PAYPAL';
const METHOD_BANKWIRE = 'TRANSF';
const METHOD_CETELEM = 'CETELEM';
const METHOD_EXTERNAL = 'EXTERNO';
const METHOD_STRIPE = 'STRIPE';
const METHOD_STRIPE_GIROPAY = 'STRIPE_GIROPAY';
const METHOD_STRIPE_SOFORT = 'STRIPE_SOFORT';
const METHOD_SKIP_FIRST_PAYMENT = 'SKIP_FIRST_PAYMENT';

const METHOD_SEQURA = 'SEQURA';
const METHOD_AFFIRM = 'AFFIRM';
const METHOD_PAY_BY_PHONE = 'PAY-BY-PHONE';

export const METHOD_AFFIRM_ID = 11;

export const AU_COUNTRY_CODE = 'AU';
export const CA_COUNTRY_CODE = 'CA';
export const DE_COUNTRY_CODE = 'DE';
export const ES_COUNTRY_CODE = 'ES';
export const FR_COUNTRY_CODE = 'FR';
export const UK_COUNTRY_CODE = 'UK';
export const US_COUNTRY_CODE = 'US';

export const AU_LANG_CODE = 'en';
export const CA_LANG_CODE = 'en';
export const DE_LANG_CODE = 'de';
export const ES_LANG_CODE = 'es';
export const FR_LANG_CODE = 'fr';
export const UK_LANG_CODE = 'en';
export const US_LANG_CODE = 'en';

export const AU_LOCALE_CODE = 'en-AU';
export const CA_LOCALE_CODE = 'en-CA';
export const DE_LOCALE_CODE = 'de-DE';
export const ES_LOCALE_CODE = 'es-ES';
export const FR_LOCALE_CODE = 'fr-FR';
export const UK_LOCALE_CODE = 'en-GB';
export const US_LOCALE_CODE = 'en-US';

// TODO remove this and use in component from state
export const getCountryFromUrl = () => {
  const market: string = store.getState()?.config?.data?.market;

  return market;
};

// TODO remove this and use in component from state
export const getLocaleFromUrl = () => {
  const language = store.getState()?.config?.data?.language;
  const locale = language?.length > 0 ? language[0]?.locale : '';

  switch (locale) {
    case 'es_ES':
      return ES_LOCALE_CODE;
    case 'en_UK':
      return UK_LOCALE_CODE;
    case 'en_CA':
      return CA_LOCALE_CODE;
    case 'fr_FR':
      return FR_LOCALE_CODE;
    case 'de_DE':
      return DE_LOCALE_CODE;
    case 'en_US':
    default:
      return US_LOCALE_CODE;
  }
};

/**
 * Get icon name from category id
 * 1 - Lujo *****
 * 2 - Encanto *** / heart
 * 3 - Primera ****
 * 4 - Gran Lujo *****GL
 * 5 - 4 estrellas ****
 * 6 - 3,5 estrellas ***SP
 * 7 - 4,5 estrellas ****SP
 * charm: heart
 * stars4: ****
 * stars1: *****
 * stars-GL: *****GL
 * stars3-SP: ***SP
 * stars4-SP: ****SP
 *
 * @param {string} iconClassId category id to specify the icon name
 * @returns {string} icon name
 */
export const iconAcommodationImageCount = (iconClassId: string): string => {
  switch (iconClassId) {
    case '1':
      return ICON_STARS_5;
    case '2':
      return ICON_HEART;
    case '3':
      return ICON_STARS_4;
    case '4':
      return ICON_STARS_5GL;
    case '5':
      return ICON_STARS_4;
    case '6':
      return ICON_STARS_3GL;
    case '7':
      return ICON_STARS_4SP;
    default:
      return '';
  }
};

/**
 * Get icon name from category id
 * 1 - Corazon / heart
 * 2 - 4 estrellas ****
 * 4 - 5 estrellas *****
 * 5 - 5 estrellas GL *****GL
 * 6 - Anillos
 * charm: heart
 * stars4: ****
 * stars1: *****
 * stars-GL: *****GL
 *
 * @param {string} iconClassId category id to specify the icon name
 * @returns {string} icon name
 */
export const iconCategoryImageCount = (id: string): string => {
  switch (id) {
    case '1':
      return ICON_HEART;
    case '2':
      return ICON_STARS_4;
    case '4':
      return ICON_STARS_5;
    case '5':
      return ICON_STARS_5GL;
    default:
      return '';
  }
};

/**
 * Get icon name from continent id
 * 1 - america
 * 2 - asia
 * 3 - africa
 * 4 - europa
 * 5 - oceania
 * @param {string} id continent id to specify the icon name
 * @returns {string} icon name
 */
export const iconContinent = (id: string): string => {
  switch (id) {
    case '1':
      return AMERICA;
    case '2':
      return ASIA;
    case '3':
      return AFRICA;
    case '4':
      return EUROPA;
    case '5':
      return OCEANIA;
    default:
      return '';
  }
};

/**
 * Get method payment name from id
 * @param {number} id method payment id to specify the payment method name
 * @returns {string} payment mathod name
 */
export const getPaymentMethodFromId = (id: number): string => {
  switch (id) {
    case 1:
      return METHOD_CARD;
    case 2:
      return METHOD_PAYPAL;
    case 3:
      return METHOD_BANKWIRE;
    case 4:
      return METHOD_CETELEM;
    case 5:
      return METHOD_EXTERNAL;
    case 6:
      return METHOD_STRIPE;
    case 7:
      return METHOD_STRIPE_GIROPAY;
    case 8:
      return METHOD_STRIPE_SOFORT;
    case 9:
      return METHOD_SEQURA;
    case 11:
      return METHOD_AFFIRM;
    case 12:
      return METHOD_PAY_BY_PHONE;
    case 13:
      return METHOD_SKIP_FIRST_PAYMENT;
    default:
      return '';
  }
};

/**
 * Get logo name from affiliate id
 * 1 - exoticca-logo
 * 2 - secret-escapes
 * 33 - veepee-logo
 * 38 - weekendesk
 * 40 - journaway
 * 44 - privalia-logo
 * 45 - avasa
 * @param {number} id affilieate id to specify the icon name
 * @returns {string[]} logo array
 */
export const iconAffiliate = (id: number, language?: string): IcomoonProps => {
  if (!language) {
    language = getLocaleFromUrl();
  }
  switch (id) {
    case 1:
    case 30:
      return {
        icon: [
          EXOTICCA_LOGO_DESKTOP,
          EXOTICCA_LOGO_TABLET,
          EXOTICCA_LOGO_MOBILE,
          EXOTICCA_LOGO_PDP,
        ],
        color: 'putty',
        width: '160px',
        height: '33px',
        marginTop: '-8px',
        iconSet: 'main',
      };
    case 2:
      const icon =
        language === 'fr_FR'
          ? [
              SECRET_ESCAPES_FR_LOGO_DESKTOP,
              SECRET_ESCAPES_FR_LOGO_TABLET,
              SECRET_ESCAPES_FR_LOGO_MOBILE,
              SECRET_ESCAPES_FR_LOGO_PDP,
            ]
          : [
              SECRET_ESCAPES_ES_LOGO_DESKTOP,
              SECRET_ESCAPES_ES_LOGO_TABLET,
              SECRET_ESCAPES_ES_LOGO_MOBILE,
              SECRET_ESCAPES_ES_LOGO_PDP,
            ];
      return {
        icon,
        color: '',
        width: '',
        height: '',
        marginTop: '',
        iconSet: 'whitelabel_logos',
      };
    case 33:
      return {
        icon: [
          VEEPEE_LOGO_DESKTOP,
          VEEPEE_LOGO_TABLET,
          VEEPEE_LOGO_MOBILE,
          VEEPEE_LOGO_PDP,
        ],
        color: '',
        width: '',
        height: '',
        marginTop: '8px',
        iconSet: 'whitelabel_logos',
      };
    case 38:
      return {
        icon: [
          WEEKENDESK_LOGO_DESKTOP,
          WEEKENDESK_LOGO_TABLET,
          WEEKENDESK_LOGO_MOBILE,
          WEEKENDESK_LOGO_PDP,
        ],
        color: '',
        width: '',
        height: '',
        marginTop: '',
        iconSet: 'whitelabel_logos',
      };
    case 40:
      return {
        icon: [
          JOURNAWAY_LOGO_DESKTOP,
          JOURNAWAY_LOGO_TABLET,
          JOURNAWAY_LOGO_MOBILE,
          JOURNAWAY_LOGO_PDP,
        ],
        color: '',
        width: '250px',
        height: '',
        marginTop: '',
        iconSet: 'whitelabel_logos',
      };
    case 44:
      return {
        icon: [
          PRIVALIA_LOGO_DESKTOP,
          PRIVALIA_LOGO_TABLET,
          PRIVALIA_LOGO_MOBILE,
          PRIVALIA_LOGO_PDP,
        ],
        color: '',
        width: '',
        height: '',
        marginTop: '10px',
        iconSet: 'whitelabel_logos',
      };
    case 45:
      return {
        icon: [
          AVASA_LOGO_DESKTOP,
          AVASA_LOGO_TABLET,
          AVASA_LOGO_MOBILE,
          AVASA_LOGO_PDP,
        ],
        color: '',
        width: '250px',
        height: '',
        marginTop: '',
        iconSet: 'whitelabel_logos',
      };
    default:
      return {
        icon: ['', '', '', ''],
        color: '',
        width: '',
        height: '',
        marginTop: '',
        iconSet: 'main',
      };
  }
};

export const getPaymentIcon = (id: number, market: any) => {
  switch (id) {
    case 1:
      return {
        icoName:
          market.country === ES_COUNTRY_CODE ||
          market.country === UK_COUNTRY_CODE ||
          market.country === FR_COUNTRY_CODE ||
          market.country === DE_COUNTRY_CODE
            ? 'paymentES-UK-FR-DE'
            : 'US-CA-DEstripe',
        iconWidth: '180px',
        iconColor: '',
        iconSet: 'payments',
      };
    case 2:
      return {
        icoName: 'PayPalcolor',
        iconWidth: '75px',
        iconColor: '',
        iconSet: 'payments',
      };
    case 3:
      return {
        icoName: 'bankTransfer_icon',
        iconWidth: '60px',
        iconColor: 'black',
        iconSet: 'payments',
      };
    case 6:
      return {
        icoName:
          market.country === US_COUNTRY_CODE ||
          market.country === CA_COUNTRY_CODE
            ? 'US-CA-DEstripe'
            : 'stripecolor',
        iconWidth:
          market.country === US_COUNTRY_CODE ||
          market.country === CA_COUNTRY_CODE
            ? '160px'
            : '58px',
        iconColor: '',
        iconSet: 'payments',
      };
    case 7:
      return {
        icoName: 'Giropaycolor',
        iconWidth: '46px',
        iconColor: '',
        iconSet: 'payments',
      };
    case 9:
      return {
        icoName: 'sequra_icon',
        iconWidth: '82px',
        iconColor: '',
        iconSet: 'payments',
      };
    case 12:
      return {
        icoName: 'call-whitelabels',
        iconWidth: '22px',
        iconColor: 'black',
        iconSet: 'common',
      };
    default:
      return { icoName: '', iconWidth: '', iconColor: '', iconSet: 'common' };
  }
};

export const isLoggedIn = () => {
  return document.cookie

    .split(';')
    .find((cookie) => cookie.includes('exoticca_usr='))
    ? true
    : false;
};

export const setExoticcaCountryCookie = (market) => {
  setCookie('exoticca_country_selected', market);
};

export const getAuthToken = (): string => {
  if (isLoggedIn()) {
    return getCookie('exoticca_usr')
      ? getCookie('exoticca_usr').toString()
      : '';
  }
  return '';
};

export const hotjarTrigger = (trigger: string): void => {
  if (typeof window.hj === 'function') {
    window.hj('trigger', trigger);
  }
};

export const getResponseTapClassName = () => {
  const state: any = store.getState();
  return state?.config?.data?.responseTap?.class;
};

export const getDedicatedPhoneNumber = () => {
  /* ENABLE when needed (TZ NW)
  const pathArrayPdp = matchPath(window.location.pathname, {
    path:
      '/:lang(es|fr|de|uk|us|ca)?/([_a-zA-Z0-9-]+)/([_a-zA-Z0-9-]+)/:campaignId([0-9]+)-([_a-zA-Z0-9-]+)',
    exact: true,
    strict: false,
  });

  const pathArrayCheckout = matchPath(window.location.pathname, {
    path:
      '/(es|fr|de|uk|us|ca)?/checkout/([_a-zA-Z0-9-pL]*)/:campaignId([a-zA-Z0-9]+)/([a-zA-Z0-9]+)/',
    exact: true,
    strict: false,
  });

  const productId =
    (pathArrayPdp && pathArrayPdp.params['campaignId']) ||
    (pathArrayCheckout && pathArrayCheckout.params['campaignId']) ||
    '';


  switch (productId) {
    case '6946':
    case '6692':
    case '5247':
    case '9016':
    case '8242':
      return '+1 888-273-1533';
  }
  */
  return '';
};

/**
 * get cookie
 *
 * @param {string} name name of the cookie
 * @returns {string | boolean} cookie value
 */
export const getCookie = (name: string): string | boolean => {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : false;
};

export const getCookieParams = (name: string) => {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');

  if (parts.length === 2) {
    const ppop = parts.pop();
    if (ppop) {
      return ppop.split(';').shift();
    }
  }
};

export const setCookie = (name: string, value: string, days?: number) => {
  let expires: string = '';
  if (days) {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    expires = `;expires=${d.toUTCString()}`;
  }
  document.cookie = `${name}=${value};path=/${expires}`;
};

export const deleteCookie = (name: string) => {
  setCookie(name, '', -1);
};

export const hasLocalStorage = () => {
  try {
    localStorage.setItem('mod', 'mod');
    localStorage.removeItem('mod');
    return true;
  } catch (e) {
    return false;
  }
};

export const hasSessionStorage = () => {
  try {
    sessionStorage.setItem('mod', 'mod');
    sessionStorage.removeItem('mod');
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * get token from localstorage
 *
 * @param {string} param  localstorage item name
 * @returns {string} localstorage token value
 */
export const getLocalStorageParam = (param: string): string => {
  const localStorage = window.localStorage;

  return localStorage.getItem(param) ? localStorage.getItem(param) : '';
};

export const getHoursFromCache = (dateCached) => {
  const dateCachedYear = dateCached.substring(0, 4);
  const dateCachedMonth = dateCached.substring(5, 7);
  const dateCachedDay = dateCached.substring(8, 10);
  const dateCachedHour = dateCached.substring(11, 13);
  const dateCachedMin = dateCached.substring(14, 16);
  const dateCachedRet = new Date(
    dateCachedYear,
    dateCachedMonth - 1,
    dateCachedDay,
    dateCachedHour,
    dateCachedMin,
  );

  return Math.round(
    Math.abs(new Date().getTime() - new Date(dateCachedRet).getTime()) / 36e5,
  );
};

export const disableScroll = () => {
  if (!document.body.classList.contains('disableScroll')) {
    document.body.classList.add('disableScroll');
  }
};

export const enableScroll = () => {
  if (document.body.classList.contains('disableScroll')) {
    document.body.classList.remove('disableScroll');
  }
};

export const disableLeads = () => {
  if (!document.body.classList.contains('noMoreLeads')) {
    document.body.classList.add('noMoreLeads');
  }
};

export const isValidEmail = (email: string) =>
  /^\w+((\.|-|--|.-|\\\\|\+)?\w+)*@\w+([.-]?\w+)*(\.\w{2,24})+$/.test(email);

export const isLocationCheckout = (location: string) =>
  /^\/reserva\/|\/booking\/|\/reservation\/|\/reservierung\/|\/checkout\/|\/booking\/|\/pago-fraccionado\/|\/part-payment\/|\/paiment-echelonne\/|\/geteilte-zahlung\//.test(
    location,
  );

export const addParam = (name, value) => {
  const params = new URLSearchParams(window.location.search);
  params.set(name, value);
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${params}${window.location.hash}`,
  );
};

export const removeParam = (name) => {
  const params = new URLSearchParams(window.location.search);
  params.delete(name);
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${params}${window.location.hash}`,
  );
};

export const removeAllParams = () => {
  window.history.replaceState({}, '', `${window.location.pathname}`);
};

export const addHash = (name) => {
  const params = new URLSearchParams(window.location.search);
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${params}#${name}`,
  );
};

export const removeHash = () => {
  const params = new URLSearchParams(window.location.search);
  window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
};

export const getMinMaxOptions = (min: number, max: number) => {
  let options: any[] = [];
  let value: number;
  for (value = min; value <= max; value++) {
    options.push({ value: value.toString(), text: value.toString() });
  }
  return options;
};

export const hasNewsletterCheckBox = (): boolean => {
  const state: any = store.getState();
  return state?.config?.data?.options?.hasNewsletterCheckBox;
};

export const parseNumberLocal = (
  number: number,
  decimals?: number,
  useSign?: boolean,
  currencyConfig?: any,
  notWrap?: boolean,
) => {
  const isNegative = number < 0;
  const positiveNumber = Math.abs(number);
  const currency: any =
    currencyConfig || store.getState()?.config?.data?.currency;
  const prefixSign = currency?.prefixSign;
  const sufixSign = currency?.sufixSign;
  const decimalSeparator = currency?.decimalSeparator;
  const thousandsSeparator = currency?.thousandsSeparator;

  if (decimals === undefined) decimals = 2;
  if (useSign === undefined) useSign = true;

  let prefix = '';
  let sufix = '';
  if (useSign) {
    if (prefixSign) {
      prefix = prefixSign;
    }
    if (sufixSign) {
      sufix = sufixSign;
    }
  }

  const spanOpen = prefix?.length > 2 && !notWrap ? '<span>' : '';
  const spanClose =
    prefix?.length > 2 && !notWrap ? '</span>' : prefix?.length > 2 ? ' ' : '';
  const formattedNumber =
    spanOpen +
    prefix +
    spanClose +
    positiveNumber
      .toFixed(decimals)
      .replace(/\./, decimalSeparator)
      .replace(/./g, function (c, i, a) {
        return i && c !== decimalSeparator && !((a.length - i) % 3)
          ? thousandsSeparator + c
          : c;
      }) +
    sufix;
  return isNegative ? `-${formattedNumber}` : formattedNumber;
};

export const getGoogleAnalyticsClientID = () => {
  try {
    var tracker = window.ga.getAll()[0];
    return tracker.get('clientId');
  } catch (e) {
    return false;
  }
};

export const getLead: any = async (leadId) => {
  const response = await fetch(
    `${getApiRoot()}/api/lead/${leadId}/email?rand=${new Date().getTime()}`,
  );
  const data = await response.json();
  return data;
};

export const getThemedAffiliateId = () => {
  const currentDomain = window.location.hostname;
  return themedAffiliates[currentDomain] || '1';
};

export const getCountryNames = (t) => ({
  es_ES: t(`header@countryName@$es_ES`, 'Spain'),
  en_UK: t(`header@countryName@$en_UK`, 'United Kingdom'),
  en_US: t(`header@countryName@$en_US`, 'United States'),
  en_CA: t(`header@countryName@$en_CA`, 'Canada'),
  fr_FR: t(`header@countryName@$fr_FR`, 'France'),
  de_DE: t(`header@countryName@$de_DE`, 'Germany'),
});

export const handleScrollToAnchor = (hash: string, headerHeight?: number) => {
  const element = document.getElementById(hash);
  const finalHeaderHeight = headerHeight ? headerHeight + 16 : 0;
  if (window && element)
    window.scroll({
      top:
        element.getBoundingClientRect().top +
        window.pageYOffset -
        finalHeaderHeight,
      behavior: 'smooth',
    });
};

export const supportedBrowser = () => {
  // Not supporting IE11, Edge 15 and 16, Prerender
  const regex = /(rv:11.0|Edge\/16|Edge\/15|Prerender|Chrome\/52|Chrome\/53)/i;
  if (navigator?.userAgent?.match(regex)) {
    return false;
  }

  return true;
};

export const getAuthCredentials = () => {
  let auth: { username: string; password: string } | undefined;
  if (process.env.REACT_APP_ENV === 'preproduction') {
    auth = { username: 'exoticca', password: 'viajes2013' };
  }

  return auth;
};
