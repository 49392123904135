export type ActionType =
  | 'SET_BUDGET_DATA'
  | 'REQUEST_BUDGET_DATA'
  | 'RECEIVED_BUDGET_DATA'
  | 'ERROR_BUDGET_DATA'
  | 'BUDGET_CREATE_DATA'
  | 'REQUEST_BUDGET_CREATE_DATA'
  | 'RECEIVED_BUDGET_CREATE_DATA'
  | 'ERROR_BUDGET_CREATE_DATA'
  | 'PUT_BUDGET_DATA'
  | 'REQUEST_UPDATE_BUDGET_DATA'
  | 'RECEIVED_UPDATE_BUDGET_DATA'
  | 'ERROR_UPDATE_BUDGET_DATA';

interface ActionProps {
  type: ActionType;
  isFetching?: boolean;
  data?: {} | any;
  error?: any;
  payload?: {} | any;
}

const initialBudgetState = {
  isFetching: true,
  data: {},
  error: {},
};

const Budget = (state = initialBudgetState, action: Partial<ActionProps>) => {
  switch (action.type) {
    case 'REQUEST_BUDGET_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_BUDGET_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: { ...payload },
      };
    }

    case 'ERROR_BUDGET_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    case 'REQUEST_BUDGET_CREATE_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_BUDGET_CREATE_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: { ...payload },
      };
    }

    case 'ERROR_BUDGET_CREATE_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    case 'REQUEST_UPDATE_BUDGET_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_UPDATE_BUDGET_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: { ...payload },
      };
    }

    case 'ERROR_UPDATE_BUDGET_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
};

export default Budget;
