import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import checkout from '../mocks/summary.json';
import passengers from '../mocks/passengers.json';
import pax from '../mocks/pax.json';
import payments from '../mocks/payments.json';
import { getApiRoot } from '../config';

const mockAxios = axios.create();
const mock = new MockAdapter(mockAxios, { delayResponse: 300 });

mock.onPost(/\/api\/campaign\/summary/).reply(() => [200, checkout]);
mock.onGet(/\/api\/campaign\/pax\/00000/).reply(() => [200, pax]);
mock.onPut(/\/api\/order\/attempt\/00000/).reply(() => [200, passengers]);
mock.onGet(/\/api\/campaign\/payment-methods*/).reply(() => [200, payments]);

const SummaryCall = {
  async getSummary(data, mocked: boolean = false) {
    const url = `${getApiRoot()}/api/campaign/summary`;
    const axiosObject = mocked ? mockAxios : axios;
    const urlParameters = Object.entries(data)
      .map((e) => e.join('='))
      .join('&');

    try {
      const response = await axiosObject.post(url, urlParameters, {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      return response.data;
    } catch (e) {
      if (
        e.response.data &&
        e.response.data.error &&
        e.response.data.error.url
      ) {
        window.location.href = e.response.data.error.url;
      } else {
        window.location.href = document.location.origin;
      }
    }
  },
};

const CheckoutCall = {
  async getCheckout(data, mocked: boolean = false) {
    const url = `${getApiRoot()}/api/campaign/summary`;
    const axiosObject = mocked ? mockAxios : axios;
    const urlParameters = Object.entries(data)
      .map((e) => e.join('='))
      .join('&');
    const response = await axiosObject.post(url, urlParameters, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return response.data;
  },
};

const PassengersCall = {
  async getPassengers(idOrderAttempt: string, mocked: boolean = false) {
    const randomNumber = Math.random() * 10000000000000000;
    const url = `${getApiRoot()}/api/campaign/pax/${idOrderAttempt}?rand=${randomNumber}`;
    const axiosObject = mocked ? mockAxios : axios;
    const response = await axiosObject.get(url, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  },
  async putPassengers(idOrderAttempt: string, data, mocked: boolean = false) {
    const url = `${getApiRoot()}/api/order/attempt/passengers/${idOrderAttempt}`;
    const axiosObject = mocked ? mockAxios : axios;
    const response = await axiosObject.put(url, data, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return response;
  },

  async createUser(data, mocked: boolean = false) {
    const url = `${getApiRoot()}/api/guest/user`;
    const axiosObject = mocked ? mockAxios : axios;

    const urlParameters = Object.entries(data)
      .map((e) => e.join('='))
      .join('&');
    const response = await axiosObject.post(url, urlParameters, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return response;
  },
};

const PaymentsCall = {
  async getPayments(
    orderAttemptId: string,
    offerId: string,
    productId: string,
    departureDate: string,
    token: string,
    mocked: boolean = false,
  ) {
    const url = `${getApiRoot()}/api/campaign/payment-methods?orderAttemptId=${orderAttemptId}&offerId=${offerId}&productId=${productId}&departureDate=${departureDate}&token=${token}`;
    const axiosObject = mocked ? mockAxios : axios;
    const response = await axiosObject.get(url, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  },

  async postVoucherCode(data) {
    const randomNumber = Math.random() * 10000;
    const url = `${getApiRoot()}/api/checkout/voucher?rand=${randomNumber}`;
    const response = await axios.get(url, {
      params: data,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return response.data;
  },
};

const FlightCall = {
  verifyFlight(data, mocked: boolean = false) {
    const url = `${getApiRoot()}/a/order/verifyFlight`;
    const axiosObject = mocked ? mockAxios : axios;
    const urlParameters = Object.entries(data)
      .map((e) => e.join('='))
      .join('&');
    axiosObject.post(url, urlParameters, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
};

const OrderAttemptCall = {
  async postAttemptStatus(data: OrderAttemptStatusRequest, idOrderAttempt) {
    const url = `${getApiRoot()}/api/order/attempt-status/${idOrderAttempt}`;
    return await axios.post(url, data, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
};

const RegisterLoginCall = {
  async postRegister(data, mocked: boolean = false) {
    const randomNumber = Math.random() * 10000;
    const url = `${getApiRoot()}/api/customer/create?rand=${randomNumber}`;
    const axiosObject = mocked ? mockAxios : axios;
    const urlParameters = Object.entries(data)
      .map((e) => e.join('='))
      .join('&');
    return await axiosObject
      .post(url, urlParameters, {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async postLogin(data, mocked: boolean = false) {
    const randomNumber = Math.random() * 10000;
    const url = `${getApiRoot()}/api/customer/login?rand=${randomNumber}`;
    const axiosObject = mocked ? mockAxios : axios;
    const urlParameters = Object.entries(data)
      .map((e) => e.join('='))
      .join('&');
    const response = await axiosObject
      .post(url, urlParameters, {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => response)
      .catch((error) => error.response);

    return response;
  },
};

export {
  SummaryCall,
  CheckoutCall,
  PassengersCall,
  PaymentsCall,
  FlightCall,
  OrderAttemptCall,
  RegisterLoginCall,
};
