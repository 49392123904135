import React from 'react';
import styled from 'styled-components/macro';

type VariantType = 'h1' | 'h2' | 'h3' | 'price' | 'bodyText' | 'smallText';

interface TypographyProps {
  children?: any;
  variant?: VariantType;
  inline: boolean;
  customStyle?: object;
  dangerouslySetInnerHTML?: { __html: string };
}

const TypographyWrapper = styled.div<{ inline: boolean }>`
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.colors.mineShaftDarker};
  margin: 6px 0;
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
`;

const StyledH1 = styled.h1`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 2.125rem;
  font-weight: bold;
  line-height: 1.12;
  margin: 6px 0;
`;

const StyledPrice = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.12;
`;

const StyledH2 = styled.h2`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.17;
  margin: 6px 0;
`;

const StyledH3 = styled.h3`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  margin: 6px 0;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1.125rem;
  }

  @media print {
    font-size: 11pt;
  }
`;

const StyledText = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  font-weight: normal;

  @media print {
    font-size: 9pt;
  }
`;

const StyledSmallText = styled.div`
  font-size: 0.875rem;
  line-height: 1.29;
  font-weight: normal;
`;

const Typography = (props: TypographyProps) => {
  const {
    children,
    customStyle,
    variant,
    inline,
    dangerouslySetInnerHTML,
  } = props;

  return (
    <TypographyWrapper inline={inline} style={customStyle}>
      {variant === 'h1' &&
        (dangerouslySetInnerHTML ? (
          <StyledH1 dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
        ) : (
          <StyledH1>{children}</StyledH1>
        ))}
      {variant === 'h2' &&
        (dangerouslySetInnerHTML ? (
          <StyledH2 dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
        ) : (
          <StyledH2>{children}</StyledH2>
        ))}
      {variant === 'h3' &&
        (dangerouslySetInnerHTML ? (
          <StyledH3 dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
        ) : (
          <StyledH3>{children}</StyledH3>
        ))}
      {variant === 'price' &&
        (dangerouslySetInnerHTML ? (
          <StyledPrice dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
        ) : (
          <StyledPrice>{children}</StyledPrice>
        ))}
      {variant === 'smallText' &&
        (dangerouslySetInnerHTML ? (
          <StyledSmallText dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
        ) : (
          <StyledSmallText>{children}</StyledSmallText>
        ))}
      {variant === 'bodyText' &&
        (dangerouslySetInnerHTML ? (
          <StyledText dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
        ) : (
          <StyledText>{children}</StyledText>
        ))}
    </TypographyWrapper>
  );
};

Typography.defaultProps = {
  variant: 'bodyText',
  inline: false,
};
export default Typography;
