import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import PromotionModal from '../../promotion/PromotionModal';

import Icon from '../../ui/Icon';

interface PromotionSummaryProps {
  promotion: any;
  value: string;
  total: string;
  setShowPromoModal: any;
}

const PromotionSummaryWrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.colors.fontFamilySecondaryMedium};
  width: 100%;
  color: ${(props) => props.color};

  @media (max-width: ${(props) => props.theme.breakpoints.mdLegacy}) {
    align-items: flex-start;
    margin-left: 10px;
  }
`;

const InfoText = styled.div`
  width: 66%;
  font-size: 0.938rem;
  font-weight: 600;

  span {
    margin-right: 4px;
  }
`;

const MobilePromotionText = styled.div<{ color: string }>`
  color: ${(props) => props.color};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    color: ${(props) => props.theme.colors.white};
  }
`;

const Value = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 33%;
  font-size: 1.125rem;

  span {
    margin-right: 4px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mdLegacy}) {
    justify-content: flex-start;
    width: inherit;
    font-size: 0.875rem;
  }
`;

const PromotionSummary = (props: PromotionSummaryProps) => {
  const { value, total, promotion, setShowPromoModal } = props;

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const theme = useTheme();
  const themeStyled = useContext(ThemeContext);

  const mdUp = useMediaQuery('@media (min-width:1199px)'); // Hack: we have to do this until we mix usage of layout and theme breakpoints
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const showModal = promotion.showModal;
  const toggleModal = () => {
    setShowPromoModal(!showModal);
  };

  return (
    <PromotionSummaryWrapper color={promotion.styling?.color}>
      {mdUp && (
        <>
          <Icon
            size={17}
            icon={promotion.styling?.checkout.icon}
            color={promotion.styling?.checkout.color}
            customStyle={{
              display: 'flex',
              marginRight: '3px',
              marginTop: '3px',
            }}
          />
          <InfoText
            dangerouslySetInnerHTML={{
              __html:
                t('promotionSummaryInfo') &&
                t('promotionSummaryInfo').replace('#TOKEN#', value),
            }}
          />
        </>
      )}
      <Value onClick={xsDown ? toggleModal : () => {}}>
        {!mdUp && (
          <Icon
            size={17}
            icon={promotion.styling?.checkout.icon}
            color={
              mdDown
                ? themeStyled.colors.white
                : promotion.styling?.checkout.color
            }
            customStyle={{
              display: 'flex',
              marginRight: '3px',
              marginTop: '3px',
            }}
          />
        )}
        <MobilePromotionText
          color={promotion.styling?.checkout.color}
          dangerouslySetInnerHTML={{ __html: total }}
        />
      </Value>
      <PromotionModal
        styling={promotion.styling.modal}
        isFlash={promotion.flash}
        isDiscount={promotion.discount}
        pose={showModal ? 'left' : 'right'}
        isVisible={showModal}
        onClose={toggleModal}
      />
    </PromotionSummaryWrapper>
  );
};

export default PromotionSummary;
