export type ActionType =
  | 'SET_ORDER_ATTEMPT_DATA'
  | 'REQUEST_ORDER_ATTEMPT_DATA'
  | 'RECEIVED_ORDER_ATTEMPT_DATA'
  | 'ERROR_ORDER_ATTEMPT_DATA'
  | 'PUT_ORDER_ATTEMPT_DATA'
  | 'REQUEST_UPDATE_ORDER_ATTEMPT_DATA'
  | 'RECEIVED_UPDATE_ORDER_ATTEMPT_DATA'
  | 'ERROR_UPDATE_ORDER_ATTEMPT_DATA'
  | 'ORDER_ATTEMPT_CREATE_DATA'
  | 'REQUEST_ORDER_ATTEMPT_CREATE_DATA'
  | 'RECEIVED_ORDER_ATTEMPT_CREATE_DATA'
  | 'ERROR_ORDER_ATTEMPT_CREATE_DATA';

interface ActionProps {
  type: ActionType;
  isFetching?: boolean;
  data?: {} | any;
  error?: any;
  payload?: {} | any;
}

const initialOrderAttemptState = {
  isFetching: true,
  data: {},
  error: {},
};

const OrderAttempt = (
  state = initialOrderAttemptState,
  action: Partial<ActionProps>,
) => {
  switch (action.type) {
    case 'REQUEST_ORDER_ATTEMPT_DATA':
    case 'REQUEST_UPDATE_ORDER_ATTEMPT_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_ORDER_ATTEMPT_DATA':
    case 'RECEIVED_UPDATE_ORDER_ATTEMPT_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          ...payload,
        },
      };
    }

    case 'ERROR_ORDER_ATTEMPT_DATA':
    case 'ERROR_UPDATE_ORDER_ATTEMPT_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    case 'REQUEST_ORDER_ATTEMPT_CREATE_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_ORDER_ATTEMPT_CREATE_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: { ...payload },
      };
    }

    case 'ERROR_ORDER_ATTEMPT_CREATE_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
};

export default OrderAttempt;
