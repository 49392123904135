import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import LazyImage from '../ui/LazyImage';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../utils';

type CampaignObject = {
  name: string;
  image: string;
  url: string;
  country: string;
  days: string;
  fromPrice: string;
};

type PromotedCardProps = {
  campaign: CampaignObject | null;
  closeMenu(): void;
};

const PromotedCardWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.938rem;
  line-height: 1.125rem;
  letter-spacing: 1px;
  color: ${(props) => props.theme.colors.mineShaftDarker};
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    color: ${(props) => props.theme.colors.white};
    font-weight: 300;
    padding: 20px 0;
  }
`;

const PromotedCardBackground = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    background-color: ${(props) => props.theme.colors.tundora50};
    padding: 0 47px 0 17px;
    width: 114%;
    margin-left: -20px;
    border: 1px solid ${(props) => props.theme.colors.doveGray};
  }
`;

const CampaignImage = styled.div`
  width: 86px;
  min-width: 86px;
  height: 88px;
  min-height: 88px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 67px;
    min-width: 67px;
    height: 73px;
    min-height: 73px;
  }
`;

const CampaignDescription = styled.div`
  margin-left: 23px;
`;

function PromotedCard({ campaign, closeMenu }: PromotedCardProps) {
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  if (!campaign) {
    return <></>;
  }
  return (
    <Link to={campaign.url} onClick={closeMenu}>
      <PromotedCardBackground>
        <PromotedCardWrapper>
          <CampaignImage>
            <LazyImage src={campaign.image} position={'relative'} />
          </CampaignImage>
          <CampaignDescription>
            <div>
              {campaign.country} · {campaign.days} {t('header@days')}
            </div>
            <div>{campaign.name}</div>
            <div
              dangerouslySetInnerHTML={{
                __html: `${t('priceFrom')} ${campaign.fromPrice}`,
              }}
            />
          </CampaignDescription>
        </PromotedCardWrapper>
      </PromotedCardBackground>
    </Link>
  );
}

export default PromotedCard;
