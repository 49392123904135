import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';
import { Hidden } from '@material-ui/core';

import Icon from '../../ui/Icon';

import PremiumCareModal from './PremiumCareModal';

interface PropsPremiumCareLogoClaim {
  withClick?: boolean;
  small?: boolean;
  checkout?: any;
}

const PremiumCareLogoClaimWrapper = styled.div<{ checkout: boolean }>`
  cursor: pointer;
  margin-right: 10px;

  ${(props) =>
    !props.checkout
      ? css`
          display: flex;
          align-items: center;
          margin-right: 0;
        `
      : null}
`;

const SmallText = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  margin-right: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-left: 16px;
    margin-right: 0;
    font-weight: normal;
  }
`;

function PremiumCareLogoClaim(props: PropsPremiumCareLogoClaim) {
  const { withClick, small, checkout } = props;

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const [showPremiumCare, setShowPremiumCare] = useState(() => false);
  const togglePremiumCare = () => {
    setShowPremiumCare(!showPremiumCare);
  };

  return (
    <>
      <PremiumCareLogoClaimWrapper
        onClick={withClick ? togglePremiumCare : () => {}}
        checkout={checkout ? checkout : false}
      >
        <Hidden xsDown>
          <SmallText>{t('Included in all our trips')}</SmallText>
        </Hidden>
        <Icon
          icon={'Premiumcare-primary'}
          customStyle={{
            width: small ? '120px' : '170px',
          }}
        />
        {!checkout && (
          <Hidden smUp>
            <SmallText>{t('Included in all our trips')}</SmallText>
          </Hidden>
        )}
      </PremiumCareLogoClaimWrapper>
      <PremiumCareModal
        pose={showPremiumCare ? 'left' : 'right'}
        isVisible={showPremiumCare}
        onClose={togglePremiumCare}
      />
    </>
  );
}

export default PremiumCareLogoClaim;
