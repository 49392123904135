import { api } from '../config';

export const setCampaignData = (data) => ({
  type: 'SET_CAMPAIGN_DATA',
  data,
});

export const setCampaignCategorySelectedIndex = (data: number) => ({
  type: 'SET_CAMPAIGN_CATEGORY_SELECTED_INDEX',
  data,
});

export const setDepartureCityId = (data) => ({
  type: 'SET_DEPARTURE_CITY_ID',
  data,
});

export const setLeadFormData = (data) => {
  return (dispatch) => {
    const parameters = Object.entries(data)
      .map(
        (e) =>
          encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1] as string),
      )
      .join('&');
    const url = `${api.leadForm}`;
    const method = 'post';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {
      data: parameters,
    };

    const type = 'SET_LEAD_FORM_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: 'FETCH',
          actionTypes: {
            request: 'REQUEST_LEAD_FORM_DATA',
            success: 'RECEIVED_LEAD_FORM_DATA',
            fail: 'ERROR_LEAD_FORM_DATA',
          },
          url,
          method,
          headers,
          options,
        },
      }),
    );
  };
};
