import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BudgetForm from './BudgetForm';

import { checkoutActions } from '../../../actions';

interface BudgetModalProps {
  isGuest: boolean;
  open: boolean;
  data: {
    productId: string;
    budgetTotal: string;
  };
  setBudgetModal(data: any): any;
}

const Wrapper = styled.div`
  position: relative;
  font-family: ${(props) => props.theme.fontFamily};
  background-color: ${(props) => props.theme.colors.white};
  width: 749px;
  padding: 41px 56px 49px 46px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    padding: 29px 17px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 2px;
  right: 14px;
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.black};
  text-shadow: 0 1px 0 ${(props) => props.theme.colors.white};
  background-color: transparent;
  border: 0;
  appearance: none;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    top: -4px;
    right: 4px;
    padding: 1px 6px;
  }
`;

const useStyles = makeStyles((theme) =>
  createStyles({ root: { overflow: 'visible' } }),
);

function BudgetModal({ open, data, setBudgetModal }: BudgetModalProps) {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const GTM_EVENT_ACTION = 'close x button';

  const classes = useStyles();

  return (
    <Dialog
      classes={{ paperScrollPaper: classes.root }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        setBudgetModal({ open: false, gtmEvent: GTM_EVENT_ACTION });
      }}
      fullScreen={xsDown ? true : false}
      maxWidth={'md'}
    >
      <Fade in={open}>
        <Wrapper>
          <CloseButton
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setBudgetModal({ open: false, gtmEvent: GTM_EVENT_ACTION });
            }}
          >
            <span aria-hidden="true">×</span>
          </CloseButton>
          <BudgetForm data={data} />
        </Wrapper>
      </Fade>
    </Dialog>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setBudgetModal: (data) => dispatch(checkoutActions.setBudgetModal(data)),
});

export default connect(null, mapDispatchToProps)(BudgetModal);
