export type ActionType =
  | 'SET_AIRPORTS'
  | 'SET_AIRPORTS_DATA'
  | 'REQUEST_AIRPORTS'
  | 'RECEIVED_AIRPORTS'
  | 'ERROR_AIRPORTS'
  | 'SET_COUNTRY_AIRPORTS'
  | 'REQUEST_COUNTRY_AIRPORTS'
  | 'RECEIVED_COUNTRY_AIRPORTS'
  | 'ERROR_COUNTRY_AIRPORTS';

interface ActionProps {
  type: ActionType;
  isFetching?: boolean;
  data?: {} | any;
  error?: any;
  payload?: {} | any;
}

const initialFlightsActionState = {
  isFetching: true,
  data: {
    content: {
      availableAirports: [],
    },
    countryAirports: [],
  },
  error: {},
};

const Airports = (
  state = initialFlightsActionState,
  action: Partial<ActionProps>,
) => {
  switch (action.type) {
    case 'REQUEST_AIRPORTS':
    case 'REQUEST_COUNTRY_AIRPORTS': {
      return {
        ...state,
      };
    }

    case 'SET_AIRPORTS_DATA':
    case 'RECEIVED_AIRPORTS': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: { ...payload },
      };
    }

    case 'RECEIVED_COUNTRY_AIRPORTS': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          countryAirports: payload,
        },
      };
    }

    case 'ERROR_AIRPORTS':
    case 'ERROR_COUNTRY_AIRPORTS': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
};

export default Airports;
