import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components/macro';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';
import {
  getLocaleFromUrl,
  getCountryNames,
  setExoticcaCountryCookie,
} from '../../utils';

import Icon from '../ui/Icon';

type CountrySelectorMobileProps = {
  country: string;
  alternativeDomains: any[];
};

const CountrySelectorMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white4};
`;

const CountrySelectorMobileText = styled.div`
  display: inline-block;
  font-size: 1rem;
  line-height: 1.19rem;
  color: ${(props) => props.theme.colors.headerFontColor};
  margin-left: 8px;
`;

const Link = styled.a<{ active: boolean }>`
  display: flex;
  font-size: 1rem;
  padding: 8px 25px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.headerFontColor};
  background-color: ${(props) =>
    props.active ? props.theme.colors.boulder : 'unset'};
`;

const CountrySelectorMobileHead = styled.div<{ open: boolean }>`
  padding: 0 5px 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${(props) =>
    props.open ? `solid 1px ${props.theme.colors.boulder}` : 'unset'};
`;

const ChangeCountry = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.19;
  color: ${(props) => props.theme.colors.white};
  margin: 10px 0;
`;

function CountrySelectorMobile({
  country,
  alternativeDomains,
}: CountrySelectorMobileProps) {
  const themeStyle = useContext(ThemeContext);

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const [open, setOpen] = useState(false);

  const countryNames = getCountryNames(t);

  return (
    <>
      <ChangeCountry>
        {t('head@changeCountry', 'Change your country')}
      </ChangeCountry>
      <CountrySelectorMobileWrapper
        style={open ? { borderBottomColor: themeStyle.colors.golden } : {}}
      >
        <CountrySelectorMobileHead
          open={open}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div>
            <Icon icon={'location'} color={themeStyle.colors.white} size={24} />
            <CountrySelectorMobileText>{country}</CountrySelectorMobileText>
          </div>
          <Icon
            icon={open ? 'iconsdown' : 'iconsright-arrow'}
            size={17}
            color={themeStyle.colors.white}
            customStyle={{
              margin: '16px 16px 16px 8px',
            }}
          />
        </CountrySelectorMobileHead>
        <Collapse in={open}>
          <div>
            {alternativeDomains &&
              alternativeDomains.map((item, index) => (
                <Link
                  active={item.locale.slice(-2) === country}
                  href={item.domain}
                  key={`countries-${index}`}
                  onClick={() =>
                    setExoticcaCountryCookie(
                      item.locale.slice(-2).toLowerCase(),
                    )
                  }
                >
                  <Icon
                    icon={item.locale}
                    iconSet={'flags'}
                    size={16}
                    customStyle={{ marginRight: '14px' }}
                  />
                  {countryNames[item.locale]}
                </Link>
              ))}
          </div>
        </Collapse>
      </CountrySelectorMobileWrapper>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    country: state.config?.data?.market,
    alternativeDomains: state.config?.data?.alternativeDomains,
  };
};

export default connect(mapStateToProps, {})(CountrySelectorMobile);
