import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../utils';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Modal from '../ui/Modal';
import Icon from '../ui/Icon';

export interface ErrorModalProps {
  errorCode: string | null;
  isVisible: boolean;
  onClose(): void;
}

const ModalContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 24px;
`;

const Text = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 1.43px;
  text-align: center;
  margin-top: 16px;

  span {
    display: block;
    color: ${(props) => props.theme.colors.golden};
    font-weight: 500;
    margin-top: 32px;
  }
`;

const GridText = styled(Grid)`
  text-align: center;
`;

const getErrorData = (errorCode, t) => {
  switch (errorCode) {
    case '406':
    case 'ERR_ORDER_11':
      return ['noDateAvailable', t('errorNoCupo')];
    case 'GENERIC':
    case 'CH_RS_001':
      return ['Refund-promo', t('errorPaymentGeneric')];
    case 'CH_RS_002':
      return ['Refund-promo', t('errorPaymentExpired')];
    case 'CH_RS_003':
      return ['Refund-promo', t('errorPaymentCallBank')];
    case 'CH_RS_004':
      return ['Refund-promo', t('errorPaymentWrongData')];
    case 'CH_RS_005':
      return ['Refund-promo', t('errorPaymentContactCustomerService')];
    case 'CH_PP_001':
      return ['Refund-promo', t('errorPaymentAPMError')];
    case 'CH_EX_000':
      return ['Refund-promo', t('errorCheckoutExpired')];
    case 'CH_CAN_001':
      return ['Refund-promo', t('errorPaymentGeneric')];
    case 'CH_UN_001':
      return ['Refund-promo', t('errorPaymentGeneric')];
    case 'CH_UN_500':
      return ['Refund-promo', t('errorPaymentGeneric')];
    case 'CH_AFF_000':
      return ['Refund-promo', t('paymentMessage8')];
    case 'CH_AFF_001':
    case 'CH_AFF_002':
    case 'CH_AFF_003':
    case 'CH_AFF_004':
    case 'CH_AFF_005':
    case 'CH_AFF_006':
    case 'CH_AFF_007':
    case 'CH_AFF_008':
      return ['Refund-promo', t('paymentMessage7')];
    case 'ERR_PROD_01':
      return ['noDateAvailable', t('Product is not activated')];
    case 'ERR_PROD_02':
      return [
        'noDateAvailable',
        t(
          'Product operating country does not match with current country iso code',
        ),
      ];
    case 'ERR_ORDER_18':
      return [
        'part-payment',
        t(
          'partPayment@bookingNotPendingToPay',
          'Booking is not pending any payment.',
        ),
      ];
    case 'ERR_ORDER_21':
      return [
        'part-payment',
        t('partPayment@invalidBookingToken', 'Part payment url is invalid.'),
      ];
    default:
      return ['noDateAvailable', t('error')];
  }
};

const ErrorModal = (props: ErrorModalProps) => {
  const { errorCode } = props;

  const theme = useContext(ThemeContext);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const [icon, message] = getErrorData(errorCode, t);

  const content = (
    <ModalContent>
      <Container>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Icon color={theme.colors.mineShaftDarker} size={64} icon={icon} />
          </Grid>
          <GridText item xs={12}>
            <Text dangerouslySetInnerHTML={{ __html: message }} />
          </GridText>
        </Grid>
      </Container>
    </ModalContent>
  );
  return (
    <Modal
      children={content}
      width={'85vw'}
      color={'mineShaftDarker'}
      {...props}
    />
  );
};

export default ErrorModal;
