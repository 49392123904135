import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import posed from 'react-pose';
import { ThemeContext } from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';

import Modal from '../../ui//Modal';
import Icon from '../../ui/Icon';
import Typography from '../../ui/v2/Typography';

interface PremiumCareProps {
  pose: string;
  isVisible: boolean;
  onClose(): void;
}

const RightLeft = posed.div({
  right: { left: '100vw' },
  left: { left: '0px' },
});

const Dialog = styled(RightLeft)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.whiteCreamy};
  z-index: 99;
  overflow: scroll;
`;

const RightIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 1;
`;

const GridPadding = styled(Grid)`
  padding-left: 48px;
  padding-right: 48px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const MobileDialog = styled(Dialog)`
  z-index: 1100;
`;

const ModalContent = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: auto;
  scrollbar-width: none;
`;

const Title = styled.div`
  margin: 16px 0;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 32px 0;
  }
`;
const BenefitsList = styled.ul`
  padding: 0;
  margin-top: 26px;
  list-style-type: none;
`;

const BenefitElementList = styled.li`
  position: relative;
  div {
    margin-left: 10px;
    b {
      font-weight: 500;
    }
  }
  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.terracota};
    position: absolute;
    top: 8px;
  }
`;

const PremiumCare = (props: PremiumCareProps) => {
  const { pose, onClose } = props;

  const themeStyled = useContext(ThemeContext);

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const content = (
    <>
      <ModalContent>
        <Container
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <GridPadding
              item
              xs={12}
              style={{
                backgroundColor: themeStyled.colors.linen,
              }}
            >
              <Title>
                <Icon
                  icon={'Premiumcare-primary'}
                  customStyle={{
                    height: '100%',
                    width: '250px',
                  }}
                />
              </Title>
            </GridPadding>
            <GridPadding container>
              <BenefitsList>
                <BenefitElementList>
                  <Typography>
                    <strong>{t('premiumCareTitle1')}</strong>
                  </Typography>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: t('premiumCareDescription1'),
                    }}
                  />
                </BenefitElementList>
                <BenefitElementList>
                  <Typography>
                    <strong>{t('premiumCareTitle2')}</strong>
                  </Typography>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: t('premiumCareDescription2'),
                    }}
                  />
                </BenefitElementList>
                <BenefitElementList>
                  <Typography>
                    <strong>{t('premiumCareTitle3')}</strong>
                  </Typography>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: t('premiumCareDescription3'),
                    }}
                  />
                </BenefitElementList>
                {t('premiumCareTitle4') !== ' ' && (
                  <BenefitElementList>
                    <Typography>
                      <strong>{t('premiumCareTitle4')}</strong>
                    </Typography>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: t('premiumCareDescription4'),
                      }}
                    />
                  </BenefitElementList>
                )}
              </BenefitsList>
            </GridPadding>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: themeStyled.colors.cararra,
                padding: xsDown ? '8px 16px 16px 16px' : '23px 72px 23px 72px',
                borderBottom: `8px solid ${themeStyled.colors.terracota}`,
              }}
            >
              <div>{t('Included in all our trips. As simple as that.')}</div>
            </Grid>
          </Grid>
        </Container>
      </ModalContent>
    </>
  );

  if (xsDown) {
    return ReactDOM.createPortal(
      <MobileDialog pose={pose}>
        <RightIcon>
          <Icon
            color={themeStyled.colors.black}
            onClick={onClose}
            size={18}
            icon="ico-close"
          />
        </RightIcon>
        {content}
      </MobileDialog>,
      document.body,
    );
  }

  return (
    <Modal
      children={content}
      width={'85vw'}
      color={themeStyled.colors.black}
      {...props}
    />
  );
};

export default PremiumCare;
