import { ActionType } from '../reducers/destinationInfo';
import { api } from '../config';
import mockData from '../mocks/destinationInfo.json';

export const setDestinationInfoData = (countryName, category) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockData;

    const url = `${api.landing}/country/${countryName}/${category}`;
    const method = 'get';

    const type: ActionType = 'SET_DESTINATION_INFO_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_DESTINATION_INFO_DATA',
            success: 'RECEIVED_DESTINATION_INFO_DATA',
            fail: 'ERROR_DESTINATION_INFO_DATA',
          },
          url,
          // props only used when type = FETCH_MOCK:
          mockResult,
          method,
        },
      }),
    );
  };
};
