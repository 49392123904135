import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import iconSetJsonMain from '../../assets/icomoon/main.json';
import IcomoonReact from 'icomoon-react';

export const IconWrapper = styled.div<{ clickable: boolean }>`
  display: inline-block;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
`;

const Icon = (props: IcomoonProps) => {
  const { icon, color, size, onClick, customStyle, iconSet } = props;

  const [iconSetJson, setIconSetJson] = useState(null);

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      const iconSetJsonImported = await import(
        /* webpackChunkName="Icomoon" */ `../../assets/icomoon/${iconSet}.json`
      );
      if (!didCancel) {
        setIconSetJson(iconSetJsonImported);
      }
    };

    if (iconSet !== 'main') {
      fetchData();
      return () => {
        didCancel = true;
      };
    }
  }, [iconSet]);

  if (iconSetJson === null && iconSet !== 'main') {
    return <></>;
  }

  return (
    <IconWrapper
      onClick={onClick}
      clickable={typeof onClick === 'function'}
      style={customStyle}
    >
      <IcomoonReact
        iconSet={iconSet === 'main' ? iconSetJsonMain : iconSetJson}
        icon={icon}
        color={color || ''}
        size={size || '100%'}
      />
    </IconWrapper>
  );
};

Icon.defaultProps = {
  iconSet: 'common',
};

export default Icon;
