const initialCampaignState = {
  days: [],
  name: '',
  destination: '',
  images: [],
  fromPriceWithCurrency: '',
  description: '',
  categories: [
    {
      adultOccupancy: [],
      childOccupancy: 0,
      babyOccupancy: 0,
      title: '',
      fromPriceWithCurrency: '',
      categoryId: '',
      tabs: {},
    },
  ],
  selectedCategoryIndex: 0,
  selectedDepartureCityId: '0',
  breadcrumb: [{ title: '', link: '' }],
  hasCabinClassFilter: true,
  heroImages: [{ mobile: '', tablet: '', desktop: '' }],
};

const campaign = (state = initialCampaignState, action) => {
  const { data, type } = action;

  switch (type) {
    case 'SET_CAMPAIGN_DATA':
      return {
        ...state,
        ...data,
        selectedDepartureCityId: data.defaultCityId,
        isDealOfTheWeek: data.isDealOfTheWeek || false,
        selectedCategoryIndex: 0,
      };
    case 'SET_CAMPAIGN_CATEGORY_SELECTED_INDEX':
      return {
        ...state,
        selectedCategoryIndex: data,
      };
    case 'SET_DEPARTURE_CITY_ID':
      return {
        ...state,
        selectedDepartureCityId: data,
        selectedCategoryIndex: 0,
      };
    default:
      return state;
  }
};

export default campaign;
