import React, { useContext, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import { Formik } from 'formik';
import { string, object, boolean } from 'yup';
import TagManager from 'react-gtm-module';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next';
import {
  getLocaleFromUrl,
  getGoogleAnalyticsClientID,
  isLoggedIn,
  hasNewsletterCheckBox,
} from '../../../utils';

import * as budgetActions from '../../../actions/budget';

import Input from '../../ui/v2/Input';
import Label from '../../ui/v2/Label';
import Icon from '../../ui/Icon';
import Button from '../../ui/v2/Button';
import InputCheckBox from '../../ui/InputCheckBox';
import InputPhone from '../../ui/v2/InputPhone';

interface BudgetFormProps {
  budgetCreate: any;
  data: {
    productId: string;
    budgetTotal: string;
  };
  flightId: string;
  categoryId: number;
  offerId: string;
  adultNumber: number;
  childrenNumber: number;
  babiesNumber: number;
  email?: string;
}

const Title = styled.h4`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  color: ${(props) => props.theme.colors.mineShaftDarker};
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1rem;
  }
`;

const CheckContent = styled.p`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1rem;
  line-height: 1.31;
  color: ${(props) => props.theme.colors.mineShaftDarker};
`;

const BlueBox = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.linkWater};
  color: ${(props) => props.theme.colors.jellyBean};
  display: flex;
  align-items: stretch;
  padding: 27px;
`;

const PhoneInputWrapper = styled.div`
  margin-top: 16px;
  .react-tel-input {
    .form-control {
      height: 48px;
      width: 100%;
      &:focus {
        outline: none;
        box-shadow: unset;
        border-color: ${(props) => props.theme.colors.silver};
      }
    }
    .flag-dropdown {
      background-color: unset;
      border-right: 0;
    }
  }
`;

const CheckBoxWrapper = styled.div`
  margin-top: 16px;
`;

const BlueBoxElement = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  width: 100%;
`;

const TermsAndConditions = styled.div`
  font-size: 0.875rem;
  line-height: 1.14;
  text-align: center;
  margin-top: 10px;
  color: ${(props) => props.theme.colors.mineShaftDarker};
`;

const StyledErrorText = styled.small`
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
  color: ${(props) => props.theme.colors.red};
`;

const SuccessForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 78px;
  background-color: ${(props) => props.theme.colors.foam};
  border-radius: 50%;
`;

function BudgetForm({
  data: { productId, budgetTotal },
  budgetCreate,
  flightId,
  categoryId,
  offerId,
  adultNumber,
  childrenNumber,
  babiesNumber,
  email,
}: BudgetFormProps) {
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);
  const [isAcceptTC, setAcceptTC] = useState(false);
  const styledTheme = useContext(ThemeContext);

  const [showForm, setShowForm] = useState(true);

  let schema = object().shape({
    email: string()
      .email(t('validEmail'))
      .required(t('checkout@fieldRequired')),
    terms: boolean().oneOf([true, false]),
    phone: string().min(5, t('minCharacters')),
  });

  return (
    <>
      {showForm && (
        <Grid container>
          <Grid item xs={12} sm={9}>
            <Title>
              {t(
                'budgetModal@searchingUltimateExperience',
                'Are you searching for the ultimate travel experience?',
              )}
            </Title>
            <CheckContent
              dangerouslySetInnerHTML={{
                __html: t(
                  'budgetModal@descriptionWhenPhone',
                  'Provide us with your email and telephone number so that one of our agents can advise you on exclusive and personalised offers not available on our website.',
                ),
              }}
            />
          </Grid>
          <Hidden only="xs">
            <Grid item xs={12} sm={3}>
              <Icon
                icon="budgetv2"
                size={60}
                customStyle={{ marginLeft: '36px' }}
              />
            </Grid>
          </Hidden>
        </Grid>
      )}
      <Formik
        initialValues={{
          email: email && isLoggedIn() ? email : '',
          terms: true,
          phone: '',
        }}
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true);

            await budgetCreate({
              email: values.email,
              phone: values.phone,
              flightId: flightId ? flightId : '',
              productId: productId,
              categoryId: categoryId,
              offerId: offerId,
              adultNumber: adultNumber.toString(),
              childrenNumber: childrenNumber.toString(),
              babiesNumber: babiesNumber.toString(),
              googleAnalyticsClientId: getGoogleAnalyticsClientID(),
              abTests: sessionStorage.getItem('abParams') || '',
              newsletter: values.terms,
            });

            setSubmitting(false);
            setShowForm(false);

            TagManager.dataLayer({
              dataLayer: {
                event: 'gaEvent',
                eventCategory: 'Budget',
                eventAction: 'send budget button 1',
                eventLabel: productId,
                eventValue: budgetTotal,
              },
            });
          } catch (error) {
            setSubmitting(false);
          }
        }}
        render={({
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          isSubmitting,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                {showForm && (
                  <Grid item xs={12} sm={6}>
                    <Label bold>{t('budgetModal@emailLabel', 'Email')}*</Label>
                    <Input
                      type="text"
                      inputName={'email'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={touched.email && errors.email ? true : false}
                      errorText={errors.email ? errors.email : ''}
                      placeHolderText={t('budgetInputPlaceholder')}
                      customStyle={{
                        height: '48px',
                        border: `solid 1px ${styledTheme.colors.silver}`,
                        padding: '8px 16px',
                      }}
                    />
                    <PhoneInputWrapper>
                      <Label bold>{t('budgetModal@phoneLabel', 'Phone')}</Label>
                      <InputPhone
                        name={'phone'}
                        setFieldValue={setFieldValue}
                        value={values.phone}
                      />
                      {touched.phone && errors.phone && (
                        <StyledErrorText>{errors.phone}</StyledErrorText>
                      )}
                    </PhoneInputWrapper>
                    {hasNewsletterCheckBox() && (
                      <CheckBoxWrapper>
                        <InputCheckBox
                          name={'terms'}
                          checked={values.terms}
                          onChange={(e) => {
                            handleChange(e);
                            setAcceptTC(!isAcceptTC);
                          }}
                          label={t(
                            'modalBudget@acceptNewsletter',
                            'I agree to receive exclusive Exoticca promotions by email.',
                          )}
                          error={touched.terms && errors.terms ? true : false}
                        />
                      </CheckBoxWrapper>
                    )}
                    <Button
                      type="submit"
                      customStyle={{
                        marginTop: '16px',
                      }}
                      loading={isSubmitting}
                    >
                      {t(
                        'budgetModal@personalizeMyTrip',
                        'Personalize my trip',
                      )}
                    </Button>
                    <TermsAndConditions
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'modalBudget@termsAndConditions',
                          "By clicking the button I accept the <a style='text-decoration:underline!important' href='/terms-and-conditions' target='_blank' rel='nofollow'>Terms of use</a>, the <a style='text-decoration:underline!important' href='/privacy-policy' target='_blank' rel='nofollow'>Privacy policy</a> and the <a style='text-decoration:underline!important' href='/cookies-policy' target='_blank' rel='nofollow'>Cookies policy</a>",
                        ),
                      }}
                    />
                  </Grid>
                )}
                {!showForm && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <SuccessForm>
                      <SuccessIcon>
                        <Icon icon="validation" size={46} />
                      </SuccessIcon>
                      <div>
                        <Title>
                          {t(
                            'budgetModal@perfectWeWillContactYou',
                            'Perfect! We will contact you shortly',
                          )}
                        </Title>
                        <CheckContent>
                          {t(
                            'budgetModal@weHaveSentYouAnEmailPhoneNotRequired',
                            'We have sent you an email with your personalised quotation so that you always have it at hand. If you have provided a telephone number, our dedicated expert will contact you as soon as possible to answer any questions.',
                          )}
                        </CheckContent>
                      </div>
                    </SuccessForm>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <BlueBox>
                    <Grid container justify={'center'} alignItems={'center'}>
                      <BlueBoxElement style={{ marginTop: '0' }}>
                        <Grid item xs={2}>
                          <Icon icon="check-rounded" size={32} />
                        </Grid>
                        <Grid item xs={10}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t(
                                'budgetModal@receiveDocumentAllInfo',
                                'Receive a <b>document</b> with all the <b>information</b>',
                              ),
                            }}
                          />
                        </Grid>
                      </BlueBoxElement>
                      <BlueBoxElement>
                        <Grid item xs={2}>
                          <Icon icon="check-rounded" size={32} />
                        </Grid>
                        <Grid item xs={10}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t(
                                'budgetModal@shareIt',
                                '<b>Share it</b> with your fellow travelers',
                              ),
                            }}
                          />
                        </Grid>
                      </BlueBoxElement>
                      <BlueBoxElement>
                        <Grid item xs={2}>
                          <Icon icon="check-rounded" size={32} />
                        </Grid>
                        <Grid item xs={10}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t(
                                'budgetModal@exclusiveForYou',
                                'Get exclusive <b>offers for you</b>',
                              ),
                            }}
                          />
                        </Grid>
                      </BlueBoxElement>
                    </Grid>
                  </BlueBox>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isFetching: state.budget.isFetching,
    flightId: state.checkout.summary.flight.id,
    categoryId: state.checkout.summary.category.id,
    offerId: state.checkout.summary.offer.id,
    adultNumber: state.checkout.summary.pax.adults,
    childrenNumber: state.checkout.summary.pax.children,
    babiesNumber: state.checkout.summary.pax.babies,
    email: state.config.data.customer?.email,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...budgetActions }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BudgetForm);
