import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  charSet?: string;
  title?: string;
  description?: string;
  keywords?: string;
  language?: string;
  robots?: string;
  revisitAfter?: string;
  copyright?: string;
  audience?: string;
  contentLanguage?: string;
  viewport?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogType?: string;
  ogSiteName?: string;
  ogImage?: string;
  ogUrl?: string;
  canonical?: string;
  alternate?: {
    locale: string;
    domain: string;
  }[];
}

const getShortLocale = (locale) => {
  switch (locale) {
    case 'en_UK':
      return 'en-gb';
    case 'en_US':
      return 'en-us';
    case 'es_ES':
      return 'es';
    case 'fr_FR':
      return 'fr';
    case 'de_DE':
      return 'de';
    case 'en_CA':
      return 'en-ca';
    case 'en_AU':
      return 'en-au';
  }
};

export default function SEOTags({
  charSet,
  title,
  description,
  keywords,
  language,
  robots,
  revisitAfter,
  copyright,
  audience,
  contentLanguage,
  viewport,
  ogTitle,
  ogDescription,
  ogType,
  ogSiteName,
  ogImage,
  ogUrl,
  canonical,
  alternate,
}: Props) {
  return (
    <Helmet
      htmlAttributes={
        contentLanguage
          ? {
              lang: contentLanguage,
            }
          : {}
      }
    >
      {charSet && <meta charSet={charSet} />}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {language && <meta name="Language" content={language} />}
      {robots && <meta name="robots" content={robots} />}
      {revisitAfter && <meta name="revisit-after" content={revisitAfter} />}
      {copyright && <meta name="copyright" content={copyright} />}
      {audience && <meta name="audience" content={audience} />}
      {contentLanguage && (
        <meta http-equiv="content-language" content={contentLanguage} />
      )}
      {viewport && <meta name="viewport" content={viewport} />}

      {ogTitle && <meta property="og:title" content={ogTitle} />}
      {ogDescription && (
        <meta property="og:description" content={ogDescription} />
      )}
      {ogType && <meta property="og:type" content={ogType} />}
      {ogSiteName && <meta property="og:site_name" content={ogSiteName} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}

      {canonical && <link rel="canonical" href={canonical} />}
      {alternate &&
        alternate.length > 0 &&
        alternate.map(({ locale, domain }) => (
          <link
            key={locale}
            rel="alternate"
            //@ts-ignore
            hreflang={getShortLocale(locale)}
            href={domain}
          />
        ))}
    </Helmet>
  );
}
