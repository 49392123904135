import { ActionType } from '../reducers/flights';
import { api } from '../config';
import mockFlightDatesData from '../mocks/flightDates.json';
import mockFlightListData from '../mocks/flightList.json';

import { isUrlCached, getUrlCache } from '../utils/cache';

export const setDepartureCity = (departureCity: object) => {
  return (dispatch) =>
    dispatch({ type: 'SET_DEPARTURE_CITY', payload: departureCity });
};

export const setDates = (dates: object) => {
  return (dispatch) => dispatch({ type: 'SET_DATES', payload: dates });
};

export const setPassengers = (passengers: object) => {
  return (dispatch) =>
    dispatch({ type: 'SET_PASSENGERS', payload: passengers });
};

export const setFlightId = (flightId: string) => {
  return (dispatch) => dispatch({ type: 'SET_FLIGHT_ID', payload: flightId });
};

export const getFlightDatesData = (
  categoryId,
  departureCity,
  passengersCount: number,
  relatedCategoryId?: string,
  privateTours?: boolean,
  privateToursRelated?: boolean,
) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockFlightDatesData;
    const method = 'get';
    const type: ActionType = 'SET_FLIGHT_DAYS';
    const soloTours = passengersCount === 1;
    const url = `${api.category}/${categoryId}/${departureCity}/prices?numPassengers=${passengersCount}`;

    let secondDispatch;
    if (soloTours) {
      const urlSoloPlusOne = `${
        api.category
      }/${categoryId}/${departureCity}/prices?numPassengers=${
        passengersCount + 1
      }`;
      secondDispatch = dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_FLIGHT_DAYS_RELATED',
            success: 'RECEIVED_FLIGHT_DAYS_RELATED',
            fail: 'ERROR_FLIGHT_DAYS',
          },
          url: urlSoloPlusOne,
          mockResult,
          method,
        },
      });
    } else if (relatedCategoryId) {
      const urlRelated = `${api.category}/${relatedCategoryId}/${departureCity}/prices?numPassengers=${passengersCount}`;
      secondDispatch = dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_FLIGHT_DAYS_RELATED',
            success: privateToursRelated
              ? 'RECEIVED_FLIGHT_DAYS_RELATED_PRIVATE'
              : 'RECEIVED_FLIGHT_DAYS_RELATED',
            fail: 'ERROR_FLIGHT_DAYS',
          },
          url: urlRelated,
          mockResult,
          method,
        },
      });
    }

    return Promise.all([
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_FLIGHT_DAYS',
            success: privateTours
              ? 'RECEIVED_FLIGHT_DAYS_PRIVATE'
              : soloTours
              ? 'RECEIVED_FLIGHT_DAYS_SOLO'
              : 'RECEIVED_FLIGHT_DAYS',
            fail: 'ERROR_FLIGHT_DAYS',
          },
          url,
          mockResult,
          method,
        },
      }),
      secondDispatch,
    ]);
  };
};

export const getFlightsListData = (data) => {
  return (dispatch, getState) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockFlightListData;

    const url = `${api.voyages}/getFlights`;
    const method = 'post';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const options = {
      cache: true,
      data: Object.entries(data)
        .map(
          (e) =>
            encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1] as string),
        )
        .join('&'),
    };

    const cancelToken = getState()?.flights?.cancelToken;

    if (cancelToken) {
      cancelToken.cancel('Cancel getFlights request');
    }

    const cacheUrl = `${url}?${options.data}`;
    if (isUrlCached(cacheUrl)) {
      const urlCache = getUrlCache(cacheUrl);
      if (urlCache.data?.flights?.length > 0) {
        return dispatch({
          type: 'IS_CACHED_FLIGHTS_LIST',
          payload: urlCache.data.flights,
        });
      }
    }

    const type: ActionType = 'SET_FLIGHTS_LIST';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_FLIGHTS_LIST',
            success: 'RECEIVED_FLIGHTS_LIST',
            fail: 'ERROR_FLIGHTS_LIST',
          },
          url,
          // props only used when type = FETCH_MOCK:
          mockResult,
          method,
          headers,
          options,
        },
      }),
    );
  };
};

export const deleteFlightList = () => {
  return (dispatch, getState) => {
    const cancelToken = getState()?.flights?.cancelToken;

    if (cancelToken) {
      cancelToken.cancel('Cancel getFlights request');
    }

    dispatch({ type: 'DELETE_FLIGHTS_LIST' });
  };
};

export const errorFlightList = () => {
  return (dispatch, getState) => {
    const cancelToken = getState()?.flights?.cancelToken;

    if (cancelToken) {
      cancelToken.cancel('Cancel getFlights request');
    }

    dispatch({ type: 'ERROR_FLIGHTS_LIST' });
  };
};
