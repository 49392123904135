import React, { useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useTheme, createStyles, makeStyles, Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';
import { Grid } from '@material-ui/core';
import {
  getLocaleFromUrl,
  getResponseTapClassName,
  getDedicatedPhoneNumber,
} from '../../utils';
import Icon from '../ui/Icon';

import expertImg from '../../assets/images/agents-phone.png';

type Props = {
  fluidGrid?: boolean;
  contactPhone: string;
};

const Title = styled.div`
  font-weight: 500;
  margin: 0 10px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 0.875rem;
  }
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.colorsmineShaftDarker};
  margin: 0 10px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 0.75rem;
  }
`;

const ImageExpert = styled.img`
  width: 58px;
  margin-right: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 24px;
  }
`;

const PhoneBox = styled.div`
  padding: 16px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.foam};
  text-align: center;
  white-space: nowrap;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 0.875rem;
    padding: 8px 4px;
    margin: 0 5px;
  }

  a {
    font-weight: bold;
    color: ${(props) => props.theme.colors.systemCrimson};
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: (props) => ({
      backgroundColor: props.white,
      borderTop: `4px solid ${props.green}`,
      padding: '20px 25px',
      boxShadow: props.fluid ? '0px 1px 10px rgba(0, 0, 0, 0.12)' : 'none',
      margin: props.fluid ? '0 auto 20px auto' : '0 auto 50px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 6px',
        marginBottom: '22px',
        marginTop: props.fluid ? '30px' : '0',
        alignItems: 'center',
      },
    }),
  }),
);

function LeadBannerCall({ contactPhone, fluidGrid }: Props) {
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);
  const themeStyle = useContext(ThemeContext);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const classes = useStyles({
    green: themeStyle.colors.eggBlue,
    white: themeStyle.colors.white,
    fluid: fluidGrid,
  });

  const dedicatedPhoneNumber = getDedicatedPhoneNumber();
  const responseTapClassName = dedicatedPhoneNumber
    ? ''
    : getResponseTapClassName();

  return (
    <>
      <Grid
        container
        item
        xs={fluidGrid ? 12 : 11}
        sm={fluidGrid ? 12 : 11}
        className={classes.gridContainer}
      >
        <Grid item xs={1}>
          <ImageExpert src={expertImg} />
        </Grid>
        <Grid item xs={5} sm={7} md={8}>
          <Title
            dangerouslySetInnerHTML={{
              __html: smUp
                ? t(
                    'leadBannerCall@title',
                    'Save time, call out Travel expert for free',
                  )
                : t('leadBannerCall@titleMobile', 'Need help?'),
            }}
          />
          <SubTitle
            dangerouslySetInnerHTML={{
              __html: smUp
                ? t(
                    'leadBannerCalll@subtitle',
                    'Allow one of our expert help you to arrange your reservation',
                  )
                : t(
                    'leadBannerCall@subtitleMobile',
                    'Get a travel specialist to plan your dream vacation',
                  ),
            }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <PhoneBox>
            <Hidden xsDown>
              <Icon
                icon="header-call-desktop"
                size="24"
                color={themeStyle.colors.systemCrimson}
                customStyle={{
                  marginRight: 5,
                }}
              />
            </Hidden>
            <a
              href={`tel:${dedicatedPhoneNumber || contactPhone}`}
              className={responseTapClassName}
              dangerouslySetInnerHTML={{
                __html: dedicatedPhoneNumber || contactPhone,
              }}
            />
          </PhoneBox>
        </Grid>
      </Grid>
    </>
  );
}

export default LeadBannerCall;
