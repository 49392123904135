import React from 'react';

export const Home = React.lazy(() =>
  import(/* webpackChunkName: "Home" */ '../containers/Home'),
);

export const PDP = React.lazy(() =>
  import(/* webpackChunkName: "PDP" */ '../containers/Campaign'),
);

export const Destination = React.lazy(() =>
  import(/* webpackChunkName: "Destination" */ '../containers/Destination'),
);

export const DestinationInfo = React.lazy(() =>
  import(
    /* webpackChunkName: "DestinationInfo" */ '../containers/DestinationInfo'
  ),
);

export const CheckoutRegisterLoginStep = React.lazy(() =>
  import(
    /* webpackChunkName: "CheckoutRegisterLoginStep" */ '../components/checkout/CheckoutRegisterLoginStep'
  ),
);

export const CheckoutPersonalizationStep = React.lazy(() =>
  import(
    /* webpackChunkName: "CheckoutPersonalizationStep" */ '../components/checkout/CheckoutPersonalizationStep'
  ),
);

export const CheckoutPassengerStep = React.lazy(() =>
  import(
    /* webpackChunkName: "CheckoutPassengerStep" */ '../components/checkout/CheckoutPassengerStep'
  ),
);

export const CheckoutPaymentsStep = React.lazy(() =>
  import(
    /* webpackChunkName: "CheckoutPaymentsStep" */ '../components/checkout/CheckoutPaymentsStep'
  ),
);

export const CheckoutPaymentsNotConfirmedStep = React.lazy(() =>
  import(
    /* webpackChunkName: "CheckoutPaymentsNotConfirmedStep" */ '../components/checkout/CheckoutPaymentsNotConfirmedStep'
  ),
);

export const CheckoutPaymentsConfirmedStep = React.lazy(() =>
  import(
    /* webpackChunkName: "CheckoutPaymentsConfirmedStep" */ '../components/checkout/CheckoutPaymentsConfirmedStep'
  ),
);

export const CheckoutBudgetStep = React.lazy(() =>
  import(
    /* webpackChunkName: "CheckoutBudgetStep" */ '../components/checkout/CheckoutBudgetStep'
  ),
);

export const Confirmation = React.lazy(() =>
  import(/* webpackChunkName: "Confirmation" */ '../containers/Confirmation'),
);

export const Login = React.lazy(() =>
  import(/* webpackChunkName: "Login" */ '../containers/Login'),
);

export const Register = React.lazy(() =>
  import(/* webpackChunkName: "Register" */ '../containers/Register'),
);

export const RecoverPassword = React.lazy(() =>
  import(
    /* webpackChunkName: "RecoverPassword" */ '../containers/RecoverPassword'
  ),
);

export const Landing = React.lazy(() =>
  import(/* webpackChunkName: "Landing" */ '../containers/Landing'),
);

export const BudgetPdf = React.lazy(() =>
  import(/* webpackChunkName: "BudgetPdf" */ '../containers/BudgetPdf'),
);

export const CancellationVoucherPdf = React.lazy(() =>
  import(
    /* webpackChunkName: "CancellationVoucherPdf" */ '../containers/CancellationVoucherPdf'
  ),
);

export const TicketPdf = React.lazy(() =>
  import(/* webpackChunkName: "TicketPdf" */ '../containers/TicketPdf'),
);

export const AboutUs = React.lazy(() =>
  import(/* webpackChunkName: "AboutUs" */ '../containers/static/AboutUs'),
);

export const GenericPages = React.lazy(() =>
  import(/* webpackChunkName: "GenericPages" */ '../containers/GenericPages'),
);

export const Contact = React.lazy(() =>
  import(/* webpackChunkName: "Contact" */ '../containers/static/Contact'),
);
export const MyOrders = React.lazy(() =>
  import(/* webpackChunkName: "MyOrders" */ '../components/myaccount/MyOrders'),
);
export const MyWishList = React.lazy(() =>
  import(
    /* webpackChunkName: "MyWishList" */ '../components/myaccount/MyWishList'
  ),
);
export const MyProfile = React.lazy(() =>
  import(
    /* webpackChunkName: "MyProfile" */ '../components/myaccount/MyProfile'
  ),
);
export const MySubscriptions = React.lazy(() =>
  import(
    /* webpackChunkName: "MySubscriptions" */ '../components/myaccount/MySubscriptions'
  ),
);
export const MyWallet = React.lazy(() =>
  import(/* webpackChunkName: "MyWallet" */ '../components/myaccount/MyWallet'),
);
export const Faq = React.lazy(() =>
  import(/* webpackChunkName: "Faq" */ '../components/myaccount/Faq'),
);

export const Error404 = React.lazy(() =>
  import(
    /* webpackChunkName: "Error404" */ '../containers/errorPages/Error404'
  ),
);

export const Error500 = React.lazy(() =>
  import(
    /* webpackChunkName: "Error500" */ '../containers/errorPages/Error500'
  ),
);

export const Maintenance = React.lazy(() =>
  import(
    /* webpackChunkName: "Maintenance" */ '../containers/errorPages/Maintenance'
  ),
);

export const PreferenceCenter = React.lazy(() =>
  import(
    /* webpackChunkName: "PreferenceCenter" */ '../containers/PreferenceCenter'
  ),
);
