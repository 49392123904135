import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components/macro';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Popper from '@material-ui/core/Popper';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';
import {
  getLocaleFromUrl,
  getCountryNames,
  setExoticcaCountryCookie,
} from '../../utils';

import Icon from '../ui/Icon';

type CountrySelectorProps = {
  handlerCountrySelector(state: boolean): void;
  open: boolean;
  promotionModeEnabled: boolean;
  yobarHeight: number;
  country: string;
  alternativeDomains: any[];
};

const CountrySelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 0 0 24px;
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  border-bottom: unset;
  right: 0px;

  &:hover {
    background-color: ${(props) => props.theme.colors.mineShaft};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    flex-direction: row;
  }
`;

const CountrySelectorText = styled.div`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.36rem;
  color: ${(props) => props.theme.colors.headerFontColor};
  margin-left: 8px;
`;

const Box = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.mineShaft};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  border-bottom: 5px solid ${(props) => props.theme.colors.golden};
  max-width: 356px;
  max-height: 509px;
`;

const Link = styled.a<{ active: boolean }>`
  display: flex;
  font-size: 1rem;
  padding: 8px 16px;
  cursor: pointer;
  border-bottom: solid 1px ${(props) => props.theme.colors.concretDarker};
  background-color: ${(props) =>
    props.active ? props.theme.colors.concretDarker : 'unset'};
  &:hover {
    text-decoration: underline !important;
  }
`;

function CountrySelector({
  handlerCountrySelector,
  open,
  promotionModeEnabled,
  yobarHeight,
  country,
  alternativeDomains,
}: CountrySelectorProps) {
  const themeStyle = useContext(ThemeContext);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const anchorFallback = document.getElementsByTagName(
    'header',
  )[0] as HTMLDivElement;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }

    handlerCountrySelector(!open);
  };

  const countryNames = getCountryNames(t);

  const modifiers = mdUp
    ? {}
    : {
        flip: {
          enabled: false,
        },
        preventOverflow: {
          enabled: false,
        },
        hide: {
          enabled: false,
        },
        offset: {
          enabled: true,
          offset: '0, -1',
        },
      };

  return (
    <>
      {open ? (
        <div
          style={{
            position: 'fixed',
            zIndex: 0,
            left: 0,
            right: 0,
            top: promotionModeEnabled ? yobarHeight : '53px',
            bottom: 0,
          }}
          onClick={() => handlerCountrySelector(false)}
        />
      ) : null}
      <CountrySelectorWrapper
        onClick={handleClick}
        style={open ? { borderBottomColor: themeStyle.colors.golden } : {}}
      >
        <>
          <Icon icon={'location'} color={themeStyle.colors.white} size={24} />
          <CountrySelectorText>{country}</CountrySelectorText>
          <Icon
            icon={open ? 'iconsup-arrow1' : 'iconsdown'}
            size={13}
            color={themeStyle.colors.white}
            customStyle={{
              margin: '16px 16px 16px 8px',
            }}
          />
        </>
      </CountrySelectorWrapper>
      {anchorFallback && (
        <Popper
          open={true}
          anchorEl={mdUp ? anchorEl || anchorFallback : anchorFallback}
          placement={'bottom-end'}
          disablePortal={true}
          transition={false}
          modifiers={modifiers}
          style={xsDown ? { width: '100vw' } : {}}
        >
          <Collapse in={open}>
            <Box>
              {alternativeDomains &&
                alternativeDomains.map((item, index) => (
                  <Link
                    active={item.locale.slice(-2) === country}
                    href={item.domain}
                    key={`countries-${index}`}
                    onClick={() =>
                      setExoticcaCountryCookie(
                        item.locale.slice(-2).toLowerCase(),
                      )
                    }
                  >
                    <Icon
                      icon={item.locale}
                      iconSet={'flags'}
                      size={16}
                      customStyle={{ marginRight: '14px' }}
                    />
                    {countryNames[item.locale]}
                  </Link>
                ))}
            </Box>
          </Collapse>
        </Popper>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    country: state.config?.data?.market,
    alternativeDomains: state.config?.data?.alternativeDomains,
  };
};

export default connect(mapStateToProps, {})(CountrySelector);
