import React, { useContext, useEffect, useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components/macro';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Popper from '@material-ui/core/Popper';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../utils';

import Icon from '../ui/Icon';

import HelpForm from './HelpForm';

import { getResponseTapClassName, getDedicatedPhoneNumber } from '../../utils';

declare global {
  interface Window {
    rTapNotifyDOMChange(body: HTMLElement): void;
  }
}

type HelpProps = {
  isCustomerServiceAvailable: boolean;
  phone: string;
  handlerHelp(state: boolean): void;
  open: boolean;
  promotionModeEnabled: boolean;
  yobarHeight: number;
  isCheckout?: boolean;
  alignRight: boolean;
  toggleCoronaModal(): void;
};

const HelpWrapper = styled.div<{ open: boolean; alignRight: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: ${(props) => (props.alignRight ? '0 0 0 auto' : '0 0 0 24px')};
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  border-bottom: unset;
  right: 0px;

  &:hover {
    background-color: ${(props) => props.theme.colors.mineShaft};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    right: unset;
    margin-left: auto;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin: ${(props) =>
      props.alignRight ? '8px 0px 0px 24px' : '0 0 0 16px'};
    padding-right: 0px;
    padding-left: 0px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: ${(props) =>
      props.alignRight ? '8px 4px 8px 20px' : '0 0 0 16px'};
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    flex-direction: row;
  }
`;

const HelpText = styled.div`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.36rem;
  color: ${(props) => props.theme.colors.headerFontColor};
  margin-left: 8px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    text-transform: uppercase;
    font-size: 0.625rem;
    line-height: 0.625rem;
    margin-top: 6px;
    margin-left: 0;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.mineShaft};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  border-bottom: 5px solid ${(props) => props.theme.colors.golden};
  padding: 16px 8px 0;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    background-color: ${(props) => props.theme.colors.mineShaftDarker};
    color: ${(props) => props.theme.colors.white};
    height: calc(100vh - 53px);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    max-width: 356px;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const Line = styled.hr`
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin: 0 !important;
  border-top: 1px solid ${(props) => props.theme.colors.grayDark};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 10px 0;
  }
`;

const Link = styled(RouteLink)`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  padding: 14px 6px 0;
  cursor: pointer;
`;

function Help({
  phone,
  handlerHelp,
  open,
  promotionModeEnabled,
  yobarHeight,
  isCheckout,
  alignRight,
  toggleCoronaModal,
}: HelpProps) {
  const themeStyle = useContext(ThemeContext);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'));
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const anchorFallback = document.getElementsByTagName(
    'header',
  )[0] as HTMLDivElement;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }

    handlerHelp(!open);
  };

  const dedicatedPhoneNumber = getDedicatedPhoneNumber();

  const responseTapClassName = dedicatedPhoneNumber
    ? ''
    : getResponseTapClassName();

  useEffect(() => {
    window.rTapNotifyDOMChange && window.rTapNotifyDOMChange(document.body);
  });

  const modifiers = mdUp
    ? {}
    : {
        flip: {
          enabled: false,
        },
        preventOverflow: {
          enabled: false,
        },
        hide: {
          enabled: false,
        },
        offset: {
          enabled: true,
          offset: '0, -1',
        },
      };

  return (
    <>
      {open ? (
        <div
          style={{
            position: 'fixed',
            zIndex: 0,
            left: 0,
            right: 0,
            top: promotionModeEnabled ? yobarHeight : '53px',
            bottom: 0,
          }}
          onClick={() => handlerHelp(false)}
        />
      ) : null}
      <HelpWrapper
        onClick={handleClick}
        open={open}
        alignRight={alignRight}
        style={open ? { borderBottomColor: themeStyle.colors.golden } : {}}
      >
        {xlUp ? (
          <>
            <Icon
              icon={'phone-menu'}
              size={24}
              customStyle={{
                marginRight: '16px',
                marginTop: '2px',
              }}
            />
            <TextWrapper>
              <HelpText className={responseTapClassName}>
                {dedicatedPhoneNumber || phone}
              </HelpText>
            </TextWrapper>
            <Icon
              icon={open ? 'iconsup-arrow1' : 'iconsdown'}
              size={13}
              color={themeStyle.colors.white}
              customStyle={{
                margin: '16px 16px 16px 8px',
              }}
            />
          </>
        ) : (
          <>
            <Icon
              icon={'phone-menu'}
              color={open ? themeStyle.colors.white : ''}
              size={24}
            />
            <HelpText>{t('help')}</HelpText>
            {!smDown && (
              <Icon
                icon={open ? 'iconsup-arrow1' : 'iconsdown'}
                size={13}
                color={themeStyle.colors.white}
                customStyle={{
                  margin: '16px 16px 16px 8px',
                }}
              />
            )}
          </>
        )}
      </HelpWrapper>
      {anchorFallback && (
        <Popper
          open={true}
          anchorEl={mdUp ? anchorEl || anchorFallback : anchorFallback}
          placement={'bottom-end'}
          disablePortal={true}
          transition={false}
          modifiers={modifiers}
          style={xsDown ? { width: '100vw' } : {}}
        >
          <Collapse in={open}>
            <Box>
              <HelpForm
                fromPortal={false}
                promotionModeEnabled={promotionModeEnabled}
              />
              <Line />
              <Link
                to={t('linkFAQ')}
                onClick={() => {
                  handlerHelp(false);
                }}
                target={isCheckout ? '_blank' : ''}
              >
                {t('FAQ')}
                <Icon
                  icon={smDown ? 'ico-arrow-right' : 'newarrowright'}
                  size={16}
                  customStyle={{ marginLeft: '13px', height: '32px' }}
                  color={
                    smDown
                      ? themeStyle.colors.white
                      : themeStyle.colors.mineShaftDarker
                  }
                />
              </Link>
              <Line />
              <Link
                to="#"
                onClick={() => {
                  handlerHelp(false);
                  toggleCoronaModal();
                }}
              >
                {t('corona')}
                <Icon
                  icon={smDown ? 'ico-arrow-right' : 'newarrowright'}
                  size={16}
                  customStyle={{ marginLeft: '13px', height: '32px' }}
                  color={
                    smDown
                      ? themeStyle.colors.white
                      : themeStyle.colors.mineShaftDarker
                  }
                />
              </Link>
              <Line />
              <Link
                to={`${t('linkFAQ')}#1`}
                onClick={() => {
                  handlerHelp(false);
                }}
                target={isCheckout ? '_blank' : ''}
              >
                {t('FAQcorona')}
                <Icon
                  icon={smDown ? 'ico-arrow-right' : 'newarrowright'}
                  size={16}
                  customStyle={{ marginLeft: '13px', height: '32px' }}
                  color={
                    smDown
                      ? themeStyle.colors.white
                      : themeStyle.colors.mineShaftDarker
                  }
                />
              </Link>
            </Box>
          </Collapse>
        </Popper>
      )}
    </>
  );
}

export default Help;
