import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';
import { LayoutContext } from '../../../context/LayoutContext';
import Icon from '../../ui/Icon';
import Modal from '../../ui/Modal';
import Accordian from '../../ui/Accordian';

export interface Include {
  icon: string;
  text: string;
}

export interface Product {
  title: string;
  desktopImage: string;
  name: string;
  departure_city: string;
  departure_date: string;
  arrival_date: string;
}

export interface Offer {
  arrivalDate: string;
  baseDays: number;
  days: number;
  departureCity: string;
  departureDate: string;
  id: number;
}

interface PriceIncludesProps {
  includes: Include[];
  product: Product;
  offer: Offer;
}

interface LayoutProp {
  layout: string;
}

export const PriceIncludesHeader = styled.div<LayoutProp>`
  background-color: ${(props) => props.theme.colors.ferraLight};
  color: ${(props) => props.theme.colors.mineShaft};
  padding: 15px;
  ${(props) =>
    props.layout === 'desktop' &&
    `
      margin: 2px 2px 15px 2px;`}
  min-height: 50px;
  font-size: 1.125rem;
  font-weight: 500;
  display: flex;
`;

interface LayoutVisibleProp {
  layout: string;
  isVisible: boolean;
}
export const PriceIncludesContent = styled.div<LayoutVisibleProp>`
  overflow-y: scroll;
`;

export const PriceIncludesHeaderText = styled.span`
  margin: 3px 0 0 5px;
`;

export const RightIcon = styled.div`
  margin-left: auto;
`;

const CampaignInfo = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin: 35px auto;
  width: 100%;
  z-index: 1;
`;

const CampaignDestination = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.brightSun};

  &:before,
  &:after {
    content: '';
    height: 1px;
    border: 1px solid ${(props) => props.theme.colors.brightSun};
    width: 25px;
    min-width: 25px;
    margin: 0 12px;
  }
`;

const CampaignTitle = styled.div`
  margin: 7px 0;
  font-size: 0.938rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.white};
`;

const CampaignDaysAndPrice = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.white};

  span {
    font-size: 0.75rem;
    font-weight: normal;
    border-top: 1px dotted ${(props) => props.theme.colors.white};
    border-bottom: 1px dotted ${(props) => props.theme.colors.white};
    padding: 1px;
  }
`;

const PeakDecorator = styled.div`
  height: 26px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  span {
    width: 200px;
    height: inherit;
    background-image: url('/img/peak-decorator.png');
    background-position: center;
    background-repeat: no-repeat;
  }

  :before,
  :after {
    height: inherit;
    width: 100%;
    background-color: ${(props) => props.theme.colors.white};
    content: '';
  }
`;

const PriceIncludesCaption = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.mineShaftDarker};
  padding: 20px 30px;
`;

const Stripes = styled.div`
  width: 82px;
  height: inherit;
  min-height: 18px;
  background: repeating-linear-gradient(
    to bottom,
    ${(props) => props.theme.colors.gray} 0px,
    ${(props) => props.theme.colors.gray} 1px,
    ${(props) => props.theme.colors.white} 1px,
    ${(props) => props.theme.colors.white} 8px,
    ${(props) => props.theme.colors.gray} 8px,
    ${(props) => props.theme.colors.gray} 9px,
    ${(props) => props.theme.colors.white} 9px,
    ${(props) => props.theme.colors.white} 17px,
    ${(props) => props.theme.colors.gray} 17px,
    ${(props) => props.theme.colors.gray} 18px
  );
  opacity: 0.29;
  margin: 0 16px;
`;

const PriceIncludesList = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 10px 32px;
`;

const PriceIncludesButton = styled.div`
  text-decoration: underline;
`;

interface PriceIncludesItemProps {
  layout: string;
}

const PriceIncludesItem = styled.div<PriceIncludesItemProps>`
  display: flex;
  width: ${(props) => (props.layout === 'mobile' ? '100%' : '50%')};
  align-items: center;
  font-size: 0.813rem;
  padding: ${(props) => (props.layout === 'mobile' ? '8px' : '18px 30px')};
  border-bottom: ${(props) =>
    props.layout !== 'mobile' ? '1px solid rgba(109, 42, 9, 0.18)' : ''};
  span {
    padding-left: 19px;
  }
  :nth-last-child(-n + 2) {
    border-bottom: none;
  }
`;

const OpacityLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #6c5047; /* Hardcoded color to keep the nice opacity over the image */
  opacity: 0.84;
`;

function PriceIncludes(props: PriceIncludesProps) {
  const { includes, product, offer } = props;

  const size = 31;

  const theme = useContext(ThemeContext);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);
  const layout = useContext(LayoutContext);
  const getPriceIncludesItems = () =>
    includes.map((include, key) => (
      <PriceIncludesItem key={key} layout={layout}>
        {include.icon && (
          <Icon color={theme.colors.golden} icon={include.icon} size={34} />
        )}
        <span>{include.text}</span>
      </PriceIncludesItem>
    ));

  const [isModalVisible, toggleModal] = useState(false);
  const getModal = () => (
    <Modal
      layout={layout}
      isVisible={isModalVisible}
      onClose={() => toggleModal(false)}
    >
      <Modal.Header backgroundUrl={product.desktopImage || ''}>
        <CampaignInfo>
          <CampaignDestination>{product.title}</CampaignDestination>
          <CampaignTitle>{product.name}</CampaignTitle>
          <CampaignDaysAndPrice>
            {offer.days} {t('checkout@days')}
          </CampaignDaysAndPrice>
        </CampaignInfo>
        <OpacityLayer />
        <PeakDecorator>
          <span />
        </PeakDecorator>
      </Modal.Header>
      <Modal.Content>
        <PriceIncludesCaption>
          <Stripes></Stripes>
          {t('checkout@priceIncludes')}
          <Stripes></Stripes>
        </PriceIncludesCaption>
        <PriceIncludesList>{getPriceIncludesItems()}</PriceIncludesList>
      </Modal.Content>
    </Modal>
  );

  const [isAccordianOpen, toggleAccordian] = useState(false);
  const getAccordian = () => (
    <Accordian isVisible={isAccordianOpen} wrapped={true}>
      {getPriceIncludesItems()}
    </Accordian>
  );

  if (layout === 'desktop') {
    return (
      <>
        <PriceIncludesButton onClick={() => toggleModal(!isModalVisible)}>
          {t('checkout@priceIncludes')}
        </PriceIncludesButton>
        {getModal()}
      </>
    );
  }

  return (
    <>
      <PriceIncludesHeader layout={layout}>
        <PriceIncludesHeaderText>
          {t('checkout@priceIncludes')}
        </PriceIncludesHeaderText>
        <RightIcon>
          <Icon
            color={theme.colors.mineShaft}
            size={size}
            icon={isAccordianOpen ? 'roundedMinus' : 'roundedPlus'}
            onClick={() => toggleAccordian(!isAccordianOpen)}
          />
        </RightIcon>
      </PriceIncludesHeader>
      <PriceIncludesContent layout={layout} isVisible={isAccordianOpen}>
        {getAccordian()}
      </PriceIncludesContent>
    </>
  );
}

export default PriceIncludes;
