import React, { useContext } from 'react';
import styled, { css } from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Icon from '../../ui/Icon';

type StyledNavLinkProps = {
  selected: boolean;
  checked: boolean;
  disabled: boolean;
};

const StyledNavLink = styled.button<StyledNavLinkProps>`
  font-family: ${(props) => props.theme.fontFamily};
  flex: 0 0 auto;
  display: inline-block;
  transition: opacity 0.2s, transform 0.2s;
  font-weight: 700;
  background: transparent;
  border: 1px solid transparent;
  font-size: 0.938rem;
  color: ${(props) => props.theme.colors.mineShaftDarker};
  opacity: 0.5;
  height: 55px;
  padding: 20px 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 0.75rem;
    height: 40px;
    padding: 12px 5px;
    text-align: center;
  }

  @media (min-width: ${(props) =>
    props.theme.breakpoints.sm}) and (max-width: ${(props) =>
  props.theme.breakpoints.md}) { padding: 20px; }


  &:disabled {
    cursor: default;
  }

  ${(props) =>
    props.selected &&
    css`
      opacity: 1;
      border-bottom: 2px solid ${props.theme.colors.mineShaftDarker};
    `}

  ${(props) =>
    props.checked &&
    css`
      opacity: 1;
      border-bottom: none;
      color: ${props.theme.colors.golden};
    `}

  ${(props) =>
    props.checked &&
    css`
      @media (max-width: ${props.theme.breakpoints.sm}) {
        color: ${props.theme.colors.golden};
      }
    `}
`;

const Placeholder = styled.div`
  display: inline-block;
  width: auto;
`;

const NumberSpan = styled.span<StyledNavLinkProps>`
  margin: 0 5px 0 10px;
  &:after {
    content: '.';
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      content: '';
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: block;
    border-radius: 50%;
    margin: 0 auto 5px;
    background: ${(props) => props.theme.colors.whiteCreamy};
    width: 20px;
    height: 20px;
  }

  ${(props) =>
    props.selected &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        color: ${(props) => props.theme.colors.whiteCreamy};
        background: ${(props) => props.theme.colors.mineShaftDarker};
      }
    `}

  ${(props) =>
    props.checked &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        color: ${(props) => props.theme.colors.mineShaftDarker};
        background: ${(props) => props.theme.colors.whiteCreamy};
      }
    `}
`;

const NavLink = ({ item, num }) => {
  const sizeCheck = 15;
  const styledTheme = useContext(ThemeContext);
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <StyledNavLink
      type="button"
      selected={item.selected}
      checked={item.checked}
      disabled={item.disabled}
    >
      <Link
        to={item.disabled ? '#' : item.url}
        style={
          item.disabled ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }
        }
      >
        {item.checked && !xsDown && (
          <Icon
            color={styledTheme.colors.golden}
            size={sizeCheck}
            icon="ico-check"
          />
        )}
        {!xsDown && (
          <NumberSpan
            selected={item.selected}
            checked={item.checked}
            disabled={item.disabled}
          >
            {num + 1}
          </NumberSpan>
        )}
        {item.text ? item.text : <Placeholder />}
      </Link>
    </StyledNavLink>
  );
};

export default NavLink;
