import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
// import Icon from '../../ui/Icon';

import { parseNumberLocal, getLocaleFromUrl } from '../../../utils';

interface DiscountTotalPriceProps {
  discount: {
    description: string;
    price: number;
    priceBeauty: string;
  };
  wallet: {
    amount: number;
    walletType: string;
  }[];
  total: string;
  totalWithoutWallet: number;
  promotion: Promotion;
}

const DiscountTotalWrapper = styled.div`
  margin: 4px 16px;
  color: ${(props) => props.theme.colors.keppel};
  font-weight: 400;
  font-size: 0.875rem;
`;

const WalletAmount = styled.div`
  color: ${(props) => props.theme.colors.keppel};
`;

const Total = styled.div`
  font-weight: 600;
  font-size: 1rem;
`;

const PromoWalletTotalTitle = styled.div`
  display: flex;
  align-items: center;
`;

function DiscountTotalPrice({
  discount,
  total,
  wallet,
  totalWithoutWallet,
  promotion,
}: DiscountTotalPriceProps) {
  const styledTheme = useContext(ThemeContext);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const promoWallet = wallet.filter(
    (item) => item.walletType === 'promo_in_wallet',
  );

  const promoWalletTotal = promoWallet.reduce(
    (acc, currentValue) => currentValue.amount + acc,
    0,
  );

  const walletTotal = wallet
    .filter((item) => item.walletType !== 'promo_in_wallet')
    .reduce((acc, currentValue) => currentValue.amount + acc, 0);

  const walletTotalBeauty: string = walletTotal
    ? parseNumberLocal(
        walletTotal + promoWalletTotal > totalWithoutWallet
          ? -totalWithoutWallet + promoWalletTotal
          : -walletTotal,
      )
    : '';

  const discountTotalBeauty: string = discount?.price
    ? parseNumberLocal(discount.price)
    : '';

  let promoGif = '';
  try {
    promoGif = require(`../../../assets/images/${promotion?.styling?.checkout?.icon}.gif`);
  } catch {
    // console.log('no promotion backgroundImage found');
  }

  return (
    <DiscountTotalWrapper>
      {walletTotal > 0 && (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={8}>
            {t(
              'checkout@summary@breakdown@freeCancelationTitlte',
              'Exoticca voucher from Premium care free cancellation',
            )}
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <WalletAmount>
              <div dangerouslySetInnerHTML={{ __html: walletTotalBeauty }} />
            </WalletAmount>
          </Grid>
        </Grid>
      )}
      {promoWalletTotal > 0 && (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={8}>
            <PromoWalletTotalTitle>
              {promotion?.styling?.checkout?.icon && (
                <img src={promoGif} width={22} alt="" />
              )}
              {t(
                'checkout@summary@breakdown@promoInWalletTitle',
                'BLACK FRIDAY discount',
              )}
            </PromoWalletTotalTitle>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <WalletAmount>
              <div
                dangerouslySetInnerHTML={{
                  __html: parseNumberLocal(-promoWalletTotal),
                }}
              />
            </WalletAmount>
          </Grid>
        </Grid>
      )}
      {discount.priceBeauty && (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={8}>
            {discount.description}
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <div dangerouslySetInnerHTML={{ __html: discountTotalBeauty }} />
          </Grid>
        </Grid>
      )}
      <Total>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{
            marginTop: '8px',
            borderTop: `1px dashed ${styledTheme.colors.ferraGrayDark18}`,
            color: styledTheme.colors.mineShaftDarker,
            textTransform: 'uppercase',
          }}
        >
          <Grid item xs={8}>
            {t('checkout@total')}
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <div dangerouslySetInnerHTML={{ __html: total }} />
          </Grid>
        </Grid>
      </Total>
    </DiscountTotalWrapper>
  );
}

export default DiscountTotalPrice;
