import { ActionType } from '../reducers/ticket';
import { api } from '../config';
import mockData from '../mocks/ticketPdf.json';

export const setTicketData = (orderId) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockData;

    const rand = Math.random() * 10000000000000000;
    const url = `${api.ticket.get}/${orderId}?rand=${rand}`;
    const method = 'get';

    const type: ActionType = 'SET_TICKET_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_TICKET_DATA',
            success: 'RECEIVED_TICKET_DATA',
            fail: 'ERROR_TICKET_DATA',
          },
          url,
          mockResult,
          method,
        },
      }),
    );
  };
};
