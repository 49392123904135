import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import TagManager from 'react-gtm-module';
import { getDefaultTagManagerData } from '../../../utils/tagManager';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';
import Icon from '../../ui/Icon';
import PriceIncludes from './PriceIncludes';
import { isLoggedIn } from '../../../utils';

interface TravelIncludedProps {
  breakdown: any;
  includes: any;
  product: any;
  offer: any;
  handleNotification?(): void;
  showLead: boolean;
  promotionColor?: string;
}

const TravelBreakDownContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: ${(props) => props.theme.colors.linkWater};
`;

const BudgetBox = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  padding: 4px;
  margin: 8px;
  justify-content: center;
  font-size: 0.938rem;
  color: ${(props) => props.theme.colors.chathamsBlue};
  border: 1px solid ${(props) => props.theme.colors.chathamsBlue};
  border-radius: 4px;
  cursor: pointer;
  span {
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 0.938rem;
    font-weight: 500;
    line-height: 1.07;
    letter-spacing: 0.37px;
  }
`;

const TravelIncludedHeadButton = styled.div`
  height: 66px;
  width: 50%;
  padding: 0 16px;
  font-size: 0.938rem;
  color: ${(props) => props.theme.colors.chathamsBlue};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
  span {
    font-family: ${(props) => props.theme.fontFamily};
    font-weight: 500;
    font-size: 0.938rem;
    line-height: 1.33;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.37px;
    text-decoration: underline;
  }
`;

function TravelIncluded(props: TravelIncludedProps) {
  const { product, offer, includes, handleNotification, showLead } = props;

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const onClickHandler = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'gaEvent',
        eventCategory: 'budget',
        eventAction: 'send_budget_clicked',
        eventLabel: product.id,
        ...getDefaultTagManagerData(),
      },
    });
    return handleNotification && handleNotification();
  };

  return (
    <TravelBreakDownContainer>
      <TravelIncludedHeadButton>
        <PriceIncludes product={product} offer={offer} includes={includes} />
      </TravelIncludedHeadButton>
      {isLoggedIn() && showLead && (
        <BudgetBox onClick={onClickHandler}>
          <span>{t('sendBudget')}</span>
          <Icon
            icon={'budget'}
            size={29}
            customStyle={{ paddingLeft: '4px' }}
          />
        </BudgetBox>
      )}
    </TravelBreakDownContainer>
  );
}

TravelIncluded.defaultProps = {
  showLead: true,
};

export default TravelIncluded;
