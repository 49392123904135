import React from 'react';
import styled from 'styled-components/macro';
import posed from 'react-pose';

interface AccordianProps {
  isVisible: boolean;
  children: any;
  wrapped?: boolean;
  customStyle?: any;
}

const Dropdown = posed.div({
  up: {
    height: 0,
  },
  down: {
    height: 'auto',
    delayChildren: 100,
  },
});

const AccordianWrapper = styled(Dropdown)`
  overflow: hidden;
`;

const BringUp = posed.div({
  down: { y: 0, opacity: 1 },
  up: { y: 10, opacity: 0 },
});

const Content = styled(BringUp)<AccordianProps>`
  ${(props) =>
    props.wrapped &&
    `
      padding: 5px 20px;`}
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  word-break: break-word;
`;

const Accordian = (props: AccordianProps) => {
  const { isVisible, children, customStyle } = props;

  return (
    <AccordianWrapper style={customStyle} pose={isVisible ? 'down' : 'up'}>
      <Content {...props}>{children}</Content>
    </AccordianWrapper>
  );
};

Accordian.defaultProps = {
  wrapped: false,
};

export default Accordian;
