import React, { Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom';

import Header from '../containers/Header';
import Footer from '../containers/Footer';
import CookieBanner from '../containers/CookieBanner';
import AuthDialog from '../components/authentication/AuthDialog';
import LeadDialog from '../components/leadLightbox/LeadDialog';

type Props = {
  children: any;
};

function MainLayout(props: Props) {
  const { children } = props;

  let confirmationPage = useRouteMatch(
    '/(reserva|booking|reservation|reservierung)/:path?',
  );

  return (
    <Fragment>
      <Header isCheckout={confirmationPage} />
      <div className="content-wrapper">{children}</div>
      <Footer />
      <AuthDialog />
      <LeadDialog />
      <CookieBanner />
    </Fragment>
  );
}

export default MainLayout;
