const initialCategoryState = {
  isCookieVisible: true,
};
const cookies = (state = initialCategoryState, action) => {
  const { data, type } = action;

  switch (type) {
    case 'SET_COOKIE_DATA':
      return { ...state, ...data };
    default:
      return state;
  }
};

export default cookies;
