export type ActionType =
  | 'SET_CONFIG_DATA'
  | 'SET_STATIC_CONFIG_DATA'
  | 'REQUEST_CONFIG_DATA'
  | 'RECEIVED_CONFIG_DATA'
  | 'IS_CACHED_CONFIG_DATA'
  | 'ERROR_CONFIG_DATA';

interface ActionProps {
  type: ActionType;
  isFetching?: boolean;
  data?: {} | any;
  error?: any;
  payload?: {} | any;
}

const initialConfigState = {
  isFetching: true,
  data: {},
  error: {},
};

const Config = (state = initialConfigState, action: Partial<ActionProps>) => {
  switch (action.type) {
    case 'REQUEST_CONFIG_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_CONFIG_DATA':
    case 'IS_CACHED_CONFIG_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: { ...payload },
      };
    }

    case 'SET_STATIC_CONFIG_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        data: { ...state.data, ...payload },
      };
    }

    case 'ERROR_CONFIG_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
};

export default Config;
