import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components/macro';
import posed from 'react-pose';
import { ThemeContext } from 'styled-components';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl, isLoggedIn, hotjarTrigger } from '../../utils';

import Modal from '../ui/Modal';
import Icon from '../ui/Icon';
import Button from '../ui/Button';

interface StyledProps {
  background: string;
}

export interface PromotionModalProps {
  isFlash: boolean;
  isDiscount: boolean;
  styling: PromotionStylingModal;
  pose: string;
  isVisible: boolean;
  onClose(): void;
  isAffiliateDomain?: boolean;
}

const RightLeft = posed.div({
  right: { left: '100vw' },
  left: { left: '0px' },
});

export const Dialog = styled(RightLeft)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.whiteCreamy};
  z-index: 99;
  overflow: scroll;
`;

export const RightIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 1;
`;

const MobileDialog = styled(Dialog)`
  z-index: 9999999;
`;

const ModalHeader = styled.div<{
  flashPromotion: boolean;
  backgroundColor: string;
  backgroundImage: any;
}>`
  background-color: ${(props) => props.backgroundColor};
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: repeat;
  overflow-x: hidden;
  background-position: top;
  display: flex;
  min-height: 72px;
  padding-bottom: 0;
  ${(props) =>
    !props.flashPromotion &&
    css`
      padding: 15px;
      min-height: 115px;
    `}

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    background-size: 10%;
  }
`;

const ModalContentTitles = styled.h3<{ color?: string }>`
  font-size: 2.5rem;
  font-weight: 400;
  margin: 4px 0;
  text-align: center;
  width: 100%;
  color: ${(props) => props.color || props.theme.colors.white};
  span {
    font-weight: 700;
  }
`;

const ModalContentSubtitles = styled.h3<{ color?: string }>`
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  width: 100%;
  color: ${(props) => props.color || props.theme.colors.white};
  span {
    font-weight: 700;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  max-width: 132px;
  color: ${(props) => props.theme.colors.white};
  transform: rotate(-6.5deg);
  margin-left: 20px;
`;

const LabelContent = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
  min-width: 120px;
`;

const LabelTextBig = styled.div`
  margin-left: 10px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.mineShaftDarker};
`;

const LabelTextLittle = styled.div`
  margin-left: 10px;
  font-weight: 400;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.mineShaftDarker};
`;

const ModalHeaderContainer = styled.div<{ flashPromotion: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.flashPromotion ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  padding: 15px 15px 0;
`;

const WhenApply = styled.div<{ color?: string }>`
  font-size: 1rem;
  line-height: 1.313rem;
  text-align: center;
  margin: 10px 0 0 0;
  color: ${(props) => props.color || props.theme.colors.white};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 200px;
  }
`;

const HeaderTitle = styled.h2`
  font-size: 3rem;
  margin: 0;
  font-weight: normal;
  color: ${(props) => props.theme.colors.white};
  text-align: center;

  span {
    font-weight: bold;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 2rem;
    line-height: 1.25;
    letter-spacing: 2.13px;

    span {
      display: inline-block;
      font-size: 2rem;
      line-height: 1.25;
      letter-spacing: 2.13px;
    }
  }
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 30%;

  @media (max-width: 768px) {
    padding: 20px 0;
    width: 90%;
    :nth-child(3) {
      border: none !important;
    }
  }
`;

const HowToContainer = styled.div`
  display: flex;
  margin-top: 16px;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const HowToText = styled.p`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  font-size: 1.188rem;
  line-height: 1.5rem;
  margin: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1.063rem;
    text-align: center;
    line-height: 1.41;
  }
`;

const TitlesContainer = styled.div`
  width: 100%;
  margin: 0 0 16px 0;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 0;
  }
`;

const ModalContent = styled.div<{
  backgroundImage: any;
  backgroundColor: string;
}>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: scroll;
  background-color: ${(props) => props.backgroundColor};
  background-size: 5%;
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: repeat;
  overflow: auto;
`;

const HowToAnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const HowToAnswerText = styled.div`
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1.188rem;
  line-height: 1.26;
  max-width: '100%';
  margin-bottom: 16px;

  span {
    display: block;
    font-weight: 600;
    text-align: center;
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 1.188rem;
    line-height: 1.26;
    margin-bottom: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1.063rem;
    line-height: 1.41;

    span {
      font-size: 1.063rem;
      line-height: 1.41;
    }
  }
`;

const PromotionModalWrapper = styled.div`
  overflow-x: hidden;
`;

const ModalConditions = styled.div`
  font-size: 0.75rem;
  margin: 0;
  padding: 10px 23px;
  background-color: ${(props) => props.theme.colors.discCompact};
  text-align: center;
  color: ${(props) => props.theme.colors.mineShaftDarker};
`;

const TopTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CircleNumber = styled.div<{ backgroundColor: string }>`
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamilyTertiaryMedium};
  font-size: 1.25rem;
  margin-right: 10px;
`;

const TopTitle = styled.h3`
  font-family: ${(props) => props.theme.fontFamilyTertiaryMedium};
  font-size: 1.75rem;
  margin: 0;
`;

const TopContent = styled.p`
  font-size: 1.188rem;
  text-align: center;
  margin-top: 8px;
  max-width: 75%;
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.16;
  letter-spacing: 0.4px;

  span {
    color: ${(props) => props.theme.colors.white};
    font-weight: bold;
  }
`;

const FlashPromoTitle = styled.div<{ color?: string }>`
  font-size: 24px;
  font-weight: 400;
  line-height: 1.26;
  color: ${(props) => props.color};
  margin-top: 18px;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamilyTertiaryMedium};
`;

const FlashPromoDescription = styled(FlashPromoTitle)`
  font-weight: 400;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 19px;
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 523px;
  }
  color: ${(props) => props.color};
  margin: 8px 4px;
`;

const FlashColumnText = styled.div<{ color: string }>`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: bold;
  color: ${(props) => props.color};
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.33px;
  text-align: center;
  text-align: center;
  margin-top: 16px;
  div {
    font-weight: 500;
    color: ${(props) => props.theme.colors.mineShaftDarker};
  }
`;

const Footer = styled.div<{ backgroundColor: string }>`
  padding-top: 16px;
  background-color: ${(props) => props.backgroundColor};
`;

const Image = styled.img<{ width?: string }>`
  width: ${(props) => props.width};
  height: auto;
  vertical-align: bottom;
  margin: 8px 0;
`;

const ValidText = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  span {
    color: ${(props) => props.theme.colors.white};
    text-decoration: line-through;
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 120px;
  margin-top: 10px;
  background-color: ${(props) => props.theme.colors.tundora};
  color: ${(props) => props.theme.colors.white};
  font-size: 1rem;
  line-height: 20px;

  span {
    font-weight: bold;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    font-size: 0.938rem;
    padding: 12px 120px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 16px;
  }
`;

const RegisterLoginTitle = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  font-size: 1rem;
  margin: 0 auto 20px auto;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 90%;
  }
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    containerStyles: {
      padding: '32px 0',
      position: 'relative',
    },
    containerOne: (props) => ({
      color: props.flashColor,
      backgroundColor: props.flashBackgroundColor,
      backgroundImage: `url(${props.backgroundImage})`,
      backgroundRepeat: 'repeat',
      [theme.breakpoints.down('sm')]: {
        border: 'none',
        padding: '10px 0',
      },
    }),
    containerTwo: (props) => ({
      color: props.discountColor,
      backgroundColor: props.backgroundColor,
      backgroundImage: `url(${props.backgroundImage})`,
      backgroundRepeat: 'repeat',
      padding: '32px 0 24px 0',
      [theme.breakpoints.down('sm')]: {
        padding: '48px 0',
      },
    }),
    flexyGrid: {
      display: 'flex',
      flexDirection: 'column',
    },
    flightTitle: (props) => ({
      backgroundColor: props.colorFerra,
      padding: '11px 17px',
      fontSize: '0.938rem',
      display: 'flex',
      alignItems: 'center',
      fontWeight: '500' as 'bold',
    }),
    emptyClass: {},
    columnsFlashPromo: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
    },
  }),
);

const PromotionModal = (props: PromotionModalProps) => {
  const {
    isFlash,
    isVisible,
    isDiscount,
    styling,
    pose,
    onClose,
    isAffiliateDomain,
  } = props;

  let backgroundImage = '';
  try {
    backgroundImage = require(`../../assets/images/${styling.background}`);
  } catch {
    // console.log('no background image found');
  }

  const themeStyled = useContext(ThemeContext);
  const classes = useStyles({
    colorMineShaftDarker: themeStyled.colors.mineShaftDarker,
    colorGolden: themeStyled.colors.golden,
    colorFerra: themeStyled.colors.ferra,
    colorWhite: themeStyled.colors.white,
    colorRegalBlue: themeStyled.colors.regalBlue,
    colorFroly: themeStyled.colors.froly,
    discountColor: styling.discountColor || styling.color,
    flashBackgroundColor:
      styling.flashBackgroundColor || styling.backgroundColor,
    flashColor: styling.flashColor || styling.color,
    backgroundColor: styling.backgroundColor,
    backgroundImage: backgroundImage,
  });

  const userLoggedIn = isLoggedIn();

  useEffect(() => {
    if (isVisible) {
      hotjarTrigger(
        userLoggedIn ? 'promotion_modal' : 'promotion_modal_not_logged_in',
      );
    }
  }, [userLoggedIn, isVisible]);

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  let logoSrc;
  try {
    logoSrc = require(`../../assets/images/logos/${styling.logo}`);
  } catch {
    // console.log('no promotion logo found');
  }

  const content = (
    <PromotionModalWrapper>
      <ModalHeader
        flashPromotion={isFlash}
        backgroundImage={backgroundImage}
        backgroundColor={styling.backgroundColor}
      >
        <ModalHeaderContainer flashPromotion={isFlash}>
          {styling.headerIcon && (
            <Icon
              size={42}
              icon={styling.headerIcon}
              customStyle={{
                margin: '16px',
              }}
            />
          )}
          <HeaderTitle
            dangerouslySetInnerHTML={{
              __html: t('modalHeaderFirstTitle'),
            }}
          />
        </ModalHeaderContainer>
      </ModalHeader>
      <ModalContent
        backgroundImage={backgroundImage}
        backgroundColor={styling.backgroundColor}
      >
        {!userLoggedIn && !isAffiliateDomain && (
          <Container
            className={classes.containerStyles + ' ' + classes.containerOne}
            style={{ paddingTop: '0' }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <RegisterLoginTitle
                  dangerouslySetInnerHTML={{
                    __html: t('modalRegisterLoginTitle'),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{
                  display: 'flex',
                  justifyContent: xsDown ? 'center' : 'flex-end',
                }}
              >
                <Link
                  onClick={onClose}
                  to={{
                    search: window.location.search,
                    hash: '#promo-login',
                  }}
                >
                  <Button
                    text={t('login')}
                    customStyle={{
                      fontFamily: themeStyled.fontFamily,
                      fontSize: '1rem',
                      backgroundColor: themeStyled.colors.white,
                      color: themeStyled.colors.mineShaftDarker,
                      width: '212px',
                      height: '48px',
                      padding: '12px',
                      marginRight: !xsDown ? '5px' : '0',
                      marginBottom: xsDown ? '10px' : '0',
                    }}
                  />
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{
                  display: 'flex',
                  justifyContent: xsDown ? 'center' : 'flex-start',
                }}
              >
                <Link
                  onClick={onClose}
                  to={{
                    search: window.location.search,
                    hash: '#promo-register',
                  }}
                >
                  <Button
                    text={t('Register')}
                    customStyle={{
                      fontFamily: themeStyled.fontFamily,
                      fontSize: '1rem',
                      backgroundColor: themeStyled.colors.white,
                      color: themeStyled.colors.mineShaftDarker,
                      borderColor: themeStyled.colors.white,
                      width: '212px',
                      height: '48px',
                      padding: '12px',
                      marginLeft: !xsDown ? '5px' : '0',
                    }}
                  />
                </Link>
              </Grid>
            </Grid>
          </Container>
        )}
        {isFlash && (
          <Container
            className={classes.containerStyles + ' ' + classes.containerOne}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.flexyGrid}
            >
              <TopTitleWrapper>
                {styling.flashIcon ? (
                  <Icon
                    size={30}
                    icon={styling.flashIcon}
                    color={styling.flashColor}
                    customStyle={{
                      marginRight: '8px',
                    }}
                  />
                ) : (
                  <CircleNumber backgroundColor={styling.backgroundColor}>
                    {'1'}
                  </CircleNumber>
                )}
                <TopTitle
                  dangerouslySetInnerHTML={{ __html: t('firstBoxTitle') }}
                />
              </TopTitleWrapper>
              <TopContent
                dangerouslySetInnerHTML={{ __html: t('firstBoxContent') }}
              />
            </Grid>
            {!xsDown && styling.separatorIcon && (
              <Icon
                size={30}
                icon={styling.separatorIcon}
                customStyle={{
                  position: 'absolute',
                  bottom: '-20px',
                  borderRadius: '50%',
                  left: '47%',
                  border: `5px solid ${themeStyled.colors.white}`,
                  overflowY: 'visible',
                  zIndex: '100',
                  backgroundColor: themeStyled.colors.white,
                }}
              />
            )}
          </Container>
        )}
        <Container
          className={
            isFlash
              ? classes.containerStyles + ' ' + classes.containerTwo
              : classes.emptyClass
          }
          style={{ position: 'relative' }}
        >
          {isFlash && (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.flexyGrid}
            >
              <TopTitleWrapper>
                {styling.discountIcon ? (
                  <Icon
                    size={30}
                    icon={styling.discountIcon}
                    color={styling.discountColor}
                    customStyle={{
                      marginRight: '8px',
                    }}
                  />
                ) : (
                  <CircleNumber backgroundColor={styling.backgroundColor}>
                    {'2'}
                  </CircleNumber>
                )}
                <TopTitle
                  dangerouslySetInnerHTML={{
                    __html: t('secondBoxTitle'),
                  }}
                />
              </TopTitleWrapper>
              <TopContent
                dangerouslySetInnerHTML={{
                  __html: t('secondBoxContent'),
                }}
              />
            </Grid>
          )}
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            {!isFlash && (
              <TitlesContainer>
                <ModalContentTitles
                  color={styling.colorContentTitle}
                  dangerouslySetInnerHTML={{ __html: t('modalContentTitle') }}
                />
                <ModalContentSubtitles
                  dangerouslySetInnerHTML={{
                    __html: t('modalContentSubTitle'),
                  }}
                />
              </TitlesContainer>
            )}
            {t('modalHowToQuestion') !== 'modalHowToQuestion' && (
              <HowToContainer>
                {!isFlash && styling.arrowRight && (
                  <Icon
                    icon={styling.arrowRight}
                    size={50}
                    color={themeStyled.colors.regalBlue}
                    customStyle={{
                      margin: '0 16px 0 24px',
                      zIndex: 2,
                    }}
                  />
                )}
                <HowToText>{t('modalHowToQuestion')}</HowToText>
                {!isFlash && styling.arrowLeft && (
                  <Icon
                    icon={styling.arrowLeft}
                    size={50}
                    color={themeStyled.colors.regalBlue}
                    customStyle={{
                      margin: '0 24px 0 16px',
                      zIndex: 2,
                    }}
                  />
                )}
              </HowToContainer>
            )}
            <HowToAnswerContainer>
              {t('modalHowToAnswer1') !== 'modalHowToAnswer1' && (
                <HowToAnswerText>
                  {t('modalHowToAnswer1') + ' '}
                </HowToAnswerText>
              )}
            </HowToAnswerContainer>
            <CardsContainer>
              <Card>
                <LabelWrapper style={{ height: '57px' }}>
                  <LabelContent
                    background={
                      isFlash
                        ? themeStyled.colors.mineShaftDarker
                        : styling.color
                    }
                  >
                    <LabelTextBig style={{ fontSize: '1.25rem' }}>
                      {t('modalDiscountAmount1')}
                    </LabelTextBig>
                    <LabelTextLittle style={{ fontSize: '0.75rem' }}>
                      {t('modalLabel')}
                    </LabelTextLittle>
                  </LabelContent>
                  <Icon
                    color={
                      isFlash
                        ? themeStyled.colors.mineShaftDarker
                        : styling.color
                    }
                    icon={
                      styling.tagDiscountArrow
                        ? `${styling.tagDiscountArrow}-1`
                        : 'tagDiscountArrow-1'
                    }
                    iconSet="promos"
                    size={60}
                    customStyle={{
                      marginLeft: '-20px',
                      transform: 'rotate(4.5deg)',
                      marginTop: '-1px',
                    }}
                  />
                </LabelWrapper>
                <WhenApply
                  color={styling.color}
                  dangerouslySetInnerHTML={{
                    __html: t('modalLabel1Copy'),
                  }}
                />
              </Card>
              <Card>
                <LabelWrapper>
                  <LabelContent
                    background={
                      isFlash
                        ? themeStyled.colors.mineShaftDarker
                        : styling.spanColor
                    }
                  >
                    <LabelTextBig style={{ fontSize: '1.5rem' }}>
                      {t('modalDiscountAmount2')}
                    </LabelTextBig>
                    <LabelTextLittle style={{ fontSize: '0.875rem' }}>
                      {t('modalLabel')}
                    </LabelTextLittle>
                  </LabelContent>
                  <Icon
                    color={
                      isFlash
                        ? themeStyled.colors.mineShaftDarker
                        : styling.spanColor
                    }
                    icon={
                      styling.tagDiscountArrow
                        ? `${styling.tagDiscountArrow}-2`
                        : 'tagDiscountArrow-2'
                    }
                    iconSet="promos"
                    size={70}
                    customStyle={{
                      marginLeft: '-24px',
                      transform: 'rotate(4.5deg)',
                      marginTop: '-1px',
                    }}
                  />
                </LabelWrapper>
                <WhenApply
                  color={styling.spanColor}
                  dangerouslySetInnerHTML={{
                    __html: t('modalLabel2Copy'),
                  }}
                />
              </Card>
              <Card>
                <LabelWrapper>
                  <LabelContent
                    background={
                      isFlash
                        ? themeStyled.colors.mineShaftDarker
                        : styling.codeColor
                    }
                  >
                    <LabelTextBig style={{ fontSize: '1.875rem' }}>
                      {t('modalDiscountAmount3')}
                    </LabelTextBig>
                    <LabelTextLittle style={{ fontSize: '1rem' }}>
                      {t('modalLabel')}
                    </LabelTextLittle>
                  </LabelContent>
                  <Icon
                    color={styling.codeColor}
                    icon={
                      styling.tagDiscountArrow
                        ? `${styling.tagDiscountArrow}-3`
                        : 'tagDiscountArrow-3'
                    }
                    iconSet="promos"
                    size={80}
                    customStyle={{
                      marginLeft: '-27px',
                      transform: 'rotate(4.5deg)',
                      marginTop: '-1px',
                    }}
                  />
                </LabelWrapper>
                <WhenApply
                  color={styling.codeColor}
                  dangerouslySetInnerHTML={{
                    __html: t('modalLabel3Copy'),
                  }}
                />
              </Card>
            </CardsContainer>
            <HowToContainer>
              {!isFlash && styling.arrowRight && (
                <Icon
                  icon={styling.arrowRight}
                  size={50}
                  color={themeStyled.colors.regalBlue}
                  customStyle={{
                    margin: '0 16px 0 24px',
                    zIndex: 2,
                  }}
                />
              )}
              {!isFlash && styling.arrowLeft && (
                <Icon
                  icon={styling.arrowLeft}
                  size={50}
                  color={themeStyled.colors.regalBlue}
                  customStyle={{
                    margin: '0 24px 0 16px',
                    zIndex: 2,
                  }}
                />
              )}
            </HowToContainer>
            <HowToAnswerContainer>
              <ValidText
                dangerouslySetInnerHTML={{
                  __html: t('modalValidText') + ' ',
                }}
              />
            </HowToAnswerContainer>
          </Grid>
        </Container>
        <Description style={{ position: 'relative' }}>
          {styling.columnIconOne && (
            <Icon
              size={styling.columnIconSize}
              icon={styling.columnIconOne}
              iconSet={'promos'}
              customStyle={{ marginRight: '8px' }}
            />
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: t('modalDescription'),
            }}
          />
        </Description>
        <ModalConditions style={{ position: 'relative' }}>
          {t('modalConditions')}
        </ModalConditions>
        {isDiscount &&
          styling.color &&
          styling.columnTitleColor &&
          styling.buttonBackgroundColor && (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <FlashPromoTitle color={styling.columnTitleColor}>
                {t('promoModalTitle')}
              </FlashPromoTitle>
              <FlashPromoDescription
                color={styling.descriptionColor}
                dangerouslySetInnerHTML={{
                  __html: t('promoModalDescription'),
                }}
              />
              {t('ctaText') !== 'ctaText' && (
                <Link to={t('ctaUrl') as string} onClick={onClose}>
                  <Button
                    text={t('ctaText')}
                    customStyle={{
                      fontFamily: themeStyled.fontFamily,
                      fontSize: '1rem',
                      borderRadius: '5px',
                      backgroundColor: styling.buttonBackgroundColor,
                      borderColor: styling.buttonBackgroundColor,
                      width: 'unset',
                      height: '53px',
                      marginTop: '36px',
                      padding: '12px 15px',
                    }}
                  />
                </Link>
              )}
              {logoSrc && <Image src={logoSrc} width={styling.logoWidth} />}
              <Grid
                container
                justify="center"
                alignItems="flex-start"
                style={{ marginTop: '16px' }}
              >
                {styling.columnIconOne && styling.columnIconSize && (
                  <Grid
                    item
                    xs={12}
                    sm={
                      styling.columnIconThree
                        ? 4
                        : styling.columnIconTwo
                        ? 6
                        : 12
                    }
                    className={classes.columnsFlashPromo}
                  >
                    <Icon
                      size={styling.columnIconSize}
                      icon={styling.columnIconOne}
                    />
                    <FlashColumnText color={styling.columnTitleColor}>
                      <span>{t('promoModalFirstColumnTitle')}</span>
                      <div>{t('promoModalFirstColumnDescription')}</div>
                    </FlashColumnText>
                  </Grid>
                )}
                {styling.columnIconTwo && styling.columnIconSize && (
                  <Grid
                    item
                    xs={12}
                    sm={styling.columnIconThree ? 4 : 6}
                    className={classes.columnsFlashPromo}
                  >
                    <Icon
                      size={styling.columnIconSize}
                      icon={styling.columnIconTwo}
                    />
                    <FlashColumnText color={styling.columnTitleColor}>
                      <span>{t('promoModalSecondColumnTitle')}</span>
                      <div>{t('promoModalSecondColumnDescription')}</div>
                    </FlashColumnText>
                  </Grid>
                )}
                {styling.columnIconThree && styling.columnIconSize && (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    className={classes.columnsFlashPromo}
                  >
                    <Icon
                      size={styling.columnIconSize}
                      icon={styling.columnIconThree}
                    />
                    <FlashColumnText color={styling.columnTitleColor}>
                      <span>{t('promoModalThirdColumnTitle')}</span>
                      <div>{t('promoModalThirdColumnDescription')}</div>
                    </FlashColumnText>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
      </ModalContent>
      {styling.footerBackgroundColor && (
        <Footer backgroundColor={styling.footerBackgroundColor} />
      )}
    </PromotionModalWrapper>
  );

  if (xsDown) {
    return ReactDOM.createPortal(
      <MobileDialog pose={pose}>
        <RightIcon>
          <Icon
            color={themeStyled.colors.whiteCreamy}
            onClick={onClose}
            size={18}
            icon="ico-close"
          />
        </RightIcon>
        {content}
      </MobileDialog>,
      document.body,
    );
  }

  return (
    <Modal children={content} width={'100vw'} isPromotion={true} {...props} />
  );
};

const mapStateToProps = (state) => {
  return {
    isAffiliateDomain: state.header.data.isAffiliateDomain,
  };
};

export default connect(mapStateToProps, {})(PromotionModal);
