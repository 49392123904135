import { ActionType } from '../reducers/partPayment';
import { api } from '../config';
import getPartPaymentMockData from '../mocks/getPartPayment.json';

export const getPartPaymentData = (methodPay: string, token: string) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = getPartPaymentMockData;
    const url =
      methodPay !== ''
        ? `${api.partPayment.get}/${token}/${methodPay}`
        : `${api.partPayment.get}/order/${token}`;
    const method = 'get';

    const type: ActionType = 'SET_PART_PAYMENT_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_PART_PAYMENT_DATA',
            success: 'RECEIVED_PART_PAYMENT_DATA',
            fail: 'ERROR_PART_PAYMENT_DATA',
          },
          url,
          mockResult,
          method,
        },
      }),
    );
  };
};
