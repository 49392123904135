import React, {
  useEffect,
  useContext,
  useState,
  useLayoutEffect,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components/macro';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import posed from 'react-pose';
import {
  disableLeads,
  enableScroll,
  disableScroll,
  hotjarTrigger,
  getLocaleFromUrl,
} from '../utils';

import { headerActions, promotionActions, authActions } from '../actions';

import Logo from '../components/header/Logo';
import MenuContinents from '../components/header/MenuContinents';
import MenuCountries from '../components/header/MenuCountries';
import CountrySelector from '../components/header/CountrySelector';
import CountrySelectorMobile from '../components/header/CountrySelectorMobile';
import Help from '../components/help/Help';
import Icon from '../components/ui/Icon';
import MyAccount from '../components/header/MyAccount';
import YoBar from '../components/promotion/YoBar';
import PromotionModal from '../components/promotion/PromotionModal';
import CoronaModal from '../components/campaign/CoronaModal';

const ANIMATION_DELAY = 255;

const PosedContent = styled(
  posed.div({
    closed: {
      height: 0,
      width: 0,
      overflow: 'hidden',
      position: 'fixed',
      zIndex: '1100',
      left: '0px',
      right: '0px',
      top: '56px',
      bottom: '0px',
    },
    open: {
      height: '500px',
      width: '100%',
      position: 'fixed',
      zIndex: '1100',
      left: '0px',
      right: '0px',
      top: '56px',
      bottom: '0px',
    },
  }),
)`
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 100% !important;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    height: 100% !important;
  }
`;

type HeaderObject = {
  phone: string;
  scheduleShort: string;
  isCustomerServiceAvailable: boolean;
  menu: any[];
  promotionModeEnabled: boolean;
  isAffiliateDomain: boolean;
  dealOfTheWeek?: string;
};

type Props = {
  country: string;
  header: HeaderObject;
  promotion: Promotion;
  language: string;
  config: any;
  isCheckout?: boolean;
  isPrivate?: boolean;
  getHeaderData(): void;
  getPromotion(): void;
  logout(): void;
  setShowPromoModal: any;
  showPromoModal: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    appBar: (props) => ({
      backgroundColor: props.headerBackground,
      boxShadow: 'none',
    }),
    MuiDrawer: (props) => ({
      backgroundColor: props.headerBackground,
      position: 'relative' as 'relative',
    }),
    toolbar: (props) => ({
      minHeight: 56,
      maxHeight: 56,
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: props.headerBackground,
    }),
  }),
);

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: absolute;
  right: 10px;
  top: 4px;
  cursor: pointer;
`;

const DealOfTheWeek = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 0 0 0 auto;
  height: 56px;
  width: 56px;
  border-radius: 0;
  font-size: 0.875rem;
  line-height: 0.86;
  color: ${(props) => props.theme.colors.putty};

  &:hover {
    background-position: 100% 0;
    background-color: ${(props) => props.theme.colors.mineShaft};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    flex-direction: column;
    font-size: 0.875rem;
    line-height: 0.875rem;
    box-shadow: none;
    max-height: 53px;
    min-height: 53px;
    max-width: 110px;
    text-align: center;
    border-radius: 1px;
    margin: 0;
    height: 40px;
    width: unset;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    background-color: ${(props) => props.theme.colors.golden};
  }
`;

const CoronaPolicy = styled.a`
  display: flex;
  justify-content: center;
  font-size: 0.875rem;
  padding: 6px;
  letter-spacing: 0.7px;
  background-color: ${(props) => props.theme.colors.coronaBackgroundColor};
  color: ${(props) => props.theme.colors.mineShaftDarker}!important;
  box-shadow: 0 1px 6px 0 ${(props) => props.theme.colors.mineShaftDarker};
`;

const handleDisableBodyScroll = (disable) => {
  if (disable) {
    disableScroll();
  } else {
    enableScroll();
  }
};

function Header({
  header: {
    isCustomerServiceAvailable,
    phone,
    menu,
    isAffiliateDomain,
    dealOfTheWeek,
  },
  promotion,
  getHeaderData,
  getPromotion,
  language,
  config,
  isCheckout,
  isPrivate,
  logout,
  setShowPromoModal,
  showPromoModal,
}: Props) {
  const langMatch = useRouteMatch('/(es|fr|de|uk|us|ca)?');
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const themeStyled = useContext(ThemeContext);
  const classes = useStyles({
    headerBackground: themeStyled.colors.headerBackground,
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [openOpenCountrySelector, setOpenCountrySelector] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [continentIndex, setContinent] = useState(-1);

  const isLogin = window.location.pathname.includes(t('/login'));
  const isRegister = window.location.pathname.includes(t('/register'));
  const isRecoverPassword = window.location.pathname.includes(
    t('/retrieve-password'),
  );
  const isAuthentication = isLogin || isRegister || isRecoverPassword;

  const showHome: boolean = config?.options?.home;

  const modalToggleButton = document.getElementById('modalToggleButton');

  const [yobarHeight, setYobarHeight] = useState(0);

  const anchorRef = useRef(null);

  const handlerDrawer = (newContinentIndex: number) => {
    /* Old school show/hide continents using DOM elements for SEO reasons */
    var continentsEl = document.querySelectorAll('.continents') as NodeListOf<
      HTMLElement
    >;
    [].forEach.call(continentsEl, (div: HTMLElement) => {
      div.style.display = 'none';
    });
    const continentElement: HTMLInputElement = document.getElementById(
      `continent-${newContinentIndex}`,
    ) as HTMLInputElement;
    continentElement.style.display = 'block';

    if (continentIndex === newContinentIndex || !openDrawer)
      setOpenDrawer(!openDrawer);
    if (continentIndex === newContinentIndex)
      setTimeout(() => {
        setContinent(-1);
      }, ANIMATION_DELAY);
    else setContinent(newContinentIndex);
    setOpenHelp(false);
    setOpenAccount(false);
    setOpenCountrySelector(false);

    const breakpoint = lgUp ? 'desktop' : smUp ? 'tablet' : 'mobile';
    if (openDrawer) {
      if (newContinentIndex !== -1) {
        hotjarTrigger(`open_${breakpoint}_continent_menu`);
      } else {
        hotjarTrigger(`open_${breakpoint}_menu`);
      }
    }
  };

  const handlerHelp = (state: boolean) => {
    setOpenHelp(state);
    setOpenDrawer(false);
    setOpenAccount(false);
    setOpenCountrySelector(false);
  };

  const handlerCountrySelector = (state: boolean) => {
    setOpenCountrySelector(state);
    setOpenDrawer(false);
    setOpenAccount(false);
    setOpenHelp(false);
  };

  const handlerAccount = (state: boolean) => {
    setOpenAccount(state);
    setOpenHelp(false);
    setOpenDrawer(false);
    setOpenCountrySelector(false);
  };

  const togglePromotionModal = () => {
    setShowPromoModal(!showPromoModal);
    handleDisableBodyScroll(!showPromoModal);
  };

  const [showCoronaModal, setShowCoronaModal] = useState(() => false);
  const toggleCoronaModal = () => {
    setShowCoronaModal(!showCoronaModal);
    handleDisableBodyScroll(!showCoronaModal);
  };

  useEffect(() => {
    getHeaderData();
    getPromotion();
  }, [getHeaderData, getPromotion]);

  const headerHeight = document.getElementsByTagName('header')[0]?.clientHeight;
  useEffect(() => {
    const contentWrapper = document.getElementsByClassName(
      'content-wrapper',
    ) as HTMLCollectionOf<HTMLDivElement>;

    if (contentWrapper && contentWrapper.length > 0) {
      contentWrapper[0].style['margin-top'] = `${headerHeight}px`;
    }
  }, [menu, promotion, headerHeight]);

  useLayoutEffect(() => {
    modalToggleButton &&
      modalToggleButton.addEventListener('click', () => {
        setShowPromoModal(!showPromoModal);
      });

    return () => {
      modalToggleButton &&
        modalToggleButton.removeEventListener('click', () => {
          setShowPromoModal(!showPromoModal);
        });
    };
  });

  const promotionEnabled = promotion.enabled;

  const yoBarHeight = document.getElementById('yobar')?.clientHeight;

  return (
    <>
      {openDrawer ? (
        <div
          style={{
            position: 'fixed',
            zIndex: 0,
            left: 0,
            right: 0,
            top: promotionEnabled ? yobarHeight : '53px',
            bottom: 0,
          }}
          onClick={() => {
            setOpenDrawer(false);
            setTimeout(() => {
              setContinent(-1);
            }, ANIMATION_DELAY);
          }}
        />
      ) : (
        <></>
      )}

      <AppBar ref={anchorRef} className={classes.appBar}>
        <Toolbar
          className={classes.toolbar}
          disableGutters={lgUp ? true : false}
          id={'innerHeader'}
        >
          {!isCheckout && !isAffiliateDomain && (
            <Hidden only={['lg', 'xl']}>
              <Icon
                icon={!openDrawer ? 'header-menu' : 'Header-X-close-thiner'}
                color={themeStyled.colors.white}
                size={!openDrawer ? 32 : 22}
                onClick={() => {
                  setOpenDrawer(!openDrawer);
                  setContinent(-1);
                  setOpenHelp(false);
                  setOpenAccount(false);
                  disableLeads();
                }}
                customStyle={{
                  margin: openDrawer ? '0px 21px 0 5px' : '2px 16px 0 0',
                  cursor: 'pointer',
                }}
              />
            </Hidden>
          )}
          {!isCheckout && showHome && !isAffiliateDomain ? (
            <a href={langMatch?.params[0] ? `/${langMatch.params[0]}` : '/'}>
              <Logo
                language={language}
                affiliateId={config && config?.affiliate?.id.toString()}
                dealOfTheWeek={dealOfTheWeek}
                promotion={promotionEnabled}
              />
            </a>
          ) : (
            <Logo
              language={language}
              affiliateId={config && config?.affiliate?.id.toString()}
              dealOfTheWeek={dealOfTheWeek}
              promotion={promotionEnabled}
            />
          )}
          {menu &&
            menu.length > 0 &&
            !isCheckout &&
            !isAffiliateDomain &&
            lgUp && (
              <MenuContinents
                continentIndex={continentIndex}
                menu={menu}
                handlerDrawer={handlerDrawer}
                closeMenu={() => setOpenDrawer(false)}
              />
            )}
          {!isCheckout && dealOfTheWeek && (
            <DealOfTheWeek to={dealOfTheWeek}>
              <Icon
                icon={'header-promo'}
                color={
                  smDown ? themeStyled.colors.white : themeStyled.colors.putty
                }
                size={lgUp ? 15 : 36}
                customStyle={{
                  marginBottom: lgUp ? '4px' : '',
                }}
              />
              {lgUp && t('Deal of the week')}
            </DealOfTheWeek>
          )}
          <Help
            isCustomerServiceAvailable={isCustomerServiceAvailable}
            phone={phone}
            handlerHelp={handlerHelp}
            open={openHelp}
            promotionModeEnabled={promotionEnabled}
            yobarHeight={yobarHeight}
            isCheckout={isCheckout}
            alignRight={!dealOfTheWeek}
            toggleCoronaModal={toggleCoronaModal}
          />
          {lgUp && !isCheckout && !isAffiliateDomain && (
            <>
              <CountrySelector
                handlerCountrySelector={handlerCountrySelector}
                open={openOpenCountrySelector}
                promotionModeEnabled={promotionEnabled}
                yobarHeight={yobarHeight}
              />
              <MyAccount
                customerLoggedName={config.customer && config.customer.name}
                handlerAccount={handlerAccount}
                open={openAccount}
                promotionModeEnabled={promotionEnabled}
                yobarHeight={yobarHeight}
                lang={langMatch?.params[0]}
                isPrivate={isPrivate}
                logout={logout}
              />
            </>
          )}
        </Toolbar>
        {promotionEnabled && (
          <>
            <YoBar
              isFlash={promotion.flash}
              isDiscount={promotion.discount}
              toggleModal={togglePromotionModal}
              styling={promotion.styling.yoBar}
              setYobarHeight={setYobarHeight}
              isAffiliateDomain={isAffiliateDomain}
            />
            <PromotionModal
              styling={promotion.styling.modal}
              isFlash={promotion.flash}
              isDiscount={promotion.discount}
              pose={showPromoModal ? 'left' : 'right'}
              isVisible={showPromoModal}
              onClose={togglePromotionModal}
            />
          </>
        )}
        {!promotionEnabled &&
          !isCheckout &&
          !isAuthentication &&
          window.location.pathname !== t('linkFAQ') && (
            <CoronaPolicy href={`${t('linkFAQ')}#1`} target={'_blank'}>
              {t('coronaPolicy')}
              <Icon
                icon={'iconsright-arrow'}
                size={8}
                color={themeStyled.colors.mineShaftDarker}
                customStyle={{ marginLeft: '8px' }}
              />
            </CoronaPolicy>
          )}
      </AppBar>
      {!isCheckout && !isAffiliateDomain && (
        <PosedContent pose={openDrawer ? 'open' : 'closed'}>
          <Drawer
            className={classes.MuiDrawer}
            variant={'persistent'}
            anchor={lgUp ? 'top' : 'left'}
            open={openDrawer}
            PaperProps={
              lgUp
                ? {
                    style: {
                      position: 'absolute',
                      maxHeight: 'unset',
                      boxShadow: '0 0 8px rgba(0, 0, 0, 0.65)',
                      backgroundColor: themeStyled.colors.drawerBg,
                      borderBottom: `5px solid ${themeStyled.colors.golden}`,
                      padding: lgUp ? 'unset' : '0 20px',
                    },
                  }
                : {
                    style: {
                      backgroundColor: themeStyled.colors.headerBackground,
                      width: '100%',
                      maxWidth: '414px',
                      padding: '0 18px',
                      top: yoBarHeight ? `${55 + yoBarHeight}px` : '55px',
                      height: yoBarHeight
                        ? `calc(100% - ${55 + yoBarHeight}px)`
                        : 'calc(100% - 55px)',
                      display: 'block',
                      boxShadow: 'inset 0 7px 9px -7px rgba(0,0,0,0.4)',
                    },
                  }
            }
            transitionDuration={lgUp ? 0 : ANIMATION_DELAY}
          >
            <Hidden mdDown>
              <Container maxWidth={'lg'} style={{ position: 'relative' }}>
                <Grid container>
                  <Grid item>
                    <CloseButton
                      onClick={() => {
                        setOpenDrawer(false);
                        setTimeout(() => {
                          setContinent(-1);
                        }, ANIMATION_DELAY);
                      }}
                    >
                      <Icon
                        icon={'ico-close'}
                        color={themeStyled.colors.mineShaftDarker}
                        size={13}
                        customStyle={{
                          cursor: 'pointer',
                          margin: '0 9px 3px 0',
                        }}
                      />
                      <span>{t('close')}</span>
                    </CloseButton>
                  </Grid>
                </Grid>
              </Container>
            </Hidden>
            {lgUp ? (
              <>
                {menu &&
                  menu.length > 0 &&
                  menu.map((item, index) => (
                    <MenuCountries
                      key={`${item.id}`}
                      menu={menu}
                      continentIndex={index}
                      closeMenu={() => {
                        setOpenDrawer(false);
                        setContinent(-1);
                      }}
                    />
                  ))}
              </>
            ) : (
              <>
                {menu && menu.length > 0 && (
                  <MenuContinents
                    continentIndex={continentIndex}
                    menu={menu}
                    setContinent={setContinent}
                    closeMenu={() => setOpenDrawer(false)}
                  />
                )}
                <MyAccount
                  customerLoggedName={config.customer && config.customer.name}
                  handlerAccount={handlerAccount}
                  yobarHeight={yobarHeight}
                  lang={langMatch?.params[0]}
                  isPrivate={isPrivate}
                  mobile
                  open={openAccount}
                  logout={logout}
                />
                <CountrySelectorMobile />
              </>
            )}
          </Drawer>
        </PosedContent>
      )}
      <CoronaModal
        pose={showCoronaModal ? 'left' : 'right'}
        isVisible={showCoronaModal}
        onClose={toggleCoronaModal}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    language:
      state.config?.data?.language?.length > 0 &&
      state.config?.data?.language[0].locale,
    country: state.config?.data?.market,
    header: state.header.data,
    promotion: state.promotion.data,
    showPromoModal: state.promotion.showModal,
    config: state.config.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getHeaderData: () => dispatch(headerActions.getHeaderData()),
  getPromotion: () => dispatch(promotionActions.getPromotion()),
  logout: () => dispatch(authActions.logout()),
  setShowPromoModal: (status) =>
    dispatch(promotionActions.setShowPromoModal(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
