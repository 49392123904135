import { ActionType } from '../reducers/confirmation';
import { api } from '../config';
import mockData from '../mocks/confirmation.json';

export const setOrderConfirmData = (orderId) => {
  return (dispatch) => {
    const shouldFetchMock = false;
    const fetchType = shouldFetchMock ? 'FETCH_MOCK' : 'FETCH';
    const mockResult = mockData;

    const url = `${api.orderConfirm}/${orderId}`;
    const method = 'get';

    const type: ActionType = 'SET_ORDER_CONFIRMATION_DATA';
    return Promise.resolve(
      dispatch({
        type,
        fetch: {
          type: fetchType,
          actionTypes: {
            request: 'REQUEST_ORDER_CONFIRMATION_DATA',
            success: 'RECEIVED_ORDER_CONFIRMATION_DATA',
            fail: 'ERROR_ORDER_CONFIRMATION_DATA',
          },
          url,
          // props only used when type = FETCH_MOCK:
          mockResult,
          method,
        },
      }),
    );
  };
};
