import React, { useContext, Fragment, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import {
  getLocaleFromUrl,
  getResponseTapClassName,
  getDedicatedPhoneNumber,
} from '../../../utils';
import { LayoutContext } from '../../../context/LayoutContext';
import Icon from '../../ui/Icon';
import { MoreInfoButton } from '../buttons/MoreInfoButton';
import FlightInfoDialog from './FlightInfoDialog';

interface FlightDetailsProps {
  flight;
  small?: boolean;
  market: string;
  departureCityIata?: string;
  phone: string;
}

interface StyledProps {
  layout: string;
  small?: boolean;
  minimalFlight?: boolean;
}

interface GrayDashProps {
  layout: string;
}

export const Flights = styled.div<StyledProps>`
  color: ${(props) => props.theme.colors.mineShaft};
  padding: ${(props) =>
    !props.small && props.minimalFlight ? '8px 0' : '8px 0 0'};
  margin: ${(props) => (props.minimalFlight ? '1px' : '0px 8px 8px')};
  font-size: 0.875rem;
  display: flex;
  flex-wrap: wrap;
  ${(props) =>
    props.layout === 'tablet' &&
    !props.small &&
    css`
      margin: 0 15px;
    `}
`;

export const SelectedFlights = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  span {
    margin-left: 10px;
  }
`;

export const Airports = styled.div<StyledProps>`
  font-size: 0.875rem;
  line-height: 1.063rem;
  font-weight: 600;
  color: ${(props) =>
    props.layout === 'desktop'
      ? props.theme.colors.mineShaftDarker
      : props.theme.colors.cafeRoyale};

  ${(props) =>
    props.layout === 'desktop' &&
    css`
      display: flex;
      align-items: center;
    `}

  ${(props) =>
    props.layout === 'mobile' &&
    css`
      display: flex;
      align-items: flex-end;
    `}
`;

const AirportDepartures = styled.div`
  display: inline-block;
`;

export const Flight = styled.div`
  color: ${(props) => props.theme.colors.grayDark};
  margin-top: 12px;
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Airport = styled.span`
  color: ${(props) => props.theme.colors.golden};
  margin: 2px 0 0 5px;
  font-size: 0.875rem;
`;

export const AirLine = styled.div`
  margin-left: auto;
`;

export const Timming = styled.div`
  font-size: 0.875rem;
  margin-top: 5px;
  color: ${(props) => props.theme.colors.mineShaft};
  flex: 1 1 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    font-weight: 600;
  }
`;

export const GreyDash = styled.span<GrayDashProps>`
  background-color: ${(props) => props.theme.secondaryColors.darkGrey};
  margin: 0 15px;
  height: 2px;
  display: inline-block;
  width: inherit;
  flex: 1;
`;

const FlightInformationTitle = styled.div`
  font-size: 0.938rem;
  line-height: 1.21;
  font-weight: 600;
  color: ${(props) => props.theme.colors.mineShaftDarker};
`;

const FlightInfoTitle = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme.colors.mineShaftDarker};
`;

const FlightInformation = styled(FlightInfoTitle)`
  line-height: 1.21;
`;

const FlightDate = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.87;
  letter-spacing: -0.61px;
  color: ${(props) => props.theme.colors.golden};
`;

const BaggagetypeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
`;

const Baggagetype = styled.div`
  width: 100%;
  font-size: 0.813rem;
  line-height: 1;
  padding: 6px;
  color: ${(props) => props.theme.colors.mineShaftDarker};
  background-color: ${(props) => props.theme.colors.gallery};
  border-radius: 12px;
  margin: 0 5px 5px 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const BaggageInfo = styled.div`
  width: 100%;
  font-size: 0.625rem;
  font-weight: 500;
`;

function FlightDetails(props: FlightDetailsProps) {
  const { flight, market, small, departureCityIata, phone } = props;

  const theme = useContext(ThemeContext);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);
  const layout = useContext(LayoutContext);

  const [showDialog, setShowDialog] = React.useState(false);

  const dedicatedPhoneNumber = getDedicatedPhoneNumber();
  const responseTapClassName = dedicatedPhoneNumber
    ? ''
    : getResponseTapClassName();

  if (
    !(
      Array.isArray(flight?.departure?.segments) &&
      flight.departure.segments.length > 0 &&
      flight.departure.segments[0].Ddate &&
      flight.departure.segments[0].Adate &&
      Array.isArray(flight?.return?.segments) &&
      flight.return.segments.length > 0 &&
      flight.return.segments[0].Ddate &&
      flight.return.segments[0].Adate
    )
  ) {
    return <></>;
  }

  const withFlighSimulator = flight.hasFlight;
  // TODO read minimal flight from flight - flight.minimalFlight;
  const minimalFlight = true;

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const getMoreInfo = () => {
    if (!minimalFlight && layout === 'tablet') {
      return (
        <>
          <MoreInfoButton type="button" onClick={toggleDialog}>
            {t('plusInfo')}
          </MoreInfoButton>
          <FlightInfoDialog
            flight={flight}
            show={showDialog}
            toggle={toggleDialog}
          />
        </>
      );
    }

    const baggageRegexEv = flight.baggage.match(/(\d+)(PC|K)/);
    let numberBaggage: number = 0;
    if (baggageRegexEv) {
      if (baggageRegexEv?.length > 1) {
        if (baggageRegexEv[2] === 'PC') {
          numberBaggage = parseInt(baggageRegexEv[1]);
        } else if (baggageRegexEv[2] === 'K') {
          numberBaggage = parseInt(baggageRegexEv[1]) > 0 ? 1 : 0;
        }
      }
    }

    const departureDateMomentObject = moment(flight?.departure?.date);
    const returnDateMomentObject = moment(flight?.return?.date);

    const dateFormat =
      market.toLocaleLowerCase() === 'us' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';

    const departureDate = departureDateMomentObject.format(dateFormat);
    const returnDate = returnDateMomentObject.format(dateFormat);

    const departureDateHour = departureDateMomentObject.hour();
    const returnDateHour = departureDateMomentObject.hour();

    return (
      <>
        {!minimalFlight && layout === 'mobile' && (
          <MoreInfoButton type="button" onClick={toggleDialog} toRight>
            {t('plusInfo')}
          </MoreInfoButton>
        )}
        {minimalFlight && (
          <Container style={{ paddingLeft: '8px', paddingRight: '8px' }}>
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Grid item xs={12}>
                <FlightInformationTitle>
                  {t('checkout@flights')}
                </FlightInformationTitle>
                <FlightInformation>
                  {t('flightInformation').replace('#TOKEN#', flight.scales)}
                </FlightInformation>
                {minimalFlight && (
                  <FlightInformation>
                    {t('flightReassurance')}
                  </FlightInformation>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              style={{
                paddingTop: '8px',
                marginTop: '8px',
                borderTop: `1px dashed ${theme.colors.ferraGrayDark18}`,
              }}
            >
              <Grid item xs={4}>
                <FlightDate>{departureDate}</FlightDate>
              </Grid>
              <Grid item xs={8}>
                <Airports layout={layout}>
                  <AirportDepartures>
                    {flight.departure.segments[0].DairportCity}{' '}
                    <strong>({departureCityIata})</strong>
                    {' - '}
                    {!minimalFlight && layout === 'desktop' && <br />}
                    {
                      flight.departure.segments[
                        flight.departure.segments.length - 1
                      ].AairportCity
                    }{' '}
                    <strong>
                      (
                      {
                        flight.departure.segments[
                          flight.departure.segments.length - 1
                        ].AairportIATA
                      }
                      )
                    </strong>
                  </AirportDepartures>
                </Airports>
              </Grid>
              <Grid item xs={4}>
                <FlightInfoTitle>{t('checkout@hours')}</FlightInfoTitle>
              </Grid>
              <Grid item xs={8}>
                <FlightInfoTitle>
                  {departureDateHour > 12
                    ? t('departureAfternoon')
                    : t('departureMorning')}
                </FlightInfoTitle>
              </Grid>
              <Grid item xs={4}>
                <FlightInfoTitle>{t('baggage')}</FlightInfoTitle>
              </Grid>
              <Grid item xs={8}>
                <BaggagetypeWrapper>
                  <Baggagetype>
                    <Icon
                      icon={'nueva-maleta-de-mano'}
                      size={14}
                      customStyle={{ marginRight: '4px' }}
                    />
                    {'1'} {t('handBaggage')}
                  </Baggagetype>
                  {numberBaggage > 0 && (
                    <Baggagetype>
                      <Icon
                        icon={'nueva-maleta-de-facturar'}
                        size={14}
                        customStyle={{ marginRight: '4px' }}
                      />
                      {numberBaggage} {t('checkinBaggage')}
                    </Baggagetype>
                  )}
                </BaggagetypeWrapper>
                {numberBaggage === 0 && (
                  <BaggageInfo
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'handBaggageNotIncludedContact',
                        'Additional luggage can be added after you have completed your booking.',
                      ),
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              style={{
                paddingTop: '8px',
                marginTop: '8px',
                borderTop: `1px dashed ${theme.colors.ferraGrayDark18}`,
              }}
            >
              <Grid item xs={4}>
                <FlightDate>{returnDate}</FlightDate>
              </Grid>
              <Grid item xs={8}>
                <Airports layout={layout}>
                  <AirportDepartures>
                    {flight.return.segments[0].DairportCity}{' '}
                    <strong>({flight.return.segments[0].DairportIATA})</strong>
                    {' - '}
                    {!minimalFlight && layout === 'desktop' && <br />}
                    {
                      flight.return.segments[flight.return.segments.length - 1]
                        .AairportCity
                    }{' '}
                    <strong>({departureCityIata})</strong>
                  </AirportDepartures>
                </Airports>
              </Grid>
              <Grid item xs={4}>
                <FlightInfoTitle>{t('checkout@hours')}</FlightInfoTitle>
              </Grid>
              <Grid item xs={8}>
                <FlightInfoTitle>
                  {returnDateHour > 12
                    ? t('departureAfternoon')
                    : t('departureMorning')}
                </FlightInfoTitle>
              </Grid>
              <Grid item xs={4}>
                <FlightInfoTitle>{t('baggage')}</FlightInfoTitle>
              </Grid>
              <Grid item xs={8}>
                <BaggagetypeWrapper>
                  <Baggagetype>
                    <Icon
                      icon={'nueva-maleta-de-mano'}
                      size={14}
                      customStyle={{ marginRight: '4px' }}
                    />
                    {'1'} {t('handBaggage')}
                  </Baggagetype>
                  {numberBaggage > 0 && (
                    <Baggagetype>
                      <Icon
                        icon={'nueva-maleta-de-facturar'}
                        size={14}
                        customStyle={{ marginRight: '4px' }}
                      />
                      {numberBaggage} {t('checkinBaggage')}
                    </Baggagetype>
                  )}
                </BaggagetypeWrapper>
                {numberBaggage === 0 && (
                  <BaggageInfo
                    className={responseTapClassName}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'handBaggageNotIncludedContact',
                        'For information and prices about checked or additional baggage, please call: {{phoneNumber}} after booking your trip.',
                        { phoneNumber: dedicatedPhoneNumber || phone },
                      ),
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
        )}
        {!minimalFlight && layout === 'desktop' && (
          <MoreInfoButton
            type="button"
            onClick={toggleDialog}
            toRight
            style={{ marginTop: '7px' }}
          >
            {t('plusInfo')}
          </MoreInfoButton>
        )}
        <FlightInfoDialog
          flight={flight}
          show={showDialog}
          toggle={toggleDialog}
        />
      </>
    );
  };

  return (
    <Fragment>
      <Flights layout={layout} small={small} minimalFlight={minimalFlight}>
        {withFlighSimulator && getMoreInfo()}
      </Flights>
    </Fragment>
  );
}

export default FlightDetails;
