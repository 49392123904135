import React, { useContext, useEffect } from 'react';
import posed from 'react-pose';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../utils';
import { LayoutContext } from '../../../context/LayoutContext';

interface OpenDialogWrapperProps {
  pose: string;
  onClick(): void;
}

const Animate = posed.div({
  closed: {
    color: '#FFFFFF',
    borderColor: '#FFFFFF',
    borderRadius: '2px',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  open: {
    color: '#2a2a2a',
    borderColor: '#544d4d',
    borderRadius: '2px',
    backgroundColor: '#ffffff',
  },
});

export const OpenDialogTabletWrapper = styled(Animate)`
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  position: relative;
  bottom: 0px;
  height: 55px;
  width: calc(15% - 30px);
  line-height: 55px;
  font-size: 0.875rem;
  text-align: center;
  margin: 0px 15px;
  padding: 0px 10px;
  text-transform: uppercase;
  vertical-align: middle;
`;

export const OpenDialogMobileWrapper = styled(OpenDialogTabletWrapper)`
  width: calc(33% - 9px);
  margin: 0px 12px 0px 0px;
`;

function OpenDialog(props: OpenDialogWrapperProps) {
  const { pose, onClick } = props;

  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);
  const layout = useContext(LayoutContext);

  let Wrapper;
  switch (layout) {
    case 'tablet':
      Wrapper = OpenDialogTabletWrapper;
      break;
    case 'mobile':
    default:
      Wrapper = OpenDialogMobileWrapper;
      break;
  }

  // TODO design is pending change here from + to some text
  return (
    <Wrapper pose={pose} onClick={onClick}>
      {pose === 'closed' ? t('show') : t('checkout@close')}
    </Wrapper>
  );
}

export default OpenDialog;
