import React, { useState } from 'react';

interface ModalProviderProps {
  children: any;
}

interface ContextObject {
  isShowing: boolean;
  setIsShowing: any;
  modalData: any;
  setModalData: any;
}

const initialContext: ContextObject = {
  isShowing: false,
  setIsShowing: () => {},
  modalData: {},
  setModalData: () => {},
};

const ModalContext = React.createContext(initialContext);

const ModalProvider = (props: ModalProviderProps) => {
  const [isShowing, setIsShowing] = useState(() => false);
  const [modalData, setModalData] = useState(() => ({}));

  return (
    <ModalContext.Provider
      value={{ isShowing, setIsShowing, modalData, setModalData }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};
export { ModalContext, ModalProvider };
