import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../utils';
import styled from 'styled-components/macro';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import Notifications from '../containers/Notifications';
import Menu from '../components/myaccount/Menu';
import SEOTags from '../components/ui/v2/SEOTags';

type Props = {
  children: any;
};

const MyAccountPage = styled.div`
  color: ${(props) => props.theme.colors.mineShaftDarker};
  background-color: ${(props) => props.theme.colors.alabaster};
  padding-top: 36px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding-top: 20px;
  }
`;

function MyAccountLayout(props: Props) {
  const { children } = props;
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const { path } = useParams();

  let title = t('meta@title@myAccount');
  switch (path) {
    case 'myorders':
      title = `${t('meta@title@myAccount@myorders')} - ${t(
        'meta@title@myAccount',
      )}`;
      break;
    case 'myprofile':
      title = `${t('meta@title@myAccount@myprofile')} - ${t(
        'meta@title@myAccount',
      )}`;
      break;
    case 'mysubscriptions':
      title = `${t('meta@title@myAccount@mysubscriptions')} - ${t(
        'meta@title@myAccount',
      )}`;
      break;
    case 'faq':
      title = `${t('meta@title@myAccount@faq')} - ${t('meta@title@myAccount')}`;
      break;
  }

  return (
    <div className="content-wrapper">
      <SEOTags title={title} />
      <Header isPrivate />
      <MyAccountPage>
        <Container maxWidth={'lg'}>
          <Grid container spacing={4}>
            <Hidden smDown>
              <Grid item md={3} sm={12}>
                <Menu activeSection={path} />
              </Grid>
            </Hidden>
            <Grid item md={9} sm={12} style={{ marginBottom: '70px' }}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </MyAccountPage>
      <Notifications />
      <Footer />
    </div>
  );
}

export default MyAccountLayout;
