import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import posed from 'react-pose';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../utils';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Modal from '../ui/Modal';
import Icon from '../ui/Icon';

export interface CoronaModalProps {
  pose: string;
  isVisible: boolean;
  onClose(): void;
}

const RightLeft = posed.div({
  right: { left: '100vw' },
  left: { left: '0px' },
});

export const Dialog = styled(RightLeft)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.whiteCreamy};
  z-index: 99;
  overflow: scroll;
`;

export const RightIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 1;
`;

const GridPadding = styled(Grid)`
  padding-left: 72px;
  padding-right: 72px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const MobileDialog = styled(Dialog)`
  z-index: 1100;
`;

const ModalContent = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: auto;
  scrollbar-width: none;
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.fontFamilyMedium};
  color: ${(props) => props.theme.colors.terracota};
  font-size: 2rem;
  line-height: 1.25;
  margin: 48px 0;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 32px 0;
  }
`;

const Text = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1rem;
  line-height: 1.19;
`;

const LegalTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.19;
  margin-bottom: 16px;
`;

const LegalList = styled.ul`
  font-family: ${(props) => props.theme.fontFamily};
  list-style: none;
`;

const LegalElement = styled.li`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1rem;
  line-height: 1.19;
  font-weight: 400;
  margin-bottom: 8px;

  &::before {
    content: '';
    font-family: icomoon;
    font-size: 8px;
    color: ${(props) => props.theme.colors.terracota};
    font-weight: bold;
    display: inline-block;
    width: 8px;
    margin-left: -24px;
    margin-right: 16px;
  }

  a {
    text-decoration: underline !important;
    color: ${(props) => props.theme.colors.regalBlue}!important;
  }
`;

const CoronaModal = (props: CoronaModalProps) => {
  const { pose, onClose } = props;

  const themeStyled = useContext(ThemeContext);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocaleFromUrl());
  }, [i18n]);

  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const content = (
    <>
      <ModalContent>
        <Container
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <GridPadding item xs={12}>
              <Title>{t('coronaModalTitle')}</Title>
            </GridPadding>
            <GridPadding
              container
              style={{
                marginBottom: xsDown ? '16px' : '32px',
              }}
            >
              <Grid
                item
                xs={12}
                sm={1}
                style={
                  xsDown ? { textAlign: 'center', marginBottom: '16px' } : {}
                }
              >
                <Icon
                  icon={'Refund-promo'}
                  size={xsDown ? '50' : '40'}
                  color={themeStyled.colors.terracota}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <Text
                  dangerouslySetInnerHTML={{ __html: t('coronaTextOne') }}
                />
              </Grid>
            </GridPadding>
            <GridPadding container>
              <Grid
                item
                xs={12}
                sm={1}
                style={
                  xsDown ? { textAlign: 'center', marginBottom: '16px' } : {}
                }
              >
                <Icon
                  icon={'Refund-promo'}
                  size={xsDown ? '50' : '40'}
                  color={themeStyled.colors.terracota}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <Text
                  dangerouslySetInnerHTML={{ __html: t('coronaTextTwo') }}
                />
              </Grid>
            </GridPadding>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: themeStyled.colors.cararra,
                marginTop: '48px',
                padding: xsDown ? '8px 16px 16px 16px' : '23px 72px 23px 72px',
                borderBottom: `8px solid ${themeStyled.colors.terracota}`,
              }}
            >
              <LegalTitle>{t('coronaLegalTitle')}</LegalTitle>
              <LegalList>
                <LegalElement
                  dangerouslySetInnerHTML={{
                    __html: t('coronaLegalElementThree'),
                  }}
                />
              </LegalList>
            </Grid>
          </Grid>
        </Container>
      </ModalContent>
    </>
  );

  if (xsDown) {
    return ReactDOM.createPortal(
      <MobileDialog pose={pose}>
        <RightIcon>
          <Icon
            color={themeStyled.colors.black}
            onClick={onClose}
            size={18}
            icon="ico-close"
          />
        </RightIcon>
        {content}
      </MobileDialog>,
      document.body,
    );
  }

  return (
    <Modal
      children={content}
      width={'85vw'}
      color={themeStyled.colors.black}
      {...props}
    />
  );
};

export default CoronaModal;
