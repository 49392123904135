export const initialCategoryState = {
  conditions: '',
  includes: [],
  itinerary: {
    map: {
      showMap: false,
      desktop: '',
      mobile: '',
      tablet: '',
    },
    pdf: '',
    days: [
      {
        image: '',
        title: '',
        day: '',
        description: '',
      },
    ],
  },
  notIncludes: '',
  activities: [],
  usefulInformation: [],
  transport: {
    airline: '',
    airplaneImages: [''],
    legs: [{}],
    logo: '',
    logoHorizontal: '',
    notes: '',
    type: '',
  },
  accommodation: [],
  conditionsBlock: '',
};
const category = (state = initialCategoryState, action) => {
  const { data, type, payload } = action;

  switch (type) {
    case 'SET_CATEGORY_DATA':
      return {
        ...initialCategoryState,
        ...data,
        conditionsBlock: state.conditionsBlock,
      };
    case 'RECEIVED_OFFER_CONDITIONS_BLOCK':
      return {
        ...state,
        conditionsBlock: payload.content,
      };
    default:
      return state;
  }
};

export default category;
