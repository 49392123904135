import React from 'react';
import styled from 'styled-components/macro';

type StyledProps = {
  bold?: boolean;
  fontSize?: string;
  color?: string;
};

const StyledLabel = styled.label<StyledProps>`
  display: block;
  color: ${(props) => props.theme.colors[props.color || 'mineShaftDarker']};
  font-size: ${(props) => (props.fontSize ? props.fontSize : 'inherit')};
  font-weight: ${(props) => (props.bold ? '500' : 'unset')};
`;

const Label = ({ ...props }) => <StyledLabel {...props} />;

export default Label;
