import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components/macro';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { iconAffiliate } from '../../utils';

import Icon from '../ui/Icon';

type Props = {
  affiliateId: string;
  language?: string;
  dealOfTheWeek?: string;
  promotion?: any;
};

const IconWrapper = styled.div`
  max-width: 300px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    margin-top: unset !important;
    margin-left: 24px;
    margin-right: 44px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    margin-right: 110px;
  }
`;

function Logo({ affiliateId, language, dealOfTheWeek, promotion }: Props) {
  const themeStyled = useContext(ThemeContext);
  const theme = useTheme();

  const xxsDown = useMediaQuery('(max-width:320px)');
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const { icon, color, width, height, marginTop, iconSet } = iconAffiliate(
    parseInt(affiliateId),
    language,
  );

  return (
    <>
      <IconWrapper
        style={{
          marginTop: marginTop,
        }}
      >
        <Icon
          icon={lgUp ? icon[0] : smUp ? icon[1] : icon[2]}
          iconSet={iconSet}
          color={color && themeStyled.colors[color]}
          customStyle={{
            width:
              smDown && (dealOfTheWeek || promotion)
                ? xxsDown
                  ? '120px'
                  : '160px'
                : width,
            height: height,
          }}
        />
      </IconWrapper>
    </>
  );
}

export default Logo;
